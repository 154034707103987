/* eslint-disable no-nested-ternary */
// @flow
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';
import { get } from 'lodash';
import CheckCircle from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import Table from '../../common/Table';
import Row, { ClientName, ClientId, Td } from '../../../../common/components/table/TableRow';
import Tooltip from '../../../../common/components/tooltip/Tooltip';
import type { Hierarchy } from '../../../../common/types/table';
import type { SignerInfo } from '../../../types/signer';
import { dataMappingToDropdown } from '../../../../common/utils/helper';
import RollForwardFinished from '../jobs/hints/RollForwardFinished';
import JobSelection from './JobSelection';
import ClientSelectionHint from './hints/ClientSelection';

const headers = [
  'Client Name and ID',
  'Financial Year End',
  'Prior Year Job Name',
  'Engagement Leader',
  'Engagement Manager'
];

export type Props = {
  audits: Hierarchy,
  deselectedHeadClient: Function,
  deselectedChild: Function,
  selectClient: Function,
  selectClientPartially: Function,
  selectedSubsidiary: Function,
  toggleExpandSubsidiary: Function,
  toggleExpand: Function,
  changeJobs: Function,
  changeChildJobs: Function,
  selectedSigner: Map<string, SignerInfo>
};

export const FlexCell = styled.div`
  display: inline-flex;
`;

class ClientsTable extends PureComponent<Props> {
  componentDidMount() {
    const { audits, selectedSigner, toggleExpand } = this.props;
    const selectedClientIds = selectedSigner.map(signer => signer.id);
    audits.forEach(audit =>
      audit.getIn(['datum', 'children']).forEach(child => {
        const childId = child.getIn(['datum', 'id']);
        // *Shallow comparison needed
        // eslint-disable-next-line eqeqeq
        const isChildSelected = selectedClientIds.some(id => id == childId);
        if (isChildSelected && !audit.get('expanded')) {
          toggleExpand(audit.getIn(['datum', 'id']));
        }
      })
    );
  }

  hasAvailableJobs = (list: Array<{ isRollForwarded: boolean }>) =>
    list.length > 0 && list.some(job => !job.isRollForwarded);

  render() {
    const {
      audits,
      selectClient,
      selectClientPartially,
      selectedSubsidiary,
      deselectedHeadClient,
      deselectedChild,
      toggleExpandSubsidiary,
      toggleExpand,
      changeJobs,
      changeChildJobs
    } = this.props;

    if (audits.size === 0) return <ClientSelectionHint />;
    return (
      <Table
        data={audits}
        getKey={datum => datum.getIn(['datum', 'id'])}
        getParentKey={datum => datum.getIn(['datum', 'headClientId'])}
        getChildList={datum => datum.getIn(['datum', 'children'])}
        getSubsidiariesStatus={datum =>
          datum.getIn(['datum', 'children']) && datum.getIn(['datum', 'children']).size > 0
        }
        getChildren={datum => get(datum, ['children'])}
        hasEmptyJobList={datum => datum.some(child => !child.getIn(['datum', 'jobList']))}
        getMoreData={toggleExpand}
        toggleExpandSubsidiary={toggleExpandSubsidiary}
        toggleExpand={toggleExpand}
        selectClient={selectClient}
        selectClientPartially={selectClientPartially}
        selectedSubsidiary={selectedSubsidiary}
        deselectedHeadClient={deselectedHeadClient}
        deselectedChild={deselectedChild}
        isAllSelectedEnabled={false}
        headers={headers}
      >
        {({ key, datum, expanded, hasChildren, Actions, children }) => (
          <Fragment key={key}>
            <Row data-cy="parent-row" hasChildren={hasChildren}>
              <Actions />
              <Td>
                <ClientName>{datum.name}</ClientName>
                <ClientId>{datum.businessId}</ClientId>
              </Td>
              <Td>{moment.months(datum.periodEndMonth)}</Td>
              <Td>
                {datum.jobId ? (
                  <FlexCell>
                    <JobSelection
                      jobName={datum.jobName}
                      jobId={datum.jobId}
                      fetchData={() =>
                        Promise.resolve(
                          dataMappingToDropdown(
                            datum.jobList.map(item => ({ ...item, headClientId: key }))
                          )
                        )
                      }
                      changeJobs={changeJobs}
                    />
                    {datum.hasSubsidiaries && datum.isRollForwarded && (
                      <Tooltip success>
                        <div>
                          <CheckCircle />
                          <RollForwardFinished />
                        </div>
                      </Tooltip>
                    )}
                  </FlexCell>
                ) : (
                  'No available job found'
                )}
              </Td>
              <Td>{datum.engagementLeader}</Td>
              <Td>{datum.engagementManager}</Td>
              <Td noPadding />
            </Row>
            {expanded &&
              children.size > 0 &&
              // show job
              children.map(({ key: childKey, datum: childDatum, Actions: ChildAction }) => (
                <Row data-cy="child-row" key={childKey} isChildren>
                  <ChildAction />
                  <Td>
                    <ClientName isChildren>{childDatum.name}</ClientName>
                    <ClientId>{childDatum.businessId}</ClientId>
                  </Td>
                  <Td>{moment.months(childDatum.periodEndMonth)}</Td>
                  <Td>
                    <JobSelection
                      jobName={childDatum.jobName}
                      jobId={childDatum.jobId}
                      fetchData={() =>
                        Promise.resolve(
                          dataMappingToDropdown(
                            childDatum.jobList.map(item => ({
                              ...item,
                              headClientId: key
                            }))
                          )
                        )
                      }
                      changeJobs={changeChildJobs}
                    />
                  </Td>
                  <Td>{childDatum.engagementLeader}</Td>
                  <Td>{childDatum.engagementManager}</Td>
                  <Td noPadding />
                </Row>
              ))}
          </Fragment>
        )}
      </Table>
    );
  }
}

export default ClientsTable;
