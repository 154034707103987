// @flow
import React from 'react';
import { connect } from 'react-redux';

import {
  type TaxReturn,
  type TaxReturnData,
  type UserRole,
  taxReturnStates,
  userRoles
} from '../../../types';
import Send from './SideBarButtons/Send';
import Reject from './SideBarButtons/Reject';
import Approve from './SideBarButtons/Approve';
import InProgress from './SideBarButtons/InProgress';
import { sendForReview, approveTaxReturn, rejectTaxReturn } from '../../../duck/TaxReturnPage';

interface StateProps {
  taxReturn: TaxReturn;
  taxReturnData: TaxReturnData;
  unsavedChanges: boolean;
  userRole: UserRole;
}

interface DispatchProps {
  sendForReview: Function;
  approveTaxReturn: Function;
  rejectTaxReturn: Function;
}

type Props = StateProps & DispatchProps;

/**
 * This component renders one of four buttons:
 * - Send for Review
 * - In Progress
 * - Approve
 * - Reject
 *
 * For Preparers, the only button with an action available to them is the Send for review button.
 * This is available, when the tax return is in the draft state and all changes have been saved.
 * Should the tax return be anything else, they will see a disabled button indicating the current
 * tax return state.
 *
 * Reviewers have two buttons with actions they can use: Approve and Reject. These are available
 * for them IF AND ONLY IF the tax return state is 'Sent for Review', and the tax return has been
 * either pre-approved or pre-rejected respectively. This happens in the backend when either all
 * tax return sections have been approved, or any of them has been rejected. In any other case,
 * the reviewer will see a disabled button indicating the current state of the tax return.
 */
const SideBarAction = ({
  taxReturn,
  taxReturnData,
  unsavedChanges,
  userRole,
  ...dispatchProps
}: Props) => {
  const { name: currentState } = taxReturn.state;

  const renderButton = () => {
    switch (currentState) {
      case taxReturnStates.DRAFT:
        return userRole === userRoles.PREPARER ? (
          <Send onClick={() => dispatchProps.sendForReview(parseInt(taxReturn.id, 10))} />
        ) : (
          <InProgress state={taxReturnStates.DRAFT} />
        );
      case taxReturnStates.SENT_FOR_REVIEW:
        if (userRole === userRoles.REVIEWER) {
          if (taxReturnData.get('approved')) {
            return (
              <Approve onClick={() => dispatchProps.approveTaxReturn(parseInt(taxReturn.id, 10))} />
            );
          } else if (taxReturnData.get('rejected')) {
            return (
              <Reject onClick={() => dispatchProps.rejectTaxReturn(parseInt(taxReturn.id, 10))} />
            );
          }
        }
        return <InProgress state={taxReturnStates.SENT_FOR_REVIEW} />;
      case taxReturnStates.APPROVED:
        return <Approve onClick={() => {}} />;
      case taxReturnStates.REJECTED:
        if (userRole === userRoles.PREPARER) {
          return <Send onClick={() => dispatchProps.sendForReview(parseInt(taxReturn.id, 10))} />;
        }
        return <Reject onClick={() => {}} />;
      default:
        return null;
    }
  };

  return renderButton();
};

const mapStateToProps = state => {
  const {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    userRole,
    loading
  } = state.veromylly.taxReturnPage;
  return {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    userRole,
    loading
  };
};

const dispatchProps: DispatchProps = {
  sendForReview,
  approveTaxReturn,
  rejectTaxReturn
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SideBarAction);
