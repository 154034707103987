// @flow
import React, { useState, useEffect } from 'react';
import * as Joi from 'joi-browser';
import Redo from '@material-ui/icons/Redo';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import type { KycRoundPut, KycStaticInformation } from '../../../../common/types/kyc';
import type { OpportunityQuery } from '../../../../common/types/opportunity';
import HeaderArea from './HeaderArea';
import ContentArea from './ContentArea';
import InfoLabel from '../../../../common/components/InfoLabel';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import MessageBar from '../../../../common/components/workflow/MessageBar';
import { mapJoiErrorsToValidationMessages, allGroundsForUbo } from '../../../utils/helper';

type OpportunityProps = {
  isLoading: boolean,
  isLoadingModal: boolean,
  onClickSave: Function,
  moveToStep: Function,
  updateUboStatus: Function,
  updateRepresentativeStatus: Function,
  deselectAllUbos: Function,
  changeGroundForUbo: Function,
  changeGroundForService: Function,
  putContactPersons: Function,
  postContactPerson: Function,
  kycRound: KycRoundPut,
  kycStaticInformation: KycStaticInformation,
  changeContactInfoName: Function,
  changeContactInfoEmail: Function,
  changeContactInfoLanguage: Function,
  showContactInformation: boolean,
  didReceiveGroundForService: boolean,
  setUboChanged: Function,
  setRiskChanged: Function,
  setAmlChanged: Function,
  setAdditionalInfoMessage: Function,
  putKycRound: Function,
  getOpportunities: Function,
  opportunitiesSearchQuery: OpportunityQuery
};

type Props = StepProps & OpportunityProps;

const clientContactInformationSchema = showContactInformation =>
  showContactInformation
    ? Joi.object({
        name: Joi.string().required(),
        email: Joi.string()
          .email({ minDomainAtoms: 2 })
          .required(),
        language: Joi.number()
          .integer()
          .required()
      })
    : Joi.object({
        name: Joi.allow(null),
        email: Joi.allow(null),
        language: Joi.allow(null)
      });

const additionalInformationSchema = isMessageRequired =>
  Joi.object({
    isUboChanged: Joi.boolean().required(),
    isRiskChanged: Joi.boolean().required(),
    isAmlChanged: Joi.boolean().required(),
    message: isMessageRequired ? Joi.string().required() : Joi.allow(null)
  });

const kycRoundSchema = (showContactInformation, isMessageRequired) =>
  Joi.object().keys({
    groundForService: Joi.string().required(),
    groundForUbo: Joi.number()
      .integer()
      .valid(allGroundsForUbo.map(groundForUbo => groundForUbo.value))
      .required(),
    ubos: Joi.alternatives().when('groundForUbo', {
      is: 7,
      then: Joi.allow(null),
      otherwise: Joi.alternatives().when('additionalInformation.isUboChanged', {
        is: true,
        then: Joi.allow(null),
        otherwise: Joi.array()
          .min(1)
          .required()
      })
    }),
    representatives: Joi.array()
      .min(1)
      .required(),
    clientContactInformation: clientContactInformationSchema(showContactInformation),
    additionalInformation: additionalInformationSchema(isMessageRequired)
  });

export default function Opportunities({
  theme,
  active,
  done,
  pending,
  isLoading,
  isLoadingModal,
  onClickSave,
  onClickEdit,
  moveToStep,
  updateUboStatus,
  updateRepresentativeStatus,
  deselectAllUbos,
  changeGroundForUbo,
  changeGroundForService,
  putContactPersons,
  postContactPerson,
  kycRound,
  kycStaticInformation,
  changeContactInfoName,
  changeContactInfoEmail,
  changeContactInfoLanguage,
  showContactInformation,
  didReceiveGroundForService,
  setUboChanged,
  setRiskChanged,
  setAmlChanged,
  setAdditionalInfoMessage,
  putKycRound,
  getOpportunities,
  opportunitiesSearchQuery
}: Props) {
  const [isAmlCheckDone, setIsAmlCheckDone] = useState(false);

  useEffect(() => {
    if (active) window.scrollTo(0, 0);
  }, [active]);

  const {
    clientId,
    opportunityId,
    clientName,
    opportunityName,
    amlClassification,
    legalRegulatoryType,
    isPepClient
  } = kycStaticInformation;

  const { groundForService, groundForUbo, ubos, representatives, additionalInformation } = kycRound;
  const { isUboChanged, isRiskChanged, isAmlChanged } = additionalInformation || {
    isUboChanged: false,
    isRiskChanged: false,
    isAmlChanged: false
  };

  const joiValidation = Joi.validate(
    {
      ...kycRound,
      ubos: ubos && ubos.filter(ubo => ubo.isActive),
      representatives:
        representatives && representatives.filter(representative => representative.isActive)
    },
    kycRoundSchema(showContactInformation, isUboChanged || isRiskChanged || isAmlChanged),
    { abortEarly: false }
  );

  const validationErrorMessages =
    joiValidation.error && mapJoiErrorsToValidationMessages(joiValidation.error.details);

  return (
    <>
      <Step
        title="Opportunities"
        subtitle="Please fill in and submit the AML Check below"
        Icon={Redo}
        theme={theme}
        active={active}
        done={done}
        pending={pending}
        stepSummaryItems={[{ title: '', text: '' }]}
        onClickEdit={onClickEdit}
      >
        <HeaderArea
          clientName={clientName}
          opportunityName={opportunityName}
          amlClassification={amlClassification}
          legalRegulatoryType={legalRegulatoryType}
          isPepClient={isPepClient}
        />
        <InfoLabel text="Please note that you can only submit the information, it is not possible to save and continue later." />
        <ContentArea
          groundForService={groundForService}
          groundForUbo={groundForUbo}
          ubos={ubos}
          representatives={representatives}
          updateUboStatus={updateUboStatus}
          updateRepresentativeStatus={updateRepresentativeStatus}
          deselectAllUbos={deselectAllUbos}
          changeGroundForUbo={changeGroundForUbo}
          changeGroundForService={changeGroundForService}
          putContactPersons={putContactPersons}
          postContactPerson={postContactPerson}
          isLoadingModal={isLoadingModal}
          clientId={clientId}
          changeContactInfoName={changeContactInfoName}
          changeContactInfoEmail={changeContactInfoEmail}
          changeContactInfoLanguage={changeContactInfoLanguage}
          kycRound={kycRound}
          didReceiveGroundForService={didReceiveGroundForService}
          showContactInformation={showContactInformation}
          setUboChanged={setUboChanged}
          setRiskChanged={setRiskChanged}
          setAmlChanged={setAmlChanged}
          setAdditionalInfoMessage={setAdditionalInfoMessage}
          validationErrorMessages={validationErrorMessages || {}}
        />
        <ActionBar
          status="Submit AML Check"
          isLoading={isLoading}
          proceed={{
            name: 'Submit',
            callback: async () => {
              await putKycRound({ clientId, opportunityId }, kycRound);
              onClickSave();
              setIsAmlCheckDone(true);
              await getOpportunities(opportunitiesSearchQuery);
              moveToStep(1);
              setTimeout(() => setIsAmlCheckDone(false), 5000);
            },
            isDisabled:
              isAmlCheckDone ||
              Joi.validate(
                {
                  ...kycRound,
                  ubos: ubos && ubos.filter(ubo => ubo.isActive),
                  representatives:
                    representatives &&
                    representatives.filter(representative => representative.isActive)
                },
                kycRoundSchema(
                  showContactInformation,
                  isUboChanged || isRiskChanged || isAmlChanged
                ),
                { abortEarly: false }
              ).error
          }}
        />
      </Step>
      <MessageBar
        type="success"
        status={isAmlCheckDone}
        onClose={() => null}
        message={`The AML check for ${clientName} has been submitted successfully`}
      />
    </>
  );
}
