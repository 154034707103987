// @flow
import { Record, List, type List as ListType, type RecordOf } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { Signer, SelectedSigner } from '../types/signer';
import type { ActionType } from '../../common/types/redux';
import { type SimpleDropdownItem } from '../../common/types/list';

type DefaultState = RecordOf<{
  signerList: ListType<Signer>,
  selectedTarget: ListType<SelectedSigner>,
  latestSelectionTarget: SelectedSigner,
  selectedFinancialYearEnd: Array<SimpleDropdownItem>,
  latestSelectionFinancialYearEnd: SimpleDropdownItem
}>;

const defaultState = Record({
  signerList: List(),
  selectedTarget: List(),
  selectedFinancialYearEnd: [],
  latestSelectionTarget: null,
  latestSelectionFinancialYearEnd: null
});

export const getSignerList = createAction('GET_SINGER_LIST', () => apiService.getSigner());
export const selectTarget = createAction('SELECT_TARGET', target => target);
export const deselectTarget = createAction('DESELECT_TARGET', target => target);
export const selectFinancialYearEnd = createAction('SELECT_FINANCIAL_YEAR_END', month => month);
export const deselectFinancialYearEnd = createAction('DESELECT_FINANCIAL_YEAR_END', month => month);
export const resetSelectTarget = createAction('RESET_SELECT_TARGET');
export default function signerReducer(state: DefaultState = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'SELECT_TARGET':
      return state
        .updateIn(['selectedTarget'], list => list.concat(payload))
        .set('latestSelectionTarget', payload);

    case 'DESELECT_TARGET': {
      const updatedList = state.selectedTarget.filter(i => i.id !== payload.id);
      return updatedList.size
        ? state.set('selectedTarget', updatedList).set('latestSelectionTarget', null)
        : state
            .set('selectedTarget', updatedList)
            .set('latestSelectionTarget', null)
            .set('selectedFinancialYearEnd', [])
            .set('latestSelectionFinancialYearEnd', null);
    }
    case 'RESET_SELECT_TARGET': {
      return state
        .set('selectedTarget', List())
        .set('latestSelectionTarget', null)
        .set('selectedFinancialYearEnd', [])
        .set('latestSelectionFinancialYearEnd', null);
    }
    case 'SELECT_FINANCIAL_YEAR_END':
      return state
        .updateIn(['selectedFinancialYearEnd'], list => list.concat(payload))
        .set('latestSelectionFinancialYearEnd', payload);

    case 'DESELECT_FINANCIAL_YEAR_END':
      return state
        .updateIn(['selectedFinancialYearEnd'], list => list.filter(i => i.value !== payload.value))
        .set('latestSelectionFinancialYearEnd', null);

    case 'GET_SINGER_LIST_FULFILLED':
      return state.set('signerList', payload);

    case 'RESET_ROLLFORWARD':
      return defaultState();

    default:
      return state;
  }
}
