// @flow
import React from 'react';
import styled from 'styled-components';
import Warning from '@material-ui/icons/Warning';
import TextLabel from './TextLabel';

const FlexTextLabel = styled(TextLabel)`
  && {
    display: flex;
    p {
      margin: ${props => `auto 0 auto ${props.theme.space.single.xs}`};
    }
  }
`;

const InfoLabel = ({ text }: { text: string }) => (
  <FlexTextLabel>
    <Warning />
    <p>{text}</p>
  </FlexTextLabel>
);

export default InfoLabel;
