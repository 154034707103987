import { connect } from 'react-redux';
import SummaryPage from './SummaryPage';
import { resetServiceOrder } from '../duck/orders';

export default connect(
  state => ({
    summary: state.serviceOrder.orders.list.filter(order => order.isSelected)
  }),
  { resetServiceOrder }
)(SummaryPage);
