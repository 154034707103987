// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button, { GlowButton } from '../../common/components/Button';
import { getDiagnosis as getDiagnosisConnect } from '../duck/auditRFDiagnostic';
import CommonTable from '../../common/components/react-table/CommonTable';
import { type RollForwardDiagnosis } from '../types';
import LoadingBar from '../../common/components/workflow/LoadingBar';

type Props = {
  rollforwardId: string,
  diagnosis?: RollForwardDiagnosis,
  getDiagnosis: (rollforwardId: string) => {}
};

type RowItem = 'Opportunity' | 'Risk' | 'Job' | 'Aura' | 'Aura link' | 'Bank confirmation';
type StatusRow = {
  item: RowItem,
  status: string,
  attemptCount: number,
  diagnosis: string,
  measure: string
};

const getStatusRows = (diagnosis): StatusRow[] => [
  { ...diagnosis.opportunityDiagnosis, item: 'Opportunity' },
  { ...diagnosis.riskDiagnosis, item: 'Risk' },
  { ...diagnosis.jobDiagnosis, item: 'Job' },
  { ...diagnosis.bankConfirmationDiagnosis, item: 'Bank confirmation' },
  { ...diagnosis.auraDiagnosis, item: 'Aura' },
  { ...diagnosis.auraLinkDiagnosis, item: 'Aura link' }
];

const DiagnosisView = ({ rollforwardId, diagnosis, getDiagnosis }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchDiagnosis = async (id: string) => {
      setIsLoading(true);
      await getDiagnosis(id);
      setIsLoading(false);
    };

    fetchDiagnosis(rollforwardId);
  }, [getDiagnosis, rollforwardId]);

  const navigateWithoutHistory = (id?: string) => {
    if (id) history.replace(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Roll Forward',
        accessor: 'item'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Attempt count',
        accessor: 'attemptCount'
      },
      {
        Header: 'Diagnosis',
        accessor: 'diagnosis'
      },
      {
        Header: 'Measure',
        accessor: 'measure'
      }
    ],
    []
  );

  return (
    <div>
      <h1>
        Roll Forward Diagnosis
        <Button style={{ float: 'right' }} primary solid rounded onClick={() => history.goBack()}>
          Back
        </Button>
      </h1>

      <h2>1. General information</h2>
      <h3>{`Roll Forward ID: ${rollforwardId}`}</h3>

      {!diagnosis || isLoading ? (
        <>
          <h2>Diagnosing...</h2>
          <LoadingBar />
        </>
      ) : (
        <div>
          {/* Client info */}
          <h3>{`Client: ${diagnosis.clientName} - ${diagnosis.clientId}`}</h3>
          {/* Parent RollForward info */}
          {diagnosis.parentRollforward && (
            <>
              <h3>Parent Roll Forward:&nbsp;</h3>
              <GlowButton
                onClick={() =>
                  navigateWithoutHistory(
                    diagnosis.parentRollforward && diagnosis.parentRollforward.id
                  )
                }
              >
                {`${diagnosis.clientName} - ${diagnosis.clientId}`}
              </GlowButton>
            </>
          )}
          {/* Child RollForward info */}
          {diagnosis.childRollforwards && diagnosis.childRollforwards.length > 0 && (
            <>
              <h3>{`Child Roll Forwards (${diagnosis.childRollforwards.length}):`}</h3>
              <Grid container direction="column">
                <Grid container direction="row">
                  {diagnosis.childRollforwards.map(child => (
                    <Grid item key={child.id}>
                      <GlowButton onClick={() => navigateWithoutHistory(child.id)}>
                        {`${child.clientName} - ${child.clientId}`}
                      </GlowButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          <h2>2. Status</h2>
          <CommonTable columns={columns} data={getStatusRows(diagnosis)} />
          <ul>
            <li>
              {`Please use this `}
              <a href="https://wwwpwcnetwork.pwc.myshn.net/hub/?id=sc_cat_item&sys_id=cb6d800bdb90eb0084055ad6dc9619a8&channel=6b565cdddba16384a416f5261d9619ec">
                link
              </a>
              {` to contact the dev team if further investigation is required`}
            </li>
            <li>
              In order to start processing the request you must include the whole description of the
              problem and the Roll Forward ID all in English.
            </li>
          </ul>
          <h2>3. Created items</h2>
          {diagnosis.opportunityDiagnosis.salesforceOpportunity && (
            <div>
              <h3>Salesforce Opportunity</h3>
              <ul>
                <li>{`ID: ${diagnosis.opportunityDiagnosis.salesforceOpportunity.id}`}</li>
                <li>{`Name: ${diagnosis.opportunityDiagnosis.salesforceOpportunity.name}`}</li>
                <li>
                  {`Stage: ${diagnosis.opportunityDiagnosis.salesforceOpportunity.stageName}`}
                </li>
                <li>
                  <a href={diagnosis.opportunityDiagnosis.salesforceOpportunity.link}>Link</a>
                </li>
              </ul>
            </div>
          )}
          {diagnosis.opportunityDiagnosis.ipowerOpportunity && (
            <div>
              <h3>IPower Opportunity</h3>
              <ul>
                <li>{`ID: ${diagnosis.opportunityDiagnosis.ipowerOpportunity.id}`}</li>
                <li>{`Name: ${diagnosis.opportunityDiagnosis.ipowerOpportunity.name}`}</li>
                <li>
                  {`Create timestamp: ${moment(
                    diagnosis.opportunityDiagnosis.ipowerOpportunity.createTimestamp
                  ).format('LLLL')}`}
                </li>
                <li>
                  {`Status code: ${diagnosis.opportunityDiagnosis.ipowerOpportunity.statusCode}`}
                </li>
                <li>
                  <a href={diagnosis.opportunityDiagnosis.ipowerOpportunity.link}>Link</a>
                </li>
              </ul>
            </div>
          )}
          {diagnosis.riskDiagnosis.risk && (
            <div>
              <h3>IPower Risk</h3>
              <ul>
                <li>{`ID: ${diagnosis.riskDiagnosis.risk.id}`}</li>
                <li>
                  {`Create timestamp: ${moment(diagnosis.riskDiagnosis.risk.createTimestamp).format(
                    'LLLL'
                  )}`}
                </li>
                <li>{`Status code: ${diagnosis.riskDiagnosis.risk.statusCode}`}</li>
              </ul>
            </div>
          )}

          {diagnosis.jobDiagnosis.job && (
            <div>
              <h3>IPower Job</h3>
              <ul>
                <li>{`ID: ${diagnosis.jobDiagnosis.job.id}`}</li>
                <li>{`Name: ${diagnosis.jobDiagnosis.job.name}`}</li>
                <li>
                  {`Create timestamp: ${moment(diagnosis.jobDiagnosis.job.createTimestamp).format(
                    'LLLL'
                  )}`}
                </li>
              </ul>
            </div>
          )}
          {diagnosis.auraDiagnosis.auraDatabaseId && (
            <div>
              <h3>Aura Database</h3>
              <ul>
                <li>{`ID: ${diagnosis.auraDiagnosis.auraDatabaseId}`}</li>
              </ul>
            </div>
          )}
          {diagnosis.bankConfirmationDiagnosis.bankConfirmation && (
            <div>
              <h3>Bank confirmation service order</h3>
              <ul>
                <li>{`ID: ${diagnosis.bankConfirmationDiagnosis.bankConfirmation
                  .newBankConfirmationId || 'not found'}`}</li>
                <li>{`Status: ${diagnosis.bankConfirmationDiagnosis.status || 'not found'}`}</li>
                <li>
                  {`Create timestamp: ${moment(
                    diagnosis.bankConfirmationDiagnosis.bankConfirmation.createTimestamp
                  ).format('LLLL')}`}
                </li>
                <li>
                  <a
                    href={
                      diagnosis.bankConfirmationDiagnosis.bankConfirmation
                        ? diagnosis.bankConfirmationDiagnosis.bankConfirmation.link
                        : '#'
                    }
                  >
                    Link
                  </a>
                </li>
              </ul>
            </div>
          )}
          <h2>4. Roll-forward logs</h2>
          <div>
            <ul>
              {diagnosis.rollforwardLogs.map((log, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{`[${moment(log.createTimestamp).format('LLLL')}] ${
                  log.message
                }`}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    diagnosis: state.auditRFDiagnostic.diagnosis
  }),
  {
    getDiagnosis: getDiagnosisConnect
  }
)(DiagnosisView);
