/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import { type ActivityLogRow } from '../../../types/activityLog';
import RowLeft from './row-left/RowLeft';
import RowRight from './row-right/RowRight';

type Props = {
  data: ActivityLogRow,
  setAuraDatabase: Function,
  isSubsidiary?: boolean
};

const Wrapper = styled.div`
  height: 180px;
  font-size: ${props => props.theme.fonts.size.body.m};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.isSubsidiary ? 0 : '30px')};
`;
const SubWrapper = styled.div`
  width: ${props => (props.left ? (props.isSubsidiary ? '29%' : '30%') : '70%')};
  height: ${props => (props.left ? '100%' : '90%')};
  margin-left: ${props => (props.isSubsidiary ? '1%' : 0)};
  background: ${props =>
    props.left
      ? props.isSubsidiary
        ? props.theme.color.ui.light
        : props.theme.color.ui.primary
      : props.theme.color.background.white};
  color: ${props =>
    props.left
      ? props.isSubsidiary
        ? props.theme.colors.gray['600']
        : props.theme.color.text.light
      : props.theme.color.text.neutral};
  border: solid 3px
    ${props => (props.left ? props.theme.color.ui.primary : props.theme.color.background.white)};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.theme.border.type.round};
  padding: ${props =>
    props.left ? `${props.theme.space.single.m} ${props.theme.space.single.l}` : 0};
`;

const HeadClientConnector = styled.div`
  height: 1em;
  width: 0.5em;
  position: relative;
  left: calc(1% + 5px);
  background: ${props => props.theme.color.ui.primary};
`;

const Row = ({ data, isSubsidiary, setAuraDatabase }: Props) => (
  <>
    {isSubsidiary && <HeadClientConnector />}
    <Wrapper isSubsidiary={isSubsidiary}>
      <SubWrapper left isSubsidiary={isSubsidiary}>
        <RowLeft data={data} isSubsidiary={isSubsidiary} />
      </SubWrapper>
      <SubWrapper>
        <RowRight data={data} setAuraDatabase={setAuraDatabase} />
      </SubWrapper>
    </Wrapper>
  </>
);

Row.defaultProps = {
  isSubsidiary: false
};

export default Row;
