// @flow
import React from 'react';
import { connect } from 'react-redux';
import ActivityLog from './components/ActivityLog';
import {
  getActivityLogList,
  getDataset,
  setDatasetLoading,
  deleteDatasetGroup,
  createServiceOrder
} from '../../duck/activity';

const ActivityLogContainer = (props: any) => {
  const { history } = props;

  const goToAccountMapping = (datasetGroupId: string) => {
    history.push(`/general-ledger/account-mapping?datasetGroupId=${datasetGroupId}`);
  };
  const goToGeneralLedger = () => {
    history.push(`/general-ledger`);
  };

  return (
    <ActivityLog
      {...props}
      goToAccountMapping={goToAccountMapping}
      goToGeneralLedger={goToGeneralLedger}
    />
  );
};

export default connect(
  state => ({
    activityLogList: state.activityGL.get('activityLogList'),
    isLoading: state.activityGL.get('isActivityLogLoading')
  }),
  { getActivityLogList, getDataset, setDatasetLoading, deleteDatasetGroup, createServiceOrder }
)(ActivityLogContainer);
