// @flow
import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import { pickBy, identity } from 'lodash';
import PersonPin from '@material-ui/icons/PersonPin';
import Assignment from '@material-ui/icons/Assignment';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import LeaderOrClientDropdown, {
  searchTypeList
} from '../../../../common/components/LeaderOrClientDropdown';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import { SearchSelection } from '../../../../common/components/selectors';
import ExtraSpace from '../../../../common/components/ExtraSpace';
import SearchBoxContainer from '../../../../common/components/SearchBoxContainer';
import TextLabel from '../../../../common/components/TextLabel';
import Expander from '../../../../common/components/Expander';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import { dataMappingToDropdown, mapToSelectionItem } from '../../../../common/utils/helper';
import service from '../../../services/api';
import { type LosList } from '../../../../common/types/los';
import { type OpportunityQuery } from '../../../../common/types/opportunity';
import { type Employee } from '../../../../common/types/employee';
import { type Client } from '../../../../common/types/client';
import { type SimpleDropdownItem } from '../../../../common/types/list';
import OnboardingInstruction from '../../../instructions/OnboardingInstructions';

type LeadersProps = {
  lineOfServices: LosList,
  isLoading: boolean,
  isOnboardingFinished: boolean,
  getOnboardingStatus: Function,
  setOnboardingStatus: Function,
  onClickSave: Function,
  getOpportunities: Function,
  getLineOfServices: Function
};

type Props = StepProps & LeadersProps;

export default function Leader({
  lineOfServices,
  theme,
  done,
  active,
  pending,
  isLoading,
  isOnboardingFinished,
  getOnboardingStatus,
  setOnboardingStatus,
  onClickSave,
  onClickEdit,
  getOpportunities,
  getLineOfServices
}: Props) {
  const [searchType, setSearchType] = useState<SimpleDropdownItem>(searchTypeList[0]);
  const [selectedTarget, setSelectedTarget] = useState<Employee | Client | null>(null);
  const [selectedLos, setSelectedLos] = useState<SimpleDropdownItem | null>(null);

  useEffect(() => {
    if (active) window.scrollTo(0, 0);
  }, [active]);

  useEffect(() => {
    getOnboardingStatus();
    getLineOfServices();
  }, [getOnboardingStatus, getLineOfServices]);

  const fetchOpportunities = async () => {
    const query = {
      [`${searchType.value === 1 ? 'employeeId' : 'clientId'}`]:
        selectedTarget && selectedTarget.id,
      losId: selectedLos && selectedLos.value
    };
    await getOpportunities((pickBy(query, identity): OpportunityQuery));
  };

  const getOptions = async input => {
    if (input.length < 3) return null;
    if (searchType.value === 1) {
      return service.getEmployees(input).then(data => dataMappingToDropdown(data));
    }
    return service.getClients(input).then(data => dataMappingToDropdown(data));
  };

  const targetStepSummary = {
    title: `${searchType.label} selected`,
    text: selectedTarget ? selectedTarget.name : ''
  };
  const losStepSummary = selectedLos && {
    title: 'LoS selected',
    text: selectedLos.label
  };

  return (
    <>
      <DialogBox
        title="Instructions"
        closeDialog={() => setOnboardingStatus(true)}
        content={OnboardingInstruction}
        isOpen={!isOnboardingFinished}
      />
      <Step
        title="Engagement Leader/ Manager or Client"
        subtitle="First, search and select engagement leader/manager or client for AML check"
        Icon={PersonPin}
        theme={theme}
        active={active}
        done={done}
        pending={pending}
        stepSummaryItems={
          losStepSummary ? [targetStepSummary, losStepSummary] : [targetStepSummary]
        }
        onClickEdit={onClickEdit}
      >
        <ExtraSpace>
          <SearchBoxContainer>
            <LeaderOrClientDropdown
              selectedType={searchType}
              onChange={e => {
                if (e.value !== searchType.value) {
                  setSearchType(e);
                  setSelectedTarget(null);
                  setSelectedLos(null);
                }
              }}
            />
            <SearchSelection
              key={searchType.label}
              value={selectedTarget && { label: selectedTarget.name, value: selectedTarget.id }}
              placeholder={`Start typing to begin search for a ${searchType.label.toLowerCase()} by name`}
              fetchData={getOptions}
              saveToStore={target => setSelectedTarget(target.data)}
              deleteFromStore={() => {
                setSelectedTarget(null);
                setSelectedLos(null);
              }}
              selectedList={selectedTarget ? List([selectedTarget]) : null}
              isSearchable
              note={`Note! You can select only one ${searchType.label.toLowerCase()} at a time`}
              type={searchType.label}
              Icon={PersonPin}
            />
          </SearchBoxContainer>
          <Expander
            data-cy="los-expander"
            isOpen={searchType.value === 2 && selectedTarget && lineOfServices}
          >
            <SearchBoxContainer>
              <TextLabel bold>LoS (optional)</TextLabel>
              {searchType.value === 2 && selectedTarget && (
                <SearchSelection
                  key="los"
                  placeholder="Optionally select a LoS"
                  fetchData={async () =>
                    lineOfServices && lineOfServices.size > 0
                      ? lineOfServices.insert(0, { value: 0, label: 'No selection' })
                      : null
                  }
                  saveToStore={target => setSelectedLos(target)}
                  deleteFromStore={() => setSelectedLos(null)}
                  selectedList={
                    selectedLos && selectedLos.value !== 0
                      ? List([mapToSelectionItem(selectedLos)])
                      : null
                  }
                  isSearchable={false}
                  note="Note! You can select only one LoS at a time"
                  type="LoS"
                  Icon={Assignment}
                />
              )}
            </SearchBoxContainer>
          </Expander>
          <ActionBar
            status={
              !selectedTarget
                ? `Select a ${searchType.label.toLowerCase()} to proceed`
                : 'Press ’Proceed’ to continue to next step'
            }
            isLoading={isLoading}
            proceed={{
              name: 'Proceed',
              callback: async () => {
                await fetchOpportunities();
                onClickSave();
              },
              isDisabled: !selectedTarget
            }}
          />
        </ExtraSpace>
      </Step>
    </>
  );
}
