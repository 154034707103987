// @flow
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  padding: 150px;
`;

const Article = styled.article`
  display: block;
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
`;

type Props = {
  serviceName: string,
  notification: string
};

const ServiceDown = ({ serviceName, notification }: Props) => {
  const heading = `${serviceName || 'Service Portal'} will be back soon!`;
  const message = `Sorry for the inconvenience. ${notification || ''}`;

  return (
    <Wrapper>
      <h2>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAABICAYAAABsigIcAAAACXBIWXMAAAsSAAALEgHS3X78AAADFUlEQVR42u2aTWsTQRyHC0kVRSGI4qXgUqumQbAgiBTERQ+CRIxeRE9z9bbg0cuerAcP+QAKAU8ehGDRg1hcC/aksJJLUnNY6lsKCku/wDgbZnQ6mdmdFCXJ9ld4SDIvuzNP//O2yRSldAr8eyABYiEWQCzEQiyAWIiFWACxEAuxo+fX47N1RmALxNqLTYRRWyDWkt7D8usfD05FVixVvm6tLPkcB2JT+HJvJmBQW5hQgQuxEAuxuWH9zv5259Y0taX36HCfeHmWbr09OQDECrG394UQC7EQC7EQC7ETxdqVPRurlwpUx8dqsQexOxa7t2sS+6FajCB2h7y7eOD7ymKB6li7XNxsXZumMhv3D/XZfHKM/nx2fIBvd5+6gl0t9sWc030+U6A6XjmFaHWORa/Ep/MH+3SuHqGfrx8dgAn9A8RCLMRCLMRCrBVMiMvwbXlZnm8ncnW8mT/Ren+6HP5lttO6UAkT1quVTvfGmXAbN8+1mdBAkDexvikCDfj4lhZiRyqWMIIhIBCLH2wAiIVYiAUQmxOx7K/GqDOC5JWnEUaTpyWvRFOvxOv6HFfJX5DyiJTuqPdTrulJ9xYk5Z2xF8s7lzQ+ZlCJiBEqaYIGr+sZyvjKPeQygZTuK/WI9A/WtUf+XBt3sa5BXhaER0+cJpbLp5ZifS5VTot5G9V2RhMxFWg6GaTI2dYxTad9aTjHQ4itasoTUwBMvFie39DIdTLE6uqYxDb5KFDLl6R5Wk6v50WsbspwTWKltLSIrUtlSpo2UM2CRtTFcbeJjaT3A9dV6izwtGDUw31cxJYMebE8D6vXVeZeTxPBA9NNnsV6QyxeakSrYgN52yZdh2Rt3XIl1rC6kwyxTSlfJz0UEZ9xHzWqxcFhYrdbIRfmaTbnjYxpIpaHsEZUbBrihqgVdcI8bLdMnfMsDhmekk+HOTVxuXFGO+qTHLGEp3tpWxx+LBbPAnzDtQU1y/aUpPuLZwT+KI6y/3XxAhALsbkXm/J4cCSLRJ7EBilALL6agViIBRALsRALIBZiIRZALMRCLNDwG1sS4Im4hjbrAAAAAElFTkSuQmCC"
          alt="PWC"
        />
      </h2>

      <Article>
        <h2 data-cy="service-down-heading">{heading}</h2>
        <div>
          <p data-cy="service-down-message">
            {message}
            <br />
            If you need to, please create a ServiceNow ticket, otherwise we‘ll be back online soon!
          </p>
          <p>&mdash; The Service Portal Team</p>
        </div>
      </Article>
    </Wrapper>
  );
};

export default ServiceDown;
