// @flow
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../common/components/Button';
import Checkbox from '../../common/components/checkbox/Checkbox';
import TextLabel from '../../common/components/TextLabel';
import CommonTable from '../../common/components/react-table/CommonTable';
import { SelectColumnFilter } from '../../common/components/react-table/Filter';
import { type ServiceOrder } from '../types';
import { deadlineFilterType } from '../utils';

export type SearchedOrderListProps = {
  data: Array<ServiceOrder>,
  loading: boolean,
  onProceed: (orders: ServiceOrder[]) => void
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const SearchedOrderList = ({ data, loading, onProceed }: SearchedOrderListProps) => {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: table => (
          <Checkbox
            color="white"
            selected={isSelectAll}
            onClick={() => {
              setIsSelectAll(!isSelectAll);
              if (!isSelectAll) setSelectedOrderIds(table.filteredRows.map(row => row.original.id));
              else setSelectedOrderIds([]);
            }}
          />
        ),

        accessor: 'client.id',
        Cell: table => {
          const serviceOrder: ServiceOrder = table.row.original;
          return (
            <Checkbox
              selected={selectedOrderIds.includes(serviceOrder.id)}
              onClick={() => {
                setSelectedOrderIds(prevList =>
                  !prevList.includes(serviceOrder.id)
                    ? [...prevList, serviceOrder.id]
                    : prevList.filter(id => id !== serviceOrder.id)
                );
              }}
            />
          );
        },
        disableFilters: true
      },
      {
        Header: 'SOID',
        accessor: 'id'
      },
      {
        Header: 'Status',
        accessor: 'statusText',
        Filter: SelectColumnFilter,
        filter: 'includes'
      },
      {
        Header: 'SO Class',
        accessor: 'soClass.name'
      },
      {
        Header: 'Deadline',
        accessor: 'deadline',
        Cell: ({ value }: { value: Date }) => moment(value).format('DD/MM/YYYY'),
        filter: 'deadlineText'
      },
      {
        Header: 'Job',
        accessor: 'job.name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ],
    [selectedOrderIds, isSelectAll]
  );
  const handleProceed = () => onProceed(data.filter(o => selectedOrderIds.includes(o.id)));

  return (
    <div data-cy="searched-orders-list">
      <TextLabel bold data-cy="searched-order-clientname">
        {data && data[0] && data[0].client.name}
      </TextLabel>

      <CommonTable
        cyName="searched"
        columns={columns}
        data={data}
        isLoading={loading}
        noResultsText="No results found"
        haveFilter
        onFilterChange={row => {
          setSelectedOrderIds(prev => prev.filter(id => row.map(r => r.original.id).includes(id)));
        }}
        filterTypes={deadlineFilterType}
      />
      <ButtonContainer>
        <Button
          data-cy="proceed-searched-order-selection-button"
          primary
          solid
          disabled={selectedOrderIds.length === 0}
          onClick={handleProceed}
        >
          Proceed
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default SearchedOrderList;
