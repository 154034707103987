// @flow
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps, withState } from 'recompose';
import {
  dataMappingToDropdown,
  mapToSimpleDropdownItem,
  dropdownTransfer,
  datafilter
} from '../../../../../common/utils/helper';
import {
  selectTarget,
  deselectTarget,
  selectFinancialYearEnd,
  deselectFinancialYearEnd,
  getSignerList,
  resetSelectTarget
} from '../../../../duck/search';
import { getAudits, resetAudits, getClientsJoblist } from '../../../../duck/audit';
import Search from '../Search';
import service from '../../../../services/api';
import { searchTypeList } from '../../../../../common/components/LeaderOrClientDropdown';

const signerTypeMapping = (type: number) => {
  switch (type) {
    case 1:
      return 'signerId';
    case 2:
      return 'clientId';
    default:
      return 'unknown';
  }
};

export default compose(
  connect(
    /** The latest selection is saved to keep the dropdown selection field in sync with the selection.
     * This way we can clear the selection field, when we deselect a target or financial year end month. */
    state => ({
      selectedTarget: state.search.get('selectedTarget'),
      selectedFinancialYearEnd: state.search.get('selectedFinancialYearEnd'),
      latestSelectionTarget: state.search.get('latestSelectionTarget'),
      latestSelectionFinancialYearEnd: state.search.get('latestSelectionFinancialYearEnd'),
      signerList: state.search.get('signerList'),
      loading: state.rfGeneral.get('loading')
    }),
    {
      deselectTarget,
      getAudits,
      resetAudits,
      getSignerList,
      selectTarget,
      resetSelectTarget,
      selectFinancialYearEnd,
      deselectFinancialYearEnd,
      getClientsJoblist
    }
  ),
  withState('searchType', 'onChangeSearchType', searchTypeList[0]),
  withHandlers({
    getOptions: ({ signerList, getSignerList: get, searchType }) => async input => {
      if (searchType.value === 1) {
        if (signerList.size < 1) {
          await get();
        }
        return dataMappingToDropdown(datafilter(signerList, input));
      }
      return input.length > 2
        ? service.getClient({ name: input }).then(data => dataMappingToDropdown(data))
        : null;
    },
    addTarget: ({ selectTarget: select, selectedTarget }) => type => item => {
      // check if the tag is already in the list
      const isDuplicate = selectedTarget.find(i => i.id === item.value && i.type === type);
      if (!isDuplicate) select({ ...dropdownTransfer(item), type });
    },
    removeTarget: ({ deselectTarget: deselect }) => type => item => deselect({ ...item, type }),
    addFinancialYearEnd: ({ selectFinancialYearEnd: select, selectedFinancialYearEnd }) => item => {
      const isDuplicate = selectedFinancialYearEnd.find(i => i.value === item.value);
      if (!isDuplicate) select(item);
    },
    removeFinancialYearEnd: ({ deselectFinancialYearEnd: deselect }) => item =>
      deselect(mapToSimpleDropdownItem(item)),
    process: ({
      selectedTarget,
      selectedFinancialYearEnd,
      getAudits: get,
      resetAudits: reset,
      getClientsJoblist: getJobs
    }) => async () => {
      reset();
      const selectedTargetsByType = selectedTarget
        .valueSeq()
        .reduce(
          (f, i) => f.updateIn([`${signerTypeMapping(i.type)}`], list => list.concat(i.id)),
          Map({ signerId: [], clientId: [] })
        )
        .toObject();

      const financialYearEnd =
        selectedFinancialYearEnd && selectedFinancialYearEnd.length
          ? selectedFinancialYearEnd.map(i => i.value)
          : null;
      const res = await get(
        financialYearEnd ? { ...selectedTargetsByType, financialYearEnd } : selectedTargetsByType
      );
      const clientList = res.value.hierarchy.valueSeq();

      if (clientList && clientList.size > 0) {
        let clientIds = [];
        const headClientIds = [];
        clientList.forEach(client => {
          clientIds.push(client.getIn(['datum', 'id']));
          headClientIds.push(client.getIn(['datum', 'id']));
          clientIds = clientIds.concat(
            client
              .getIn(['datum', 'children'])
              .keySeq()
              .toJS()
          );
        });
        await getJobs(clientIds);
      }
    }
  }),
  withProps(({ selectedTarget, selectedFinancialYearEnd }) => {
    const targetStepSummary = {
      title: 'Targets selected',
      text: selectedTarget ? selectedTarget.map(i => i.name).join(', ') : ''
    };
    const financialYearEndStepSummary = selectedFinancialYearEnd &&
      selectedFinancialYearEnd.length && {
        title: 'Financial year end',
        text: selectedFinancialYearEnd.map(i => i.label).join(', ')
      };
    return {
      stepSummaryItems: financialYearEndStepSummary
        ? [targetStepSummary, financialYearEndStepSummary]
        : [targetStepSummary]
    };
  })
)(Search);
