// @flow
import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  getStatusSummaries as getStatusSummariesConnect,
  findClientsByName as findClientsByNameConnect,
  selectClients as selectClientsConnect
} from '../duck/auditRFDiagnostic';
import { download } from '../../common/utils/api';
import TextLabel from '../../common/components/TextLabel';
import CommonTable from '../../common/components/react-table/CommonTable';
import LoadingBar from '../../common/components/workflow/LoadingBar';
import SearchMultiSelect from '../../common/components/selectors/SearchMultiSelect';
import SimpleSelect from '../../common/components/selectors/SimpleSelect';
import Button from '../../common/components/Button';
import { type ClientList } from '../../common/types/client';
import { type StatusSummary } from '../types';

type Props = {
  getStatusSummaries: (clientIds: number[], onlyErrors?: boolean, onlyFailedBC?: boolean) => {},
  findClientsByName: (name: string) => {},
  selectClients: ({ name: string, id: number }[]) => {},
  statusSummaries: StatusSummary[],
  foundClients: ClientList,
  selectedClients: ClientList
};

const FilterContainer = styled.div`
  display: flex;
  width: 50%;
  & > div {
    width: 60%;
    display: inline-block;
    margin: 10px;
  }
`;

const DownloadLink = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  color: ${props => props.theme.color.ui.primary};
  margin-top: 10px;
`;

const soFilterList = [
  { value: 1, label: 'Show all' },
  { value: 2, label: 'Show failed RF transactions' },
  { value: 3, label: 'Show failed bank confirmation SOs' }
];

const StatusSummaryTable = ({
  getStatusSummaries,
  findClientsByName,
  selectClients,
  statusSummaries,
  foundClients,
  selectedClients
}: Props) => {
  const [clientSearchString, setClientSearchString] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'rollforwardId'
      },
      {
        Header: 'Client Name',
        accessor: 'clientName'
      },
      {
        Header: 'Created',
        accessor: d => moment(d.createTimestamp).format('LLLL')
      },
      {
        Header: 'Updated',
        accessor: d => moment(d.updateTimestamp).format('LLLL')
      },
      {
        Header: 'Status',
        accessor: 'overallStatus'
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: info => (
          <Button
            primary
            solid
            rounded
            onClick={() =>
              history.push(`/auditrf-diagnostic/${info.cell.row.values.rollforwardId}`)
            }
          >
            Diagnose
          </Button>
        )
      }
    ],
    [history]
  );

  const debounceSetClientSearchString = debounce(setClientSearchString, 300);
  const onClientInputChanged = value => {
    debounceSetClientSearchString(value);
  };

  const [soFilter, setSOFilter] = useState(soFilterList[0]);

  useEffect(() => {
    getStatusSummaries(selectedClients.map(client => client.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients]);

  useEffect(() => {
    findClientsByName(clientSearchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSearchString]);

  const errorFilterOnChange = (value: any) => {
    setSOFilter(value);
    if (value.value === 1) {
      getStatusSummaries(selectedClients.map(client => client.id), false, false);
    }
    if (value.value === 2) {
      getStatusSummaries(selectedClients.map(client => client.id), true, false);
    }
    if (value.value === 3) {
      getStatusSummaries(selectedClients.map(client => client.id), false, true);
    }
  };

  return (
    <>
      <h1>Roll Forward Status Summary</h1>
      <FilterContainer>
        <SearchMultiSelect
          value={selectedClients.map(client => ({
            label: client.name,
            value: client.id
          }))}
          placeholder="Find clients"
          options={foundClients.map(client => ({
            label: client.name,
            value: client.id
          }))}
          onChange={selectedValues => {
            selectClients(
              selectedValues.map(value => ({
                name: value.label,
                id: value.value
              }))
            );
          }}
          onInputChange={onClientInputChanged}
        />
        <SimpleSelect onChange={errorFilterOnChange} value={soFilter} optionList={soFilterList} />
        <DownloadLink
          onClick={async () => {
            setIsLoading(true);
            const downloadFile = await download('/auditrf-diagnostic/error', 'errorList.csv');
            setIsLoading(false);
            return downloadFile;
          }}
        >
          <TextLabel clientName isLink>
            Export as excel file
          </TextLabel>
          <IconWrapper>
            <CloudDownloadIcon />
          </IconWrapper>
        </DownloadLink>
      </FilterContainer>
      {isLoading && <LoadingBar />}
      <CommonTable columns={columns} data={statusSummaries} noResultsText="No results found" />
    </>
  );
};

export default connect(
  state => ({
    statusSummaries: state.auditRFDiagnostic.statusSummaries,
    foundClients: state.auditRFDiagnostic.foundClients,
    selectedClients: state.auditRFDiagnostic.selectedClients
  }),
  {
    getStatusSummaries: getStatusSummariesConnect,
    findClientsByName: findClientsByNameConnect,
    selectClients: selectClientsConnect
  }
)(StatusSummaryTable);
