import { configureStore as configureReduxStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rollForward from './roll-forward/duck/rollForward';
import audit from './roll-forward/duck/audit';
import rfGeneral from './roll-forward/duck/general';
import search from './roll-forward/duck/search';
import accountSystem from './general-ledger/duck/accountSystem';
import accountMapping from './general-ledger/duck/accountMapping';
import jobs from './general-ledger/duck/jobs';
import dataset from './general-ledger/duck/dataset';
import team from './general-ledger/duck/team';
import glGeneral from './general-ledger/duck/general';
import common from './common/duck/common';
import activityGL from './general-ledger/duck/activity';
import activityRF from './roll-forward/duck/activityLog';
import los from './aml-check/duck/los';
import opportunity from './aml-check/duck/opportunity';
import kyc from './aml-check/duck/kyc';
import amlOnboarding from './aml-check/duck/onboarding';
import serviceOrder from './service-order/duck';
import auditRFDiagnostic from './auditrf-diagnostic/duck/auditRFDiagnostic';
/*
 * Veromylly
 */
import veromylly from './veromylly/duck';
/*
 * SDC Upload
 */
import sdcUpload from './sdc-upload/duck';
import talentLink from './talentlink/duck';

const rootReducer = combineReducers({
  audit,
  rfGeneral,
  search,
  rollForward,
  jobs,
  activityGL,
  activityRF,
  dataset,
  accountSystem,
  accountMapping,
  team,
  common,
  glGeneral,
  los,
  opportunity,
  kyc,
  amlOnboarding,
  serviceOrder,
  auditRFDiagnostic,
  /*
   * Veromylly
   */
  veromylly,
  /*
   * SDC Upload
   */
  sdcUpload,
  talentLink
});

const enhancers = [];
const middleware = [thunk, promiseMiddleware()];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export default function configureStore() {
  return configureReduxStore({
    reducer: rootReducer,
    middleware,
    devTools: false,
    enhancers
  });
}
