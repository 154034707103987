// @flow
import { type OrderedMap, type RecordOf, List } from 'immutable';

export const userRoles = {
  PREPARER: 'preparer',
  REVIEWER: 'reviewer'
};

export type UserRole = $Values<typeof userRoles>;

export const taxReturnSteps = {
  PREPARE: 'prepare',
  REVIEW: 'review',
  PACKAGE: 'package'
};

export const taxReturnStates = {
  DRAFT: 'Draft',
  SENT_FOR_REVIEW: 'Sent for review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  COMPLETED: 'Delivered'
};

export type TaxReturnState = $Values<typeof taxReturnStates>;

export type TaxReturnStep = $Values<typeof taxReturnSteps>;

export type MFilesSelection = {
  id: number,
  name: string
};

export type TaxYear = MFilesSelection;
export type Client = MFilesSelection;
export type Language = MFilesSelection;
export type Staff = MFilesSelection;
export type Country = MFilesSelection;

export type Assignee = {
  id: number,
  name: string,
  firstName: string,
  lastName: string,
  tigerId: string,
  clients: Array<Client>
};

export type Assignees = OrderedMap<String, Assignee>;

export type Staffs = OrderedMap<String, Staff>;

export type SearchMyTaxReturnParams = {
  taxYear: number,
  assignee: string,
  client: string
};

export type CreateMyTaxReturnParams = {
  year: number,
  assignee: number,
  client: number,
  language: number,
  country: number
};

export type TaxReturn = {
  id: string,
  assignee: MFilesSelection,
  client: Client,
  year: TaxYear,
  language: Language,
  country: Country,
  preparer: Staff,
  reviewer?: Staff,
  state: {
    id: number,
    name: TaxReturnState
  }
};

export type TaxReturns = OrderedMap<String, TaxReturn>;

export const formFieldTypes = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  DATE: 'date'
};

export type FormFieldType = $Values<typeof formFieldTypes>;

export type FormField = RecordOf<{
  id: string,
  name: string,
  type: FormFieldType,
  value: any,
  hidden: boolean,
  formula?: string,
  format?: string,
  preheader?: string,
  pretext?: string,
  imported: boolean
}>;

export const commentTypes = {
  COMMENT: 'comment',
  APPROVAL: 'approval',
  REJECTION: 'rejection'
};

export type CommentType = $Values<typeof commentTypes>;

export type Comment = RecordOf<{
  type: CommentType,
  created?: Date,
  author: string,
  content?: string,
  resolved: boolean
}>;

export type Section = RecordOf<{
  name: string,
  hidden: boolean,
  formfields: List<FormField>,
  comments: List<Comment>,
  activated: boolean,
  approved: boolean,
  rejected: boolean
}>;

export type SingleForm = RecordOf<{
  id: string,
  name: string,
  pdfable: boolean,
  sections: List<Section>,
  approved: boolean,
  rejected: boolean
}>;

export type CompositeForm = RecordOf<{
  id: string,
  name: string,
  // eslint-disable-next-line no-use-before-define
  subforms: List<Form>,
  addable: List<string>,
  approved: boolean,
  rejected: boolean
}>;

export type Form = SingleForm | CompositeForm;

export type Tab = RecordOf<{
  name: string,
  forms: List<Form>,
  approved: boolean,
  rejected: boolean
}>;

export type DocumentSection = RecordOf<{
  uuid: string,
  id: number,
  name: string,
  comments: List<Comment>
}>;

export type TaxReturnData = RecordOf<{
  tabs: List<Tab>,
  documents: List<DocumentSection>,
  approved: boolean,
  rejected: boolean,
  requiresReview: boolean
}>;

export type TaxReturnWithData = TaxReturn & {
  data: TaxReturnData,
  userrole: UserRole
};

export type Document = RecordOf<{
  id: number,
  category?: string,
  name: string,
  filename?: string,
  viewUrl?: string,
  editUrl?: string
}>;

export type DocumentTemplate = RecordOf<{
  id: string,
  name: string,
  categoryName: string
}>;
