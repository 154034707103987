// @flow
import { connect } from 'react-redux';
import { getMyOrders, getFiles, selectOrders, unselectOrder, resetState } from './duck';
import MainPage from './MainPage';

const Container = connect(
  state => ({
    myOrders: state.sdcUpload.myOrders,
    selectedOrders: state.sdcUpload.selectedOrders,
    files: state.sdcUpload.files
  }),
  { getMyOrders, getFiles, selectOrders, unselectOrder, resetState }
)(MainPage);

export default Container;
