// @flow
import React from 'react';
import styled from 'styled-components';
import { CustomHeading, CustomTextLabel } from './Common';

type Props = {
  id: string,
  label: string
};

const StatusHeading = styled(CustomHeading)`
  margin-left: 0;
`;

const StatusInfo = ({ id, label }: Props) => (
  <>
    <StatusHeading noPadding>Status</StatusHeading>
    <CustomTextLabel data-cy={`status-${id}`}>{label}</CustomTextLabel>
  </>
);

export default StatusInfo;
