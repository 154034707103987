// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { DoneIcon } from '../../../../../common/components/Icons';
import PageHeader from '../../../../../common/components/canvas/PageHeader';
import { Box, BoxItem } from '../../../../../common/components/canvas/BoxView';
import LoadingBar from '../../../../../common/components/workflow/LoadingBar';
import TextLabel from '../../../../../common/components/TextLabel';
import Button from '../../../../../common/components/Button';
import Alink from '../../../../../common/components/Link';
import CustomBoxItem from './CustomBoxItem';
import FilesListDialog from '../../../common/FilesListDialog';
import { type Summary, type FileSummary, type FileData } from '../../../../types/summary';

type Props = {
  clientInfo: Summary,
  jobInfo: Summary,
  teamInfo: Summary,
  filesInfo: FileSummary,
  filesData: FileData,
  haveDatasetsFullfilled: boolean,
  refreshStatus: Function,
  goToAccountMapping: Function,
  goToActivityLog: Function
};

const CustomButton = styled(Button)`
  margin: ${props => props.theme.space.single.m};
`;

const SuccessPage = ({
  clientInfo,
  jobInfo,
  teamInfo,
  filesInfo,
  filesData,
  haveDatasetsFullfilled,
  refreshStatus,
  goToAccountMapping,
  goToActivityLog
}: Props) => {
  const [showModal, toggleModal] = useState(false);
  const openModal = () => {
    toggleModal(true);
  };
  const closeModal = () => {
    toggleModal(false);
  };

  return (
    <>
      <DoneIcon />

      <h1>Files have now been submitted! You can check the status below.</h1>

      {haveDatasetsFullfilled ? (
        <>
          <PageHeader getLog={goToActivityLog} title="" subTitle="" />

          <Box Icon={null} header="">
            <TextLabel bold>File Upload Status</TextLabel>
            <BoxItem id="" Icon={clientInfo.icon} name={clientInfo.name} />
            <BoxItem id="" Icon={jobInfo.icon} name={jobInfo.name} />
            <CustomBoxItem
              Icon={filesInfo.icon}
              summary={filesInfo.summary.dataByType}
              onClick={openModal}
            />
            <BoxItem id="" Icon={teamInfo.icon} name={teamInfo.name} />
          </Box>

          <CustomButton data-cy="refresh-status" onClick={() => refreshStatus()} isLarge primary>
            Refresh Status (Qlik Sense & Account Mapping)
          </CustomButton>

          <TextLabel bold>
            It will take some minutes to get your report and account mapping ready, you can also
            check it later in the activity log.
          </TextLabel>

          {filesInfo.isQlikSenseAvailable ? (
            <TextLabel clientName isLink>
              <OpenInNewIcon />
              <Alink data-cy="qlicksense-link" href="https://qliksense.pwc.fi/" target="_blank">
                View report in Qlik Sense
              </Alink>
            </TextLabel>
          ) : (
            <TextLabel>The report is not ready for Qlik Sense yet.</TextLabel>
          )}

          {filesInfo.isMappingAvailable ? (
            <TextLabel
              data-cy="tb-mapping-available"
              onClick={() => goToAccountMapping()}
              clientName
              isLink
            >
              <AccessTimeIcon />
              Trial balance account mapping
            </TextLabel>
          ) : (
            <TextLabel data-cy="tb-mapping-unavailable">
              The account mapping is not available yet.
            </TextLabel>
          )}

          {filesData.files && filesData.files.length > 0 && (
            <FilesListDialog
              files={
                filesData.files
                  ? filesData.files.map(file => ({ ...file, fileId: file.id, fileName: file.name }))
                  : []
              }
              showModal={showModal}
              closeModal={closeModal}
            />
          )}
        </>
      ) : (
        <LoadingBar />
      )}
    </>
  );
};

export default SuccessPage;
