// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Add, DoneAll } from '@material-ui/icons';
import { List } from 'immutable';

import { type TaxReturn, type Staff } from '../../../types';
import Button from '../../../../common/components/Button';
import SearchSelection from '../../../../common/components/selectors/SearchSelection';
import { callOnKeypress, dataMappingToDropdown } from '../../../../common/utils/helper';
import { type DropdownItem } from '../../../../common/types/list';
import { updateReviewer } from '../../../duck/TaxReturnPage';
import { searchStaff } from '../../../duck/HomePage';

interface StateProps {
  taxReturn: TaxReturn;
}

interface DispatchProps {
  updateReviewer: Function;
  searchStaff: Function;
}

type Props = StateProps & DispatchProps;

const Reviewer = ({ taxReturn: { reviewer, id, preparer }, ...dispatchProps }: Props) => {
  const [isReviewerSearchOpen, setIsReviewerSearchOpen] = useState(false);

  useEffect(() => {
    const cancelSearch = (event: KeyboardEvent) =>
      callOnKeypress(event, 27, () => setIsReviewerSearchOpen(false));
    document.addEventListener('keydown', cancelSearch, false);
    return () => {
      document.removeEventListener('keydown', cancelSearch, false);
    };
  }, []);

  const fetchStaffList = (input: string): Promise<Array<DropdownItem<Staff>>> => {
    // eslint-disable-next-line no-array-constructor
    if (input.length < 3) return Promise.resolve(Array<DropdownItem<Staff>>());
    try {
      return dispatchProps
        .searchStaff(input)
        .then(({ value: staff }) => (staff ? dataMappingToDropdown(staff) : []));
    } catch (err) {
      // eslint-disable-next-line no-array-constructor
      return Promise.resolve(Array<DropdownItem<Staff>>());
    }
  };

  const renderSelection = () =>
    isReviewerSearchOpen ? (
      <SearchSelection
        type="reviewer"
        saveToStore={({ data }) => dispatchProps.updateReviewer(data)}
        deleteFromStore={undefined}
        noMinWidth
        placeholder="Find reviewer"
        isClearable
        selectedList={List<Object>()}
        width="250px"
        fetchData={fetchStaffList}
        onChange={() => setIsReviewerSearchOpen(false)}
        value={reviewer}
      />
    ) : (
      <Button
        data-cy="sidebar-reviewer-button"
        solid
        primary
        onClick={() => setIsReviewerSearchOpen(true)}
      >
        {reviewer ? (
          <>
            <DoneAll />
            {reviewer.name}
          </>
        ) : (
          <>
            <Add />
            Add reviewer
          </>
        )}
      </Button>
    );

  return renderSelection();
};

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn
});

const dispatchProps: DispatchProps = {
  updateReviewer,
  searchStaff
};

export default connect(
  mapStateToProps,
  dispatchProps
)(Reviewer);
