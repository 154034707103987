// @flow
import styled from 'styled-components';

const StyledTable = styled.table.attrs({
  cellSpacing: 0
})`
  width: 100%;
  svg {
    margin: 0;
  }
  td,
  th {
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: ${props => props.theme.color.border.default};
  }

  tr > *:last-child,
  tr > *:first-child {
    width: 1px;
  }
  tr:last-child td {
    border-bottom: none;
  }
  tr > *:nth-last-child(2),
  tr > *:last-child {
    border-right: none;
  }
  thead > tr th:not(:last-child) {
    padding: ${props => props.theme.space.single.m};
  }
`;

export default StyledTable;
