// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { List, type OrderedMap } from 'immutable';
import { type AccountMapper } from '../../../types/accountMapping';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import TextLabel from '../../../../common/components/TextLabel';
import { FileSelector } from '../../../../common/components/selectors';
import Button from '../../../../common/components/Button';
import MessageBar from '../../../../common/components/workflow/MessageBar';
import fileReader from '../../../utils/fileReader';

const ButtonContainer = styled.div`
  padding: 0.5em;
  display: flex;
  border-color: ${props => props.theme.border.white};
  justify-content: flex-end;
  button {
    margin: 4px;
  }
`;

type Props = {
  title: string,
  type: string,
  accountMappingList: OrderedMap<AccountMapper>,
  toggleModal: Function,
  showModal: Function,
  handleCustomFSLI: Function,
  setCustomFsliList: Function,
  restoreAccountMappingList: Function
};

const CustomDialogBox = ({
  title,
  type,
  toggleModal,
  showModal,
  accountMappingList,
  handleCustomFSLI,
  setCustomFsliList,
  restoreAccountMappingList
}: Props) => {
  const [selectedFilesList, setFile] = useState(List([]));
  const [isFileUploaded, setFileUploadStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error');
  const [showMessage, setMessageStatus] = useState(false);

  const saveFile = file => {
    if (file) {
      setFile(selectedFilesList.concat(List([{ fileName: file.name, fileId: 1, file }])));
      setFileUploadStatus(true);
    }
  };

  const processCustomFSLI = result => {
    setCustomFsliList(result);
    handleCustomFSLI(true);
  };

  const processRestoreFSLI = result => {
    handleCustomFSLI(false);
    toggleModal(false, '');

    const newFsliValues = [];
    accountMappingList
      .valueSeq()
      .toArray()
      .forEach(currentItem => {
        const oldItem = result.find(elem => elem.accountNumber === currentItem.accountNumber);
        newFsliValues.push({
          fsliMappingId: currentItem.fsliMappingId,
          accountName: currentItem.accountName,
          accountNumber: currentItem.accountNumber,
          fsli: oldItem && oldItem.fsli ? oldItem.fsli : '',
          customFSLI: oldItem && oldItem.customFSLI ? oldItem.customFSLI : ''
        });
      });
    restoreAccountMappingList(newFsliValues);
  };

  const parseFile = file => {
    fileReader(type, file, response => {
      if (response.success && response.result) {
        if (type === 'custom-fsli') {
          processCustomFSLI(response.result);
        } else {
          processRestoreFSLI(response.result);
        }
        setMessageType('success');
        setMessage('File uploaded successfully!');
      } else {
        setMessageType('error');
        setMessage(response.error);
      }
      setMessageStatus(true);
      setFile(List([]));
    });
  };

  const submitFile = () => {
    if (!isFileUploaded) {
      setMessage('Please upload a file in order to proceed.');
      setMessageStatus(true);
    } else if (selectedFilesList.get(0) && selectedFilesList.get(0).file) {
      parseFile(selectedFilesList.get(0).file);
    }
  };

  return (
    <>
      <DialogBox
        title={title}
        closeDialog={() => toggleModal(false)}
        content={() => (
          <>
            <TextLabel>You can upload only one file</TextLabel>
            <TextLabel>Upload files</TextLabel>
            <FileSelector
              title="custom-fsli"
              selectedFilesList={selectedFilesList}
              saveFile={file => saveFile(file)}
              removeFile={() => setFile(selectedFilesList.clear())}
              disabled={selectedFilesList && selectedFilesList.size > 0}
            />
          </>
        )}
        ButtonElement={() => (
          <ButtonContainer>
            <Button data-cy="fsli-dialog-submit" rounded primary solid onClick={() => submitFile()}>
              Submit
            </Button>
            <Button
              data-cy="fsli-dialog-skip"
              rounded
              primary
              onClick={() =>
                type === 'custom-fsli' ? handleCustomFSLI(false) : toggleModal(false, '')
              }
            >
              Skip
            </Button>
          </ButtonContainer>
        )}
        isOpen={showModal}
      />
      <MessageBar
        type={messageType}
        status={showMessage}
        message={message}
        onClose={() => setMessageStatus(false)}
      />
    </>
  );
};

export default CustomDialogBox;
