/* eslint-disable react/no-array-index-key */
// @flow
import { OrderedMap } from 'immutable';
import { Clear } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import DialogBox from '../../common/components/canvas/DialogBox';
import Button from '../../common/components/Button';
import { type ServiceOrder, type File } from '../types';

const DialogWrapper = styled.div`
  min-heigth: 400px;
  display: flex;
  flex-direction: column;
`;

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  & svg:hover {
    cursor: pointer;
    color: red;
  }
`;

type Props = {
  isOpen: boolean,
  toggle: () => void,
  serviceOrders: ServiceOrder[],
  files: OrderedMap<number, File[]>,
  removeFile: Function,
  handleProceed: Function
};
const ConfirmDialog = ({
  isOpen,
  toggle,
  serviceOrders,
  files,
  removeFile,
  handleProceed
}: Props) => (
  <DialogBox
    isOpen={isOpen}
    maxWidth="lg"
    hasCloseButton
    closeButtonOnClick={() => toggle()}
    title="Confirm files"
    closeDialog={() => {}}
    ButtonElement={() => (
      <Button
        disabled={!files.length}
        solid
        primary
        data-cy="sdc-confirm-submit-button"
        onClick={() => handleProceed()}
      >
        Submit
      </Button>
    )}
    content={() => (
      <DialogWrapper data-cy="confirm-dialog">
        <h3>Please confirm to upload the following files:</h3>
        <div>
          {files &&
            files.map((file, i) => (
              <DeleteContainer key={i}>
                <span data-cy={`sdc-file-name-${i}`}>{file.name}</span>
                <Clear data-cy={`remove-file-button-${i}`} onClick={() => removeFile(file)} />
              </DeleteContainer>
            ))}
        </div>
      </DialogWrapper>
    )}
  />
);

export default ConfirmDialog;
