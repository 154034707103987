// @flow
import { get, put, download } from '../../common/utils/api';
import {
  type Client,
  type Job,
  type StaffMember,
  type FinancialInstitution,
  type ServiceOrderFilter,
  type ServiceOrder,
  NORMAL_FINANCIAL_INSTITUTION,
  EXTRA_FINANCIAL_INSTITUTION
} from '../types';

export default {
  fetchClients: async (
    name: string | null,
    engagementContactName: string | null
  ): Promise<Array<Client>> => get('sorf/client', { name, engagementContactName }),
  fetchJobsByClientId: async (id: number): Promise<Array<Job>> => get(`sorf/client/${id}/job`),
  fetchStaff: async (name: string): Promise<Array<StaffMember>> => get('sorf/staff', { name }),
  fetchFinancialInstitutions: async (name: string): Promise<Array<FinancialInstitution>> =>
    get('sorf/financial-institution', { name, type: NORMAL_FINANCIAL_INSTITUTION }),
  fetchExtraFinancialInstitutions: async (name: string): Promise<Array<FinancialInstitution>> =>
    get('sorf/financial-institution', { name, type: EXTRA_FINANCIAL_INSTITUTION }),
  fetchServiceOrders: async (params: ServiceOrderFilter): Promise<Array<ServiceOrder>> =>
    get('sorf/service-order', params),
  submitServiceOrders: async (rollForwards: Array<ServiceOrder>): Promise<string> =>
    put('sorf/roll-forward', rollForwards),
  getFinishedServiceOrders: async () => download('sorf/service-order/finished', 'finished_so.csv'),
  getErrorServiceOrders: async () => download('sorf/service-order/error', 'error_so.csv')
};
