/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import React, { Fragment, useEffect, useState } from 'react';
import { Record, type List as ListType } from 'immutable';
import { type FilesData } from '../../../../../../common/types/files';
import { FileSelector, SearchSelection } from '../../../../../../common/components/selectors';
import TextLabel from '../../../../../../common/components/TextLabel';
import { dataMappingToDropdown } from '../../../../../../common/utils/helper';
import systemTypeMapping from '../../../../../../common/utils/systemTypeMapping';
import prefixMapping from '../../../../../utils/accountSystemMapping';
import { splitFile } from '../../../../../utils/fileReader';

type Props = {
  headClient: Object,
  accountSystemList: ListType<Object>,
  selectedAccountSystem: Object,
  filesData: FilesData,
  Icon: any,
  selectAccountSystem: Function,
  saveHeadClientFile: Function,
  datasetId: string,
  removeHeadClientFile: Function,
  turnOnLoading: Function,
  isLoading: boolean
};
const CustomFileSelector = ({ title, optional, contentFormat, waitMessage, ...rest }: any) => (
  <>
    <TextLabel>
      {title}
      {optional && ' (optional)'}
    </TextLabel>
    {waitMessage.showMessage && waitMessage.contentFormatType === contentFormat.type ?
      <TextLabel>Upload files <i>(Please wait. It can take a few seconds before the file starts uploading.)</i></TextLabel> :
      <TextLabel>Upload files</TextLabel>
    }
    <FileSelector title={title} {...rest} />
  </>
);

const FilesUpload = ({
  accountSystemList,
  selectedAccountSystem,
  filesData,
  Icon,
  selectAccountSystem,
  saveHeadClientFile,
  removeHeadClientFile,
  datasetId,
  turnOnLoading,
  isLoading
}: Props) => {
  // This message will get activated on file upload and it will remain active until the file starts loading
  const [waitMessage, setWaitMessage] = useState({ contentFormatType: "", showMessage: false });

  // Read the file and split it in chunks.
  // The extra parameters are for subsidiaries
  const handleSaveFile = async (datasetid, contentFormat, accountingSystem, file, clientId) => {
    // Split files only for the "Other" accounting system
    if (selectedAccountSystem.label === 'Other') {
      setWaitMessage({ contentFormatType: contentFormat.type, showMessage: true })
      const splittedFiles = await splitFile(
        datasetid,
        contentFormat,
        accountingSystem,
        file,
        clientId
      );

      for (let i in splittedFiles) {
        setWaitMessage({ ...waitMessage, showMessage: false })
        turnOnLoading(['headClient', splittedFiles[i].contentFormat.type]);
        await saveHeadClientFile(datasetId, splittedFiles[i].contentFormat, splittedFiles[i].file);
      }
    } else {
      turnOnLoading(['headClient', contentFormat.type]);
      saveHeadClientFile(datasetId, contentFormat, file);
    }
  };

  return (
    <>
      {isLoading && <TextLabel>Datsets are creating...</TextLabel>}
      {accountSystemList.length > 0 && !isLoading && (
        <>
          <TextLabel>Accounting System</TextLabel>
          <SearchSelection
            placeholder={
              selectedAccountSystem
                ? selectedAccountSystem.label
                : 'Select accounting system from the dropdown menu'
            }
            isSearchable={false}
            key="SearchJob"
            deleteFromStore={() => { }}
            type="account-system"
            Icon={Icon}
            note="Note! Pay attention that you select the appropriate accounting system and file format, and that the uploaded data file(s) match to file format selected."
            fetchData={() =>
              Promise.resolve(accountSystemList).then(() =>
                dataMappingToDropdown(accountSystemList)
              )
            }
            saveToStore={selectAccountSystem}
            selectedList={null}
            onChange={() => { }}
          />
          <TextLabel>{selectedAccountSystem && systemTypeMapping()}</TextLabel>
          {
            selectedAccountSystem && selectedAccountSystem.label === 'Other' &&
            <TextLabel><b>Please note that you can upload single large file with Other CSV parser. You don&apos;t need to split it yourself. Processing the large file might take a few seconds and Service Portal might appear unresponsive, but the file is being processed.</b></TextLabel>
          }
        </>
      )}
      {selectedAccountSystem &&
        selectedAccountSystem.data.contentFormats &&
        selectedAccountSystem.data.contentFormats.map(contentFormat => (
          <CustomFileSelector
            key={contentFormat.id}
            title={`${prefixMapping(contentFormat.type)} (${contentFormat.name})`}
            optional={contentFormat.type !== 'GL'}
            contentFormat={contentFormat}
            waitMessage={waitMessage}
            selectedFilesList={filesData.headClient[contentFormat.type]
              .filter((_, k) => k !== 'isLoading')
              .valueSeq()
              .toList()}
            rejectedFilesList={filesData.headClient.dataFailures[contentFormat.type]
              .valueSeq()
              .toList()}
            isLoading={filesData.headClient[contentFormat.type].get('isLoading') > 0}
            saveFile={file => handleSaveFile(datasetId, contentFormat, null, file, null)}
            removeFile={file => removeHeadClientFile(datasetId, contentFormat, file)}
          />
        ))}
    </>
  );
};
export default FilesUpload;
