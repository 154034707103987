// @flow

import { createAction } from 'redux-actions';
import { Record, type RecordOf } from 'immutable';
import type { ActionType } from '../types/redux';

type DefaultState = RecordOf<{
  error: boolean,
  errorMessages: string
}>;

const defaultState = Record({
  error: false,
  errorMessages: ''
});

export const resetError = createAction('RESET_ERROR');
export const setErrorMessage = createAction('SET_ERROR_MESSAGE');
export default (state: DefaultState = defaultState(), action: ActionType) => {
  const { type, error, payload } = action;
  if (error) {
    return state.set('error', true);
  }
  if (type === 'SET_ERROR_MESSAGE') {
    return state.set('error', true).set('errorMessages', payload);
  }
  if (type === 'RESET_ERROR') {
    return state.set('error', false).set('errorMessage', '');
  }

  return state;
};
