// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  setLeader,
  clearLeader,
  addClient,
  addHeadClient,
  removeClient,
  clearClients,
  getClients
} from '../../duck/filters';
import { getOrders, toggleSelectAll } from '../../duck/orders';
import Search from './Search';

const Container = (props: any) => {
  const { selectedLeader, clients } = props;
  const isLeaderType = selectedLeader && selectedLeader.size > 0;
  const selectedClients = clients.valueSeq().toList();

  let selectedItems = [];
  if (isLeaderType && selectedLeader) {
    selectedItems = selectedLeader.map(leader => leader.name).join(',');
  } else if (selectedClients) {
    selectedItems = selectedClients
      .map(client =>
        client.subsidiaries
          ? `${client.name}  ${
              client.subsidiaries.length ? `+ ${client.subsidiaries.length} subsidiaries` : ``
            }`
          : client.name
      )
      .join('\n');
  }
  const stepSummaryItems = [
    {
      title: `${isLeaderType ? 'Leader' : 'Client(s)'} Selected`,
      text: selectedItems
    }
  ];
  return (
    <Search stepSummaryItems={stepSummaryItems} selectedClients={selectedClients} {...props} />
  );
};

const LeaderClientSearch = connect(
  state => ({
    selectedLeader: state.serviceOrder.filters.selectedLeader,
    clients: state.serviceOrder.filters.selectedClients
  }),
  {
    setLeader,
    clearLeader,
    addClient,
    addHeadClient,
    removeClient,
    clearClients,
    getClients,
    getOrders,
    toggleSelectAll
  }
)(Container);

export default LeaderClientSearch;
