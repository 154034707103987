// @flow
import { connect } from 'react-redux';
import {
  fetchTaxReturn,
  updateFormFieldValue,
  addComment,
  previewTaxForm,
  previewDocument,
  updateReviewer,
  saveTaxReturn,
  sendForReview,
  approveTaxReturn,
  rejectTaxReturn,
  fetchDocuments,
  fetchDocumentTemplates,
  fetchAssignee,
  previewPackage,
  completeTaxReturn
} from '../../duck/TaxReturnPage';
import TaxReturnPage from './TaxReturn';

const Container = connect(
  state => ({
    taxReturn: state.veromylly.taxReturnPage.taxReturn,
    taxReturnData: state.veromylly.taxReturnPage.taxReturnData,
    unsavedChanges: state.veromylly.taxReturnPage.unsavedChanges,
    userRole: state.veromylly.taxReturnPage.userRole,
    documents: state.veromylly.taxReturnPage.documents,
    documentTemplates: state.veromylly.taxReturnPage.documentTemplates,
    loading: state.veromylly.taxReturnPage.loading
  }),
  {
    fetchTaxReturn,
    updateFormFieldValue,
    addComment,
    previewTaxForm,
    previewDocument,
    updateReviewer,
    saveTaxReturn,
    sendForReview,
    approveTaxReturn,
    rejectTaxReturn,
    fetchDocuments,
    fetchDocumentTemplates,
    fetchAssignee,
    previewPackage,
    completeTaxReturn
  }
)(TaxReturnPage);

export default Container;
