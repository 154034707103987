// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../common/components/TextLabel';
import DialogBox from '../../../common/components/canvas/DialogBox';
import { getFileStatusText, getFileStatusIcon } from '../../utils/status';

const FileRow = styled.div`
  width: 500px;
  margin: ${props => props.theme.space.square.m};
  border-bottom: ${props => `1px solid ${props.theme.color.border.default}`};
  text-align: center;
`;

const FileStatus = styled.div`
  float: left;
`;

type Props = {
  files: Array<any>,
  showModal: boolean,
  closeModal: Function
};

const FilesListDialog = ({ files, showModal, closeModal }: Props) => (
  <DialogBox
    title="Uploaded Files List"
    closeDialog={closeModal}
    content={() => (
      <>
        {files.map((item, key) => (
          <FileRow key={item.fileId || item.id} data-cy={`file-${key}`}>
            <FileStatus>
              <TextLabel>{getFileStatusIcon(item.status)}</TextLabel>
            </FileStatus>
            <FileStatus>
              <TextLabel>{getFileStatusText(item.status)}</TextLabel>
            </FileStatus>
            <TextLabel clientName inline>
              {item.fileName}
            </TextLabel>
          </FileRow>
        ))}
      </>
    )}
    isOpen={showModal}
  />
);

export default FilesListDialog;
