// @flow
import React from 'react';
import { type List as ListType } from 'immutable';
import styled from 'styled-components';

type Props = {
  rejectedFilesList: ListType<Object> | null
};

const Div = styled.div`
  color: ${props => props.theme.color.ui.error};
  padding: ${props => (props.size === 'small' && '3px') || '6px'};
`;

const Span = styled.span`
  margin: 0 5px 0 5px;
`;

const getFileNames = list => {
  const newList = list ? list.map(item => item.fileName) : [];
  return `${newList.join(', ')}.`;
};

const ErrorFileUpload = ({ rejectedFilesList }: Props) => (
  <Div>
    Failed to upload:
    <Span>{getFileNames(rejectedFilesList)}</Span>
    Please try again.
  </Div>
);

export default ErrorFileUpload;
