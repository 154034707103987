// @flow
import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import Warning from '@material-ui/icons/Warning';

const InstructionsWrapper = styled.div`
  > :first-child {
    margin-top: 0px;
  }
  > h4 {
    margin-bottom: 0px;
  }
  > ul {
    margin-top: ${props => props.theme.space.single.s};
  }
`;

export default () => (
  <InstructionsWrapper>
    <p>
      On this section you are required to perform required checks regarding current identified AML
      information to keep client’s AML information up to date. The partially automated AML check
      will be performed based on these answers.
    </p>
    <h4>To take into account:</h4>
    <ul>
      <li>
        Once a won Opportunity to an existing client is created in iPower it can take up to 30min
        for the AML Check to appear in Service Portal
      </li>
      <li>You are only able to fill and submit an AML Check for one client at a time.</li>
      <li>
        All information must be checked/filled at one time, it is not possible to save and continue
        later.
      </li>
    </ul>
    <h4>How to use tips:</h4>
    <ul>
      <li>
        If you move your mouse over the tooltip sign (
        <InfoIcon />
        ), you get more information regarding the related questions to be answered / checked.
      </li>
      <li>
        Warning sign (
        <Warning className="highlight" />) gives guidance on the technical restrictions of the AML
        check.
      </li>
      <li>
        Once you have submitted the answers by selecting ‘Submit’, you are no longer able to change
        your answers.
      </li>
    </ul>
    <p>
      If you have any changes to the Submitted information, please contact Client Desk via email
      fi_kyc@pwc.com
    </p>
  </InstructionsWrapper>
);
