// @flow
import { Record, type RecordOf } from 'immutable';
import { createAction } from 'redux-actions';
import { isEqual, unionBy } from 'lodash';
import apiService from '../services/api';
import type { ActionType } from '../../common/types/redux';
import type { KycRoundPut, KycRoundGet, KycStaticInformation } from '../../common/types/kyc';
import type { GetContactPerson } from '../../common/types/client';

type DefaultState = RecordOf<{
  kycRound: KycRoundPut,
  initialKycRound: KycRoundPut,
  kycStaticInformation: KycStaticInformation,
  isLoading: boolean,
  isLoadingModal: boolean,
  showContactInformation: boolean
}>;

const defaultState = Record({
  kycRound: {},
  initialKycRound: {},
  kycStaticInformation: {},
  isLoading: false,
  isLoadingModal: false,
  showContactInformation: false
});

const mapKycRoundData = (
  kycRound: KycRoundGet,
  opportunityId: string,
  opportunityName: string
): { kycRound: KycRoundPut, kycStaticInformation: KycStaticInformation } => ({
  kycRound: {
    groundForService: kycRound.groundForService || '',
    groundForUbo: kycRound.groundForUbo,
    ubos: kycRound.ubos,
    representatives: kycRound.representatives,
    clientContactInformation: {
      name: '',
      email: '',
      language: null
    },
    additionalInformation: {
      isUboChanged: false,
      isRiskChanged: false,
      isAmlChanged: false,
      message: ''
    }
  },
  kycStaticInformation: {
    clientId: kycRound.clientId,
    clientName: kycRound.clientName,
    amlClassification: kycRound.amlClassification,
    legalRegulatoryType: kycRound.legalRegulatoryType,
    isPepClient: kycRound.isPepClient,
    opportunityId,
    opportunityName
  }
});

const activeUbosLength = ubos => ubos.reduce((acc, cur) => (cur.isActive ? acc + 1 : acc), 0);

const updateStatus = (person, status) => ({
  ...person,
  isActive: status
});

const updateStatusList = (persons, status): Array<GetContactPerson> =>
  persons.map(person => updateStatus(person, status));

const updatePersonInList = (persons, updatedPerson, isSingleSelect) =>
  persons.map(person => {
    if (person.id === updatedPerson.id) {
      return updatedPerson;
    }
    return isSingleSelect && updatedPerson.isActive ? updateStatus(person, false) : person;
  });

export const updateUboListBasedOnGrounds = (
  ubos: Array<GetContactPerson>,
  groundForUbo: number
): Array<GetContactPerson> => {
  if (groundForUbo === 7) return updateStatusList(ubos, false);
  if (groundForUbo === 3 && activeUbosLength(ubos) > 1) {
    const activeUbo = ubos.find(ubo => ubo.isActive);
    return ubos.map(ubo => (activeUbo && ubo.id === activeUbo.id ? ubo : updateStatus(ubo, false)));
  }
  return ubos;
};

const pickFieldsToCompare = kyc => {
  const { groundForUbo, ubos } = kyc;
  return {
    groundForUbo,
    ubos: ubos.filter(ubo => ubo.isActive)
  };
};

const hasPersonsUpdated = (updatedKyc, initialKyc, amlClassification): boolean => {
  const hasNewActiveRepresentatives =
    [2, 3].includes(amlClassification) &&
    updatedKyc.representatives.some(
      updatedRepresentative =>
        updatedRepresentative.isActive &&
        !initialKyc.representatives.some(
          initialRepresentative => initialRepresentative.id === updatedRepresentative.id
        )
    );
  return (
    hasNewActiveRepresentatives ||
    updatedKyc.additionalInformation.isUboChanged ||
    !isEqual(pickFieldsToCompare(initialKyc), pickFieldsToCompare(updatedKyc))
  );
};

export const getKycRound = createAction(
  'GET_KYC_ROUND',
  ({ clientId, opportunityId, opportunityName }) =>
    apiService
      .getKycRound({ clientId, opportunityId })
      .then(data => mapKycRoundData(data, opportunityId, opportunityName))
);

export const updateRepresentativeStatus = createAction(
  'UPDATE_REPRESENTATIVE_STATUS',
  updateStatus
);
export const updateUboStatus = createAction('UPDATE_UBO_STATUS', updateStatus);
export const deselectAllUbos = createAction('DESELECT_ALL_UBOS');
export const changeGroundForUbo = createAction('CHANGE_GROUND_FOR_UBO', selection => selection);
export const changeGroundForService = createAction('CHANGE_GROUND_FOR_SERVICE', input => input);
export const postContactPerson = createAction(
  'POST_CONTACT_PERSON',
  (clientId, contactPerson, isUbo) =>
    apiService
      .postContactPerson(clientId, contactPerson)
      .then(data => ({ personData: { ...data, isActive: true }, isUbo }))
);
export const putContactPersons = createAction(
  'PUT_CONTACT_PERSONS',
  (clientId, contactPersons, isUbo) =>
    apiService
      .putContactPersons(clientId, contactPersons)
      .then(data => ({ persons: data.map(person => ({ ...person, isActive: true })), isUbo }))
);
export const changeContactInfoName = createAction('CHANGE_CONTACT_INFO_NAME', name => name);
export const changeContactInfoEmail = createAction('CHANGE_CONTACT_INFO_EMAIL', email => email);
export const changeContactInfoLanguage = createAction(
  'CHANGE_CONTACT_INFO_LANGUAGE',
  language => language
);
export const setUboChanged = createAction('SET_UBO_CHANGED', value => value);
export const setRiskChanged = createAction('SET_RISK_CHANGED', value => value);
export const setAmlChanged = createAction('SET_AML_CHANGED', value => value);
export const setAdditionalInfoMessage = createAction('SET_ADDITIONAL_INFO_MESSAGE', value => value);

export const putKycRound = createAction('PUT_KYC_ROUND', apiService.putKycRound);

export default function kycRoundReducer(state: DefaultState = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_KYC_ROUND_PENDING':
      return state.set('isLoading', true);
    case 'GET_KYC_ROUND_REJECTED':
      return state.set('isLoading', false);
    case 'GET_KYC_ROUND_FULFILLED': {
      const updatedKycRound = {
        ...payload.kycRound,
        ubos: updateUboListBasedOnGrounds(payload.kycRound.ubos, payload.kycRound.groundForUbo)
      };
      return state
        .set('kycRound', updatedKycRound)
        .set('initialKycRound', updatedKycRound)
        .set('kycStaticInformation', payload.kycStaticInformation)
        .set('isLoading', false);
    }
    case 'UPDATE_UBO_STATUS': {
      const updatedKycRound = {
        ...state.kycRound,
        ubos: updatePersonInList(state.kycRound.ubos, payload, state.kycRound.groundForUbo === 3)
      };
      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        );
    }
    case 'UPDATE_REPRESENTATIVE_STATUS': {
      const updatedKycRound = {
        ...state.kycRound,
        representatives: updatePersonInList(state.kycRound.representatives, payload)
      };
      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        );
    }
    case 'DESELECT_ALL_UBOS': {
      const updatedKycRound = {
        ...state.kycRound,
        ubos: updateStatusList(state.kycRound.ubos, false)
      };
      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        );
    }
    case 'CHANGE_GROUND_FOR_UBO':
      return state.setIn(['kycRound', 'groundForUbo'], payload);
    case 'CHANGE_GROUND_FOR_SERVICE':
      return state.setIn(['kycRound', 'groundForService'], payload);
    case 'PUT_CONTACT_PERSONS_PENDING':
      return state.set('isLoadingModal', true);
    case 'PUT_CONTACT_PERSONS_REJECTED':
      return state.set('isLoadingModal', false);
    case 'PUT_CONTACT_PERSONS_FULFILLED': {
      if (payload.isUbo) {
        return state
          .setIn(
            ['kycRound', 'ubos'],
            updateUboListBasedOnGrounds(
              unionBy(payload.persons, state.kycRound.ubos, person => person.id),
              state.kycRound.groundForUbo
            )
          )
          .set('showContactInformation', true)
          .set('isLoadingModal', false);
      }
      const updatedKycRound = {
        ...state.kycRound,
        representatives: unionBy(
          payload.persons,
          state.kycRound.representatives,
          person => person.id
        )
      };
      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        )
        .set('isLoadingModal', false);
    }
    case 'POST_CONTACT_PERSON_PENDING':
      return state.set('isLoadingModal', true);
    case 'POST_CONTACT_PERSON_REJECTED':
      return state.set('isLoadingModal', false);
    case 'POST_CONTACT_PERSON_FULFILLED': {
      if (payload.isUbo) {
        return state
          .setIn(
            ['kycRound', 'ubos'],
            updateUboListBasedOnGrounds(
              unionBy([payload.personData], state.kycRound.ubos, person => person.id),
              state.kycRound.groundForUbo
            )
          )
          .set('showContactInformation', true)
          .set('isLoadingModal', false);
      }
      const updatedKycRound = {
        ...state.kycRound,
        representatives: unionBy(
          [payload.personData],
          state.kycRound.representatives,
          person => person.id
        )
      };
      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        )
        .set('isLoadingModal', false);
    }
    case 'CHANGE_CONTACT_INFO_NAME':
      return state.setIn(['kycRound', 'clientContactInformation', 'name'], payload);
    case 'CHANGE_CONTACT_INFO_EMAIL':
      return state.setIn(['kycRound', 'clientContactInformation', 'email'], payload);
    case 'CHANGE_CONTACT_INFO_LANGUAGE':
      return state.setIn(['kycRound', 'clientContactInformation', 'language'], payload);
    case 'SET_UBO_CHANGED': {
      const updatedKycRound = {
        ...state.kycRound,
        additionalInformation: { ...state.kycRound.additionalInformation, isUboChanged: payload }
      };

      return state
        .set('kycRound', updatedKycRound)
        .set(
          'showContactInformation',
          hasPersonsUpdated(
            updatedKycRound,
            state.initialKycRound,
            state.kycStaticInformation.amlClassification
          )
        );
    }
    case 'SET_RISK_CHANGED':
      return state.setIn(['kycRound', 'additionalInformation', 'isRiskChanged'], payload);
    case 'SET_AML_CHANGED':
      return state.setIn(['kycRound', 'additionalInformation', 'isAmlChanged'], payload);
    case 'SET_ADDITIONAL_INFO_MESSAGE':
      return state.setIn(['kycRound', 'additionalInformation', 'message'], payload);
    case 'PUT_KYC_ROUND_PENDING':
      return state.set('isLoading', true);
    case 'PUT_KYC_ROUND_REJECTED':
      return state.set('isLoading', false);
    case 'PUT_KYC_ROUND_FULFILLED': {
      return state.set('isLoading', false);
    }
    default:
      return state;
  }
}
