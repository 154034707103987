// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled, { ThemeProvider } from 'styled-components';
import PageHeader from '../../../common/components/canvas/PageHeader';
import TodoList from './components/TodoList';
import CompletedList from './components/CompletedList';
import SearchBar from './components/SearchBar';
import PopUp from './components/PopUp/index';
import { orange } from '../../../common/theme/theme';
import { getTaxYear, getLanguageList } from '../../duck/HomePage';
import StatusBar from '../../../common/components/StatusBar';

const Container = styled.div`
  background-color: white;
  padding: 48px;
`;

const CompletedListContainer = styled.div`
  display: flex;
  width: 50%;
`;

interface StateProps {
  loading: Map<{ isLoading: boolean, msg: string }>;
}

interface DispatchProps {
  getTaxYear: () => Promise<void>;
  getLanguageList: () => Promise<void>;
}

type Props = StateProps & DispatchProps;

const HomePage = ({ loading, ...dispatchProps }: Props) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    dispatchProps.getTaxYear();
    dispatchProps.getLanguageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={orange}>
      <>
        <PageHeader title="Veromylly" />
        <StatusBar loading={loading} />
        <Container>
          <TodoList />
          <CompletedListContainer>
            <CompletedList />
          </CompletedListContainer>
          <SearchBar setIsPopUpOpen={setIsPopUpOpen} />
          <PopUp isOpen={isPopUpOpen} setIsOpen={setIsPopUpOpen} />
        </Container>
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  loading: state.veromylly.homePage.loading
});

const dispatchProps: DispatchProps = {
  getTaxYear,
  getLanguageList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(HomePage);
