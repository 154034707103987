// @flow
import { Record, fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import services from '../services';
import { type ActionType } from '../../common/types/redux';
import { commentTypes } from '../types';

const defaultState = Record({
  taxReturn: null,
  taxReturnData: null,
  unsavedChanges: false,
  userRole: null,
  documents: null,
  documentTemplates: null,
  assignee: null,
  loading: null,
  extractedData: null
});

export const fetchTaxReturn = createAction('FETCH_TAX_RETURN', (id: number) =>
  services.fetchTaxReturn(id)
);

export const updateFormFieldValue = createAction('UPDATE_FORM_FIELD_VALUE', (value, ...path) => ({
  value,
  path
}));

export const updateExtractedFieldValue = createAction(
  'UPDATE_EXTRACTED_FIELD_VALUE',
  (value, ...path) => ({ value, path })
);

export const saveTaxReturn = createAction('SAVE_TAX_RETURN', (metadata, data) =>
  services.saveTaxReturn(parseInt(metadata.id, 10), { ...metadata, data: data.toJS() })
);

export const sendForReview = createAction('SEND_TAX_RETURN_FOR_REVIEW', (id: number) =>
  services.sendForReview(id)
);

export const approveTaxReturn = createAction('APPROVE_TAX_RETURN', (id: number) =>
  services.approveTaxReturn(id)
);

export const rejectTaxReturn = createAction('REJECT_TAX_RETURN', (id: number) =>
  services.rejectTaxReturn(id)
);

export const updateReviewer = createAction('UPDATE_REVIEWER');
export const addComment = createAction('ADD_COMMENT', (comment, ...path) => ({
  comment,
  path
}));

export const previewTaxForm = createAction('PREVIEW_TAX_FORM', (formId: number, metadata, data) =>
  services.previewTaxForm(formId, { ...metadata, data: data.toJS() })
);

export const previewDocument = createAction('PREVIEW_DOCUMENT', (documentId: number) =>
  services.previewDocument(documentId)
);

export const addNewTaxForm = createAction(
  'ADD_NEW_TAX_FORM',
  async (path: Array<string>, name: string, id) => {
    const template = await services.getPackageTemplate(id);
    return {
      path,
      name,
      template
    };
  }
);

export const deleteTaxForm = createAction('DELETE_TAX_FORM');

export const fetchDocuments = createAction(
  'FETCH_DOCUMENTS',
  (clientId: number, assigneeId: number, yearId: number) =>
    services.fetchDocuments(clientId, assigneeId, yearId)
);

export const uploadDocuments = createAction('UPLOAD_DOCUMENTS', services.uploadDocuments);

export const addNewDocument = createAction('ADD_NEW_DOCUMENT', services.addNewDocument);

export const addExistingDocument = createAction(
  'ADD_EXISTING_DOCUMENT',
  (documentId: number, sectionName: string) => ({
    documentId,
    sectionName
  })
);

export const getExtractedQuestionnaireData = createAction(
  'GET_EXTRACTED_QUESTIONNAIRE_DATA',
  services.getExtractedQuestionnaireData
);

export const importExtractedData = createAction(
  'IMPORT_EXTRACTED_DATA',
  services.importExtractedData
);

export const fetchDocumentTemplates = createAction('FETCH_DOCUMENT_TEMPLATES', () =>
  services.fetchDocumentTemplates()
);

export const fetchAssignee = createAction('FETCH_ASSIGNEE', (assigneeId: number) =>
  services.fetchAssignee(assigneeId)
);

export const previewPackage = createAction('PREVIEW_PACKAGE', services.previewPackage);

export const completeTaxReturn = createAction('COMPLETE_TAX_RETURN', services.completeTaxReturn);

export const updateLanguage = createAction('UPDATE_LANGUAGE');
export const updateCountry = createAction('UPDATE_COUNTRY');

export const updateLoading = createAction('UPDATE_LOADING', (isLoading: boolean, msg: string) => ({
  isLoading,
  msg
}));

const Reducer = (state: any = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * Current tax return
     */
    case 'FETCH_TAX_RETURN_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Fetching tax return' }));
    }
    case 'FETCH_TAX_RETURN_FULFILLED': {
      const { data, userrole, ...taxReturnInfo } = payload;
      return state
        .set('taxReturn', taxReturnInfo)
        .set('taxReturnData', fromJS(data))
        .set('unsavedChanges', false)
        .set('loading', fromJS({ isLoading: false, msg: '' }))
        .set('userRole', userrole);
    }
    case 'SAVE_TAX_RETURN_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Saving tax return' }));
    }
    case 'SAVE_TAX_RETURN_FULFILLED': {
      return state.set('unsavedChanges', false).set(
        'loading',
        fromJS({
          isLoading: false,
          msg: ''
        })
      );
    }
    case 'PREVIEW_TAX_FORM_REJECTED':
    case 'PREVIEW_PACKAGE_REJECTED':
    case 'FETCH_DOCUMENTS_REJECTED':
    case 'FETCH_DOCUMENT_TEMPLATES_REJECTED':
    case 'FETCH_ASSIGNEE_REJECTED':
    case 'UPLOAD_DOCUMENTS_REJECTED':
    case 'PREVIEW_DOCUMENT_REJECTED':
    case 'FETCH_TAX_RETURN_REJECTED':
    case 'SAVE_TAX_RETURN_REJECTED': {
      return state.set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'SEND_TAX_RETURN_FOR_REVIEW_PENDING': {
      return state.set(
        'loading',
        fromJS({ isLoading: true, msg: 'Sending tax return for review' })
      );
    }
    case 'REJECT_TAX_RETURN_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Rejecting tax return' }));
    }
    case 'APPROVE_TAX_RETURN_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Approving tax return' }));
    }
    case 'COMPLETE_TAX_RETURN_PENDING': {
      return state.set(
        'loading',
        fromJS({ isLoading: true, msg: 'Sending tax return to compliance' })
      );
    }
    case 'SEND_TAX_RETURN_FOR_REVIEW_FULFILLED':
    case 'REJECT_TAX_RETURN_FULFILLED':
    case 'APPROVE_TAX_RETURN_FULFILLED':
    case 'COMPLETE_TAX_RETURN_FULFILLED': {
      const updated = {
        ...state.taxReturn,
        state: payload
      };
      return state.set('taxReturn', updated).set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'UPDATE_FORM_FIELD_VALUE': {
      const { value, path } = payload;
      return state
        .setIn(['taxReturnData', ...path], value)
        .setIn(['taxReturnData', ...path.slice(0, -1), 'imported'], false)
        .set('unsavedChanges', true);
    }
    case 'UPDATE_EXTRACTED_FIELD_VALUE': {
      const { value, path } = payload;
      return state.setIn(['extractedData', ...path], value);
    }
    case 'ADD_COMMENT': {
      const { comment, path } = payload;
      return state
        .updateIn(['taxReturnData', ...path, 'comments'], comments =>
          comments.push(fromJS(comment))
        )
        .setIn(['taxReturnData', ...path, 'approved'], comment.type === commentTypes.APPROVAL)
        .setIn(['taxReturnData', ...path, 'rejected'], comment.type === commentTypes.REJECTION)
        .set('unsavedChanges', true);
    }
    case 'PREVIEW_TAX_FORM_PENDING':
    case 'PREVIEW_PACKAGE_PENDING':
    case 'PREVIEW_DOCUMENT_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Generating preview PDF' }));
    }
    case 'PREVIEW_TAX_FORM_FULFILLED':
    case 'PREVIEW_PACKAGE_FULFILLED':
    case 'PREVIEW_DOCUMENT_FULFILLED': {
      window.open(payload, '_blank');
      return state.set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'UPDATE_REVIEWER': {
      const updatedTaxReturn = {
        ...state.taxReturn,
        reviewer: payload
      };
      return state.set('taxReturn', updatedTaxReturn).set('unsavedChanges', true);
    }
    case 'FETCH_DOCUMENTS_FULFILLED': {
      return state.set('documents', fromJS(payload));
    }
    case 'UPLOAD_DOCUMENTS_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Uploading documents' }));
    }
    case 'UPLOAD_DOCUMENTS_FULFILLED': {
      return state
        .set('documents', fromJS(payload))
        .set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'UPDATE_LANGUAGE': {
      const updatedLanguage = {
        ...state.taxReturn,
        language: payload
      };
      return state.set('taxReturn', updatedLanguage).set('unsavedChanges', true);
    }
    case 'UPDATE_COUNTRY': {
      const updated = {
        ...state.taxReturn,
        country: payload
      };
      return state.set('taxReturn', updated).set('unsavedChanges', true);
    }
    case 'ADD_NEW_DOCUMENT_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Adding document' }));
    }
    case 'ADD_NEW_DOCUMENT_FULFILLED': {
      return state
        .update('documents', documents => documents.insert(0, fromJS(payload)))
        .set('loading', fromJS({ isLoading: false, msg: '' }))
        .updateIn(['taxReturnData', 'documents'], documents =>
          documents.push(
            fromJS({
              uuid: uuidv4(),
              id: payload.id,
              name: payload.name,
              comments: []
            })
          )
        )
        .set('loading', fromJS({ isLoading: false, msg: '' }))
        .set('unsavedChanges', true);
    }
    case 'ADD_NEW_DOCUMENT_REJECTED': {
      return state.set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'ADD_EXISTING_DOCUMENT': {
      return state
        .updateIn(['taxReturnData', 'documents'], documents =>
          documents.push(
            fromJS({
              uuid: uuidv4(),
              id: payload.documentId,
              name: payload.sectionName,
              comments: []
            })
          )
        )
        .set('unsavedChanges', true);
    }
    case 'GET_EXTRACTED_QUESTIONNAIRE_DATA_PENDING': {
      return state.set(
        'loading',
        fromJS({ isLoading: true, msg: 'Extracting questionnaire data' })
      );
    }
    case 'GET_EXTRACTED_QUESTIONNAIRE_DATA_FULFILLED': {
      return state
        .set('extractedData', fromJS(payload))
        .set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'GET_EXTRACTED_QUESTIONNAIRE_DATA_REJECTED': {
      return state.set('extractedData', null).set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'IMPORT_EXTRACTED_DATA_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Importing extracted data' }));
    }
    case 'IMPORT_EXTRACTED_DATA_FULFILLED': {
      const { data } = payload;
      return state
        .set('taxReturnData', fromJS(data))
        .set('extractedData', null)
        .set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'IMPORT_EXTRACTED_DATA_REJECTED': {
      return state.set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'FETCH_DOCUMENT_TEMPLATES_FULFILLED': {
      return state.set('documentTemplates', fromJS(payload));
    }
    case 'FETCH_ASSIGNEE_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Fetching assignee data' }));
    }
    case 'FETCH_ASSIGNEE_FULFILLED': {
      return state
        .set('assignee', fromJS(payload))
        .set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    case 'UPDATE_LOADING': {
      return state.set('loading', fromJS(payload));
    }
    case 'ADD_NEW_TAX_FORM_FULFILLED': {
      const duplicateCount = state.getIn(['taxReturnData', 'tabs', ...payload.path]).size + 1;

      return state
        .updateIn(['taxReturnData', 'tabs', ...payload.path], list =>
          list.push(
            fromJS({
              ...payload.template,
              name: `${payload.template.name}${duplicateCount > 1 ? `-${duplicateCount}` : ''}`
            })
          )
        )
        .set('unsavedChanges', true);
    }
    case 'DELETE_TAX_FORM': {
      return state.deleteIn(['taxReturnData', 'tabs', ...payload]).set('unsavedChanges', true);
    }
    default:
      return state;
  }
};

export default Reducer;
