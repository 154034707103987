import React from 'react';

export default () => (
  <div>
    <span>
      Indicate if you want to request for automated roll-forward of existing Aura database.
      <br />
      <br />
      Note! If the Aura database is indicated here, it will be automatically rolled forward (you can
      change the Aura database to be rolled forward by clicking the arrow icon and selecting
      ‘Roll-forward’ procedure).
      <br />
      <br />
      If you want to create a new database or roll-forward manually, remove the name by selecting
      procedure ‘Do not roll-forward’.
    </span>
  </div>
);
