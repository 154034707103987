/* eslint-disable no-nested-ternary */
// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { type OrderedMap } from 'immutable';
import { type AccountMapper } from '../../../types/accountMapping';
import { SearchSelection } from '../../../../common/components/selectors';
import TextLabel from '../../../../common/components/TextLabel';
import TextInput from '../../../../common/components/TextInput';
import ToggleCustomColumn from './ToggleCustomColumn';

const AccountItem = styled.div`
  padding: 15px;
`;

const Item = styled.div`
  height: 80px;
  padding-left: 10px;
`;

const CustomHeading = styled.div`
  padding: ${props => props.theme.space.square.m};
  margin: 0 0 20px 0;
  border-bottom: ${props => `1px solid ${props.theme.color.border.default}`};
  border-right: ${props =>
    props.noRightBorder ? '0' : `1px solid ${props.theme.color.border.default}`};
`;

const FSLIHeading = styled(CustomHeading)`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  float: left;
  width: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.firstColumn && props.showCustomColumn
      ? '15%'
      : !props.firstColumn && props.showCustomColumn
      ? '42%'
      : '50%'};
`;

type Props = {
  column: {
    id: number,
    type: string,
    heading: string,
    tag: string,
    menuText: string
  },
  showCustomColumn: boolean,
  showCustomInput: boolean,
  accountMappingList: OrderedMap<AccountMapper>,
  fetchFsliList: Function,
  fetchCustomFsliList: Function,
  saveSelectedFsli: Function,
  saveCustomInput: Function,
  toggleModal: Function,
  deleteCustomFsli: Function
};

const CustomColumn = ({
  column,
  showCustomColumn,
  showCustomInput,
  accountMappingList,
  fetchFsliList,
  fetchCustomFsliList,
  saveSelectedFsli,
  saveCustomInput,
  toggleModal,
  deleteCustomFsli
}: Props) => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const toggleCustomColumn = flag => {
    toggleModal(flag, 'Custom FSLI File Upload (.csv)');
    setMenuVisibility(!flag);
  };

  return (
    <Column data-cy={column.tag} showCustomColumn={showCustomColumn} firstColumn={column.id === 1}>
      {column.id === 1 ? (
        <CustomHeading>
          <TextLabel bold>{column.heading}</TextLabel>
        </CustomHeading>
      ) : (
        <FSLIHeading
          noRightBorder={
            (!showCustomColumn && column.id === 2) || (showCustomColumn && column.id === 3)
          }
        >
          <TextLabel bold>{column.heading}</TextLabel>

          {((!showCustomColumn && column.id === 2) || (showCustomColumn && column.id === 3)) && (
            <ToggleCustomColumn
              type={column.type}
              onMouseLeave={() => setMenuVisibility(false)}
              visible={isMenuVisible}
              text={column.menuText}
              onClick={() => setMenuVisibility(true)}
              toggleFsli={() =>
                column.type === 'fsli' ? toggleCustomColumn(true) : deleteCustomFsli()
              }
            />
          )}
        </FSLIHeading>
      )}
      {accountMappingList &&
        accountMappingList
          .valueSeq()
          .toArray()
          .map(item => (
            <Item key={`${column.id}-${item.fsliMappingId}-${item.updatedAt}`}>
              {column.id === 1 ? (
                <AccountItem>
                  <TextLabel noPadding clientName>
                    {item.accountNumber}
                  </TextLabel>
                  <TextLabel noPadding>{item.accountName}</TextLabel>
                </AccountItem>
              ) : column.id === 2 ? (
                <SearchSelection
                  type="FSLI"
                  placeholder="Select an FSLI"
                  value={item.fsli ? { label: item.fsli, value: item.fsli } : null}
                  fetchData={fetchFsliList}
                  saveToStore={input => saveSelectedFsli(item.fsliMappingId, 'fsli', input)}
                />
              ) : showCustomInput ? (
                <TextInput
                  customWidth="80%"
                  onChange={e => {
                    saveCustomInput({
                      fsliMappingId: item.fsliMappingId,
                      customFSLI: e.target.value
                    });
                  }}
                  defaultValue={item.customFSLI}
                />
              ) : (
                <SearchSelection
                  type="CustomFSLI"
                  placeholder="Select a Custom FSLI"
                  value={
                    item.customFSLI ? { label: item.customFSLI, value: item.customFSLI } : null
                  }
                  fetchData={fetchCustomFsliList}
                  saveToStore={input => saveSelectedFsli(item.fsliMappingId, 'customFSLI', input)}
                />
              )}
            </Item>
          ))}
    </Column>
  );
};

export default CustomColumn;
