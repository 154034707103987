// @flow

import { Record, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import services from '../services';
import { type ActionType } from '../../common/types/redux';
import { type ServiceOrder, type File } from '../types';
import { defaultSort } from '../utils';

const defaultState = Record({
  myOrders: [],
  selectedOrders: [],
  files: OrderedMap({})
});

type DefaultState = OrderedMap<{
  myOrders: ServiceOrder[],
  selectedOrders: ServiceOrder[],
  files: OrderedMap<number, File[]>
}>;

type GetFileActionResponse = {
  serviceOrderId: number,
  files: File[]
};

export const getMyOrders = createAction('GET_MY_ORDERS', services.getMyOrder);
export const selectOrders = createAction<ServiceOrder[]>('SELECT_ORDERS');
export const unselectOrder = createAction<number>('UNSELECT_ORDER');
export const getFiles = createAction('GET_FILES', async (serviceOrderId: number) => {
  const files: File[] = await services.getFiles(serviceOrderId);
  return ({
    files,
    serviceOrderId
  }: GetFileActionResponse);
});
export const resetState = createAction('RESET_STATE');

const Reducer = (state: DefaultState = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_MY_ORDERS_FULFILLED': {
      return state.set('myOrders', defaultSort(payload));
    }
    case 'SELECT_ORDERS': {
      return state.set('selectedOrders', payload);
    }
    case 'UNSELECT_ORDER': {
      const removedId: number = payload;
      return state.set(
        'selectedOrders',
        state.get('selectedOrders').filter((o: ServiceOrder) => o.id !== removedId)
      );
    }
    case 'GET_FILES_FULFILLED': {
      const filesPayload: GetFileActionResponse = payload;
      return state.setIn(
        ['files', filesPayload.serviceOrderId],
        filesPayload.files.sort((a: File, b: File) => {
          // Sort DESC
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
      );
    }
    case 'RESET_STATE': {
      return defaultState();
    }
    default:
      return state;
  }
};

export default Reducer;
