// @flow
import React from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { type TaxReturn, type Document } from '../../../../types';
import { previewDocument, uploadDocuments } from '../../../../duck/TaxReturnPage';

const StyledDialogActions = styled(DialogActions)`
  input: {
    display: 'none';
  }
`;

interface StateProps {
  taxReturn: TaxReturn;
  documents?: ImmutableList<Document>;
}

interface OwnProps {
  open: boolean;
  onClose: Function;
}

interface DispatchProps {
  previewDocument: Function;
  uploadDocuments: Function;
}

type Props = StateProps & OwnProps & DispatchProps;

const DocumentListDialog = ({ taxReturn, documents, open, onClose, ...dispatchProps }: Props) => (
  <Dialog maxWidth="md" open={open} onClose={onClose}>
    <DialogTitle>Related documents</DialogTitle>
    {documents && documents.size > 0 ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Document name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map(document => (
            <TableRow key={document.get('id')}>
              <TableCell>{document.get('filename') || document.get('name')}</TableCell>
              <TableCell>{document.get('category')}</TableCell>
              <TableCell>
                {document.get('filename') ? (
                  <Link
                    href="#"
                    onClick={async event => {
                      event.preventDefault();
                      await dispatchProps.previewDocument(document.get('id'));
                    }}
                  >
                    Preview
                  </Link>
                ) : null}

                {document.get('viewUrl') ? (
                  <>
                    {' | '}
                    <Link href={document.get('viewUrl')}>View</Link>
                  </>
                ) : null}
                {document.get('editUrl') ? (
                  <>
                    {' | '}
                    <Link href={document.get('editUrl')}>Edit</Link>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <DialogContent>No documents found</DialogContent>
    )}
    <StyledDialogActions>
      <Button onClick={onClose}>Close</Button>
      <Button color="primary" component="label">
        Upload
        <input
          accept=".pdf,.doc,.docx,.xml"
          hidden
          multiple
          type="file"
          onChange={async event => {
            await dispatchProps.uploadDocuments(
              event.target.files,
              taxReturn.client.id,
              taxReturn.assignee.id,
              taxReturn.year.id,
              taxReturn.country.id
            );
          }}
        />
      </Button>
    </StyledDialogActions>
  </Dialog>
);

const mapStateToProps = state => {
  const { taxReturn, documents } = state.veromylly.taxReturnPage;
  return {
    taxReturn,
    documents
  };
};

const mapDispatch = {
  previewDocument,
  uploadDocuments
};

export default connect(
  mapStateToProps,
  mapDispatch
)(DocumentListDialog);
