// @flow
import React from 'react';
import moment from 'moment';
import { LabelWrapper, CustomHeading, CustomTextLabel } from './Common';

type Props = {
  id: string,
  created: string,
  creator: string
};

const CreationInfo = ({ id, created, creator }: Props) => (
  <>
    <CustomHeading noPadding>Created</CustomHeading>
    <LabelWrapper>
      <CustomTextLabel data-cy={`created-${id}`}>
        {moment(created).format('DD/MM/YYYY')}
      </CustomTextLabel>
      <CustomTextLabel noCustomColor>by</CustomTextLabel>
      <CustomTextLabel data-cy={`creator-${id}`}>{creator}</CustomTextLabel>
    </LabelWrapper>
  </>
);

export default CreationInfo;
