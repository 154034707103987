// @flow
import { Record, List } from 'immutable';
import { createAction } from 'redux-actions';
import { type AccountSystemState as State } from '../types/duck';
import { fetchAccountSystem } from '../services/accountSystem';
import { type ActionType } from '../../common/types/redux';

const defaultState = Record({
  accountSystemList: List(),
  selectedAccountSystem: null
});
export const getAccountSystemList = createAction('FETCH_ACCOUNT_SYSTEM_LIST', () =>
  fetchAccountSystem()
);
export const selectAccountSystem = createAction('SELECT_ACCOUNT_SYSTEM');
export const resetAccountSystem = createAction('RESET_ACCOUNT_SYSTEM');
// reducer
export default (state: State = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'FETCH_ACCOUNT_SYSTEM_LIST_FULFILLED': {
      return state.set(
        'accountSystemList',
        payload
          .filter(accountSystem => accountSystem.contentFormats)
          .map(accountSystem => ({
            ...accountSystem,
            contentFormats: accountSystem.contentFormats.sort((a, b) => {
              const types =
                accountSystem.contentFormats.length === 2 ? ['GL', 'TB'] : ['GL', 'PL', 'BS'];
              return types.indexOf(a.type) - types.indexOf(b.type);
            })
          }))
      );
    }
    case 'SELECT_ACCOUNT_SYSTEM': {
      return state.set('selectedAccountSystem', payload);
    }

    case 'SAVE_SESSION_CLIENT':
    case 'SAVE_SELECTED_JOB':
    case 'RESET_ACCOUNT_SYSTEM': {
      return state.set('selectedAccountSystem', null);
    }
    case 'CLEAN_GENERAL_LEDGER_DUCK': {
      return defaultState();
    }

    default:
      return state;
  }
};
