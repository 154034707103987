// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Send as SendIcon } from '@material-ui/icons';

import { type TaxReturn } from '../../../../types';
import Button from '../../../../../common/components/Button';

interface StateProps {
  taxReturn: TaxReturn;
  unsavedChanges: boolean;
}

interface OwnProps {
  onClick: Function;
}

type Props = StateProps & OwnProps;

const Send = ({ taxReturn: { reviewer }, unsavedChanges, onClick }: Props) => (
  <Button
    data-cy="sidebar-review-button"
    solid
    primary
    disabled={unsavedChanges || !reviewer}
    onClick={onClick}
    title={
      // eslint-disable-next-line no-nested-ternary
      unsavedChanges
        ? `All changes must be saved before proceeding`
        : !reviewer
        ? `Tax return must have a reviewer before sending it to review`
        : `Send tax return for review`
    }
  >
    <SendIcon />
    Send for review
  </Button>
);

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn,
  unsavedChanges: state.veromylly.taxReturnPage.unsavedChanges
});

export default connect(
  mapStateToProps,
  {}
)(Send);
