// @flow
import { Map, Record, OrderedMap } from 'immutable';
import { get } from 'lodash';
import type { TableItemProps, TableItemFactory } from '../types/table';
import type { ApiOpportunityList, OpportunityOrderedMap } from '../types/opportunity';

const defaultValues: TableItemProps = {
  key: '',
  state: 0,
  datum: Map(),
  expanded: false
};

const factory: TableItemFactory = Record(defaultValues);

export const initHierarchy = (items: any, expanded?: boolean = false) => {
  const hierarchy = items.reduce((acc, cur) => {
    const key = cur.id || cur.clientId;
    const item = factory({
      id: key,
      key,
      expanded,
      state: 0,
      datum: {
        ...cur,
        id: cur.id || cur.clientId,
        children: OrderedMap(
          cur.children.map(child => [
            child.id || child.clientId,
            OrderedMap({
              datum: {
                ...child,
                headClientId: key,
                id: child.id || child.clientId
              },
              state: 0
            })
          ])
        )
      }
    });
    return acc.set(key, item);
  }, OrderedMap());

  return hierarchy;
};

export const initOpportunityOrderedMap = (items: ApiOpportunityList): OpportunityOrderedMap =>
  items.reduce((acc, cur) => {
    const clientId = get(cur, ['client', 'id']);
    const opportunityId = get(cur, 'id');
    const key = `${clientId}/${opportunityId}`;
    return acc.set(key, { ...cur, key, isEnabled: true });
  }, OrderedMap());

export default factory;
