import styled from 'styled-components';

const InputBox = styled.textarea`
  width: 100%;
  min-height: 50px;
  padding: ${props => props.theme.space.single.s};
  color: ${props => props.theme.color.ui.primary};
  outline: none;
`;

export default InputBox;
