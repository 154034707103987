import Warning from '@material-ui/icons/Warning';
import Done from '@material-ui/icons/Done';
import styled from 'styled-components';
import Close from '@material-ui/icons/Close';

// override external library
export const DoneIcon = styled(Done)`
  && {
    color: ${props => props.theme.color.ui.success};
  }
`;

// override external library
export const WarningIcon = styled(Warning)`
  && {
    color: ${props => props.theme.color.ui.error};
  }
`;
export const FailedIcon = styled(Close)`
  && {
    color: ${props => props.theme.color.ui.error};
  }
`;
