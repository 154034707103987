// @flow
import React from 'react';
import styled from 'styled-components';
import { ClientName } from '../../../../common/components/table/TableRow';
import TitleLabelWithTooltip from '../../../../common/components/tooltip/TitleLabelWithTooltip';
import { mapCodeToAmlClassification } from '../../../utils/helper';

type Props = {
  clientName: string,
  opportunityName: string,
  amlClassification: number,
  legalRegulatoryType: string,
  isPepClient: boolean
};

const StyledInfoSection = styled.div`
  display: flex;
  height: 120px;
  & > * {
    flex-grow: 1;
    padding: ${props => props.theme.space.square.l};
    background-color: ${props => props.theme.color.background.light};
    span:first-of-type {
      line-height: 28px;
    }
  }
  & > :first-child {
    flex-grow: 2;
    text-align: center  
    background-color: ${props => props.theme.color.ui.light};
  }
`;

const OpportunityName = styled.span`
  display: block;
  margin: ${props => props.theme.space.inline.m};
  color: ${props => props.theme.color.ui.secondary};
  font-size: 18px;
  line-height: 1.5;
`;

export default function HeaderArea({
  clientName,
  opportunityName,
  amlClassification,
  legalRegulatoryType,
  isPepClient
}: Props) {
  return (
    <StyledInfoSection data-cy="opportunities-header-area">
      <div>
        <ClientName>{clientName}</ClientName>
        <OpportunityName>{opportunityName}</OpportunityName>
      </div>
      <div>
        <TitleLabelWithTooltip
          text="AML Classification"
          tooltip={{
            __html: '<p>The AML classification is based on PwC Finland’s AML risk assessment.</p>'
          }}
        />
        <p>{mapCodeToAmlClassification(amlClassification)}</p>
      </div>
      <div>
        <TitleLabelWithTooltip
          text="Legal & Regulatory Type"
          tooltip={{
            __html:
              '<p>Indication of known restriction for the client regarding Politically Exposed Persons, sanctions and Negative News.</p>'
          }}
        />
        <p>{legalRegulatoryType}</p>
      </div>
      <div>
        <TitleLabelWithTooltip
          text="PEP Client"
          tooltip={{
            __html:
              '<p>If "Yes", ensure to carry out enhanced monitoring when performing the work.</p> <p>For more information regarding the PEP status please contact Client Desk (fi_kyc@pwc.com).</p>'
          }}
        />
        <p>{isPepClient ? 'Yes' : 'No'}</p>
      </div>
    </StyledInfoSection>
  );
}
