// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Button from '../Button';

type Props = {
  title: string,
  titleInfo?: string,
  isOpen: boolean,
  closeDialog: Function,
  ButtonElement?: null | ({ onClick: Function, disabled: boolean }) => Node,
  dialogStyle?: Object,
  contentStyle?: Object,
  maxWidth?: string,
  buttonIsDisabled?: boolean,
  hasCloseButton?: boolean,
  closeButtonOnClick?: Function,
  content: () => Node,
  scroll?: string,
  hasBlurBackdrop: boolean
};

export const DialogStyledContent = styled(DialogContent)`
  svg {
    vertical-align: middle;
  }
  .highlight {
    color: ${props => props.theme.color.ui.primary};
  }
  li {
    padding: ${props => props.theme.space.inline.s};
  }
`;

const TitleInfo = styled.div`
  display: flex;
  color: ${props => props.theme.color.ui.error};
  font-size: ${props => props.theme.fonts.size.body.xs};
`;

const StyledDialog = styled(Dialog)`
  .flex-wrapper {
    display: flex;
    align-items: center;
  };
  backdrop-filter: ${props => props.hasBlurBackdrop? 'blur(100px)' : 'unset'};
`;

const DialogBox = ({
  isOpen,
  content,
  title,
  titleInfo,
  closeDialog,
  ButtonElement,
  dialogStyle,
  contentStyle,
  maxWidth,
  buttonIsDisabled,
  hasCloseButton,
  closeButtonOnClick,
  scroll,
  hasBlurBackdrop
}: Props) => (
  <div>
    <StyledDialog
      open={isOpen}
      aria-labelledby="scroll-dialog-title"
      maxWidth={maxWidth}
      PaperProps={{ style: dialogStyle }}
      scroll={scroll}
      classes={{ scrollBody: scroll === 'body' && 'flex-wrapper' }}
      hasBlurBackdrop={hasBlurBackdrop}
    >
      <DialogTitle data-cy="dialog-box-title">
        <Grid direction="column" container>
          <Grid>
            {title}
            {hasCloseButton && (
              <IconButton
                onClick={closeButtonOnClick}
                style={{
                  float: 'right',
                  padding: 0
                }}
              >
                <Close />
              </IconButton>
            )}
          </Grid>
          {titleInfo && <TitleInfo>{titleInfo}</TitleInfo>}
        </Grid>
      </DialogTitle>
      <DialogStyledContent data-cy="dialog-box-content" style={contentStyle}>
        {content()}
      </DialogStyledContent>
      {ButtonElement && <ButtonElement onClick={closeDialog} disabled={!!buttonIsDisabled} />}
    </StyledDialog>
  </div>
);

DialogBox.defaultProps = {
  ButtonElement: ({ onClick, disabled }: { onClick: Function, disabled: boolean }) => (
    <Button data-cy="dialogButton" primary solid onClick={onClick} disabled={disabled}>
      Got it
    </Button>
  ),
  dialogStyle: {},
  contentStyle: {},
  maxWidth: 'sm',
  buttonIsDisabled: false,
  hasCloseButton: false,
  closeButtonOnClick: () => null,
  scroll: 'paper',
  hasBlurBackdrop: false
};

export default DialogBox;
