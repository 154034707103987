// @flow
import { get, put } from '../../common/utils/api';
import { type ActivityLogRow, type ActivityLogsFilters } from '../types/activityLog';
import endpoint from '../constants/endpoint';

export default {
  fetchActivityLogs: async (query: ActivityLogsFilters): Promise<ActivityLogRow> =>
    get(endpoint.rollForward.getHistory(), query),
  linkAuraToRF: async (rollforwardId: string, auraDb: Object): Promise<string> =>
    put(endpoint.rollForward.putAura(rollforwardId, auraDb.id), auraDb)
};
