/* eslint-disable react/jsx-one-expression-per-line */
// @flow
import React from 'react';
import Info from '@material-ui/icons/Info';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Tooltip from '../../../../common/components/tooltip/Tooltip';
import {
  SubSection,
  FlexSpaceBetween,
  CustomTextLabel,
  Span,
  FlexDisplay
} from './StyledComponent';

export type RowLeftProps = {
  clientName: string,
  subsidiaryCount: number,
  job: string,
  groupDatasetId: string,
  createdDate: string,
  startDate: string,
  endDate: string
};
const RowLeft = ({
  clientName,
  subsidiaryCount,
  job,
  groupDatasetId,
  createdDate,
  startDate,
  endDate
}: RowLeftProps) => (
  <SubSection left>
    <FlexSpaceBetween>
      <div>
        <CustomTextLabel noBottomPadding left data-cy={`${clientName}-subsidiary-count`}>
          <Span data-cy={clientName}>{clientName}</Span>{' '}
          {subsidiaryCount !== 0 && `+ ${subsidiaryCount} subsidiaries`}
        </CustomTextLabel>
        <CustomTextLabel left>{job}</CustomTextLabel>
      </div>

      <Tooltip flexstart>
        <div>
          <Info />
          <div>
            Group dataset id:
            {groupDatasetId}
          </div>
        </div>
      </Tooltip>
    </FlexSpaceBetween>
    <FlexSpaceBetween>
      <FlexDisplay>
        <QueryBuilder />
        <div>
          <CustomTextLabel stack left data-cy={`${clientName}-created`}>
            {createdDate}
          </CustomTextLabel>
          <CustomTextLabel left>created</CustomTextLabel>
        </div>
      </FlexDisplay>
      <FlexDisplay>
        <QueryBuilder />
        <div>
          <CustomTextLabel stack left data-cy={`${clientName}-duration`}>
            {startDate}-{endDate}
          </CustomTextLabel>
          <CustomTextLabel left>Selected period</CustomTextLabel>
        </div>
      </FlexDisplay>
    </FlexSpaceBetween>
  </SubSection>
);

export default RowLeft;
