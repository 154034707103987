import styled from 'styled-components';
import TextLabel from '../../../../../common/components/TextLabel';

export const LabelWrapper = styled.div`
  margin-left: ${props => props.theme.space.single.s};
`;

export const CustomHeading = styled(TextLabel)`
  margin: ${props => props.theme.space.single.s};
`;

export const CustomTextLabel = styled(TextLabel)`
  color: ${props => (props.noCustomColor ? 'default' : props.theme.color.text.dark)};
  float: left;
  padding: 2px;
`;
