// @flow
import { type List } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import Clear from '@material-ui/icons/Clear';
import TextLabel from '../../TextLabel';
import Button from '../../Button';

type Props = {
  selectedList: List<{ name: string, id: number | string }> | null,
  type: string,
  Icon: string,
  size?: string,
  removeSelection: (item: Object) => void
};
const ChipList = styled.div`
  ${Button} {
    margin: ${props => props.theme.space.single.xs};
  }
  text-align: ${props => (props.size === 'small' ? 'center' : 'left')};
`;

const SelectedList = ({ selectedList, type, Icon, size, removeSelection }: Props) =>
  selectedList &&
  selectedList.size > 0 && (
    <>
      {size !== 'small' && <TextLabel noBottomPadding>{`Selected ${type}`}</TextLabel>}
      <ChipList data-cy="selected-tag" type={type} size={size}>
        {selectedList.map((item, i) => (
          <Button
            primary
            solid
            key={item.id || Math.random()}
            data-cy={`selected-tag-${type.toLowerCase()}-${i}`}
            size={(size === 'small' && 's') || 'm'}
          >
            <Icon />
            {item.name}
            <Clear
              data-cy={`selected-tag-${type.toLowerCase()}-${i}-remove`}
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                cursor: 'pointer'
              }}
              onClick={() => removeSelection(item)}
            >
              remove
            </Clear>
          </Button>
        ))}
      </ChipList>
    </>
  );

SelectedList.defaultProps = {
  size: 'default'
};

export default SelectedList;
