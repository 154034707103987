import { connect } from 'react-redux';
import Jobs from '../Jobs';
import {
  deselectedRollForward,
  updateRollForward,
  updateRollForwardSubProperty,
  selectRollForwards,
  selectRollForwardsPartially,
  submitRollForwards,
  toggleExpandRollForward,
  selectSubsidiary,
  deselectSubsidiary
} from '../../../../duck/rollForward';
import { triggerInstruction } from '../../../../duck/general';

export default connect(
  state => ({
    audits: state.rollForward.selectedRollForwards,
    isOnboardingFinished: state.rfGeneral.isOnboardingFinished,
    rollForwards: state.rollForward.rollForwards,
    loading: state.rfGeneral.loading
  }),
  {
    deselectedRollForward,
    toggleExpandRollForward,
    updateRollForward,
    updateRollForwardSubProperty,
    selectRollForwards,
    selectRollForwardsPartially,
    submitRollForwards,
    triggerInstruction,
    selectSubsidiary,
    deselectSubsidiary
  }
)(Jobs);
