// @flow
import React, { PureComponent } from 'react';
import { type List } from 'immutable';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Redo from '@material-ui/icons/Redo';
import { Box, BoxItem } from '../../common/components/canvas/BoxView';
import Button, { Buttons } from '../../common/components/Button';
import { type Audit } from '../types/audit';
import Wrapper from '../../common/components/Wrapper';
import { DoneIcon } from '../../common/components/Icons';

type Props = {
  summary: List<Audit & { hasRollForwardFinished: boolean }>,
  resetRollForward: Function,
  history: any
};

class SubmmittedPage extends PureComponent<Props> {
  render() {
    const { summary, history, resetRollForward } = this.props;
    return (
      <Wrapper>
        <DoneIcon />
        <h1>Roll-forward tasks have now been submitted</h1>
        <p>You can follow your Audit Roll Forward progress in the Activity Log</p>
        <Box Icon={QueryBuilder} header="Summary of the submitted tasks">
          {summary.map(selected => (
            <BoxItem
              Icon={Redo}
              key={selected.id}
              name={selected.name}
              id={selected.jobName}
              status={selected.hasRollForwardFinished ? 'Roll forward has been done before' : ''}
            />
          ))}
        </Box>
        <Buttons>
          <Button
            primary
            solid
            round
            onClick={() => {
              resetRollForward();
              history.push('/roll-forward');
            }}
          >
            Start new Roll Forward
          </Button>
          <Button
            primary
            round
            onClick={() => {
              resetRollForward();
              history.push('/');
            }}
          >
            Back to Start Page
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

export default SubmmittedPage;
