// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import Business from '@material-ui/icons/Business';
import { type Client } from '../../../../../../common/types/client';
import { type Subsidiaries } from '../../../../../types/subsidiary';
import TextLabel from '../../../../../../common/components/TextLabel';
import { SearchSelection } from '../../../../../../common/components/selectors';
import clientService from '../../../../../services/client';
import { dataMappingToDropdown, dropdownTransfer } from '../../../../../../common/utils/helper';
import FileSelector from '../../../../../../common/components/selectors/file-selector/FileSelector';
import { t } from '../../../../../../common/translations/stringList';
import { type ContentFormat } from '../../../../../types/accountSystem';

const CustomTextLabel = styled(TextLabel)`
  color: ${props => props.theme.color.ui.primary};
  cursor: pointer;
`;
const FlexDisplay = styled.div`
  display: flex;
`;
type Props = {
  addSubsidiary: Function,
  subsidiaries: Subsidiaries,
  client: Client,
  contentFormats: Array<ContentFormat>,
  createSubsidiaryDataset: Function,
  startDate: Date,
  endDate: Date,
  datasetGroupId: string
};
const AddSubsidiary = ({
  client,
  subsidiaries,
  addSubsidiary,
  contentFormats,
  createSubsidiaryDataset,
  startDate,
  endDate,
  datasetGroupId
}: Props) => {
  const fetchData = (inputValue: string) => {
    if (!inputValue || inputValue.length < 3) {
      return Promise.resolve(null);
    }
    return clientService
      .fetchClientData(inputValue)
      .then(data =>
        data.filter(
          datum =>
            subsidiaries.every(subsidiary => subsidiary.id !== datum.id) && datum.id !== client.id
        )
      )
      .then(data => (data ? dataMappingToDropdown(data) : data));
  };
  const [searchMode, setSearchMode] = useState(false);

  return searchMode ? (
    <>
      <div>
        <SearchSelection
          deleteFromStore={() => {}}
          key="SearchClient"
          type="add-subsidiary"
          Icon={Business}
          fetchData={fetchData}
          placeholder="Search by client name"
          saveToStore={input => {
            addSubsidiary(dropdownTransfer(input));
            createSubsidiaryDataset(
              {
                clientBusinessId: dropdownTransfer(input).businessId,
                clientId: dropdownTransfer(input).id,
                clientName: dropdownTransfer(input).name,
                endDate,
                startDate,
                datasetGroupId
              },
              dropdownTransfer(input).id
            );
          }}
          onChange={() => setSearchMode(false)}
          selectedList={null}
          width="90%"
          noMinWidth
        />
        <CustomTextLabel onClick={() => setSearchMode(false)}>
          {t('fileUpload', 'cancelSubsidiaryAdding')}
        </CustomTextLabel>
      </div>
      <FlexDisplay>
        {[...new Array(contentFormats.length)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <FileSelector size="small" disabled key={i} />
        ))}
      </FlexDisplay>
    </>
  ) : (
    <CustomTextLabel onClick={() => setSearchMode(true)} data-cy="add-subsidiary-button">
      {t('fileUpload', 'addNewSubsidiary')}
    </CustomTextLabel>
  );
};

export default AddSubsidiary;
