// @flow
import { connect } from 'react-redux';
import Search from '../Search';
import { getOpportunities } from '../../../../duck/opportunity';
import { getLineOfServices } from '../../../../duck/los';
import { getOnboardingStatus, setOnboardingStatus } from '../../../../duck/onboarding';

export default connect(
  state => ({
    isLoading: state.opportunity.get('isLoading'),
    isOnboardingFinished: state.amlOnboarding.get('isOnboardingFinished'),
    lineOfServices: state.los.get('lineOfServices')
  }),
  { getOpportunities, getLineOfServices, getOnboardingStatus, setOnboardingStatus }
)(Search);
