// @flow
import React from 'react';
import Redo from '@material-ui/icons/Redo';
import Info from '@material-ui/icons/Info';
import { List } from 'immutable';
import { type JobList } from '../../../../../common/types/jobs';
import TextLabel from '../../../../../common/components/TextLabel';
import { SearchSelection } from '../../../../../common/components/selectors';
import { dataMappingToDropdown, datafilter } from '../../../../../common/utils/helper';
import convertDropdownToJob from '../utils/jobs';
import { t } from '../../../../../common/translations/stringList';

type Props = {
  selectedJob: Object,
  jobList: JobList,
  handleSaveSelectedJob: Function,
  removeSelectedJob: Function,
  setShouldgetSubsidiaries: Function
};

const JobSelector = ({
  selectedJob,
  jobList,
  handleSaveSelectedJob,
  removeSelectedJob,
  setShouldgetSubsidiaries
}: Props) =>
  jobList.size > 0 ? (
    <>
      <TextLabel>Job</TextLabel>
      <SearchSelection
        key="SearchJob"
        deleteFromStore={removeSelectedJob}
        type="Job"
        Icon={Redo}
        isSearchable={false}
        placeholder={t('jobSelector', 'searchSelectionPlaceholder')}
        fetchData={inputValue =>
          Promise.resolve(jobList).then(() =>
            dataMappingToDropdown(datafilter(jobList, inputValue))
          )
        }
        saveToStore={input => handleSaveSelectedJob(convertDropdownToJob(input))}
        selectedList={selectedJob ? List([selectedJob]) : null}
        onChange={setShouldgetSubsidiaries}
      />
    </>
  ) : (
    <TextLabel>
      <Info />
      {t('jobSelector', 'noJobListWarning')}
    </TextLabel>
  );

export default JobSelector;
