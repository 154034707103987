// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { Typography, Grid, Button, Paper } from '@material-ui/core';
import { Map } from 'immutable';
import { importData, setLoading, setMessage } from '../duck/TalentLink';
import StatusBar from '../../common/components/StatusBar';
import { orange } from '../../common/theme/theme';
import PageHeader from '../../common/components/canvas/PageHeader';

const Container = styled(Paper)`
  padding: 48px;
`;

interface StateProps {
  loading: boolean;
  message: string;
}

interface DispatchProps {
  importData: () => void;
  setLoading: (loading: boolean) => void;
  setMessage: (message: string) => void;
}

type Props = StateProps & DispatchProps;
const Home = ({ loading, message, ...dispatchProps }: Props) => (
  <ThemeProvider theme={orange}>
    <>
      <PageHeader title="TalentLink Sync Tool" />
      <StatusBar loading={Map({ isLoading: loading, msg: message })} />
      <Container elevation={0} square>
        <Typography variant="body1" gutterBottom align="center">
          Click &quot;Import&quot; to authorize the synchronization of TalentLink bookings to your
          Google Calendar.
        </Typography>
        <br />
        <Grid container justify="center">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatchProps.importData()}
              size="large"
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  </ThemeProvider>
);

const mapStateToProps = state => ({
  loading: state.talentLink.talentlinkReducer.loading,
  message: state.talentLink.talentlinkReducer.message
});

const dispatchProps: DispatchProps = {
  importData,
  setLoading,
  setMessage
};

export default connect(
  mapStateToProps,
  dispatchProps
)(Home);
