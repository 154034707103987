// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../common/assets/logo.png';

export default () => (
  <AppBar position="static" color="inherit">
    <Toolbar>
      <Typography variant="title">
        <Link to="/">
          <img src={logo} alt="log" />
        </Link>
      </Typography>
    </Toolbar>
  </AppBar>
);
