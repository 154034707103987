// @flow
import { Record, OrderedMap, fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import services from '../services';
import { type ActionType } from '../../common/types/redux';
import { type MFilesSelection, type CreateMyTaxReturnParams } from '../types';

const defaultState = Record({
  todoList: OrderedMap({}),
  completedList: OrderedMap({}),
  selectedAssignee: null,
  selectedAssigneeTaxDetails: [],
  taxYears: [],
  languageList: [],
  countryList: [],
  loading: null
});

export const getMyTodoList = createAction('GET_MY_TODO_LIST', () =>
  services.fetchMyTaxReturn('in_progress')
);
export const getMyCompletedList = createAction('GET_MY_COMPLETED_LIST', () =>
  services.fetchMyTaxReturn('completed')
);

export const searchStaff = createAction('SEARCH_STAFF', (name: string) =>
  services.searchStaff(name)
);

export const searchAssignee = createAction('SEARCH_ASSIGNEE', (searchString: string) =>
  services.searchAssignee(searchString)
);

export const updatePreparer = createAction(
  'UPDATE_PREPARER',
  (taxReturnId: number, preparer: MFilesSelection) => services.updatePreparer(taxReturnId, preparer)
);

export const createTaxReturn = createAction(
  'CREATE_TAX_RETURN',
  (params: CreateMyTaxReturnParams) => services.createTaxReturn(params)
);

export const updateAssignee = createAction('UPDATE_ASSIGNEE');
export const clearAssignee = createAction('CLEAR_ASSIGNEE');
export const searchTaxReturn = createAction('SEARCH_TAX_RETURN', services.searchTaxReturn);
export const getTaxYear = createAction('GET_TAX_YEAR', services.fetchTaxYear);
export const getLanguageList = createAction('GET_LANGUAGE_LIST', services.fetchLanguage);
export const getCountryList = createAction('GET_COUNTRY_LIST', services.fetchCountries);

const Reducer = (state: any = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * Todo list
     */
    case 'GET_MY_TODO_LIST_FULFILLED': {
      return state.set('todoList', payload);
    }
    case 'GET_MY_TODO_LIST_REJECTED': {
      return state.set('todoList', []);
    }
    /**
     * Completed list
     */
    case 'GET_MY_COMPLETED_LIST_REJECTED': {
      return state.set('completedList', []);
    }
    case 'GET_MY_COMPLETED_LIST_FULFILLED': {
      return state.set('completedList', payload);
    }
    /**
     * Selected assginee
     */
    case 'UPDATE_ASSIGNEE': {
      return state.set('selectedAssignee', payload);
    }
    case 'CLEAR_ASSIGNEE': {
      return state.set('selectedAssignee', null);
    }
    case 'SEARCH_TAX_RETURN_FULFILLED': {
      return state.set('selectedAssigneeTaxDetails', payload);
    }
    case 'GET_TAX_YEAR_FULFILLED': {
      return state.set('taxYears', payload);
    }
    case 'GET_LANGUAGE_LIST_FULFILLED': {
      return state.set('languageList', payload);
    }
    case 'GET_COUNTRY_LIST_FULFILLED': {
      return state.set('countryList', payload);
    }
    case 'CREATE_TAX_RETURN_PENDING': {
      return state.set('loading', fromJS({ isLoading: true, msg: 'Creating new tax return' }));
    }
    case 'CREATE_TAX_RETURN_FULFILLED':
    case 'CREATE_TAX_RETURN_REJECTED': {
      return state.set('loading', fromJS({ isLoading: false, msg: '' }));
    }
    default:
      return state;
  }
};

export default Reducer;
