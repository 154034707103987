// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Domain from '@material-ui/icons/Domain';
import { type List as ListType, OrderedMap } from 'immutable';
import { CircularProgress } from '@material-ui/core';
import { type Client, type ServiceOrder, type ServiceOrderType } from '../../types';
import { type StepAction } from '../../../common/components/workflow/Steps';
import Step, { type Props as StepProps } from '../../../common/components/workflow/Step';
import MultiSelect from '../../../common/components/selectors/MultiSelect';
import Checkbox from '../../../common/components/checkbox/Checkbox';
import OrderItem from './OrderItem';
import ActionBar from '../../../common/components/workflow/ActionBar';
import MessageBar from '../../../common/components/workflow/MessageBar';
import { validateFields } from '../../utils';
import Switch from '../../../common/components/Switch';
import TextLabel from '../../../common/components/TextLabel';
import Button from '../../../common/components/Button';
import MassUpdateDialog from './MassUpdateDialog';
import { serviceOrderType } from '../../constants';

type Props = StepProps & {
  ordersList: ListType<ServiceOrder>,
  selectedClients: ListType<Client>,
  types: Array<ServiceOrderType>,
  getOrders: Function,
  updateOrder: Function,
  onClickSave: () => StepAction,
  submitOrders: Function,
  toggleSelectAll: Function,
  shouldSelectAll: boolean,
  canMassUpdate: boolean
};

const Spinner = styled(CircularProgress)`
  & svg {
    color: ${props => props.theme.color.ui.primary};
  }
`;

const Header = styled.div`
  background-color: ${props => props.theme.color.ui.light};
  padding: ${props => props.theme.space.single.s};
  display: flex;
  justify-content: space-between;
`;
const SubHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Orders = ({
  selectedClients,
  ordersList,
  types,
  getOrders,
  updateOrder,
  done,
  theme,
  onClickSave,
  submitOrders,
  toggleSelectAll,
  shouldSelectAll,
  canMassUpdate,
  ...rest
}: Props) => {
  const [isLoading, setLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [message, setMessage] = useState({ type: 'error', text: '' });
  const [showRollForwarded, setShowRollForwarded] = useState(false);
  const [isMassUpdateDialogOpen, setIsMassUpdateDialogOpen] = useState(false);
  const [massUpdateData, setMassUpdateData] = useState<ServiceOrder>(OrderedMap());

  const selectedOrders = ordersList.filter(order => order.isSelected && !order.isRollForwarded);
  const handleMassUpdate = () => {
    ordersList
      .filter(order => order.isSelected)
      .forEach(order =>
        updateOrder({
          id: order.id,
          key: null,
          value: {
            ...massUpdateData,
            id: order.id,
            client: order.client,
            additionalInformation: order.additionalInformation,
            isRollForwarded: order.isRollForwarded
          }
        })
      );
  };
  const handleProceed = async () => {
    const validation = validateFields(selectedOrders);
    const { isValid, error } = validation;
    if (!isValid) {
      setMessage({
        type: 'error',
        text: error
      });
    } else {
      setLoader(true);
      try {
        await submitOrders(
          selectedOrders.toJS().map(order => ({
            ...order,
            /*
             * Combine 2 financial institution 2 one field to send to BE
             */
            financialInstitutions: [
              ...(order.financialInstitutionsType2 || []),
              ...(order.financialInstitutionsType1 || [])
            ],
            clientContact:
              order.type === serviceOrderType.BANK_CONFIRMATION ? order.clientContact : false,
            confirmationComClientContact:
              order.type === serviceOrderType.BANK_CONFIRMATION
                ? order.confirmationComClientContact
                : false,
            serviceOrderId: order.id,
            clientId: order.client.id,
            jobId: order.job.id
          }))
        );
        setMessage({
          type: 'success',
          text: 'Selected orders are submitted successfully.'
        });
      } finally {
        setLoader(false);
      }

      onClickSave();
    }
  };

  const toggleAll = flag => {
    setSelectAll(flag);
    // * When deselect, filter out bankconfirmation
    const filteredOrderList = ordersList.filter(order => !order.isRollForwarded);

    filteredOrderList.forEach(order =>
      updateOrder({
        id: order.id,
        key: 'isSelected',
        value: flag
      })
    );
  };

  useEffect(() => {
    if (shouldSelectAll && ordersList.size > 0) {
      toggleSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSelectAll, ordersList.size]);

  const changeType = async selectedTypes => {
    setLoader(true);
    try {
      const clientId = selectedClients.map(client => client.id).toJS();
      const newOrderList = await getOrders({
        clientId,
        type: selectedTypes
      });
      if (selectAll)
        newOrderList.value.forEach(order =>
          updateOrder({
            id: order.id,
            key: 'isSelected',
            value: true
          })
        );

      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const displayedOrderList = showRollForwarded
    ? ordersList.filter(order => order.client)
    : ordersList.filter(order => !order.isRollForwarded && order.client);

  return (
    <>
      <Step
        title="Service Order Roll-Forward"
        subtitle="Select service orders to roll forward"
        Icon={Domain}
        theme={theme}
        done={done}
        {...rest}
      >
        <Header>
          <MultiSelect
            noPadding
            placeholder="Select Service Order Type"
            onChange={selectedValues =>
              changeType(selectedValues ? selectedValues.map(item => item.value) : [])
            }
            options={types
              .filter(
                type =>
                  type.id !== serviceOrderType.BANK_CONFIRMATION &&
                  type.id !== serviceOrderType.REPORT_LIABILITY_TESTING
              )
              .map(type => ({ label: type.name, value: type.id }))}
          />
          {isLoading ? (
            <SubHeader>
              <Spinner />
            </SubHeader>
          ) : (
            <>
              <SubHeader>
                <TextLabel onClick={() => toggleAll(!selectAll)} inlineSmall isLink>
                  Select All:
                </TextLabel>

                <Checkbox selected={selectAll} onClick={() => toggleAll(!selectAll)} />
              </SubHeader>
              <SubHeader>
                <TextLabel small>Show Roll-Forwarded service order:</TextLabel>
                <Switch
                  onChange={() => setShowRollForwarded(!showRollForwarded)}
                  checked={showRollForwarded}
                />
              </SubHeader>
              {canMassUpdate && (
                <>
                  <SubHeader>
                    <Button
                      data-cy="mass-update-field-open"
                      solid
                      rounded
                      primary
                      onClick={() => {
                        setIsMassUpdateDialogOpen(true);
                        setMassUpdateData(displayedOrderList.get(0));
                      }}
                    >
                      Mass Update Field
                    </Button>
                  </SubHeader>
                  <MassUpdateDialog
                    isMassUpdateDialogOpen={isMassUpdateDialogOpen}
                    setIsMassUpdateDialogOpen={setIsMassUpdateDialogOpen}
                    massUpdateData={massUpdateData}
                    setMassUpdateData={setMassUpdateData}
                    handleMassUpdate={handleMassUpdate}
                  />
                </>
              )}
            </>
          )}
        </Header>
        {displayedOrderList.map(order => (
          <OrderItem
            key={order.id}
            order={order}
            updateOrder={updateOrder}
            setMessage={setMessage}
          />
        ))}
        <ActionBar
          status="Select a service order to proceed"
          isLoading={isLoading}
          proceed={{
            name: 'Proceed',
            callback: handleProceed,
            isDisabled: !selectedOrders || selectedOrders.size === 0 || isLoading
          }}
        />
      </Step>
      <MessageBar
        type={message.type}
        status={Boolean(message && message.text)}
        onClose={() => setMessage({ type: '', text: '' })}
        message={message.text}
      />
    </>
  );
};

export default Orders;
