import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${props => props.theme.color.ui.primary};
    background-color: white;
  }
`;

export default StyledCheckbox;
