// @flow
import React, { useState } from 'react';
import DialogBox from '../../../common/components/canvas/DialogBox';
import OrderItem from './OrderItem';
import Button from '../../../common/components/Button';

type Props = {
  isMassUpdateDialogOpen: boolean,
  setIsMassUpdateDialogOpen: Function,
  massUpdateData: Function,
  setMassUpdateData: Function,
  handleMassUpdate: Function
};
const MassUpdateDialog = ({
  isMassUpdateDialogOpen,
  setIsMassUpdateDialogOpen,
  massUpdateData,
  setMassUpdateData,

  handleMassUpdate
}: Props) => {
  const [isConfimMassUpdateOpen, setIsConfimMassUpdateOpen] = useState(false);
  return (
    <DialogBox
      isOpen={isMassUpdateDialogOpen}
      maxWidth="lg"
      hasCloseButton
      title="Mass Update Field"
      closeButtonOnClick={() => setIsMassUpdateDialogOpen(false)}
      closeDialog={() => {}}
      content={() => (
        <div>
          <OrderItem
            order={massUpdateData}
            isMassUpdate
            updateOrder={order => {
              setMassUpdateData(data => {
                if (Array.isArray(order.key)) {
                  return {
                    ...data,
                    [order.key[0]]: {
                      ...data[order.key[0]],
                      [order.key[1]]: order.value
                    }
                  };
                }
                return {
                  ...data,
                  [order.key]: order.value
                };
              });
            }}
          />
          <DialogBox
            isOpen={isConfimMassUpdateOpen}
            title="Confirm Mass Update"
            hasCloseButton
            closeButtonOnClick={() => {}}
            closeDialog={() => {}}
            ButtonElement={() => (
              <Button
                solid
                primary
                data-cy="mass-update-field-confirm"
                onClick={() => {
                  handleMassUpdate();
                  setIsMassUpdateDialogOpen(false);
                  setIsConfimMassUpdateOpen(false);
                }}
              >
                Confirm
              </Button>
            )}
            content={() => <h3>Are you sure to mass modify the service orders?</h3>}
          />
        </div>
      )}
      ButtonElement={() => (
        <Button
          solid
          primary
          data-cy="mass-update-field-submit"
          onClick={() => setIsConfimMassUpdateOpen(true)}
        >
          Submit
        </Button>
      )}
    />
  );
};

export default MassUpdateDialog;
