// @flow
import { List } from 'immutable';
import { uniqBy } from 'lodash';
import { type FilesData } from '../../common/types/files';
import { type AccountSystem } from '../types/accountSystem';

const getFormatTypes = (selectedAccountSystem: AccountSystem) =>
  selectedAccountSystem && selectedAccountSystem.data && selectedAccountSystem.data.contentFormats
    ? selectedAccountSystem.data.contentFormats.map(el => el.type)
    : [];

const getHeadClientFilesByType = (key: string, filesData: FilesData) =>
  filesData.headClient[key]
    ? filesData.headClient[key]
      .filter(value => value.fileId)
      .map(value => ({
        datasetStatus: filesData.headClient.datasetStatus,
        fileId: value.fileId,
        fileName: value.fileName
      }))
      .toList()
    : List([]);

const getSubsidiaryFilesByType = (key: string, subsidiary: Object) =>
  subsidiary[key]
    ? subsidiary[key]
      .filter(value => value.fileId)
      .map(value => ({
        datasetStatus: subsidiary.datasetStatus,
        fileId: value.fileId,
        fileName: value.fileName
      }))
      .toList()
    : List([]);

const getSubsidiariesFilesByType = (key: string, filesData: FilesData) =>
  List(
    filesData.subsidiaries
      .map(item => item.toObject())
      .map(subsidiary => getSubsidiaryFilesByType(key, subsidiary))
  );

const getHeadClientFileNames = (key: string, filesData: FilesData) =>
  getHeadClientFilesByType(key, filesData)
    .reduce((result, value) => (value.fileName ? [...result, value.fileName] : result), [])
    .join(',');

const getSubsidiariesFileCount = (key: string, filesData: FilesData) =>
  filesData.subsidiaries
    .filter((_, k) => k !== 'addedSubsidiaries')
    .reduce(
      (sum, subsidiary) =>
        subsidiary.toObject()[key]
          ? sum + subsidiary.toObject()[key].filter((_, k) => k !== 'isLoading').size
          : sum,
      0
    );

export const getTotalFileCount = (key: string, filesData: FilesData) =>
  filesData.headClient[key].filter((_, k) => k !== 'isLoading').size +
  getSubsidiariesFileCount(key, filesData);

export const getFilesDataSummary = (selectedAccountSystem: AccountSystem, filesData: FilesData) => {
  const summary = {
    dataByType: [],
    files: List([])
  };

  let files = List([]);
  getFormatTypes(selectedAccountSystem).forEach((type, index) => {
    summary.dataByType[index] = {};
    const typeFiles = getHeadClientFilesByType(type, filesData);
    files = files.concat(typeFiles);
    summary.dataByType[index].isActive = true;
    summary.dataByType[index].type = type;
    const subsidiariesFileCount = getSubsidiariesFileCount(type, filesData);
    if (typeFiles.size > 0) { // files uploaded for head client and/or for subsidiaries
      summary.dataByType[index].heading = `${getHeadClientFileNames(
        type,
        filesData
      )} + ${subsidiariesFileCount} file(s) for subsidiaries`;
      files = files.concat(getSubsidiariesFilesByType(type, filesData));
    } else if (subsidiariesFileCount > 0) { // files uploaded only for subsidiaries
      summary.dataByType[index].heading = `No files for head client + ${subsidiariesFileCount} file(s) for subsidiaries`;
      files = files.concat(getSubsidiariesFilesByType(type, filesData));
    } else { // no files uploaded
      summary.dataByType[index].heading = `No ${type} files uploaded.`;
    }
  });
  summary.files = files;
  return summary;
};

export const getDatasetsWithFiles = (
  selectedAccountSystem: AccountSystem,
  filesData: FilesData
) => {
  const datasetIds = [];
  getFormatTypes(selectedAccountSystem).forEach(type => {
    if (getHeadClientFilesByType(type, filesData).size > 0) {
      datasetIds.push({
        isHeadClient: true,
        clientId: '',
        datasetId: filesData.headClient.datasetId,
        datasetStatus: filesData.headClient.datasetStatus
      });
    }

    filesData.subsidiaries
      .map(item => item.toObject())
      .map(subsidiary => {
        if (getSubsidiaryFilesByType(type, subsidiary).size > 0) {
          datasetIds.push({
            isHeadClient: false,
            clientId: subsidiary.clientId,
            datasetId: subsidiary.datasetId,
            datasetStatus: subsidiary.datasetStatus
          });
        }
        return subsidiary;
      });
  });
  return uniqBy(datasetIds, 'datasetId');
};

export const haveDatasetsFullfilled = (
  selectedAccountSystem: AccountSystem,
  filesData: FilesData
) => {
  const isFullfilled = [];
  getFormatTypes(selectedAccountSystem).forEach(type => {
    if (getHeadClientFilesByType(type, filesData).size > 0) {
      isFullfilled.push(!filesData.headClient.isGettingDataset);
    }
    filesData.subsidiaries
      .map(item => item.toObject())
      .map(subsidiary => {
        if (getSubsidiaryFilesByType(type, subsidiary).size > 0) {
          isFullfilled.push(!subsidiary.isGettingDataset);
        }
        return subsidiary;
      });
  });
  return isFullfilled.every(el => el === true);
};

export const isQlikSenseAvailable = (selectedAccountSystem: AccountSystem, filesData: FilesData) =>
  getDatasetsWithFiles(selectedAccountSystem, filesData).some(
    dataset => dataset.datasetStatus === 10
  );

export const isMappingAvailable = (selectedAccountSystem: AccountSystem, filesData: FilesData) =>
  getDatasetsWithFiles(selectedAccountSystem, filesData).every(
    dataset => dataset.datasetStatus >= 6
  );
