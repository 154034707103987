// @flow
import React from 'react';
import { ChevronRight } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import Button from '../Button';

const Icon = styled(ChevronRight)`
  ${props =>
    props.open &&
    css`
      transform: rotate(-90deg);
    `};
`;

type Props = {
  onClick: Function,
  isOpen: boolean,
  isDisabled?: boolean
};

const ToggleChildren = ({ onClick, isOpen, isDisabled }: Props) => (
  <Button data-cy="toggle-children" link size="none" onClick={onClick} disabled={isDisabled}>
    <Icon open={isOpen ? 1 : 0} />
  </Button>
);
ToggleChildren.defaultProps = {
  isDisabled: false
};

export default ToggleChildren;
