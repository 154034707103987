// @flow
import React from 'react';
import DiagnosisView from './components/DiagnosisView';

type Props = {
  match: Object
};

const AuditRFDiagnosis = ({ match }: Props) => {
  const { rollforwardId } = match.params;
  return (
    <>
      <DiagnosisView rollforwardId={rollforwardId} />
    </>
  );
};

export default AuditRFDiagnosis;
