// @flow
import React from 'react';
import Business from '@material-ui/icons/Business';
import { List } from 'immutable';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import { SearchSelection } from '../../../../common/components/selectors';
import clientService from '../../../services/client';
import { dataMappingToDropdown, dropdownTransfer } from '../../../../common/utils/helper';
import { type Client } from '../../../../common/types/client';
import { type JobType } from '../../../../common/types/jobs';
import ActionBar from '../../../../common/components/workflow/ActionBar';

type Props = StepProps & {
  onClickSave: Function,
  sessionClient: Client,
  saveSessionClient: Function,
  removeSessionClient: Function,
  fetchJobList: Function,
  getSubsidiaries: Function,
  isJobListLoading: boolean,
  removeSelectedJob: Function,
  selectedJob: JobType | null
};

const ClientSearch = ({
  onClickSave,
  selectedJob,
  removeSelectedJob,
  saveSessionClient,
  sessionClient,
  removeSessionClient,
  fetchJobList,
  getSubsidiaries,
  isJobListLoading,
  done,
  theme,
  ...rest
}: Props) => {
  const handleProceed = async () => {
    if (!selectedJob) await fetchJobList(sessionClient.id);
    await getSubsidiaries(sessionClient.id);
    onClickSave();
  };

  return (
    <Step
      title="Client"
      subtitle="Start by selecting the client in question"
      Icon={Business}
      theme={theme}
      done={done}
      {...rest}
    >
      <SearchSelection
        deleteFromStore={removeSessionClient}
        key="SearchClient"
        type="Client"
        Icon={Business}
        fetchData={(inputValue: string) => {
          if (!inputValue || inputValue.length < 3) {
            return Promise.resolve(null);
          }
          return clientService
            .fetchClientData(inputValue)
            .then(data => (data ? dataMappingToDropdown(data) : data));
        }}
        note="Note! You can select only one client at a time"
        placeholder="Start typing to begin search for the client by name"
        saveToStore={input => saveSessionClient(dropdownTransfer(input))}
        selectedList={sessionClient.name ? List([sessionClient]) : null}
        onChange={removeSelectedJob}
      />
      <ActionBar
        isLoading={isJobListLoading}
        status={
          !sessionClient.name
            ? `Select client above to proceed `
            : `Press ’Proceed’ to continue to next step`
        }
        proceed={{
          name: 'Proceed',
          callback: handleProceed,
          isDisabled: !sessionClient.name
        }}
        selectedList={sessionClient}
      />
    </Step>
  );
};

export default ClientSearch;
