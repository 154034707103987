// @flow

import React from 'react';
import Edit from '@material-ui/icons/Edit';
import styled from 'styled-components';
import { type StepAction } from './Steps';
import Button from '../Button';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => (props.pending ? props.theme.space.single.m : props.theme.space.single.l)}
  flex-shrink: 0;

  color: ${props => {
    if (props.pending) return props.theme.color.icon.disabled;
    return props.theme.color.icon.light;
  }};

  background-color: ${props => {
    if (props.pending) return props.theme.color.background.medium;
    return props.theme.color.ui.primary;
  }};
`;
IconWrapper.displayName = 'IconWrapper';

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${props =>
    props.pending
      ? `${props.theme.space.single.m} 0`
      : `${props.theme.space.single.l} ${props.theme.space.single.m}`}
  color: ${props => {
    if (props.pending) return props.theme.color.heading.neutral;
    if (props.hasBgColor) return props.theme.color.heading.light;
    return 'inherit';
  }};

  background-color: ${props => {
    if (props.pending) return props.theme.color.background.medium;
    if (props.hasBgColor) return props.theme.color.ui.primary;
    return 'inherit';
  }};
  p, h3 {
    margin: 0;
  }
  p {
    margin-top: 1em;
  }
  & > *:first-child {
    flex-grow: 2;
  }
  & > *:last-child {
    margin: auto;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${props => {
    if (props.pending) return props.theme.color.background.medium;
    if (props.hasBgColor) return props.theme.color.ui.primary;
    return 'inherit';
  }};
  & > * {
    vertical-align: middle;
  }
`;

export const StepHeaderTitle = styled.h3`
  color: ${props =>
    props.active ? props.theme.color.heading.light : props.theme.color.heading.neutral};
  font-style: ${props => (props.active ? 'italic' : 'normal')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

type StepSummaryItem = {
  title: string,
  text: string
};
export type Props = {
  Icon: string,
  title: string,
  subtitle: string,
  onClickEdit: StepAction,
  pending: boolean,
  active: boolean,
  done: boolean,
  stepSummaryItems: Array<StepSummaryItem>
};

export const StepSummaryWrapper = styled.div`
  display: flex;
  & > * {
    margin-right: ${props => props.theme.space.single.m};
    max-width: 300px;
  }
  p {
    white-space: pre-line;
  }
`;

export const StepSummary = ({
  stepSummaryItem,
  active
}: {
  stepSummaryItem: StepSummaryItem,
  active: boolean
}) => (
  <div>
    <StepHeaderTitle active={active}>{stepSummaryItem.title}</StepHeaderTitle>
    <p>{stepSummaryItem.text}</p>
  </div>
);

const StepHeader = ({
  title,
  subtitle,
  stepSummaryItems,
  Icon,
  onClickEdit,
  pending,
  active,
  done
}: Props) => {
  const titleFormat = title
    .toLocaleLowerCase()
    .split(' ')
    .join('-');
  return (
    <StepWrapper hasBgColor={active} active={active} pending={pending}>
      <IconWrapper item pending={pending} noPadding={pending}>
        <Icon style={{ fontSize: 40 }} />
      </IconWrapper>
      <ContentWrapper item hasBgColor={active} pending={pending}>
        <div data-cy={`step-header-${titleFormat}`}>
          {done && !active ? (
            <StepSummaryWrapper>
              {stepSummaryItems &&
                stepSummaryItems.map(stepSummaryItem => (
                  <StepSummary
                    key={stepSummaryItem.title}
                    stepSummaryItem={stepSummaryItem}
                    active={active}
                  />
                ))}
            </StepSummaryWrapper>
          ) : (
            <>
              {title && <StepHeaderTitle active={active}>{title}</StepHeaderTitle>}
              {active && subtitle && <p>{subtitle}</p>}
            </>
          )}
        </div>
        {done && !active && (
          <Button
            padding="squish"
            size="s"
            onClick={onClickEdit}
            data-cy={`step-edit-${titleFormat}`}
          >
            <Edit />
            Edit
          </Button>
        )}
      </ContentWrapper>
    </StepWrapper>
  );
};

export default StepHeader;
