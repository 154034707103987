// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Redo from '@material-ui/icons/Redo';
import { type OrderedMap } from 'immutable';
import { type AccountMapper } from '../../../types/accountMapping';
import PageHeader from '../../../../common/components/canvas/PageHeader';
import { dataMappingToDropdown, datafilter } from '../../../../common/utils/helper';
import MessageBar from '../../../../common/components/workflow/MessageBar';
import LoadingBar from '../../../../common/components/workflow/LoadingBar';
import { getFsliListByName } from '../../../services/accountMapping';
import CustomButtonContainer from './CustomButtonContainer';
import CustomColumn from './CustomColumn';
import CustomDialogBox from './CustomDialogBox';
import Button from '../../../../common/components/Button';

const Container = styled.div`
    background: ${props => props.theme.color.background.white}
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

type Props = {
  isLoading: boolean,
  isFSLIMappingQueued: boolean,
  isSaving: boolean,
  isSavingSuccess: boolean,
  datasetGroupId: string,
  accountMappingList: OrderedMap<AccountMapper>,
  showCustomColumn: boolean,
  showCustomInput: boolean,
  getAccountMappingList: Function,
  saveAccountMappingList: Function,
  restoreAccountMappingList: Function,
  setFSLI: Function,
  updateCustomFsli: Function,
  removeCustomColumn: Function,
  toggleCustomColumn: Function,
  toggleCustomInput: Function,
  goToActivityLog: Function,
  clearAccountMapping: Function,
  isRejected: boolean
};

const AccountMapping = ({
  isLoading,
  isFSLIMappingQueued,
  isSaving,
  isSavingSuccess,
  datasetGroupId,
  accountMappingList,
  showCustomColumn,
  showCustomInput,
  getAccountMappingList,
  saveAccountMappingList,
  restoreAccountMappingList,
  setFSLI,
  updateCustomFsli,
  removeCustomColumn,
  toggleCustomColumn,
  toggleCustomInput,
  goToActivityLog,
  clearAccountMapping,
  isRejected
}: Props) => {
  /**
   * Initialise View
   */
  const [customFsliList, setCustomFsliList] = useState([]);
  const [shouldLoadData, setLoadData] = useState(true);
  if (shouldLoadData && datasetGroupId) {
    (async () => {
      try {
        await getAccountMappingList(datasetGroupId);
        setLoadData(false);
      } catch(ex) {
        setLoadData(false);
      }
    })();
  }

  /**
   * Columns to be displayed
   */
  const columns = [
    {
      id: 1,
      heading: 'Account Info',
      tag: 'mapping-column-account',
      type: '',
      isCustomInput: false,
      menuText: ''
    },
    {
      id: 2,
      heading: 'FSLI',
      tag: 'mapping-column-fsli',
      type: 'fsli',
      isCustomInput: false,
      menuText: 'Add Custom FSLI Column'
    },
    {
      id: 3,
      heading: 'Custom Mapping',
      tag: 'mapping-column-custom-fsli',
      type: 'custom-fsli',
      isCustomInput: true,
      menuText: 'Delete Custom FSLI Column'
    }
  ];

  /**
   * Fetch FSLI & Custom FSLI List
   */
  const fetchFsliList = async inputValue => {
    if (inputValue && inputValue.length >= 3) {
      const result = await getFsliListByName(inputValue);
      return dataMappingToDropdown(datafilter(result, inputValue));
    }
    return null;
  };
  const fetchCustomFsliList = async inputValue =>
    dataMappingToDropdown(datafilter(customFsliList, inputValue));

  /**
   * Save selected FSLI / Custom FSLI
   */
  const saveSelectedFsli = (fsliMappingId, field, input) => {
    setFSLI({ fsliMappingId, field, value: input.label });
  };

  /**
   * Dialog Box Related Hooks & Methods
   */
  const [showModal, setModalVisibility] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogType, setDialogType] = useState('');
  const toggleModal = (flag, title) => {
    setModalVisibility(flag);
    setDialogTitle(title);
    setDialogType('custom-fsli');
  };
  const handleCustomFSLI = isSkipped => {
    setModalVisibility(false);
    toggleCustomColumn(true);
    if (!isSkipped) toggleCustomInput(true);
  };
  const restoreFsli = () => {
    setModalVisibility(true);
    setDialogTitle('Restore Old FSLI values (.xlsx, .xls, .csv)');
    setDialogType('restore-fsli');
  };

  const [showMessage, toggleMessage] = useState(false);
  const optionalAction = {
    title: 'Restore FSLI Values',
    icon: Redo,
    onClick: () => restoreFsli()
  };
  const isMappingAvailable = accountMappingList && accountMappingList.size > 0;

  useEffect(() => {
    clearAccountMapping();
  }, []);

  return (
    <>
      <PageHeader
        getLog={goToActivityLog}
        title="Trial balance account mapping"
        subTitle="Upload general ledger and trial balance data files extracted from the client’s accounting system.
      Please follow the instructions in the following link to get the accounting data from the client in appropriate format and perform the steps below to create the Qlik Sense analysis.
      "
        optionalAction={isMappingAvailable ? optionalAction : null}
      />
      {(isLoading || shouldLoadData) && <LoadingBar />}
      {(isRejected) ? 
        <h2>Account Mapping for this dataset group is not available.
        Please check the dataset group id again and your access level for this dataset group.</h2>
       :
      !isFSLIMappingQueued && !isLoading && !isMappingAvailable && (
        <h2>Account Mapping for this dataset group is not available yet.</h2>
      )}
      {isFSLIMappingQueued && !isMappingAvailable && !shouldLoadData && (
        <>
          <h2>AI is currently processing FSLI mappings.</h2>
          <h2>Please refresh the page to see the results.</h2>
          <Button onClick={() => getAccountMappingList(datasetGroupId)} primary>
            Retry
          </Button>
        </>
      )}
      {isMappingAvailable && !shouldLoadData && !isLoading && !shouldLoadData && (
        <>
          <Container>
            {columns.map(
              column =>
                (!column.isCustomInput || showCustomColumn) && (
                  <CustomColumn
                    key={column.id}
                    column={column}
                    showCustomColumn={showCustomColumn}
                    showCustomInput={showCustomInput}
                    accountMappingList={accountMappingList}
                    fetchFsliList={fetchFsliList}
                    fetchCustomFsliList={fetchCustomFsliList}
                    saveSelectedFsli={saveSelectedFsli}
                    saveCustomInput={updateCustomFsli}
                    toggleModal={toggleModal}
                    deleteCustomFsli={removeCustomColumn}
                  />
                )
            )}
          </Container>
          {isSaving && <LoadingBar />}
          <CustomDialogBox
            title={dialogTitle}
            type={dialogType}
            toggleModal={toggleModal}
            showModal={showModal}
            accountMappingList={accountMappingList}
            restoreAccountMappingList={restoreAccountMappingList}
            handleCustomFSLI={handleCustomFSLI}
            setCustomFsliList={setCustomFsliList}
          />
          <CustomButtonContainer
            saveAccountMappingList={saveAccountMappingList}
            getAccountMappingList={getAccountMappingList}
            datasetGroupId={datasetGroupId}
            accountMappingList={accountMappingList}
            toggleMessage={toggleMessage}
          />
        </>
      )}
      <MessageBar
        type={isSavingSuccess ? 'success' : 'error'}
        status={showMessage}
        message={
          isSavingSuccess
            ? 'Account Mapping is saved successfully!'
            : 'Account Mapping could not be saved. Please try again!'
        }
        onClose={() => toggleMessage(false)}
      />
    </>
  );
};

export default AccountMapping;
