// @flow
import React, { useState } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import { SearchSelection } from '../../../../common/components/selectors';
import { type TeamList } from '../../../../common/types/team';
import service from '../../../services/team';
import { dataMappingToDropdown } from '../../../../common/utils/helper';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import TextLabel from '../../../../common/components/TextLabel';

type Props = StepProps & {
  isDatasetLoading: boolean,
  isTeamLoading: boolean,
  selectedTeamList: TeamList,
  datasetGroupId: string,
  datasetIds: Array<Object>,
  removeTeamFromSelected: Function,
  saveToTeamList: Function,
  submitFinalTeamList: Function,
  putQueueAll: Function,
  getDataset: Function,
  onClickSave: Function
};

const TeamAccess = ({
  isDatasetLoading,
  isTeamLoading,
  selectedTeamList,
  datasetGroupId,
  datasetIds,
  removeTeamFromSelected,
  saveToTeamList,
  submitFinalTeamList,
  putQueueAll,
  getDataset,
  onClickSave,
  theme,
  ...rest
}: Props) => {
  const [isQueueAllPending, setIsQueueAllPending] = useState(false);
  const handleProceed = async () => {
    await putQueueAll(datasetGroupId);
    await datasetIds.forEach(item => getDataset(item.isHeadClient, item.clientId, item.datasetId));
    onClickSave();
  };

  const getTeamList = async inputValue => {
    if (inputValue && inputValue.length >= 3) {
      const result = await service.fetchTeamList(inputValue);
      return dataMappingToDropdown(result);
    }
    return [];
  };
  return (
    <Step
      title="Team (optional)"
      subtitle="Give team members access to the data by selecting one or more Aura engagement(s)"
      Icon={AccountCircle}
      theme={theme}
      {...rest}
    >
      <TextLabel>
        You already have access to the dataset in Qlik Sense. Access rights to dataset in Qlik Sense
        can be granted in addition to the same users which are indicated in Aura engagement access
        control list (ACL).
        <br />
        <br />
        If there is Aura database linked for this Job in iPower, it is selected here automatically.
        You can remove that or select another or/and additional Aura engagements (ACLs) below.
      </TextLabel>
      <SearchSelection
        placeholder="Start typing to begin search for the Aura engagement by name"
        key="SearchTeam"
        deleteFromStore={removeTeamFromSelected}
        type="Team"
        Icon={AccountCircle}
        fetchData={getTeamList}
        saveToStore={saveToTeamList}
        selectedList={selectedTeamList}
      />
      <ActionBar
        status={
          selectedTeamList.size > 0
            ? `${selectedTeamList.size}  Aura engagement(s) selected. Press 'Proceed' to finalize the task submission.`
            : `Select an Aura database to proceed`
        }
        isLoading={isTeamLoading || isDatasetLoading}
        optional={{
          name: 'Skip and submit',
          callback: async () => {
            setIsQueueAllPending(true);
            try {
              await handleProceed();
              selectedTeamList.forEach(team => removeTeamFromSelected(team));
            } finally {
              setIsQueueAllPending(false);
            }
          },
          isDisabled: isQueueAllPending
        }}
        proceed={{
          name: 'Submit',
          callback: async () => {
            setIsQueueAllPending(true);
            try {
              await submitFinalTeamList(datasetGroupId, selectedTeamList);
              await handleProceed();
            } finally {
              setIsQueueAllPending(false);
            }
          },
          isDisabled: selectedTeamList.size < 1 || isQueueAllPending
        }}
      />
    </Step>
  );
};

export default TeamAccess;
