// @flow
import { get, put } from '../../common/utils/api';
import { type FsliList } from '../types/accountMapping';

export const getFsliListByName = (name: string) => get(`/gl/fsli?name=${name}`);

export const getFsliMapping = (datasetGroupId: string) =>
  get(`/gl/dataset-group/${datasetGroupId}/fsli`);

export const putFsliList = (datasetGroupId: string, fsliList: Array<FsliList>) =>
  put(`/gl/dataset-group/${datasetGroupId}/fsli`, fsliList);
