// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import OrderInputs from './OrderInputs';
import { type ServiceOrder, type Job } from '../../types';
import TextLabel from '../../../common/components/TextLabel';
import Checkbox from '../../../common/components/checkbox/Checkbox';
import { SearchSelection } from '../../../common/components/selectors';
import services from '../../services';
import { getInputFields } from '../../utils';

type Props = {
  order: ServiceOrder,
  updateOrder: Function,
  setMessage?: Function,
  isMassUpdate?: boolean
};

const Wrapper = styled.div`
  > *:not(:first-child) {
    border: ${props => `solid 1px ${props.theme.color.border.default}`};
  }
  margin: ${props => props.theme.space.inline.xl};
  > div {
    width: 100%;
  }
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
`;

const Header = styled.div`
  background-color: ${props => props.theme.color.ui.primary};
  padding: ${props => props.theme.space.single.s};
  display: flex;
`;

const JobSelection = styled.div`
  background-color: ${props => props.theme.color.background.white};
  display: flex;
  padding: ${props => props.theme.space.single.s};
  align-items: center;
  > div:nth-child(2) {
    padding: ${props => !props.isRollForwarded && '0 10%'};
  }
`;
const CustomizedCheckCircle = styled(CheckCircle)`
  color: ${props => props.theme.colors.green[600]};
  margin: 5px;
`;
const mapJobToSimpleDropDown = job => ({
  label: job.state ? `${job.name} (${job.state})` : `${job.name} ( Empty state )`,
  value: job.id
});

const mapToDropdownJob = (i: Job) => ({
  label: i.state ? `${i.name} (${i.state})` : `${i.name} ( Empty state )`,
  value: i.id,
  data: i
});

const jobMappingToDropdown = (list: Array<Job>) => list.map(mapToDropdownJob);

const ServiceOrderItem = ({ order, updateOrder, setMessage, isMassUpdate }: Props) => {
  const [jobList, setJobList] = useState([]);

  const searchJobListByName = (list, name) =>
    jobMappingToDropdown(list.filter(job => job.name && job.name.toLowerCase().includes(name)));

  const fetchJobs = (input: string) => {
    if (jobList.length === 0)
      return services
        .fetchJobsByClientId(order.client.id)
        .then(data => {
          if (data) {
            const orderJobId = order.job.id;
            const matchedJob = data.find(d => d.id === order.job.id);
            if (orderJobId && matchedJob) {
              updateOrder({
                id: order.id,
                key: ['job', 'state'],
                value: matchedJob.state
              });
            }

            setJobList(data);
            return searchJobListByName(data, input);
          }
          return [];
        })
        .catch(() => []);
    return Promise.resolve(searchJobListByName(jobList, input));
  };

  return (
    <Wrapper>
      {!isMassUpdate &&
        (order.isRollForwarded ? (
          <CustomizedCheckCircle />
        ) : (
          <Checkbox
            selected={order.isSelected}
            onClick={() =>
              updateOrder({
                id: order.id,
                key: 'isSelected',
                value: !order.isSelected
              })
            }
          />
        ))}
      <div>
        <Header>
          <TextLabel heading bold noPadding>
            {!isMassUpdate
              ? `${order.client.name} - ${order.typeLabel || ''}${
                  order.additionalInformation ? ` - ${order.additionalInformation}` : ''
                }`
              : 'Mass Update Field'}
          </TextLabel>
        </Header>
        <OrderInputs
          order={order}
          inputFields={getInputFields(order.type)}
          updateOrder={updateOrder}
        />
        {!isMassUpdate && (
          <JobSelection isRollForwarded={order.isRollForwarded}>
            <TextLabel bold noPadding>
              Job to link:
            </TextLabel>
            {order.isRollForwarded ? (
              <TextLabel>{order.job ? order.job.name : 'no job selected'}</TextLabel>
            ) : (
              <SearchSelection
                noPadding
                key={`search-job-${order.id}`}
                type="job"
                placeholder="Select the job"
                value={order.job ? mapJobToSimpleDropDown(order.job) : null}
                fetchData={fetchJobs}
                saveToStore={option =>
                  updateOrder({
                    id: order.id,
                    key: 'job',
                    value: option.data
                  })
                }
              />
            )}
          </JobSelection>
        )}
      </div>
    </Wrapper>
  );
};

ServiceOrderItem.defaultProps = {
  setMessage: () => {}
};

export default ServiceOrderItem;
