// @flow
import React from 'react';
import styled from 'styled-components';
import { BoxItemWrapper } from '../../../../../common/components/canvas/BoxView';
import Button from '../../../../../common/components/Button';
import TextLabel from '../../../../../common/components/TextLabel';
import accountSystemMapping from '../../../../utils/accountSystemMapping';

/**
 * ExtendedBoxItem
 */

const Wrapper = styled.div`
  margin: 10px;
`;

const Heading = styled.h3`
  word-break: break-all;
`;

type Props = {
  Icon: string,
  summary: Object,
  onClick?: Function
};

const CustomBoxItem = ({ Icon, summary, onClick }: Props) => (
  <BoxItemWrapper>
    <Icon />
    <div data-cy="extended-box-item">
      {summary.map(
        item =>
          item.isActive && (
            <Wrapper key={item.type}>
              <TextLabel bold noPadding>
                {accountSystemMapping(item.type)}
              </TextLabel>
              <Heading>{item.heading}</Heading>
            </Wrapper>
          )
      )}
    </div>
    {onClick && (
      <Button link onClick={onClick} data-cy="file-details">
        Show Details
      </Button>
    )}
  </BoxItemWrapper>
);

CustomBoxItem.defaultProps = {
  onClick: undefined
};

export default CustomBoxItem;
