import React from 'react';

export default () => (
  <div>
    <span>
      Download activity log and save it to your desktop in csv format.
      <br />
      <ul>
        <li>Open a blank Excel</li>
        <li>
          Go to&nbsp;
          <b>Data</b>
          &nbsp;Tab
        </li>
        <li>
          Select&nbsp;
          <b>From Text</b>
          &nbsp;option
        </li>
        <li>
          Select the file you downloaded and press&nbsp;
          <b>Import</b>
          &nbsp;
        </li>
        <li>
          Select&nbsp;
          <b>Delimited</b>
          &nbsp;as file type and choose the file format&nbsp;
          <b>UTF-8</b>
          &nbsp;from dropdown menu of File origin and press&nbsp;
          <b>Next</b>
          &nbsp;
        </li>
        <li>
          Select&nbsp;
          <b>Comma</b>
          &nbsp;as delimiters (deselect any other options) and press&nbsp;
          <b>Next</b>
          &nbsp;
        </li>
        <li>
          Press&nbsp;
          <b>Finish</b>
          &nbsp;
        </li>
        <li>
          Press&nbsp;
          <b>OK</b>
          &nbsp;
        </li>
      </ul>
    </span>
  </div>
);
