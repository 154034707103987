// @flow
import React, { PureComponent } from 'react';
import Domain from '@material-ui/icons/Domain';
import { Map } from 'immutable';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import ClientsTable, { type Props as ClientsTableProps } from './ClientsTable';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import type { Hierarchy } from '../../../../common/types/table';
import type { JobList } from '../../../types/audit';
import type { SignerInfo } from '../../../types/signer';
import type { RollForwards } from '../../../types/rollForward';

type Props = StepProps &
  ClientsTableProps & {
    getRollForwards: Function,
    selectRollForwards: Function,
    selectSubsidiaryRollForward: Function,
    onClickSave: Function,
    loading: number,
    getOnboardingInfo: Function,
    deselectedClient: Function,
    deselectedSubsidiary: Function,
    selectClientPartially: Function,
    selectedSubsidiary: Function,
    selectedList: Hierarchy,
    toggleExpandSubsidiary: Function,
    toggleExpand: Function,
    saveSelectedAudits: Function,
    changeJobs: Function,
    jobList: JobList,
    changeChildJobs: Function,
    updateRollForward: Function,
    selectedSigner: Map<string, SignerInfo>
  };

class Clients extends PureComponent<Props> {
  handleProcess = async () => {
    const {
      getOnboardingInfo,
      saveSelectedAudits,
      selectedList,
      getRollForwards,
      onClickSave,
      updateRollForward,
      audits,
      selectRollForwards,
      selectSubsidiaryRollForward
    } = this.props;
    await getOnboardingInfo();
    await saveSelectedAudits(selectedList);
    const getRollForwardsParams = audits
      .filter(audit => audit.get('state') > 0)
      .valueSeq()
      .map(audit => ({
        clientId: audit.getIn(['datum', 'id']),
        jobId: audit.getIn(['datum', 'jobId']),
        children: audit
          .getIn(['datum', 'children'])
          .filter(child => child.get('state') > 0)
          .valueSeq()
          .map(child => ({
            clientId: child.getIn(['datum', 'id']),
            jobId: child.getIn(['datum', 'jobId'])
          }))
      }))
      .toJS();
    const heads: RollForwards = (await getRollForwards(getRollForwardsParams)).value;
    heads.forEach((head, headId) => {
      selectRollForwards(headId);
      head.getIn(['datum', 'children']).forEach((child, childId) => {
        selectSubsidiaryRollForward(headId, childId);
        if (child.getIn(['datum', 'auraDatabase', 'option']) === 'Do_Nothing') {
          const parentAuraDatabase = heads.getIn([headId, 'datum', 'auraDatabase']);
          updateRollForward(
            childId,
            'auraDatabase',
            { ...parentAuraDatabase, isAuraAsParent: true },
            headId
          );
        }
      });
    });
    onClickSave();
  };

  render() {
    const {
      audits,
      selectedSubsidiary,
      selectClient,
      selectedList,
      selectRollForwards,
      theme,
      loading,
      getOnboardingInfo,
      deselectedClient,
      deselectedSubsidiary,
      selectClientPartially,
      toggleExpandSubsidiary,
      toggleExpand,
      changeJobs,
      jobList,
      changeChildJobs,
      selectedSigner,
      ...rest
    } = this.props;

    const isJobSelected =
      selectedList.size &&
      selectedList
        .filter(roll => roll.getIn(['datum', 'jobList']) && roll.getIn(['datum', 'jobList']).length)
        .every(roll => roll.getIn(['datum', 'jobId']) && roll.getIn(['datum', 'jobName']));
    const clientCount = selectedList.filter(
      roll => roll.getIn(['datum', 'jobList']) && roll.getIn(['datum', 'jobList']).length
    ).size;
    return (
      <Step
        title="Clients"
        subtitle="Select client(s) that you want to roll-forward"
        stepSummaryItems={[
          {
            title: `${selectedList.size} client(s) selected`,
            text:
              selectedList.size > 0 &&
              selectedList
                .valueSeq()
                .map(i => i.getIn(['datum', 'name']))
                .join(', ')
          }
        ]}
        Icon={Domain}
        theme={theme}
        {...rest}
      >
        <ClientsTable
          audits={audits}
          selectedSubsidiary={selectedSubsidiary}
          selectClientPartially={selectClientPartially}
          selectClient={selectClient}
          deselectedHeadClient={deselectedClient}
          deselectedChild={deselectedSubsidiary}
          toggleExpandSubsidiary={toggleExpandSubsidiary}
          toggleExpand={toggleExpand}
          changeJobs={changeJobs}
          changeChildJobs={changeChildJobs}
          selectedSigner={selectedSigner}
        />
        <ActionBar
          status={
            selectedList.size
              ? `${clientCount} client(s) selected. Press ’Proceed’ to continue to next step.`
              : 'Select a Client to proceed'
          }
          isLoading={!!loading}
          proceed={{
            name: 'Proceed',
            callback: this.handleProcess,
            isDisabled: !isJobSelected || !!loading
          }}
        />
      </Step>
    );
  }
}

export default Clients;
