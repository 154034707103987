// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import InfoIcon from '@material-ui/icons/Info';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextLabel from '../TextLabel';
import Button from '../Button';
import { DialogStyledContent } from '../canvas/DialogBox';
import Tooltip from '../tooltip/Tooltip';
import ClientRestriction from './hints/ClientRestriction';
import InputBox from '../InputBox';
import Alink from '../Link';
import StyledCheckbox from '../checkbox/StyledCheckbox';

const QuestionBox = styled.div`
  .extra-queston {
    margin: ${props => props.theme.space.single.xl};
  }
  span {
    color: ${props => props.theme.color.ui.primary};
  }
`;

const MultiLineCheckBox = styled.div`
  display: inline-flex;
  align-items: center;
`;

type Props = {
  isOpen: boolean,
  requestName: string,
  closeDialog: Function,
  createServiceOrder: Function,
  createNewDataset: Function
};
type State = {
  isReqSent: boolean,
  extraQ: boolean,
  isRestrictionClient: boolean,
  information: string
};
class ServiceReqest extends PureComponent<Props, State> {
  state: State = {
    isReqSent: false,
    extraQ: false,
    isRestrictionClient: false,
    information: ''
  };

  onChangeRequest = (status: boolean) => {
    this.setState({
      isReqSent: status
    });
  };

  onChangeExtraQ = (status: boolean) => {
    this.setState({
      extraQ: status
    });
  };

  onChangeRestrictionClient = (status: boolean) => {
    this.setState({
      isRestrictionClient: status
    });
  };

  render() {
    const { isOpen, requestName, closeDialog, createServiceOrder, createNewDataset } = this.props;
    const { isReqSent, extraQ, isRestrictionClient, information } = this.state;
    return (
      <div>
        <Dialog open={isOpen} onClose={closeDialog} aria-labelledby="scroll-dialog-title">
          <DialogTitle data-cy="dialog-header">{requestName}</DialogTitle>

          <DialogStyledContent>
            <TextLabel data-cy="status-label">
              The reports you loaded were not recognised by the system and the automatic conversion
              of the files into the data platform was not successful. What would you like to do?
            </TextLabel>
            <MultiLineCheckBox>
              <StyledCheckbox
                color="default"
                checked={!isReqSent}
                onChange={() => {
                  this.onChangeRequest(false);
                  this.onChangeRestrictionClient(false);
                }}
                value="should_send_req"
              />
              <div>
                End the process, check the data with a help of a
                <Alink
                  href="https://sites.google.com/pwc.com/auditoperatingmodelfinland/supporting-roles/data-specialists"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Specialist
                </Alink>
                and try loading it again. You can find examples of dataset standards
                <Alink
                  href="https://drive.google.com/open?id=1B8oIauxyIJ039PeM_VteFyZ4F5gUdNk3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </Alink>
              </div>
            </MultiLineCheckBox>

            <QuestionBox>
              <div>
                <StyledCheckbox
                  data-cy="option-send-sdc-order"
                  color="default"
                  checked={isReqSent}
                  onChange={() => this.onChangeRequest(true)}
                  value="should_send_req"
                />
                Send a request to the SDC to manually process the data
              </div>

              {isReqSent && (
                <div className="extra-queston">
                  <div>
                    {isReqSent && !extraQ && (
                      <span>
                        * Please note that the SDC does not guarantee the capacity to handle ad hoc
                        requests. Do you wish to send a request to the SDC anyway? The SDC will
                        confirm / reject the request within 1 workday.
                      </span>
                    )}
                  </div>
                  <div>
                    <Tooltip>
                      Are there any restrictions relating to this client?
                      <div>
                        <InfoIcon />
                        <ClientRestriction />
                      </div>
                    </Tooltip>

                    <div>
                      <StyledCheckbox
                        color="default"
                        data-cy="option-restrictions"
                        checked={isRestrictionClient}
                        onChange={() => this.onChangeRestrictionClient(true)}
                        value="should_send_req"
                      />
                      Yes
                    </div>
                    <div>
                      <StyledCheckbox
                        color="default"
                        checked={!isRestrictionClient}
                        onChange={() => this.onChangeRestrictionClient(false)}
                        value="should_send_req"
                      />
                      No
                    </div>
                    {isRestrictionClient && (
                      <InputBox
                        data-cy="input-restrictions"
                        placeholder="Please provide more information for restrictions relating to this client"
                        value={information}
                        onChange={e => this.setState({ information: e.target.value })}
                      />
                    )}
                  </div>
                </div>
              )}
            </QuestionBox>
          </DialogStyledContent>
          <Button
            data-cy="sdc-submit"
            primary={!(isRestrictionClient && !information)}
            solid
            disabled={isRestrictionClient && !information}
            onClick={() => {
              if (isReqSent) {
                createServiceOrder(information);
                closeDialog();
              } else {
                createNewDataset();
              }
            }}
          >
            Submit
          </Button>
        </Dialog>
      </div>
    );
  }
}
export default ServiceReqest;
