// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { type List as ListType } from 'immutable';
import Domain from '@material-ui/icons/Domain';
import Row from './Row';
import { type Client, type StaffMember } from '../../types';
import { type StepAction } from '../../../common/components/workflow/Steps';
import Step, { type Props as StepProps } from '../../../common/components/workflow/Step';
import ActionBar from '../../../common/components/workflow/ActionBar';

type Props = StepProps & {
  clients: ListType<Client>,
  selectedLeader?: ListType<StaffMember>,
  toggleClientSelection: Function,
  getOrders: Function,
  onClickSave: () => StepAction,
  toggleSelectAll: Function
};

const Wrapper = styled.div`
  > div {
    margin: 10px;
  }
`;

const ClientsList = ({
  clients,
  selectedLeader,
  toggleClientSelection,
  getOrders,
  theme,
  onClickSave,
  toggleSelectAll,
  ...rest
}: Props) => {
  const [isLoading, setLoader] = useState(false);
  const handleProceed = async () => {
    setLoader(true);

    try {
      const clientId = clients
        .filter(client => client.isSelected)
        .map(client => client.id)
        .toJS();
      await getOrders({
        clientId
      });
      setLoader(false);
      onClickSave();
      toggleSelectAll(true);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <Step
      title="Clients"
      subtitle="Select Clients"
      Icon={Domain}
      theme={theme}
      onClickEdit={() => {}}
      {...rest}
    >
      <Wrapper>
        {clients.map(client => (
          <Row
            key={client.id}
            id={client.id}
            isSelected={client.isSelected}
            clientName={client.name}
            leader={selectedLeader && selectedLeader.get(0) && selectedLeader.get(0).name}
            subsidiaryCount={client.subsidiaries ? client.subsidiaries.length : 0}
            onChange={value => {
              toggleClientSelection({
                id: client.id,
                value
              });
            }}
          />
        ))}
      </Wrapper>
      <ActionBar
        status="Select a client to proceed"
        isLoading={isLoading}
        proceed={{
          name: 'Proceed',
          callback: handleProceed,
          isDisabled: !isLoading && clients.every(client => !client.isSelected)
        }}
      />
    </Step>
  );
};

export default ClientsList;
