import React from 'react';
import styled, { css } from 'styled-components';
import Close from '@material-ui/icons/Close';
import { getPadding, getBorderRadius, getColor } from '../theme/styled-utils';

const Button = styled.button`
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  border: ${props => (props.link ? 'none' : '1px solid')};
  outline: none;
  padding: ${getPadding()};
  border-radius: ${getBorderRadius()};
  opacity: ${props => props.opacity || 1}
  font-size: ${props => (props.isLarge ? props.theme.fonts.size.body.m : '')};
  background-color: ${props => {
    if (props.solid) {
      if (props.disabled) return props.theme.color.ui.disabled;
      if (props.primary) return props.theme.color.ui.primary;
      if (props.color) return getColor(props.color)(props);
    }
    if (props.backgroundColor) return getColor(...props.backgroundColor)(props);
    return props.theme.color.background.transparent;
  }};
  
  opacity: ${props => props.disabled && props.deleteSubsidiaries && '0.3'};

  color: ${props => {
    if (props.solid || (props.disabled && !props.deleteSubsidiaries))
      return props.theme.color.text.light;
    if (props.primary) return props.theme.color.ui.primary;
    if (props.success) return props.theme.colors.orange[600];
    if (props.color) return getColor(props.color)(props);
    return props.theme.color.ui.default;
  }};
  border-color: ${props => {
    if (props.disabled) return props.theme.color.ui.disabled;
    if (props.primary) return props.theme.color.ui.primary;
    if (props.color) return getColor(props.color)(props);
    return props.theme.color.ui.default;
  }};

  svg {
    vertical-align: middle;
    margin: ${props => props.theme.space.stack.xs};
  }

  ${props =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `};

  ${props =>
    props.withIcon &&
    css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `};
`;

export const Buttons = styled.div`
  display: flex;
  button {
    flex-grow: 1;
    margin: 4px;
  }
`;

export const LinkButton = styled(Button).attrs({ link: 1, size: 'none' })`
  font-size: ${props => (props.large ? props.theme.fonts.size.body.m : '11px')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-weight: ${props => props.bold && 'bold'};
}
`;

export const BlockLinkButton = styled(Button).attrs({
  link: 1,
  block: 1,
  size: 'none'
})``;

export const CloseButton = props => (
  <LinkButton {...props}>
    <Close />
  </LinkButton>
);

export const GlowButton = styled(Button)`
  border: none;
  font-size: 16px;

  :not(:hover) {
    color: inherit;
  }
`;

export default Button;
