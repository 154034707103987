// @flow
import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList, Map } from 'immutable';
import styled from 'styled-components';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Grid from '@material-ui/core/Grid';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Button, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import DraggableText from './DraggableText';
import MessageBar from '../../../../common/components/workflow/MessageBar';
import {
  updateFormFieldValue,
  previewPackage,
  completeTaxReturn
} from '../../../duck/TaxReturnPage';
import { type TaxReturn, type UserRole, userRoles } from '../../../types';

const ItemWrapper = styled.div`
  display: flex;
  margin: 8px;
  align-items: center;
  width: 100%;
`;
const Span = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface StateProps {
  taxReturn: TaxReturn;
  allDocs: Array<any>;
  packageDocs: Array<any>;
  selectedData: Array<string>;
  unsavedChanges: boolean;
  loading: Map<{ isLoading: boolean, msg: string }>;
  userRole: UserRole;
}

interface DispatchProps {
  updateFormFieldValue: (value: any, path: any[]) => void;
  previewPackage: (id: string, guidList: string[]) => void;
  completeTaxReturn: (id: string) => void;
}

type Props = StateProps & DispatchProps;

const PreparePackage = ({
  taxReturn: { id: taxReturnId },
  allDocs,
  packageDocs,
  selectedData,
  unsavedChanges,
  loading,
  userRole,
  ...dispatchProps
}: Props) => {
  // The packageDocs array is not in the correct `selectedData` order by default. After page load
  // or refresh the order in the view won't match the "real order", so we need initial sorting
  const [cards, setCards] = useState(
    [...packageDocs].sort((a, b) => selectedData.indexOf(a.uuid) - selectedData.indexOf(b.uuid))
  );
  const [messageBarOpen, setMessageBarOpen] = useState(false);
  const availableCards = useMemo(() => allDocs.filter(doc => !selectedData.includes(doc.uuid)), [
    allDocs,
    selectedData
  ]);
  const isLoading = useMemo(() => (loading ? loading.isLoading : false), [loading]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      const newCards = update(cards, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
      });
      const newUUidOrder = newCards.map(c => c.uuid);
      setCards(newCards);
      const newOrder = selectedData.sort(
        (a, b) => newUUidOrder.indexOf(a) - newUUidOrder.indexOf(b)
      );

      dispatchProps.updateFormFieldValue(ImmutableList(newOrder), 'package');
    },
    [dispatchProps, selectedData, cards]
  );

  const PreviewButton = () => (
    <Button
      data-cy="preview-button"
      variant="contained"
      color="primary"
      style={{ margin: '0 8px' }}
      disabled={isLoading}
      href="#"
      onClick={async event => {
        event.preventDefault();
        await dispatchProps.previewPackage(taxReturnId, selectedData);
      }}
    >
      Preview
    </Button>
  );

  const SendToComplianceButton = () => (
    <Button
      data-cy="send-to-compliance"
      variant="contained"
      color="primary"
      disabled={isLoading || unsavedChanges || userRole !== userRoles.PREPARER}
      onClick={async () => {
        await dispatchProps.completeTaxReturn(taxReturnId);
        setMessageBarOpen(true);
      }}
      title={
        userRole === userRoles.REVIEWER
          ? `Only preparer can send the package to compliance`
          : `Complete tax return by sending the package to compliance`
      }
    >
      Send to compliance team
    </Button>
  );

  const renderSelectedPackageItem = (card, index) => (
    <React.Fragment key={card.id}>
      <ListItem>
        <ItemWrapper>
          <Grid item xs={2}>
            <DraggableText
              key={card.id}
              index={index}
              id={card.id}
              text={<ReorderIcon data-cy={`drag-item-${index}`} />}
              moveCard={moveCard}
            />
          </Grid>
          <Grid item xs={8}>
            <Span data-cy={`drag-item-name-${index}`}>{card.name}</Span>
          </Grid>
          <Grid item>
            <Button
              data-cy={`package-item-remove-button-${index}`}
              variant="outlined"
              onClick={() => {
                setCards(cards.filter(o => o.uuid !== card.uuid));
                dispatchProps.updateFormFieldValue(
                  ImmutableList(selectedData.filter(o => o !== card.uuid)),
                  'package'
                );
              }}
              color="secondary"
            >
              Remove
            </Button>
          </Grid>
        </ItemWrapper>
      </ListItem>
      {index !== cards.length - 1 && <Divider />}
    </React.Fragment>
  );

  const renderAvailablePackageItem = (card, index) => (
    <React.Fragment key={card.id}>
      <ListItem>
        <ItemWrapper>
          <Grid item xs={2}>
            <Button
              data-cy={`drag-item-check-box-${index}`}
              variant="outlined"
              onClick={() => {
                setCards([...cards, card]);
                dispatchProps.updateFormFieldValue(
                  ImmutableList([...selectedData, card.uuid]),
                  'package'
                );
              }}
              color="primary"
            >
              Select
            </Button>
          </Grid>
          <Grid item xs={10}>
            {card.name}
          </Grid>
        </ItemWrapper>
      </ListItem>
      {index !== allDocs.length - packageDocs.length - 1 && <Divider />}
    </React.Fragment>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Typography variant="h5" gutterBottom>
        Documents included in the package
      </Typography>
      <List> {cards.map((card, i) => renderSelectedPackageItem(card, i))} </List>
      <Grid container spacing={8} alignItems="flex-end">
        <Grid item>
          <PreviewButton />
        </Grid>
        <Grid item>
          <SendToComplianceButton />
        </Grid>
        {unsavedChanges && (
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Unsaved changes
            </Typography>
          </Grid>
        )}
      </Grid>
      <MessageBar
        type="success"
        message="Succesfully sent to compliance team!"
        status={messageBarOpen}
        onClose={() => setMessageBarOpen(false)}
      />
      <br />
      <Typography variant="h5" gutterBottom>
        All documents
      </Typography>
      <List> {availableCards.map((card, index) => renderAvailablePackageItem(card, index))} </List>
    </DndProvider>
  );
};

// Cypress tests will fail without the try-catch block. Mock data is not immutable, so the `get`
// calls will result in runtime errors there.
const mapStateToProps = (state): StateProps => {
  const {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    loading,
    userRole
  } = state.veromylly.taxReturnPage;
  let allDocs = [];
  let selectedData = [];
  try {
    allDocs = taxReturnData
      .get('packageable')
      .valueSeq()
      .toJS();
    selectedData = taxReturnData.get('package').toJS();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Failed to retrieve tax return package data`);
  }

  return {
    taxReturn,
    allDocs,
    selectedData,
    packageDocs: allDocs.filter(doc => selectedData.includes(doc.uuid)),
    unsavedChanges,
    loading,
    userRole
  };
};

const dispatchProps: DispatchProps = {
  updateFormFieldValue,
  previewPackage,
  completeTaxReturn
};

export default connect(
  mapStateToProps,
  dispatchProps
)(PreparePackage);
