// @flow
import React, { PureComponent, Fragment } from 'react';
import ToggleChildren from '../../../common/components/table/ToggleChildren';
import Row, { Td } from '../../../common/components/table/TableRow';
import TableHeader, { Th, CustomTh } from '../../../common/components/table/TableHeader';
import Checkbox from '../../../common/components/checkbox/Checkbox';
import { type TableItem, type Hierarchy } from '../../../common/types/table';
import StyledTable from '../../../common/components/StyledTable';
import ActionItems from '../../../common/components/table/ActionItems';
import theme from '../../../common/theme/theme';

type Indicator = {
  name: string,
  size: string
};
type Props = {
  getChildren: Function,
  isAllSelectedEnabled?: boolean,
  headers: Array<any>,
  data: Hierarchy,
  children: Function,
  indicators?: Array<Indicator>,
  toggleExpandSubsidiary: Function,
  toggleExpand: Function,
  getMoreData?: Function,
  selectClient: Function,
  selectedSubsidiary: Function,
  selectClientPartially: Function,
  deselectedHeadClient: Function,
  deselectedChild: Function,
  getParentKey: Function,
  getKey: Function,
  getSubsidiariesStatus: Function,
  getChildList: Function,
  hasEmptyJobList: Function
};

class Table extends PureComponent<Props> {
  static defaultProps = {
    indicators: undefined,
    isAllSelectedEnabled: true,
    getMoreData: undefined
  };

  selectedItems = (item: TableItem) => {
    const {
      selectClient,
      selectedSubsidiary,
      selectClientPartially,
      getMoreData,
      data,
      getParentKey,
      getKey,
      getSubsidiariesStatus,
      getChildList,
      hasEmptyJobList
    } = this.props;
    const hasSubsidiaries = getSubsidiariesStatus(item);
    const parentKey = getParentKey(item);
    const key = getKey(item);
    const children = getChildList(item);

    // select single client
    if (!hasSubsidiaries && !parentKey) {
      selectClient(key);
    }
    // select headclient
    if (hasSubsidiaries) {
      selectClient(key);
      if (getMoreData && hasEmptyJobList(children)) {
        getMoreData(key);
      }

      children.forEach(child => {
        selectedSubsidiary(getParentKey(child), getKey(child));
      });
    }

    // select children under headclient
    if (!hasSubsidiaries && parentKey) {
      selectedSubsidiary(parentKey, key);
      const parentState = data.getIn([parentKey, 'state']);
      const shouldSelectHeadClient =
        (data.getIn([parentKey, 'datum', 'children']) || []).filter(
          child => child.get('state') !== 2
        ).size === 1;

      if (shouldSelectHeadClient) {
        selectClient(parentKey);
      } else if (parentState > 1) {
        selectClient(parentKey);
      } else {
        selectClientPartially(parentKey);
      }
    }
  };

  deselectedItems = (item: TableItem) => {
    const {
      deselectedHeadClient,
      deselectedChild,
      selectClientPartially,
      data,
      getParentKey,
      getKey,
      getSubsidiariesStatus,
      getChildList
    } = this.props;
    const hasSubsidiaries = getSubsidiariesStatus(item);
    const parentKey = getParentKey(item);
    const key = getKey(item);
    const children = getChildList(item);

    // deselected headClient
    if (hasSubsidiaries) {
      deselectedHeadClient(key);
      children.forEach(child => {
        deselectedChild(getParentKey(child), getKey(child));
      });
    }
    // deselected Single Client
    if (!hasSubsidiaries && !parentKey) {
      deselectedHeadClient(key);
    }

    // deselected child under headclient
    if (!hasSubsidiaries && parentKey) {
      const parentState = data.getIn([parentKey, 'state']);
      deselectedChild(parentKey, key);
      const shoulDeselectHeadClient =
        (data.getIn([parentKey, 'datum', 'children']) || []).filter(
          child => child.get('state') !== 0
        ).size === 1;

      if (shoulDeselectHeadClient) {
        deselectedHeadClient(parentKey);
      } else if (parentState < 1) {
        deselectedHeadClient(parentKey);
      } else {
        selectClientPartially(parentKey);
      }
    }
  };

  toggleExpand = (item: TableItem) => {
    const { toggleExpandSubsidiary, toggleExpand, getMoreData, getChildren } = this.props;
    const hasSubsidiaries = item.children;
    if (hasSubsidiaries) {
      toggleExpand(item.key);
      if (getMoreData) getMoreData(item.key);
      if (item.get('children').some(child => !child.getIn(['datum', 'jobList'])) && getMoreData) {
        getMoreData(item.key);
      } else {
        getChildren(item).forEach(child => {
          toggleExpandSubsidiary(item.key, child.key);
        });
      }
    }
  };

  getGeneralByChildren = (children: any) => {
    if (children.every(child => child.get('state') === 2)) return 2;
    else if (
      children.some(child => child.get('state') === 1) ||
      children.some(child => child.get('state') === 2)
    )
      return 1;
    return 0;
  };

  toggleSelectAllItems = (data: Hierarchy) => {
    if (this.getGeneralByChildren(data) > 0) {
      data.forEach(i => this.deselectedItems(i));
    } else {
      data.forEach(i => this.selectedItems(i));
    }
  };

  createActionColumns = (item: TableItem, isChild: boolean = false) => () => {
    const { getMoreData } = this.props;
    return (
      <Fragment>
        <Td>
          <ActionItems>
            {item.datum && item.datum.hasSubsidiaries && (
              <ToggleChildren
                onClick={() => {
                  if (getMoreData) {
                    getMoreData(item.getIn(['datum', 'id']));
                  }
                }}
                isOpen={item.expanded}
              />
            )}
            <Checkbox
              isDisabled={isChild}
              state={item.get('state')}
              color={isChild ? theme.color.ui.disabled : ''}
              onClick={() => {
                if (item.get('state') > 0) {
                  this.deselectedItems(item);
                } else {
                  this.selectedItems(item);
                }
              }}
            />
          </ActionItems>
        </Td>
      </Fragment>
    );
  };

  render() {
    const { children, headers, indicators, isAllSelectedEnabled, data } = this.props;
    return (
      <StyledTable>
        <thead>
          {indicators && (
            <Row>
              {indicators.map(i => (
                <CustomTh key={i.name} colSpan={i.size}>
                  {i.name}
                </CustomTh>
              ))}
              <Th noPadding />
            </Row>
          )}
          <Row>
            <Th>
              {isAllSelectedEnabled && (
                <ActionItems>
                  <Checkbox
                    state={this.getGeneralByChildren(data)}
                    onClick={() => this.toggleSelectAllItems(data)}
                  />
                </ActionItems>
              )}
            </Th>
            {headers.map(TableHeader)}
            <Th noPadding />
          </Row>
        </thead>
        <tbody>
          {data.valueSeq().map((item: TableItem) =>
            children({
              key: item.getIn(['datum', 'id']),
              datum: item.get('datum'),
              expanded: item.get('expanded'),
              hasChildren: item.hasSubsidiaries,
              Actions: this.createActionColumns(item),
              children: item
                .getIn(['datum', 'children'])
                .valueSeq()
                .map(child => ({
                  key: child.getIn(['datum', 'id']),
                  datum: child.get('datum'),
                  expanded: item.get('expanded'),
                  Actions: this.createActionColumns(child, true),
                  children: {}
                }))
            })
          )}
        </tbody>
      </StyledTable>
    );
  }
}

export default Table;
