import styled from 'styled-components';

export const Menu = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: ${props => (props.visible ? 'absolute' : 'relative')};
  padding: ${props => (props.noPadding ? 0 : props.theme.space.single.m)};
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${props => props.theme.color.background.white};
  border: 1px solid ${props => props.theme.color.border.default};
  border-radius: 9px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  white-space: nowrap;
  color: ${props => props.theme.color.text.neutral};
  width: ${props => props.width || null};
`;

export const MenuWrapper = styled.div`
  .database {
    z-index: 2;
  }
  position: relative;
  text-align: ${props => props.align};
  && {
    padding: ${props => (props.noPadding ? 0 : props.theme.space.single.m)};
  }
`;
