// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { type ActivityLogRow } from '../../../../types/activityLog';
import CreationInfo from './CreationInfo';
import StatusInfo from './StatusInfo';
import AuraInfo from './AuraInfo';
import DiagnosisLink from './DiagnosisLink';
import ProgressBar from '../../../../../common/components/workflow/LoadingBar';
import getStatusLabel from '../../../../utils/status';

type Props = {
  data: ActivityLogRow,
  setAuraDatabase: Function
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubWrapper = styled.div`
  width: ${props => (props.right ? '25%' : '37.5%')};
  margin-top: ${props => props.theme.space.single.xl};
`;

/*
 * ClientId given to components for Cypress test id
 */
const RowRight = ({ data, setAuraDatabase }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading ? (
        <ProgressBar />
      ) : (
        <Wrapper>
          <SubWrapper>
            <CreationInfo
              created={data.get('created')}
              creator={data.get('creator')}
              id={data.getIn(['client', 'id'])}
            />
          </SubWrapper>
          <SubWrapper>
            <StatusInfo
              id={data.getIn(['client', 'id'])}
              label={getStatusLabel(
                data.get('opportunityStatus'),
                data.get('jobStatus'),
                data.get('riskStatus'),
                data.get('auraDatabaseStatus'),
                data.get('auraLinkStatus'),
                data.getIn(['auraDatabase', 'id']) || -1
              )}
            />
          </SubWrapper>
          <SubWrapper right>
            <AuraInfo
              id={data.getIn(['auraDatabase', 'id']) || -1}
              name={data.getIn(['auraDatabase', 'name']) || ''}
              setAuraDatabase={async aura => {
                if (aura.id) {
                  setIsLoading(true);
                  try {
                    await setAuraDatabase(aura);
                    setIsLoading(false);
                  } catch {
                    setIsLoading(false);
                  }
                }
              }}
            />
          </SubWrapper>
          <DiagnosisLink id={data.get('id')} />
        </Wrapper>
      )}
    </>
  );
};

export default RowRight;
