// @flow
import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { type ActivityLogRow } from '../../types/activityLog';
import PageHeader from '../../../common/components/canvas/PageHeader';
import Button from '../../../common/components/Button';
import HeaderSection from '../../../common/components/HeaderSection';
import SearchBar, { userOptions } from './search-bar/SearchBar';
import Row from './row/Row';
import LoadingBar from '../../../common/components/workflow/LoadingBar';

type Props = {
  activityLogs: OrderedMap<ActivityLogRow>,
  isSearching: boolean,
  getActivityLogs: Function,
  updateAura: Function,
  history: any
};

const ListWrapper = styled.div`
  margin-top: ${props => props.theme.space.single.m};
`;

const ActivityLog = ({
  activityLogs,
  isSearching,
  getActivityLogs,
  updateAura,
  history
}: Props) => {
  const [clientName, setClientName] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [infoMessage, setInfoMessage] = useState('');
  const [showAll, setShowAll] = useState(userOptions[0]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const applyFilters = order => {
    const isNameMatch =
      clientName === '' ||
      order
        .getIn(['client', 'name'])
        .toLowerCase()
        .includes(clientName.toLowerCase());
    const isStatusMatch = statuses.length === 0 || statuses.includes(order.get('status'));

    return isNameMatch && isStatusMatch;
  };

  useEffect(() => {
    // All logs for done by me in a year
    (async () => {
      await getActivityLogs({
        statuses: [],
        showAll: false,
        showMonths: 1
      });
      setIsInitialLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll.value && clientName.length < 3)
      setInfoMessage(
        'Search results for all users are shown when name search of at least 3 letters is given. Excel download is still available.'
      );
    else setInfoMessage('');
  }, [showAll.value, clientName.length]);

  return (
    <>
      <HeaderSection>
        <PageHeader title="Activity Log for Roll-Forward" />
        <Button
          data-cy="back-button"
          primary
          solid
          rounded
          onClick={() => history.push('/roll-forward')}
        >
          <ArrowBack />
          Back to service page
        </Button>
      </HeaderSection>
      {!isInitialLoading ? (
        <SearchBar
          isSearching={isSearching}
          activityLogs={activityLogs.filter(applyFilters)}
          infoMessage={infoMessage}
          clientName={clientName}
          setClientName={setClientName}
          selectedStatuses={statuses}
          setStatuses={setStatuses}
          setShowAll={setShowAll}
          showAll={showAll}
          getActivityLogs={getActivityLogs}
        />
      ) : (
        <LoadingBar />
      )}

      <ListWrapper>
        {activityLogs.size > 0 &&
          ((showAll.value && clientName.length >= 3) || !showAll.value) &&
          activityLogs
            .filter(applyFilters)
            .valueSeq()
            .map(headClient => (
              <Fragment key={headClient.get('id')}>
                <Row
                  key={headClient.getIn(['client', 'id'])}
                  data={headClient}
                  setAuraDatabase={value =>
                    updateAura({
                      isHeadClient: true,
                      headClientId: headClient.get('id'),
                      value
                    })
                  }
                />
                {headClient.getIn(['client', 'hasSubsidiaries']) &&
                  headClient.getIn(['client', 'subsidiaries']) &&
                  headClient
                    .getIn(['client', 'subsidiaries'])
                    .valueSeq()
                    .map(subsidiary => (
                      <Row
                        key={`${headClient.getIn(['client', 'id'])}-${subsidiary.get('id')}`}
                        data={subsidiary}
                        setAuraDatabase={value =>
                          updateAura({
                            isHeadClient: false,
                            headClientId: headClient.get('id'),
                            subsidiaryId: subsidiary.get('id'),
                            value
                          })
                        }
                        isSubsidiary
                      />
                    ))}
              </Fragment>
            ))}
      </ListWrapper>
    </>
  );
};

export default ActivityLog;
