// @flow
import { Record, type RecordOf, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { Hierarchy } from '../../common/types/table';
import type { JobList } from '../types/audit';
import type { ActionType } from '../../common/types/redux';

type DefaultState = RecordOf<{
  audits: Hierarchy,
  jobList: JobList
}>;

const defaultState = Record({
  audits: OrderedMap()
});

// select and deselect audit
export const changeJobs = createAction('CHANGE_JOB', dropdown => ({
  clientId: dropdown.data.clientId,
  jobId: dropdown.data.id,
  jobName: dropdown.data.name,
  isRollForwarded: dropdown.data.isRollForwarded
}));

// select and deselect audit
export const changeChildJobs = createAction('CHANGE_CHILD_JOB', dropdown => ({
  clientId: dropdown.data.clientId,
  headClientId: dropdown.data.headClientId,
  jobId: dropdown.data.id,
  jobName: dropdown.data.name,
  isRollForwarded: dropdown.data.isRollForwarded
}));

export const getClientsJoblist = createAction('GET_CLIENTS_JOBLIST', async clientIds => {
  const jobs = await apiService.getJobList(clientIds);
  return { jobs };
});

export const getAudits = createAction('GET_AUDITS', apiService.getAudits);

export const deselectedClient = createAction('DESELECT_CLIENT', key => key);
export const selectClientPartially = createAction('SELECT_CLIENT_PARTIALLY', key => key);
export const deselectedSubsidiary = createAction(
  'DESELECT_SUBSIDIARY',
  (headclientKey, childKey) => ({
    headclientKey,
    childKey
  })
);

export const selectClient = createAction('SELECT_CLIENT', auditsId => auditsId);
export const selectedSubsidiary = createAction('SELECT_SUBSIDIARY', (headclientKey, childKey) => ({
  headclientKey,
  childKey
}));
export const toggleExpand = createAction('SELECT_TOGGLE', headClientId => headClientId);
export const toggleExpandSubsidiary = createAction(
  'SELECT_TOGGLE_SUBSIDIARY',
  (headClientId, childId) => ({
    headClientId,
    childId
  })
);

export const resetAudits = createAction('RESET_AUDITS');

export default function auditReducer(state: DefaultState = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_AUDITS_FULFILLED':
      return state.set('audits', payload.hierarchy);

    case 'RESET_AUDITS':
      return state.set('audits', defaultState().audits);

    case 'CHANGE_JOB':
      return state
        .setIn(['audits', payload.clientId, 'datum', 'jobId'], payload.jobId)
        .setIn(['audits', payload.clientId, 'datum', 'jobName'], payload.jobName)
        .setIn(['audits', payload.clientId, 'datum', 'isRollForwarded'], payload.isRollForwarded);

    case 'CHANGE_CHILD_JOB':
      return state
        .setIn(
          ['audits', payload.headClientId, 'datum', 'children', payload.clientId, 'datum', 'jobId'],
          payload.jobId
        )
        .setIn(
          [
            'audits',
            payload.headClientId,
            'datum',
            'children',
            payload.clientId,
            'datum',
            'jobName'
          ],
          payload.jobName
        )
        .setIn(
          [
            'audits',
            payload.headClientId,
            'datum',
            'children',
            payload.clientId,
            'datum',
            'isRollForwarded'
          ],
          payload.isRollForwarded
        );

    case 'GET_CLIENTS_JOBLIST_FULFILLED': {
      let newState = state;
      state.audits.keySeq().forEach(headId => {
        const headJobs = payload.jobs[headId] || [];
        const childCount = state.audits.getIn([headId, 'datum', 'children']).size;
        const isHeadRollForwarded = headJobs.every(job => job.isRollForwarded);
        const areAllChildrenRollForwarded =
          childCount > 0 &&
          state.audits
            .getIn([headId, 'datum', 'children'])
            .keySeq()
            .every(childId => (payload.jobs[childId] || []).every(job => job.isRollForwarded));
        const isRollForwardedSingle =
          childCount === 0 && (!headJobs || headJobs.every(job => job.isRollForwarded));

        // Single or head client must have not rolled jobs
        // And head client's children must also have some NOT rolled jobs
        if (isRollForwardedSingle || isHeadRollForwarded || areAllChildrenRollForwarded) {
          newState = newState.removeIn(['audits', headId]);
        } else {
          if (headJobs && headJobs.length > 0) {
            const filteredHeadJobs = headJobs.filter(job => !job.isRollForwarded);
            newState = newState
              .setIn(['audits', headId, 'datum', 'jobList'], filteredHeadJobs)
              .setIn(['audits', headId, 'datum', 'jobId'], filteredHeadJobs[0].id)
              .setIn(['audits', headId, 'datum', 'jobName'], filteredHeadJobs[0].name)
              .setIn(
                ['audits', headId, 'datum', 'isRollForwarded'],
                filteredHeadJobs[0].isRollForwarded
              );
          }
          state.audits
            .getIn([headId, 'datum', 'children'])
            .keySeq()
            .forEach(childId => {
              const childJobs = payload.jobs[childId] || [];
              const notRolledChildJobs = childJobs.filter(j => !j.isRollForwarded);

              if (notRolledChildJobs.length > 0) {
                newState = newState
                  .setIn(
                    ['audits', headId, 'datum', 'children', childId, 'datum', 'jobList'],
                    notRolledChildJobs
                  )
                  .setIn(
                    ['audits', headId, 'datum', 'children', childId, 'datum', 'jobId'],
                    notRolledChildJobs[0].id
                  )
                  .setIn(
                    ['audits', headId, 'datum', 'children', childId, 'datum', 'jobName'],
                    notRolledChildJobs[0].name
                  )
                  .setIn(
                    ['audits', headId, 'datum', 'children', childId, 'datum', 'isRollForwarded'],
                    notRolledChildJobs[0].isRollForwarded
                  );
              } else newState = newState.removeIn(['audits', headId, 'datum', 'children', childId]);
            });
        }
      });
      return newState;
    }

    case 'SELECT_TOGGLE':
      return state.updateIn(['audits', payload, 'expanded'], e => !e);

    case 'SELECT_TOGGLE_SUBSIDIARY':
      return state.updateIn(
        ['audits', payload.headClientId, 'children', payload.childId, 'expanded'],
        e => !e
      );

    case 'SELECT_CLIENT':
      return state
        .setIn(['audits', payload, 'state'], 2)
        .setIn(['audits', payload, 'expanded'], true);

    case 'SELECT_CLIENT_PARTIALLY':
      return state.setIn(['audits', payload, 'state'], 1);

    case 'DESELECT_CLIENT':
      return state.setIn(['audits', payload, 'state'], 0);

    case 'DESELECT_SUBSIDIARY':
      return state.setIn(
        ['audits', payload.headclientKey, 'datum', 'children', payload.childKey, 'state'],
        0
      );

    case 'SELECT_SUBSIDIARY':
      return state.setIn(
        ['audits', payload.headclientKey, 'datum', 'children', payload.childKey, 'state'],
        2
      );

    case 'RESET_ROLLFORWARD':
      return defaultState();

    default:
      return state;
  }
}
