/*
 *   if a date is in this list, it is not acceptable by M-Files thus will be greyed out in the calender for date chosing in step 3.
 */
const holidayList = [
  '10/04/2020',
  '13/04/2020',
  '01/05/2020',
  '21/05/2020',
  '25/12/2020',
  '26/12/2020',
  '01/01/2021',
  '06/01/2021',
  '02/04/2021',
  '05/04/2021',
  '14/06/2021',
  '25/06/2021',
  '06/12/2021',
  '24/12/2021',
  '25/12/2021',
  '26/12/2021',
  '06/01/2022',
  '15/04/2022',
  '18/04/2022',
  '06/01/2022',
  '26/05/2022',
  '06/12/2022',
  '25/12/2022',
  '26/12/2022'
];

export default holidayList;
