// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SimpleSelect } from '../../../../../common/components/selectors';
import TextLabel from '../../../../../common/components/TextLabel';
import { type TaxYear, type TaxReturn, type Language } from '../../../../types';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
    color: green;
  }
  & > *:first-child {
    min-width: 200px;
  }
`;

interface StateProps {
  languageList: Array<Language>;
}

interface OwnProps {
  existingTaxReturn: TaxReturn | null;
  selectedYear: TaxYear | null;
  selectedLanguage: Language | null;
  updateLanguage: (newLanguage: Language) => void;
}

type Props = StateProps & OwnProps;

const LanguageInfo = ({
  languageList,
  existingTaxReturn,
  selectedYear,
  selectedLanguage,
  updateLanguage
}: Props) => {
  if (existingTaxReturn) {
    return <TextLabel data-cy="language-info">{existingTaxReturn.language.name}</TextLabel>;
  } else if (selectedYear)
    return (
      <SelectContainer>
        <SimpleSelect
          name="language"
          optionList={languageList.map(y => ({
            value: y,
            label: y.name
          }))}
          onChange={language => updateLanguage(language.value)}
          value={
            selectedLanguage ? { label: selectedLanguage.name, value: selectedLanguage } : null
          }
        />
      </SelectContainer>
    );
  return <></>;
};

const mapStateToProps = state => ({
  languageList: state.veromylly.homePage.languageList
});

export default connect(
  mapStateToProps,
  {}
)(LanguageInfo);
