// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Redo from '@material-ui/icons/Redo';
import Info from '@material-ui/icons/Info';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import { type Props as JobTableProps } from './JobTable';
import JobTable from './container/JobTableContainer';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import OnboardingInstruction from './hints/OnboardingInstruction';
import TextLabel from '../../../../common/components/TextLabel';
import Checkbox from '../../../../common/components/checkbox/Checkbox';

type Props = StepProps &
  JobTableProps & {
    onClickSave: () => {},
    submitRollForwards: Function,
    loading: boolean,
    isOnboardingFinished: boolean,
    triggerInstruction: Function,
    deselectedRollForward: Function,
    selectRollForwards: Function,
    selectRollForwardsPartially: Function,
    submitRollForwards: Function,
    toggleExpandRollForward: Function,
    toggleSelectAll: Function
  };
type State = {
  selectAllJobMemo: boolean
};

const MemoSelectAll = styled.div`
  display: flex;
`;
class Jobs extends PureComponent<Props, State> {
  state = {
    selectAllJobMemo: false
  };

  handleProcess = async () => {
    const { submitRollForwards, onClickSave, rollForwards } = this.props;
    const selectedRollForwards = rollForwards
      .filter(roll => roll.get('state') > 0)
      .valueSeq()
      .map(roll =>
        roll.get('datum').set(
          'children',
          roll
            .getIn(['datum', 'children'])
            .filter(child => child.get('state') > 0)
            .map(child => child.get('datum'))
            .valueSeq()
        )
      );
    await submitRollForwards(selectedRollForwards);
    onClickSave();
  };

  render() {
    const {
      rollForwards,
      triggerInstruction,
      selectRollForwards,
      selectRollForwardsPartially,
      updateRollForward,
      updateRollForwardSubProperty,
      loading,
      theme,
      auraDatabases,
      getAuraDatabases,
      isOnboardingFinished,
      deselectedRollForward,
      toggleExpandRollForward,
      toggleSelectAll,
      selectSubsidiary,
      deselectSubsidiary,
      ...rest
    } = this.props;
    const { selectAllJobMemo } = this.state;
    const selectedItem = rollForwards.filter(i => i.get('state') > 0);
    const selectedCount = selectedItem.reduce(
      (sum, roll) => sum + 1 + roll.getIn(['datum', 'children']).filter(r => r.get('state')).size,
      0
    );
    const areSignersSelected = selectedItem.every(
      roll =>
        roll.getIn(['datum', 'partner1', 'type']) &&
        (!roll.get(['datum', 'partner2']) || roll.getIn(['datum', 'partner2', 'type'])) &&
        roll
          .getIn(['datum', 'children'])
          .filter(child => child.get('state'))
          .every(
            child =>
              child.getIn(['datum', 'partner1', 'type']) &&
              (!roll.get(['datum', 'partner2']) || roll.getIn(['datum', 'partner2', 'type']))
          )
    );

    return (
      <Step
        title="Jobs"
        subtitle="Select and submit roll-forward tasks"
        Icon={Redo}
        theme={theme}
        {...rest}
      >
        <TextLabel>
          <Info />
          You can check the instruction
          <b
            className="highlight"
            onClick={triggerInstruction}
            onKeyPress={triggerInstruction}
            role="textbox"
            tabIndex={0}
          >
            {' here'}
          </b>
        </TextLabel>
        <DialogBox
          title="Instruction"
          closeDialog={triggerInstruction}
          content={OnboardingInstruction}
          isOpen={!isOnboardingFinished}
        />
        <MemoSelectAll data-cy="select-all-job-memo">
          <Checkbox
            selected={selectAllJobMemo}
            onClick={() => {
              this.setState({ selectAllJobMemo: !selectAllJobMemo });
              rollForwards.forEach(roll => {
                updateRollForward(roll.get('key'), 'jobMemo', !selectAllJobMemo);
                roll
                  .getIn(['datum', 'children'])
                  .forEach(child =>
                    updateRollForward(
                      child.getIn(['datum', 'clientId']),
                      'jobMemo',
                      !selectAllJobMemo,
                      roll.get('key')
                    )
                  );
              });
            }}
          />
          <TextLabel>Select All For Job Memo</TextLabel>
        </MemoSelectAll>

        <JobTable
          deselectedRollForward={deselectedRollForward}
          updateRollForward={updateRollForward}
          deselectSubsidiary={deselectSubsidiary}
          selectSubsidiary={selectSubsidiary}
          rollForwards={rollForwards}
          updateRollForwardSubProperty={updateRollForwardSubProperty}
          selectClient={selectRollForwards}
          selectClientPartially={selectRollForwardsPartially}
          auraDatabases={auraDatabases}
          getAuraDatabases={getAuraDatabases}
          toggleExpandRollForward={toggleExpandRollForward}
          toggleSelectAll={toggleSelectAll}
        />
        <ActionBar
          status={
            selectedCount > 0
              ? `${selectedCount} job(s) selected. Press ’Proceed’ to continue to next step.`
              : 'Select a Job to proceed'
          }
          isLoading={!!loading}
          proceed={{
            name: 'Submit',
            callback: () => this.handleProcess(),
            isDisabled: !selectedItem.size || !areSignersSelected
          }}
        />
      </Step>
    );
  }
}

export default Jobs;
