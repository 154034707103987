// @flow
import React from 'react';
import Info from '@material-ui/icons/Info';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import { linkStyle } from '../Link';

const ContentWrapper = styled.div`
  a {
    ${props => linkStyle(props)}
  }
`;

const InfoIconTooltip = ({ tooltip }: { tooltip: { __html: string } }) => (
  <Tooltip>
    <div>
      <Info data-cy="tooltip-info" />
      <ContentWrapper dangerouslySetInnerHTML={tooltip} />
    </div>
  </Tooltip>
);

export default InfoIconTooltip;
