// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import JobAndPeriod from './components/JobAndPeriod';
import {
  createDatasetGroup,
  createHeadClientDataset,
  createSubsidiaryDataset,
  saveDate,
  getSubsidiaries
} from '../../../duck/dataset';
import { getAccountSystemList, resetAccountSystem } from '../../../duck/accountSystem';
import { saveSelectedJobList, removeSelectedJob } from '../../../duck/jobs';
import { textDateCreator, duration } from '../../../../common/utils/helper';

const JobSelectionContainer = (props: any) => {
  const { selectedJob, endDate, startDate } = props;
  const [inputFocused, onFocusChange] = useState(null);
  const stepSummaryItems = [
    {
      title: 'Job selected',
      text: selectedJob ? selectedJob.name : ''
    },
    {
      title: `${endDate && duration(startDate, endDate)} days selected`,
      text: `From ${textDateCreator(startDate)} to ${textDateCreator(endDate)}`
    }
  ];
  const childProps = { ...props, inputFocused, onFocusChange, stepSummaryItems };
  return <JobAndPeriod {...childProps} />;
};

export default connect(
  state => ({
    isLoading:
      state.dataset.get('isDatasetGroupLoading') ||
      state.dataset.get('isDatasetLoading') ||
      state.dataset.get('isFileLoading'),
    selectedJob: state.jobs.get('selectedJob'),
    startDate: state.dataset.get('startDate'),
    endDate: state.dataset.get('endDate'),
    sessionClient: state.dataset.filesData.headClient,
    jobList: state.jobs.get('jobList'),
    datasetGroupId: state.dataset.get('filesData')
      ? state.dataset.get('filesData').datasetGroupId
      : '',
    headClientDatasetId: state.dataset.get('filesData').headClient.datasetId,
    subsidiariesFileInfo: state.dataset.get('filesData').subsidiaries,
    selectedAccountSystem: state.accountSystem.selectedAccountSystem
  }),
  {
    getAccountSystemList,
    saveSelectedJobList,
    removeSelectedJob,
    saveDate,
    createDatasetGroup,
    createHeadClientDataset,
    createSubsidiaryDataset,
    getSubsidiaries,
    resetAccountSystem
  }
)(JobSelectionContainer);
