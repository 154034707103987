import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

export default () => (
  <div data-cy="onboarding-hint">
    <span>
      On this section you are required to answer specific questions regarding the iPower job and as
      well as Aura database roll-forward information. The automated roll-forward process will be
      performed based on these answers.
    </span>
    <br />
    <br />
    <span>How to use tips:</span>
    <ul>
      <li>
        If you move your mouse over the tooltip sign ( <InfoIcon /> ), you get more information
        regarding the related question to be answered.
      </li>
      <li>
        Once you have submitted the answers by selecting ‘Proceed’, you are no longer able to change
        your answers.
      </li>
      <li>
        After submitting your answers, you can follow the progress of the process in the Activity
        Log.
      </li>
    </ul>
  </div>
);
