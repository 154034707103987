// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { ExpandMore } from '@material-ui/icons';
import Button from '../../../../common/components/Button';
import Tooltip from '../../../../common/components/tooltip/Tooltip';
import AuraSelector from '../../common/AuraSelectorContainer';
import ColumnOptions from './container/ColumnOptionsContainer';
import RollForwardFinished from './hints/RollForwardFinished';
import SignerTypeSelection from './SignerTypeSelection';
import TableRow, { ClientName, ClientId, Td } from '../../../../common/components/table/TableRow';
import Switch from '../../../../common/components/Switch';
import Checkbox from '../../../../common/components/checkbox/Checkbox';
import type { RollForward } from '../../../types/rollForward';
import type { AuraDatabases } from '../../../types/auraDatabase';
import type { Hierarchy } from '../../../../common/types/table';

const SwitchInfoText = styled.span`
  margin-left: ${props => props.theme.space.single.s};
  a {
    color: ${props => props.theme.color.ui.primary};
    text-decoration: none;
  }
`;
const AuraDBSwitchInfoText = styled(SwitchInfoText)`
  display: flex;
  align-items: flex-start;
  > button {
    margin-right: 5px;
  }
`;

type Props = {
  id: string,
  datum: RollForward,
  Actions: () => Node,
  onChange: Function,
  auraDatabases: AuraDatabases,
  getAuraDatabases: Function,
  hasChildren?: boolean,
  isChildren?: boolean,
  childs?: Hierarchy,
  toggleAuraAsParent: Function,
  headClientKey?: string
};

const CustomTd = styled(Td)`
  @media (max-width: 1550px) {
    max-width: 120px;
    padding: 8px 8px !important;
  }

  text-align: center;
`;

const ClientTd = styled(CustomTd)`
  min-width: 250px;
`;

const SignerTypeTd = styled(CustomTd)`
  max-width: 300px;
`;

const Row = ({
  id,
  datum,
  hasChildren,
  isChildren,
  Actions,
  childs,
  onChange,
  auraDatabases,
  getAuraDatabases,
  toggleAuraAsParent,
  headClientKey
}: Props) => {
  const isDisabled = datum.get('hasRollForwardFinished');
  return (
    <TableRow
      hasChildren={hasChildren}
      isChildren={isChildren}
      data-cy={`${isChildren ? 'child' : 'parent'}-row`}
    >
      <Actions />
      <ClientTd>
        <Tooltip success>
          <div>
            <ClientName isChildren={isChildren}>{datum.get('clientName')}</ClientName>
            <ClientId>{datum.get('businessId')}</ClientId>
          </div>
          {isDisabled && (
            <div>
              <CheckCircle data-cy="rf-finished" />
              <RollForwardFinished />
            </div>
          )}
        </Tooltip>
      </ClientTd>
      <CustomTd data-cy="select-job-memo-column">
        <Switch
          checked={datum.get('jobMemo')}
          isDisabled={datum.get('hasRollForwardFinished')}
          onChange={() =>
            onChange(id, 'jobMemo', null, !datum.get('jobMemo'), childs, headClientKey)
          }
        />
      </CustomTd>
      <SignerTypeTd>
        <SignerTypeSelection
          signerInfo={[
            datum.get('partner1') && datum.get('partner1').toJS(),
            datum.get('partner2') && datum.get('partner2').toJS()
          ]
            .filter(partner => partner)
            .map((partner, i) => ({
              ...partner,
              number: i + 1,
              id: `${id}-${i}`
            }))}
          handleTabChange={number => option => {
            onChange(id, `partner${number}`, 'type', option.value, null, headClientKey);
          }}
        />
      </SignerTypeTd>
      <CustomTd>
        {isChildren && datum.getIn(['auraDatabase', 'isAuraAsParent']) ? (
          <AuraDBSwitchInfoText>
            <Checkbox
              data-cy="select-aura-as-parent"
              selected={datum.getIn(['auraDatabase', 'isAuraAsParent'])}
              onClick={() => {
                onChange(
                  id,
                  'auraDatabase',
                  null,
                  {
                    id: null,
                    name: 'Not selected',
                    option: 'Do_Nothing',
                    isAuraAsParent: false
                  },
                  null,
                  headClientKey
                );
              }}
            />
            Same As Head Client
          </AuraDBSwitchInfoText>
        ) : (
          <AuraSelector
            onChange={changes => {
              onChange(id, 'auraDatabase', null, changes, childs, headClientKey);
            }}
            auraDatabaseList={auraDatabases}
            getAuraDatabases={getAuraDatabases}
          >
            {({ openMenu, indicator }) => (
              <Button
                data-cy="aura-database-button"
                onClick={openMenu}
                size="s"
                primary={!isDisabled}
                withIcon
                disabled={isDisabled}
                solid={isDisabled}
              >
                {datum.getIn(['auraDatabase', 'option']) === 'Do_Nothing' &&
                  indicator &&
                  'Do not roll-forward'}
                {datum.getIn(['auraDatabase', 'option']) === 'Do_Nothing' && !indicator
                  ? 'Not selected'
                  : datum.getIn(['auraDatabase', 'name'])}
                <ExpandMore />
              </Button>
            )}
          </AuraSelector>
        )}
      </CustomTd>
      <CustomTd>
        {isChildren && (
          <ColumnOptions
            companyName={datum.get('clientName')}
            toggleAuraAsParent={toggleAuraAsParent}
            sameAuraDatabaseAsParent={datum.getIn(['auraDatabase', 'isAuraAsParent'])}
          />
        )}
      </CustomTd>
    </TableRow>
  );
};

Row.defaultProps = {
  childs: undefined,
  hasChildren: undefined,
  isChildren: false,
  headClientKey: ''
};

export default Row;
