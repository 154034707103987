// @flow
import { type List } from 'immutable';
import { get, post } from '../../common/utils/api';
import { type Client, type ServiceOrder, type File } from '../types';

export default {
  addFiles: async (orderId: number, file: FormData): Promise<number> =>
    post(`material-upload/service-order/${orderId}/file`, file),
  getFiles: async (orderId: number): Promise<List<File>> =>
    get(`material-upload/service-order/${orderId}/files`),
  getMyOrder: async (): Promise<List<ServiceOrder>> => get('material-upload/service-order/my'),
  searchOrders: async (clientId: number): Promise<List<ServiceOrder>> =>
    get('material-upload/service-order', { client_ids: clientId }),
  searchClients: async (name: string, limit: number = 25): Promise<List<Client>> =>
    get('material-upload/client', { name }),
  addInstruction: async (id: number, instruction: string) =>
    post(`material-upload/service-order/${String(id)}/instruction`, { text: instruction })
};
