// @flow
import { Record, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import { type AccountMappingState as State } from '../types/duck';
import { type ActionType } from '../../common/types/redux';
import { type AccountMapper } from '../types/accountMapping';
import { getFsliMapping, putFsliList } from '../services/accountMapping';

const defaultState = Record({
  isLoading: true,
  isSaving: false,
  isSavingSuccess: false,
  accountMappingList: OrderedMap({}),
  isFSLIMappingQueued: false,
  showCustomColumn: false,
  showCustomInput: false,
  isRejected: false
});

/**
 * ACTIONS
 */
export const getAccountMappingList = createAction(
  'FETCH_ACCOUNT_MAPPING_LIST',
  (datasetGroupId: string) => getFsliMapping(datasetGroupId)
);
export const saveAccountMappingList = createAction(
  'SAVE_ACCOUNT_MAPPING_LIST',
  (datasetGroupId: string, fsliList: OrderedMap<AccountMapper>) =>
    putFsliList(datasetGroupId, fsliList)
);
export const restoreAccountMappingList = createAction('RESTORE_ACCOUNT_MAPPING_LIST');
export const setFSLI = createAction('SET_FSLI'); // For both FSLI & Custom FSLI
export const updateCustomFsli = createAction('UPDATE_CUSTOM_FSLI');
export const removeCustomColumn = createAction('REMOVE_CUSTOM_COLUMN');
export const toggleCustomColumn = createAction('TOGGLE_CUSTOM_COLUMN', (flag: boolean) => flag);
export const toggleCustomInput = createAction('TOGGLE_CUSTOM_INPUT', (flag: boolean) => flag);
export const clearAccountMapping =  createAction('CLEAR_ACCOUNT_MAPPING');

/**
 * REDUCER
 */
export default (state: State = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'CLEAR_ACCOUNT_MAPPING': {
      return state.set('accountMappingList',  OrderedMap({})).set('isRejected', false)
    }
    case 'FETCH_ACCOUNT_MAPPING_LIST_FULFILLED': {
      if (!Array.isArray(payload) && payload.isFSLIMappingQueued) {
        return state.set('isFSLIMappingQueued', true).set('isLoading', false);
      }
      return state
        .set(
          'accountMappingList',
          payload &&
            OrderedMap(
              payload
                .map(el => {
                  // eslint-disable-next-line no-param-reassign
                  el.updatedAt = Date.now();
                  return el;
                })
                .map(el => [el.fsliMappingId, el])
            )
        )
        .set('isLoading', false);
    }
    case 'FETCH_ACCOUNT_MAPPING_LIST_REJECTED': {
      return state
        .set('isFSLIMappingQueued', false)
        .set('isLoading', false)
        .set('isRejected', true)
        .set('accountMappingList', OrderedMap({}));
    }
    case 'RESTORE_ACCOUNT_MAPPING_LIST': {
      return state.set(
        'accountMappingList',
        payload &&
          OrderedMap(
            payload
              .map(el => {
                // eslint-disable-next-line no-param-reassign
                el.updatedAt = Date.now();
                return el;
              })
              .map(el => [el.fsliMappingId, el])
          )
      );
    }
    case 'TOGGLE_CUSTOM_COLUMN': {
      return state.set('showCustomColumn', payload);
    }
    case 'TOGGLE_CUSTOM_INPUT': {
      return state.set('showCustomInput', payload);
    }
    case 'SET_FSLI': {
      return payload.fsliMappingId
        ? state.setIn(['accountMappingList', payload.fsliMappingId, payload.field], payload.value)
        : state;
    }
    case 'SAVE_ACCOUNT_MAPPING_LIST_PENDING': {
      return state.set('isSaving', true);
    }
    case 'SAVE_ACCOUNT_MAPPING_LIST_FULFILLED': {
      return state.set('isSavingSuccess', true).set('isSaving', false);
    }
    case 'SAVE_ACCOUNT_MAPPING_LIST_REJECTED': {
      return state.set('isSavingSuccess', false).set('isSaving', false);
    }
    case 'UPDATE_CUSTOM_FSLI': {
      return payload.fsliMappingId
        ? state.setIn(
            ['accountMappingList', payload.fsliMappingId, 'customFSLI'],
            payload.customFSLI
          )
        : state;
    }
    case 'REMOVE_CUSTOM_COLUMN': {
      return state
        .update('accountMappingList', list =>
          list.map(datasetGroup => ({ ...datasetGroup, customFSLI: '' }))
        )
        .set('showCustomColumn', false)
        .set('showCustomInput', false);
    }
    default:
      return state;
  }
};
