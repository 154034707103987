import React from 'react';
import Alink from '../../../common/components/Link';

const getLedgerInsightsToolUrl = () => {
  const env = process.env.NODE_ENV;
  let url = 'https://wezdkgxiapwa001.azurewebsites.net';
  if (env == 'development') {
    url = 'https://wezdkgxiadwa001.azurewebsites.net';
  }
  if (env == 'stage') {
    url = 'https://wezdkgxiaswa001.azurewebsites.net';
  }
  return url;
};

export default () => (
  <div>
    <span>
      {'GL Data Platform has been decommissioned. Please use '}
      <Alink
          href={getLedgerInsightsToolUrl()}
          target="_blank"
          rel="noopener noreferrer"
      >
          Ledger Insights
      </Alink>
      {' for GL data analytics. Information and guidance about Ledger Insights can be found in '}
      <Alink
          href='https://pwceur.sharepoint.com/sites/FI-ASR-asmt-finland/SitePages/Ledger-Insights.aspx'
          target="_blank"
          rel="noopener noreferrer"
      >
          Point
      </Alink>
    </span>
  </div>
);
