// @flow
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';

type Props = {
  status: boolean,
  onClose: Function,
  message: string,
  type?: string
};

const SnackBarWrapper = styled(Snackbar)`
  & > *:first-child {
    background-color: ${props => {
      if (props.type === 'error') return props.theme.color.ui.error;
      if (props.type === 'success') return props.theme.color.ui.success;
      return props.theme.color.ui.error;
    }};
  }
`;

const MessageBar = ({ status, onClose, message, type }: Props) => (
  <SnackBarWrapper
    data-cy={`${type || 'general'}-message-bar`}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    type={type}
    open={status}
    autoHideDuration={6000}
    onClose={onClose}
    message={<span id="message-id">{message}</span>}
  />
);

MessageBar.defaultProps = {
  type: 'error'
};
export default MessageBar;
