// @flow
import React, { PureComponent } from 'react';
import Info from '@material-ui/icons/Info';
import PageHeader from '../../common/components/canvas/PageHeader';
import ClientSearchContainer from './steps/client-search/ClientSearchContainer';
import JobSelectionContainer from './steps/job-selection/JobSelectionContainer';
import TeamAccessContainer from './steps/team-access/TeamAccessContainer';
import Steps from '../../common/components/workflow/Steps';
import DialogBox from '../../common/components/canvas/DialogBox';
import LoadingBar from '../../common/components/workflow/LoadingBar';
import GeneralLedgerInstruction from './hints/GeneralLedgerInstruction';
import GeneralLedgerDisclaimer from './hints/GeneralLedgerDisclaimer';
import TextLabel from '../../common/components/TextLabel';
import FileUploadContainer from './steps/file-upload/FileUploadContainer';
import Alink from '../../common/components/Link';

type Props = {
  history: any,
  goToActivityLog: Function,
  triggerInstruction: Function,
  isOnboardingFinished: boolean,
  getOnboardingInfo: Function,
  isGeneralLoading: boolean,
  cleanGeneralLedgerDuck: Function
};

type State = {
  isDisclaimerVisible: boolean
};

class GeneralLedger extends PureComponent<Props, State> {
  state: State = {
    isDisclaimerVisible: true,
  };

  componentDidMount() {
    const { getOnboardingInfo, cleanGeneralLedgerDuck } = this.props;
    cleanGeneralLedgerDuck();
    getOnboardingInfo();
  }

  render() {
    const {
      history,
      goToActivityLog,
      triggerInstruction,
      isOnboardingFinished,
      isGeneralLoading
    } = this.props;
    return (
      <div>
        <PageHeader
          getLog={goToActivityLog}
          title="General Ledger and Trial Balance upload"
          subTitle="Upload general ledger and trial balance data files extracted from the client’s accounting system.
      Please follow the instructions in the following link to get the accounting data from the client in appropriate format and perform the steps below to create the Qlik Sense analysis.
      "
        />

        <TextLabel>
          <Info />
          You can check the instruction
          <b
            className="highlight"
            onClick={triggerInstruction}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            {' '}
            here
          </b>
        </TextLabel>
        {isGeneralLoading && (
          <>
            <TextLabel clientName>The application is loading...</TextLabel>
            <LoadingBar />
          </>
        )}
        {!isGeneralLoading && (
          <>
            <Steps
              onDone={isValid => {
                if (isValid) history.push('/general-ledger/success');
              }}
            >
              <ClientSearchContainer key="clientSearch" />
              <JobSelectionContainer key="jobSelection" />
              <FileUploadContainer key="fileUpload" />
              <TeamAccessContainer key="teamAccess" />
            </Steps>
          </>
        )}
        {this.state.isDisclaimerVisible ? (
          <DialogBox
              title="Disclaimer"
              closeDialog={() => this.setState({isDisclaimerVisible: false})}
              content={GeneralLedgerDisclaimer}
              isOpen={this.state.isDisclaimerVisible}
              ButtonElement={null}
              hasBlurBackdrop
          />
        ) : (
          <DialogBox
            title="Instructions"
            closeDialog={triggerInstruction}
            content={GeneralLedgerInstruction}
            isOpen={!isOnboardingFinished && !isGeneralLoading}
          />
        )}
      </div>
    );
  }
}

export default GeneralLedger;
