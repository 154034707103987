// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import { type ActivityLogRow } from '../../../types/activityLog';
import MultiSelect from '../../../../common/components/selectors/MultiSelect';
import TabsToggle from '../../../../common/components/selectors/TabsToggle';
import TextInput from '../../../../common/components/TextInput';
import TextLabel from '../../../../common/components/TextLabel';
import statuses from '../../../constants/statuses';
import ResultsInfo from './ResultsInfo';

export const userOptions = [
  {
    value: 0,
    label: 'Me'
  },
  {
    value: 1,
    label: 'All'
  }
];

const Wrapper = styled.div`
  height: ${props => `${80 + props.extraHeight}px`};
  background: ${props => props.theme.color.background.white};
  font-size: ${props => props.theme.fonts.size.body.m};
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'space-between'};
`;

const SubWrapper = styled.div`
  padding: ${props => ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const customizedUiProperties = {
  colorTheme: 'orange',
  width: 'm',
  icon: () => {}
};

type Props = {
  isSearching: boolean,
  activityLogs: OrderedMap<ActivityLogRow>,
  infoMessage: string,
  clientName: string,
  setClientName: Function,
  setStatuses: Function,
  selectedStatuses: Array<any>,
  getActivityLogs: Function,
  showAll: Object,
  setShowAll: Function
};

const SearchBar = ({
  isSearching,
  activityLogs,
  infoMessage,
  clientName,
  setClientName,
  setStatuses,
  selectedStatuses,
  getActivityLogs,
  setShowAll,
  showAll
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {/**
        Top Row of Search Bar
        */}
      <Wrapper extraHeight={selectedStatuses.length * 5}>
        <SubWrapper>
          <TextInput
            onChange={event => {
              setClientName(event.target.value);
            }}
            value={clientName}
            placeholder="Search for a client by name"
            customWidth="260px;"
          />
        </SubWrapper>
        <SubWrapper>
          <MultiSelect
            placeholder="Select Status"
            options={statuses}
            onChange={selectedValues => {
              setStatuses(selectedValues.map(value => value.label));
            }}
          />
        </SubWrapper>

        <SubWrapper>
          <TextLabel>Show Jobs for Users</TextLabel>
          <TabsToggle
            options={userOptions}
            disabled={isLoading}
            onChange={async tab => {
              if (tab.value !== showAll.value) {
                const showAllValue = tab.value === 1;
                setShowAll(tab);
                // All logs done in a year
                setIsLoading(true);
                await getActivityLogs({
                  statuses: [],
                  showAll: showAllValue,
                  showMonths: 1
                });
                setIsLoading(false);
              }
            }}
            selectedItem={showAll}
            {...customizedUiProperties}
          />
        </SubWrapper>
      </Wrapper>

      {/**
        Bottom Row of Search Bar
        */}

      <ResultsInfo
        isSearching={isSearching}
        infoMessage={infoMessage}
        activityLogs={activityLogs}
      />
    </>
  );
};
export default SearchBar;
