// @flow
import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash';
import { Grid } from '@material-ui/core';
import CardView from '../common/components/canvas/CardView';
import { themesByStep } from '../common/components/workflow/Steps';
import PageHeader from '../common/components/canvas/PageHeader';
import PageContent from '../common/components/canvas/PageContent';
import { ServiceInfoContext } from '../common/duck/service-info.context';
import { type ServiceInfo } from '../common/types/serviceInfo';

const ServiceGroup = ({
  services,
  themeOffset
}: {
  services: ServiceInfo[],
  themeOffset: number
}) => {
  const history = useHistory();
  return (
    <Grid
      container
      data-cy="service-group"
      direction="row"
      justify="flex-start"
      style={{ background: 'white', marginTop: '20px' }}
    >
      {services.map((service, i) => (
        <CardView
          id={service.key}
          title={service.name}
          content={service.description}
          tooltipText={service.notification}
          onClick={() =>
            service.external
              ? window.open(service.externalUrl, '_blank')
              : history.push(service.path)
          }
          theme={themesByStep[(i + 1 + themeOffset) % themesByStep.length]}
          {...service}
        />
      ))}
    </Grid>
  );
};

const HomePage = () => {
  const { infos } = useContext(ServiceInfoContext);

  // Group visible services
  const groupedServices: { [string]: ServiceInfo[] } = groupBy(
    sortBy(
      infos.filter(i => i.visible),
      (info: ServiceInfo) => info.groupName,
      (info: ServiceInfo) => info.name
    ),
    (info: ServiceInfo) => info.groupName
  );

  return (
    <>
      <PageHeader
        getLog={null}
        title="Welcome to Service Portal"
        subTitle="Select a task to get started"
        isHomePage
      />
      <PageContent>
        {Object.keys(groupedServices).map((groupName, i) => (
          <Fragment key={groupName}>
            <h2>{groupName}</h2>
            <ServiceGroup services={groupedServices[groupName]} themeOffset={i} />
          </Fragment>
        ))}
      </PageContent>
    </>
  );
};

export default HomePage;
