// @flow
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TeaLoader, Login } from '@pwc-fi/ui-library';

import ThemeProvider from './common/theme/ThemeProvider';
import NavBar from './general-page/NavBar';
import ErrorMessage from './common/components/container/ErrorMessageContainer';
import Footer from './common/components/Footer';
import services from './common/services';
import { ServiceInfoProvider } from './common/duck/service-info.context';
import configureStore from './store';
import { defaultRoutes, getAppRoutes } from './routes';

const inside = {
  maxWidth: '80%',
  minHeight: '100%',
  margin: 'auto',
  marginBottom: '10%'
};

const App = () => {
  const [appRoutes, setAppRoutes] = useState(defaultRoutes);
  const [serviceInfos, setServiceInfos] = useState([]);
  const [isLoading, setLoadingStatus] = useState(true);

  useEffect(() => {
    // Init routes and service info context
    (async () => {
      services
        .getServiceInfo()
        .then(infos => {
          const routes = getAppRoutes(infos);
          setAppRoutes(routes);
          setServiceInfos(infos);
          setLoadingStatus(false);
        })
        .catch(() => {
          setLoadingStatus(false);
        });
    })();
  }, []);

  if (isLoading) return <TeaLoader />;
  return (
    <Provider store={configureStore()}>
      <ServiceInfoProvider defaultState={serviceInfos}>
        <ThemeProvider>
          <Router>
            <div>
              <NavBar />
              <div style={inside}>
                <Switch>
                  {/* Login route must be placed before other mapped routes! */}
                  <Route
                    key="login"
                    exact
                    path="/login"
                    render={props => (
                      // Reload page after login redirect to init app routes
                      <Login redirectDeniedUrl {...props}>
                        <TeaLoader />
                      </Login>
                    )}
                  />
                  {appRoutes.map(route => (
                    <Route
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      render={props => {
                        if (route.path == '/talentlink') {
                          const env = process.env.NODE_ENV;
                          let talentLink = 'https://talentlinkbookings.serviceportal.pwc.fi/';
                          if (env == 'development') {
                            talentLink = 'https://sandboxtl.azurewebsites.net/';
                          }
                          if (env == 'stage') {
                            talentLink = 'https://wezddbtwapwa01-testing.azurewebsites.net';
                          }
                          window.location.href = talentLink;
                          return null;
                        }
                        return (
                          <route.component
                            serviceName={route.serviceName}
                            notification={route.notification}
                            {...props}
                          />
                        );
                      }}
                    />
                  ))}

                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </div>
              <ErrorMessage />
            </div>
          </Router>
          <Footer />
        </ThemeProvider>
      </ServiceInfoProvider>
    </Provider>
  );
};

export default App;
