// @flow
import { get } from '../../common/utils/api';
import { type ClientList } from '../../common/types/client';
import { type StatusSummary, type RollForwardDiagnosis } from '../types';

export default {
  getStatusSummaries: async (
    clientIds: number[],
    onlyErrors: boolean = false,
    onlyFailedBC: boolean = false
  ): Promise<StatusSummary> =>
    get('/auditrf-diagnostic/status-summary', {
      clientIds,
      onlyErrors,
      onlyFailedBC
    }),

  findClientsByName: async (name: string): Promise<ClientList> => {
    if (!name || name.length < 3) {
      return [];
    }
    return get('/client', {
      name
    });
  },

  getDiagnosis: async (rollforwardId: string): Promise<RollForwardDiagnosis> =>
    get(`auditrf-diagnostic/diagnosis/${rollforwardId}`)
};
