// @flow
import React from 'react';
import { connect } from 'react-redux';

import { Business, Redo, AccountCircle, VerticalAlignTop } from '@material-ui/icons';
import { textDateCreator } from '../../../../common/utils/helper';
import { getDataset, resetGettingDataset, clearFilesList } from '../../../duck/dataset';
import {
  getFilesDataSummary,
  isQlikSenseAvailable,
  isMappingAvailable,
  getDatasetsWithFiles,
  haveDatasetsFullfilled
} from '../../../duck/helpers';
import SuccessPage from './components/SuccessPage';

const SuccessPageContainer = (props: any) => {
  const {
    history,
    client,
    job,
    date,
    selectedAccountSystem,
    filesData,
    team,
    datasetGroupId,
    datasetIds
  } = props;

  /**
   * Redirec the user to start page of General Ledger on refreshing the page
   */
  if (!client || !job) {
    history.push('/general-ledger');
  }

  const goToActivityLog = () => {
    history.push('/general-ledger/activity-log');
  };

  const goToAccountMapping = () => {
    history.push(`/general-ledger/account-mapping?datasetGroupId=${datasetGroupId}`);
  };

  const clientInfo = {
    icon: Business,
    name: client ? client.name : 'None'
  };
  const jobInfo = {
    icon: Redo,
    name: job && date ? `${job.name}  ${date}` : 'None'
  };
  const teamInfo = {
    icon: AccountCircle,
    name: team || 'None'
  };
  const filesInfo = {
    icon: VerticalAlignTop,
    summary: getFilesDataSummary(selectedAccountSystem, filesData),
    isQlikSenseAvailable: isQlikSenseAvailable(selectedAccountSystem, filesData),
    isMappingAvailable: isMappingAvailable(selectedAccountSystem, filesData)
  };

  const refreshStatus = () => {
    // To show loader, reset 'isGettingDataset' as false
    datasetIds.forEach(item =>
      props.resetGettingDataset({
        isHeadClient: item.isHeadClient,
        clientId: item.clientId
      })
    );
    // empty files list to avoid duplication
    props.clearFilesList();

    datasetIds.forEach(item => props.getDataset(item.isHeadClient, item.clientId, item.datasetId));
  };

  return (
    <SuccessPage
      clientInfo={clientInfo}
      jobInfo={jobInfo}
      teamInfo={teamInfo}
      filesInfo={filesInfo}
      refreshStatus={refreshStatus}
      goToActivityLog={goToActivityLog}
      goToAccountMapping={goToAccountMapping}
      {...props}
    />
  );
};

export default connect(
  state => ({
    client: state.dataset.filesData.headClient,
    job: state.jobs.get('selectedJob'),
    date: `${textDateCreator(state.dataset.get('startDate'))}-${textDateCreator(
      state.dataset.get('endDate')
    )}`,
    team: state.team
      .get('selectedTeamList')
      .map(m => m.name)
      .join(', '),
    selectedAccountSystem: state.accountSystem.get('selectedAccountSystem'),
    filesData: state.dataset.filesData,
    datasetGroupId: state.dataset.get('filesData')
      ? state.dataset.get('filesData').datasetGroupId
      : '',
    datasetIds: getDatasetsWithFiles(
      state.accountSystem.get('selectedAccountSystem'),
      state.dataset.filesData
    ),
    haveDatasetsFullfilled: haveDatasetsFullfilled(
      state.accountSystem.get('selectedAccountSystem'),
      state.dataset.filesData
    )
  }),
  {
    getDataset,
    resetGettingDataset,
    clearFilesList
  }
)(SuccessPageContainer);
