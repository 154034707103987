// @flow
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { type SimpleDropdownItem } from '../../types/list';

type Props = {
  optionList: Array<SimpleDropdownItem>,
  onChange: Function,
  value: SimpleDropdownItem | null | typeof undefined,
  placeholder?: string,
  name?: string
};
// override external library
const StyledSelect = styled(Select)`
  .select__control {
    background-color: white;
    border: none;
    box-shadow: none;
    .is-selected {
      background-color: red;
    }
  }
  .select__option--is-selected {
    background: ${props => props.theme.color.ui.primary};
  }
  .select__option {
    &:hover,
    &:focus {
      background: ${props => props.theme.color.ui.light};
    }
  }
`;

const SearchBox = styled.div`
  background: white;
  border: solid 1px ${props => props.theme.color.border.default};
  border-radius: ${props => props.theme.border.type.round};
  padding: ${props => ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
`;

const SimpleSelect = ({ optionList, onChange, value, placeholder, name }: Props) =>
  optionList.length > 0 && (
    <SearchBox data-cy={`simple-select${name ? `-${name}` : ''}`}>
      <StyledSelect
        defaultOptions
        className="basic-single"
        classNamePrefix="select"
        value={value}
        onChange={selectedOption => onChange(selectedOption)}
        name="simpleSelect"
        options={optionList}
        isSearchable={false}
        placeholder={placeholder}
      />
    </SearchBox>
  );

export default SimpleSelect;
