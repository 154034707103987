// @flow
import React, { useState } from 'react';
import { type List as ListType } from 'immutable';
import PersonPin from '@material-ui/icons/PersonPin';

import Step, { type Props as StepProps } from '../../../common/components/workflow/Step';
import { type Client, type StaffMember } from '../../types';
import ExtraSpace from '../../../common/components/ExtraSpace';
import SearchBoxContainer from '../../../common/components/SearchBoxContainer';
import LeaderOrClientDropdown, {
  searchTypeList
} from '../../../common/components/LeaderOrClientDropdown';
import { SearchSelection } from '../../../common/components/selectors';
import ActionBar from '../../../common/components/workflow/ActionBar';
import services from '../../services';
import { dataMappingToDropdown } from '../../../common/utils/helper';

type Props = StepProps & {
  selectedLeader: ListType<StaffMember>,
  selectedClients: ListType<Client>,
  setLeader: Function,
  clearLeader: Function,
  addClient: Function,
  removeClient: Function,
  clearClients: Function,
  getClients: Function,
  getOrders: Function,
  onClickSave: Function,
  moveToStep: Function,
  toggleSelectAll: Function,
  addHeadClient: Function
};

const LeaderClientSearch = ({
  selectedLeader,
  selectedClients,
  setLeader,
  clearLeader,
  addClient,
  addHeadClient,
  removeClient,
  clearClients,
  getClients,
  getOrders,
  done,
  theme,
  onClickSave,
  moveToStep,
  toggleSelectAll,
  ...rest
}: Props) => {
  const [isLoading, setLoader] = useState(false);
  const [selectedType, setType] = useState(searchTypeList[0]);
  const selectedTypeLabel = selectedType.label.toLocaleLowerCase();
  const isLeaderType = selectedType.value === 1;
  const isHeadClientType = selectedType.value === 3;

  const fetchOptions = async (inputValue: string) => {
    if (!inputValue || inputValue.length < 3) {
      return Promise.resolve(null);
    }

    if (isLeaderType) {
      return services
        .fetchStaff(inputValue)
        .then(data => (data ? dataMappingToDropdown(data) : []))
        .catch(() => []);
    }
    return services
      .fetchClients(inputValue, null)
      .then(data => (data ? dataMappingToDropdown(data) : []))
      .catch(() => []);
  };
  const changeType = type => {
    setType(type);

    /**
     * Reset Leader & Client Selections
     */
    clearLeader();
    clearClients();
  };
  const handleProceed = async () => {
    setLoader(true);

    try {
      if (isLeaderType && selectedLeader.get(0)) {
        await getClients(null, selectedLeader.get(0).name);
      } else {
        const clientId = selectedClients.map(client => client.id).toJS();
        await getOrders({ clientId });
      }
      setLoader(false);
      onClickSave();
      if (!(isLeaderType || isHeadClientType)) {
        moveToStep(2);
      } else {
        onClickSave();
      }
      toggleSelectAll(true);
    } catch (err) {
      setLoader(false);
    }
  };

  const selectedList = isLeaderType ? selectedLeader : selectedClients;
  return (
    <Step
      title="Search"
      subtitle="First, search for the clients by Leader/Manager OR Client"
      Icon={PersonPin}
      theme={theme}
      done={done}
      {...rest}
    >
      <ExtraSpace>
        <SearchBoxContainer>
          <LeaderOrClientDropdown
            selectedType={selectedType}
            onChange={type => changeType(type)}
            additionalValues={[
              {
                value: 3,
                label: 'Head Client'
              }
            ]}
          />
          <SearchSelection
            key={selectedType.label}
            fetchData={fetchOptions}
            selectedList={selectedList}
            saveToStore={selectedItem => {
              if (isLeaderType) setLeader(selectedItem.data);
              else if (isHeadClientType) addHeadClient(selectedItem.data);
              else addClient(selectedItem.data);
            }}
            deleteFromStore={selectedItem =>
              isLeaderType ? clearLeader() : removeClient(selectedItem)
            }
            placeholder={`Start typing to begin search for a ${selectedTypeLabel} by name`}
            note={
              isLeaderType
                ? 'Note! You can only select one target at a time'
                : 'Note! You can select multiple targets at a time'
            }
            type="targets"
            Icon={PersonPin}
            value={null}
            isSearchable
          />
        </SearchBoxContainer>
        <ActionBar
          status={`Select a ${selectedTypeLabel} to proceed`}
          isLoading={isLoading}
          proceed={{
            name: 'Proceed',
            callback: handleProceed,
            isDisabled: !isLoading && selectedList.size === 0
          }}
        />
      </ExtraSpace>
    </Step>
  );
};

export default LeaderClientSearch;
