// @flow
import React from 'react';
import { type ActivityLogRow } from '../../../../types/activityLog';
import ClientJobInfo from './ClientJobInfo';
import LeaderManagerInfo from './LeaderManagerInfo';

type Props = {
  data: ActivityLogRow,
  isSubsidiary?: boolean
};
/*
 * ClientId given to components for Cypress test id
 */
const RowLeft = ({ data, isSubsidiary }: Props) => (
  <>
    <ClientJobInfo
      id={data.getIn(['client', 'id'])}
      clientName={data.getIn(['client', 'name'])}
      job={data.getIn(['job', 'name'])}
      isSubsidiary={isSubsidiary}
    />
    <LeaderManagerInfo
      id={data.getIn(['client', 'id'])}
      leader={data.getIn(['client', 'engagementLeader'])}
      manager={data.getIn(['client', 'engagementManager'])}
      isSubsidiary={isSubsidiary}
    />
  </>
);

RowLeft.defaultProps = {
  isSubsidiary: false
};

export default RowLeft;
