// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import type { SignerInfo } from '../../../types/signer';
import signerTypeList from '../../../constants/signerTypes';
import TextLabel from '../../../../common/components/TextLabel';
import TabsToggle, { DoneIcon } from '../../../../common/components/selectors/TabsToggle';

const SignerSelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 3px;
  max-width: 400px;
`;

const NameTextLabel = styled(TextLabel)`
  margin: 0.5em;
  font-size: 1em;
  color: ${props => props.theme.color.text.darkish};
  text-align: center;
`;

type SignerTypeSelectionProps = {
  signerInfo: Array<SignerInfo>,
  handleTabChange: Function
};

const SignerTypeSelection = ({ signerInfo, handleTabChange }: SignerTypeSelectionProps) => (
  <>
    {signerInfo.length > 0 &&
      signerInfo.map(signer => (
        <Fragment key={signer.id}>
          <NameTextLabel noPadding>{signer.name}</NameTextLabel>
          <SignerSelectionContainer data-cy="signer-type-selection">
            <TabsToggle
              onChange={handleTabChange(signer.number)}
              options={signerTypeList}
              selectedItem={signer.type ? signerTypeList[signer.type - 1] : null}
              colorTheme="green"
              customWidth="l"
              icon={() => <DoneIcon />}
            />
          </SignerSelectionContainer>
        </Fragment>
      ))}
  </>
);

export default SignerTypeSelection;
