// @flow
import { connect } from 'react-redux';
import Opportunity from '../Opportunity';
import { getOpportunities } from '../../../../duck/opportunity';
import {
  updateUboStatus,
  updateRepresentativeStatus,
  deselectAllUbos,
  changeGroundForUbo,
  changeGroundForService,
  putContactPersons,
  postContactPerson,
  changeContactInfoName,
  changeContactInfoEmail,
  changeContactInfoLanguage,
  setUboChanged,
  setRiskChanged,
  setAmlChanged,
  setAdditionalInfoMessage,
  putKycRound
} from '../../../../duck/kyc';

export default connect(
  state => ({
    opportunitiesSearchQuery: state.opportunity.get('searchQuery'),
    kycRound: state.kyc.get('kycRound'),
    kycStaticInformation: state.kyc.get('kycStaticInformation'),
    didReceiveGroundForService: !!state.kyc.getIn(['initialKycRound', 'groundForService']),
    isLoading: state.kyc.get('isLoading'),
    isLoadingModal: state.kyc.get('isLoadingModal'),
    showContactInformation: state.kyc.get('showContactInformation')
  }),
  {
    getOpportunities,
    updateUboStatus,
    updateRepresentativeStatus,
    deselectAllUbos,
    changeGroundForUbo,
    changeGroundForService,
    putContactPersons,
    postContactPerson,
    changeContactInfoName,
    changeContactInfoEmail,
    changeContactInfoLanguage,
    setUboChanged,
    setRiskChanged,
    setAmlChanged,
    setAdditionalInfoMessage,
    putKycRound
  }
)(Opportunity);
