// @flow
import React from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';
import { Snackbar, Typography } from '@material-ui/core';
import LoadingBar from './workflow/LoadingBar';

interface OwnProps {
  loading: Map<{ isLoading: boolean, msg: string }> | null;
}

type Props = OwnProps;

const Content = styled.div`
  && {
    background-color: ${props => props.theme.color.ui.primary};
    min-width: 400px;
    margin-top: ${props => props.theme.space.single.m};
    padding: 8px 0 4px 0;
  }

  & > * {
    color: ${props => props.theme.color.text.light};
  }

  // Make this work for the text
  & > *:first-child {
    padding: ${props => props.theme.space.single.s};
  }
`;

/**
 * This component can be used in any Service Portal project to display loading status.
 * To use it, you must supply the component with the current loading status via Props.
 *
 * Ideally, the loading status is stored in the corresponding service's Redux store and
 * updated via redux actions, meaning that you'd set the loading status whilst you're making
 * the API call. See Veromylly for examples (src/veromyly/duck/TaxReturnPage.js).
 *
 * @param {loading} loading is an Immutable Map with two fields.
 */
const StatusBar = ({ loading }: Props) => {
  const isOpen: boolean =
    loading !== null && (loading.get('isLoading') || loading.get('msg') !== '');

  return (
    loading && (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isOpen}
      >
        <Content>
          <Typography variant="subtitle2" align="center" gutterBottom>
            {loading.get('msg')}
          </Typography>
          {loading.get('isLoading') && <LoadingBar />}
        </Content>
      </Snackbar>
    )
  );
};

export default StatusBar;
