// @flow
import styled from 'styled-components';

const ErrorMessageLabel = styled.span.attrs({ 'data-cy': 'error-message-label' })`
  color: ${props => props.theme.color.ui.error};
  padding-left: ${props => props.theme.space.square.m};
  font-size: ${props => props.theme.fonts.size.body.s};
  font-weight: normal;
`;

export default ErrorMessageLabel;
