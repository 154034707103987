// @flow
import React, { PureComponent } from 'react';
import PageHeader from '../../common/components/canvas/PageHeader';
import DialogBox from '../../common/components/canvas/DialogBox';
import Steps from '../../common/components/workflow/Steps';
import Search from './steps/search/container/SearchContainer';
import Clients from './steps/clients/container/ClientsContainer';
import Jobs from './steps/jobs/container/JobsContainer';
import CsvInstruction from './hints/CsvInstruction';

type Props = {
  history: any
};
type State = {
  isCsvHintsOpen: boolean
};

class RollForwardPage extends PureComponent<Props, State> {
  state = {
    isCsvHintsOpen: false
  };

  done = () => {
    const { history } = this.props;
    history.push('/roll-forward/submitted');
  };

  render() {
    const { isCsvHintsOpen } = this.state;
    const { history } = this.props;

    return (
      <div>
        <PageHeader
          getLog={() => history.push('/roll-forward/activity-log')}
          activityLogTitle="View Activity Log"
          title="Audit Engagement Roll-forward"
          subTitle="Efficiently roll-forward Salesforce opportunity, IPower job and as well as Aura engagement database for the existing clients.
          The audit engagement can be rolled-forward in iPower once the financial year end has passed. Note! Aura engagement database will be rolled forward only once assessment profiles are completed Acceptance+ and source engagement database is archived."
        />
        <DialogBox
          title="Instruction: activity log in Excel"
          closeDialog={() => this.setState({ isCsvHintsOpen: false })}
          content={CsvInstruction}
          isOpen={isCsvHintsOpen}
        />
        <Steps
          onDone={isValid => {
            if (isValid) this.done();
          }}
        >
          <Search key="search" />
          <Clients key="clients" />
          <Jobs key="jobs" />
        </Steps>
      </div>
    );
  }
}

export default RollForwardPage;
