// @flow
import React from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

const Card = styled.div`
  opacity: ${props => (props.disabled ? 0.3 : 'inherit')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => props.theme.color.background.white};
  border: 1px solid ${props => props.theme.color.border.default};
  width: 320px;
  margin: ${props => props.theme.space.single.l};
  & > :first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    background-color: ${props => props.theme.color.ui.primary};
    text-align: center;
    svg {
      font-size: 64px;
      color: ${props => props.theme.color.ui.light};
    }
    .material-icons {
      font-size: 64px;
      color: ${props => props.theme.color.ui.light};
    }
  }
  & > :last-child {
    padding: ${props => props.theme.space.square.m};
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.space.inline.s};
  font-size:  ${props => props.theme.fonts.size.heading.m}
  color: ${props => props.theme.colors.orange[600]};
`;

const TooltipWrapper = ({ title, children }: { title?: string, children: any }) => {
  if (!title) return children;
  return <Tooltip title={title}>{children}</Tooltip>;
};
type Props = {
  id: string,
  title: string,
  external: boolean,
  disabled: boolean,
  content?: string,
  iconName: string,
  tooltipText?: string,
  theme: string,
  onClick: Function
};

const CardView = ({
  title,
  content,
  disabled,
  external,
  iconName,
  theme,
  onClick,
  id,
  tooltipText
}: Props) => (
  <TooltipWrapper title={tooltipText}>
    <Card
      disabled={disabled}
      theme={theme}
      onClick={disabled ? null : onClick}
      data-cy={`card-${id}`}
    >
      <div>
        <Icon>{iconName}</Icon>
      </div>
      <div>
        <Title>
          {title}&nbsp;{external && <Icon>open_in_new</Icon>}
        </Title>
        <span>{content}</span>
      </div>
    </Card>
  </TooltipWrapper>
);

export default CardView;
