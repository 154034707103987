// @flow
import { connect } from 'react-redux';
import Clients from '../Clients';
import { getKycRound } from '../../../../duck/kyc';
import { changeOpportunityStatus } from '../../../../duck/opportunity';

export default connect(
  state => ({
    opportunities: state.opportunity.get('opportunities'),
    isLoading: state.kyc.get('isLoading')
  }),
  { getKycRound, changeOpportunityStatus }
)(Clients);
