import styled from 'styled-components';

export const linkStyle = props => `
  color: ${props.theme.color.ui.primary};
  margin: ${props.theme.space.stack.xs};
  text-decoration: none;
`;

const Alink = styled.a`
  ${props => linkStyle(props)}
`;

export default Alink;
