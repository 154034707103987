// @flow
import React from 'react';
import { HourglassEmptyOutlined } from '@material-ui/icons';

import { type TaxReturnState, taxReturnStates } from '../../../../types';
import Button from '../../../../../common/components/Button';

type Props = {
  state: TaxReturnState
};

const InProgress = ({ state }: Props) => (
  <Button
    data-cy="sidebar-review-button"
    solid
    primary
    disabled
    onClick={() => {}}
    title={
      state === taxReturnStates.DRAFT
        ? `Tax return is being prepared`
        : `Tax return is being reviewed`
    }
  >
    <HourglassEmptyOutlined />
    {state === taxReturnStates.DRAFT ? `In preparation` : `In review`}
  </Button>
);

export default InProgress;
