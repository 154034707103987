// @flow
import { connect } from 'react-redux';
import TeamAccess from './TeamAccess';
import {
  removeTeamFromSelected,
  saveToTeamList,
  submitFinalTeamList,
  putQueueAll
} from '../../../duck/team';
import { getDataset } from '../../../duck/dataset';
import { getDatasetsWithFiles } from '../../../duck/helpers';

export default connect(
  state => ({
    isDatasetLoading: state.dataset.get('isDatasetLoading'),
    isTeamLoading: state.team.get('isTeamLoading'),
    selectedTeamList: state.team.get('selectedTeamList'),
    datasetGroupId: state.dataset.filesData.datasetGroupId,
    datasetIds: getDatasetsWithFiles(
      state.accountSystem.get('selectedAccountSystem'),
      state.dataset.filesData
    )
  }),
  {
    removeTeamFromSelected,
    saveToTeamList,
    submitFinalTeamList,
    putQueueAll,
    getDataset
  }
)(TeamAccess);
