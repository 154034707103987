// @flow
import { OrderedMap, List } from 'immutable';
import { Clear } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import DialogBox from '../../common/components/canvas/DialogBox';
import Button from '../../common/components/Button';
import { type ServiceOrder, type File } from '../types';
import TextLabel from '../../common/components/TextLabel';
import FileSelector from '../../common/components/selectors/file-selector/FileSelector';
import { TextArea } from '../../common/components/TextInput';

const DialogWrapper = styled.div`
  min-heigth: 400px;
  display: flex;
  flex-direction: column;
`;

const DeleteContainer = styled.div`
  display: flex;

  & svg:hover {
    cursor: pointer;
    color: red;
  }
`;

const OrderListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 0 50%;
  }
`;

const OrderTile = styled.div`
  align-items: center;
  display: flex;
  padding: 5px;
`;

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  & .tooltip {
    font-size: ${props => props.theme.fonts.size.body.m};
  }
`;

type Props = {
  isOpen: boolean,
  toggle: () => void,
  unselectOrder: (id: number) => void,
  serviceOrders: ServiceOrder[],
  files: OrderedMap<number, File[]>,
  handleProceed: Function,
  uploadedFileList: List<File>,
  setUploadedFileList: Function,
  comment: string,
  setComment: Function
};
const UploadDialog = ({
  isOpen,
  toggle,
  unselectOrder,
  serviceOrders,
  files,
  handleProceed,
  uploadedFileList,
  setUploadedFileList,
  comment,
  setComment
}: Props) => {
  const showFiles = (orderId: number) => {
    if (files.has(orderId) && files.get(orderId).length !== 0) {
      return (
        <div data-cy="service-order-file-list">
          {files.get(orderId).map((f, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={index}>
              {f.name} - {f.uploadedBy || 'Unknown'}
            </p>
          ))}
        </div>
      );
    }
    return 'No files';
  };

  return (
    <DialogBox
      isOpen={isOpen}
      maxWidth="md"
      hasCloseButton
      closeButtonOnClick={() => toggle()}
      title="Choose files"
      closeDialog={() => {}}
      ButtonElement={() => (
        <Button
          solid
          primary
          data-cy="submit-button"
          onClick={() => handleProceed()}
          disabled={!uploadedFileList.length || !serviceOrders.length}
        >
          Submit
        </Button>
      )}
      content={() => (
        <DialogWrapper data-cy="upload-dialog">
          <FileSelector
            title="sdc-upload"
            selectedFilesList={
              new List(uploadedFileList.map(file => ({ ...file, fileName: file.name })))
            }
            saveFile={file => {
              setUploadedFileList(list => [...list, file]);
            }}
            removeFile={file => setUploadedFileList(list => list.filter(f => f.name !== file.name))}
          />
          <h3>Comment</h3>
          <TextArea
            data-cy="sdc-upload-comment-box"
            resize
            value={comment}
            onChange={e => setComment(e.target.value)}
          />

          <h3>Orders</h3>
          <OrderListContainer>
            {serviceOrders && serviceOrders.length !== 0 ? (
              serviceOrders.map(order => (
                <OrderTile key={order.id}>
                  <StyledTooltip title={showFiles(order.id)} data-cy="service-order-tile">
                    <div>
                      {order.client.name} - {order.soClass.name}
                    </div>
                  </StyledTooltip>
                  <DeleteContainer>
                    <Clear
                      data-cy="remove-service-order-button"
                      onClick={() => unselectOrder(order.id)}
                    />
                  </DeleteContainer>
                </OrderTile>
              ))
            ) : (
              <TextLabel style={{ flex: '1 0' }}>No service orders selected</TextLabel>
            )}
          </OrderListContainer>
        </DialogWrapper>
      )}
    />
  );
};

export default UploadDialog;
