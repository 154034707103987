// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../../common/components/TextLabel';
import TextInput from '../../../../common/components/TextInput';
import { SimpleSelect, SearchSelection } from '../../../../common/components/selectors';
import InfoIconTooltip from '../../../../common/components/tooltip/InfoIconTooltip';
import { linkNewTab } from '../../../../common/utils/helper';
import InputDatePicker from './InputDatePicker';
import { type PostContactPerson } from '../../../../common/types/client';
import {
  allAuthorizationTypes,
  allCountriesList,
  mapCodeToAuthorizationType,
  mapCountryNameToSimpleDropdownItem
} from '../../../utils/helper';

type Props = {
  contactPersonData: PostContactPerson,
  setContactPersonData: Function,
  isUbo: boolean
};

const ContentWrapper = styled.div`
  > * {
    width: 400px;
  }

  .react-select__menu,
  .select__menu {
    color: ${props => props.theme.color.text.neutral};
  }
`;

export default function AddNewPersonDialog({
  contactPersonData,
  setContactPersonData,
  isUbo
}: Props) {
  return (
    <ContentWrapper>
      <TextLabel bold>Last Name *</TextLabel>
      <TextInput
        data-cy="dialog-box-content-lastname"
        value={contactPersonData.lastName}
        onChange={e => {
          setContactPersonData({ ...contactPersonData, lastName: e.target.value });
        }}
      />
      <TextLabel bold>First Names *</TextLabel>
      <TextInput
        data-cy="dialog-box-content-firstname"
        value={contactPersonData.firstName}
        onChange={e => setContactPersonData({ ...contactPersonData, firstName: e.target.value })}
      />
      <TextLabel bold>Date of Birth *</TextLabel>
      <InputDatePicker
        date={contactPersonData.dateOfBirth}
        setDate={target => setContactPersonData({ ...contactPersonData, dateOfBirth: target })}
      />
      <TextLabel bold>{`Nationality${isUbo ? ' *' : ''}`}</TextLabel>
      <SearchSelection
        key="add-new-person-dialog"
        value={
          contactPersonData.nationality &&
          mapCountryNameToSimpleDropdownItem(contactPersonData.nationality)
        }
        placeholder="Start typing to search for a country"
        fetchData={input =>
          Promise.resolve(
            [{ value: null, label: 'No selection' }]
              .concat(allCountriesList)
              .filter(country =>
                input
                  ? country.value && country.value.toLowerCase().startsWith(input.toLowerCase())
                  : country
              )
          )
        }
        saveToStore={target =>
          setContactPersonData({ ...contactPersonData, nationality: target.value })
        }
        deleteFromStore={() => setContactPersonData({ ...contactPersonData, nationality: null })}
        selectedList={null}
        isSearchable
        type="select-nationality"
      />
      {!isUbo && (
        <>
          <TextLabel bold>
            Authorization to Sign *
            <InfoIconTooltip
              tooltip={{
                __html: `<p>Please see more detailed guidance and examples in${linkNewTab(
                  'SharePoint',
                  'https://pwceur.sharepoint.com/sites/ClientDeskPwcFinland/SitePages/AML%20Check%20Tooltip%20Guidance.aspx'
                )}</p>`
              }}
            />
          </TextLabel>
          <SimpleSelect
            onChange={target =>
              setContactPersonData({ ...contactPersonData, authorization: target.value })
            }
            optionList={allAuthorizationTypes}
            value={
              contactPersonData.authorization
                ? mapCodeToAuthorizationType(contactPersonData.authorization)
                : null
            }
          />
        </>
      )}
    </ContentWrapper>
  );
}
