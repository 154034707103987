/* eslint-disable react/no-array-index-key */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import Warning from '@material-ui/icons/Warning';
import FileSelector from '../../../../../../common/components/selectors/file-selector/FileSelector';
import TextLabel from '../../../../../../common/components/TextLabel';
import { type Subsidiaries as SubsidiariesType } from '../../../../../types/subsidiary';
import accountSystemMapping from '../../../../../utils/accountSystemMapping';
import DeleteSubsidiaries from './DeleteSubsidiaries';
import AddSubsidiaryContainer from './AddSubsidiaryContainer';
import { t } from '../../../../../../common/translations/stringList';
import { type ContentFormat } from '../../../../../types/accountSystem';
import { splitFile } from '../../../../../utils/fileReader';

const Container = styled.div`
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: 30% 65% auto;
`;

type Props = {
  subsidiary: SubsidiariesType,
  contentFormats: Array<ContentFormat>,
  selectedAccountSystem: Object,
  removeSubsidiary: Function,
  addedSubsidiary: SubsidiariesType,
  saveSubsidiaryFile: Function,
  removeSubsidiaryFile: Function,
  turnOnLoading: Function,
  createSubsidiaryDataset: Function,
  startDate: Date,
  endDate: Date,
  datasetGroupId: String,
  createSubsidiaryDataset: Function
};
const FlexDisplay = styled.div`
  display: flex;
`;
const CustomTextLabel = styled(TextLabel)`
  width: ${props => props.length};
  margin: ${props => props.theme.space.stack.s};
`;
const Subsidiaries = ({
  subsidiary,
  addedSubsidiary,
  contentFormats,
  selectedAccountSystem,
  removeSubsidiary,
  saveSubsidiaryFile,
  removeSubsidiaryFile,
  turnOnLoading,
  startDate,
  endDate,
  datasetGroupId,
  createSubsidiaryDataset
}: Props) => {
  // This message will get activated on file upload and it will remain active until the file starts loading
  const [waitMessage, setWaitMessage] = useState({ contentFormatType: "", showMessage: false });
  // Read the file and split it in chunks.
  // The extra parameters are for head client
  const handleSaveFile = async (datasetid, contentFormat, accountingSystem, file, clientId) => {
    // Split files only for the "Other" accounting system
    if (selectedAccountSystem.label === 'Other') {
      setWaitMessage({ contentFormatType: accountingSystem.type, showMessage: true })
      const splittedFiles = await splitFile(
        datasetid,
        contentFormat,
        accountingSystem,
        file,
        clientId
      );

      for (let i in splittedFiles) {
        setWaitMessage({ ...waitMessage, showMessage: false })
        turnOnLoading(['subsidiaries', clientId, accountingSystem.type]);
        await saveSubsidiaryFile(datasetid, accountingSystem, splittedFiles[i].file, clientId);
      }
    } else {
      turnOnLoading(['subsidiaries', clientId, accountingSystem.type]);
      saveSubsidiaryFile(datasetid, accountingSystem, file, clientId);
    }
  };

  return (
    <>
      {addedSubsidiary.size > 0 && (
        <TextLabel>
          <Warning />
          {t('fileUpload', 'iPowerWarning')}
        </TextLabel>
      )}
      {subsidiary.size === 0 && <TextLabel>{t('fileUpload', 'noSubsidiaryReminder')}</TextLabel>}
      <Container accountSystemTypeCount={contentFormats.length}>
        {subsidiary.size !== 0 && (
          <>
            <TextLabel>Client name</TextLabel>
            <FlexDisplay>
              {contentFormats.map(accountSystem => (
                <Fragment key={accountSystem.id}>
                  <CustomTextLabel
                    length={`${100 / contentFormats.length}%`}
                    inline
                  >
                    {`${accountSystemMapping(accountSystem.type)} files (${accountSystem.name})`}
                    {
                      waitMessage.showMessage && waitMessage.contentFormatType === accountSystem.type &&
                      <TextLabel>Uploading files <i>(Please wait. It can take a few seconds before the file starts uploading.)</i></TextLabel>
                    }
                  </CustomTextLabel>
                </Fragment>
              ))}
            </FlexDisplay>
            <div />
            {subsidiary.valueSeq().map((dataset, key) => (
              <React.Fragment key={`${dataset.id}-${key}`}>
                <div>
                  <TextLabel noPadding clientName data-cy={`subsidiary-name-${key}`}>
                    {dataset.get('name')}
                  </TextLabel>
                  <TextLabel noPadding>
                    {dataset.get('businessId') ? (
                      dataset.get('businessId')
                    ) : (
                      <>
                        <Warning />
                        {` Business Id is missing in Ipower`}
                      </>
                    )}
                  </TextLabel>
                  {!dataset.get('datasetStatus') && (
                    <TextLabel noPadding>
                      <Warning />
                      {` dataset is pending for creating`}
                    </TextLabel>
                  )}
                  {dataset.get('datasetStatus') === 'fail' && (
                    <TextLabel noPadding>
                      <Warning />
                      {` creation failed, please try again`}
                    </TextLabel>
                  )}
                </div>

                <FlexDisplay>
                  {contentFormats.map((accountSystem, i) =>
                    dataset.get('datasetStatus') ? (
                      <FileSelector
                        size="small"
                        count={contentFormats.length}
                        key={i}
                        title={`${key}-${i}`}
                        selectedFilesList={
                          dataset && dataset.get(accountSystem.type)
                            ? dataset
                              .get(accountSystem.type)
                              .filter((_, k) => k !== 'isLoading')
                              .valueSeq()
                              .toList()
                            : []
                        }
                        saveFile={file =>
                          handleSaveFile(
                            dataset.get('datasetId'),
                            null,
                            accountSystem,
                            file,
                            dataset.get('id')
                          )
                        }
                        rejectedFilesList={
                          dataset.getIn(['dataFailures', accountSystem.type])
                            ? dataset
                              .getIn(['dataFailures', accountSystem.type])
                              .valueSeq()
                              .toList()
                            : []
                        }
                        isLoading={dataset.getIn([accountSystem.type, 'isLoading']) > 0}
                        removeFile={file =>
                          removeSubsidiaryFile(
                            dataset.get('id'),
                            dataset.get('datasetId'),
                            accountSystem.type,
                            file
                          )
                        }
                      />
                    ) : (
                      // eslint-disable-next-line react/no-array-index-key
                      <FileSelector size="small" disabled key={i} />
                    )
                  )}
                </FlexDisplay>

                <DeleteSubsidiaries
                  isDatasetCreating={!dataset.get('datasetStatus')}
                  onDelete={() => removeSubsidiary(dataset.get('datasetId'), dataset.get('id'))}
                  dataCy={key}
                  isDatasetFail={dataset.get('datasetStatus') === 'fail'}
                  createDataset={() =>
                    createSubsidiaryDataset(
                      {
                        clientBusinessId: dataset.get('businessId'),
                        clientId: dataset.get('id'),
                        clientName: dataset.get('name'),
                        datasetGroupId,
                        endDate,
                        startDate
                      },
                      dataset.get('id')
                    )
                  }
                />
              </React.Fragment>
            ))}
          </>
        )}

        <AddSubsidiaryContainer />
      </Container>
    </>
  );
};

export default Subsidiaries;