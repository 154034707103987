// @flow
import React, { PureComponent } from 'react';
import { Ballot } from '@material-ui/icons';
import { MenuButton } from '../../../common/components/Menu';
import { SearchSelection } from '../../../common/components/selectors';
import Menu from '../../../common/components/menu/Menu';
import type { AuraDatabase, AuraDatabases } from '../../types/auraDatabase';
import type { WithMenu } from '../../utils/containerUtils';
import { dataMappingToDropdown, dropdownTransfer } from '../../../common/utils/helper';
import service from '../../services/api';

type Action = null | 'ROLL_FORWARD' | 'DO_NOTHING';

type ActionSelectorProps = {
  selectAction: Action => void
};

const ActionSelector = ({ selectAction }: ActionSelectorProps) => (
  <div data-cy="selection-menu">
    <MenuButton data-cy="roll-forward-button-one" onClick={() => selectAction('ROLL_FORWARD')}>
      <strong>Roll-forward</strong>
      <small>Request for automated roll-forward of existing Aura database</small>
    </MenuButton>
    <MenuButton data-cy="roll-forward-button-two" onClick={() => selectAction('DO_NOTHING')}>
      <strong>Do not roll-forward</strong>
      <small>Audit team will manually create new or roll-forward existing Aura database</small>
    </MenuButton>
  </div>
);

type Props = WithMenu & {
  isDisabled: boolean,
  onChange: any => void,
  auraDatabase: AuraDatabase,
  auraDatabaseList: AuraDatabases,
  getAuraDatabases: Function,
  children: any,
  noPadding: boolean,
  id: string
};

type State = {
  action: Action,
  indicator: boolean
};

class AuraSelector extends PureComponent<Props, State> {
  state = {
    action: null,
    // eslint-disable-next-line react/no-unused-state
    indicator: false
  };

  selectAction = (action: Action) => {
    const { onChange, closeMenu } = this.props;
    if (action === 'DO_NOTHING') {
      this.setState(() => ({
        indicator: true
      }));
      onChange({
        option: 'Do_Nothing',
        id: '',
        name: ''
      });
      closeMenu();
    } else {
      this.setState(() => ({ action }));
    }
  };

  setAuraDatabase = (option: any) => {
    const { onChange, closeMenu } = this.props;
    onChange({ ...dropdownTransfer(option), option: 'Roll_Forward' });
    this.setState({ action: null });
    closeMenu();
  };

  getMenuTitle = () => {
    const { action } = this.state;
    return action === 'ROLL_FORWARD' ? 'Roll forward' : 'Select procedure';
  };

  handleCloseMenu = () => {
    const { closeMenu } = this.props;
    this.setState(() => ({ action: null }));
    closeMenu();
  };

  render() {
    const { action, indicator } = this.state;
    const { menuVisibility, openMenu, children, id } = this.props;
    return (
      <Menu
        header={this.getMenuTitle()}
        headerOnClick={this.handleCloseMenu}
        ToggleElement={() => children({ openMenu, indicator })}
        isVisible={menuVisibility}
        onShow={openMenu}
        onHide={this.handleCloseMenu}
        align="left"
      >
        {!action && <ActionSelector selectAction={this.selectAction} />}
        {action === 'ROLL_FORWARD' && (
          <SearchSelection
            data-cy={`auraDB-search-${id}`}
            key="database_list"
            fetchData={inputValue => {
              if (!inputValue || inputValue.length < 3) {
                return Promise.resolve(null);
              }
              return service
                .getAuraDatabases(inputValue)
                .then(res => dataMappingToDropdown(res.toList()));
            }}
            saveToStore={this.setAuraDatabase}
            deleteFromStore={() => {}}
            selectedList={null}
            type="database"
            Icon={Ballot}
            isSearchable
            placeholder="Search your database here"
          />
        )}
      </Menu>
    );
  }
}

export default AuraSelector;
