// @flow
import * as Joi from 'joi-browser';
import { type List as ListType } from 'immutable';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { type ServiceOrder, type ServiceOrderType, type InputField } from '../types';
import {
  communicationLanguages,
  orderTypes,
  inputFields,
  serviceOrderType,
  questionType
} from '../constants';
import holidayList from '../constants/holidayList';
import { isWeekend } from '../../common/components/selectors/DatePicker';

const moment = extendMoment(Moment);

export const getTypeLabel = (types: Array<ServiceOrderType>, typeId: number): string => {
  const item = types.find(type => type.id === typeId);
  return item ? item.name : '';
};

export const getInputFields = (typeId: number): Array<InputField> => {
  const result = [];
  const orderType = orderTypes.find(type => type.id === typeId);
  if (orderType && orderType.inputFieldIds) {
    orderType.inputFieldIds.forEach(id => {
      const item = inputFields.find(field => field.id === id);
      if (item) {
        result.push(item);
      }
    });
  }
  return result;
};

const isEmailValid = (email: string): boolean =>
  !Joi.validate(
    email,
    Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
  ).error;

const isDeadlineValid = (deadline: moment): boolean =>
  !deadline.isBefore(
    moment()
      .add(1, 'day')
      .endOf('day')
  );

const isJobApproved = job => job && job.state === 'Approved';

/*
 * bank confirmation deadline has to be at least 10 working day later than date to be confirmed
 * holidays and weekends do not count
 */
const isBCDateConditionSatisfied = order => {
  const MINIMUM_WORKING_DAY_DIFFERENCE = 10;

  const { toBeConfirmed, deadLine } = order;
  const dateDifference = deadLine.diff(toBeConfirmed, 'days');
  const range = moment().range(toBeConfirmed, deadLine);

  const holidayWithinRange = Array.from(range.by('days')).filter(r =>
    holidayList.includes(r.format('DD/MM/YYYY'))
  ).length;
  const weekendWithinRange = Array.from(range.by('days')).filter(r => isWeekend(r)).length;

  return (
    dateDifference >= MINIMUM_WORKING_DAY_DIFFERENCE + holidayWithinRange + weekendWithinRange &&
    isBCDeadlineValid(deadLine)
  );
};

const isBCDeadlineValid = (deadline: moment): boolean =>
  !deadline.isBefore(
    moment()
      .add(10, 'day')
      .endOf('day')
  );
export const validateFields = (
  orders: ListType<ServiceOrder>
): { isValid: boolean, error: string } => {
  const reducer = orders.reduce(
    (init, order) => {
      const fields = getInputFields(order.type);
      // One of the financial institution could be empty
      const isValid =
        init.isValid &&
        Boolean(order.job) &&
        fields
          .filter(
            field =>
              field.id !== questionType.FINACIAL_INSTITUTION &&
              field.id !== questionType.EXTRA_FINANCIAL_INSTITUTION &&
              field.id !== questionType.AFS_REPORT_DATE &&
              field.id !== questionType.AFS_REPORT_RUN_TIME &&
              field.id !== questionType.CES_REPORT_COMPLETION_PHASE &&
              field.id !== questionType.CLIENT_CONTACT_NAME &&
              field.id !== questionType.CLIENT_CONTACT_EMAIL
          )
          .every(item => {
            const checkedField = order[item.key];
            const isArrayEmpty = Array.isArray(checkedField) && checkedField.length === 0;
            return !(isArrayEmpty || checkedField === null);
          });
      const isFinancialInstitutionValid =
        (order.type === serviceOrderType.BANK_CONFIRMATION &&
          ((order.financialInstitutionsType1 && order.financialInstitutionsType1.length > 0) ||
            (order.financialInstitutionsType2 && order.financialInstitutionsType2.length > 0))) ||
        order.type !== serviceOrderType.BANK_CONFIRMATION;
      const allEmailsValid = fields.find(field => field.key === 'clientContactPerson')
        ? init.allEmailsValid && isEmailValid(order.clientContactPerson)
        : init.allEmailsValid;
      const allDeadlinesValid = fields.find(field => field.key === 'deadLine')
        ? init.allDeadlinesValid && isDeadlineValid(order.deadLine)
        : init.allDeadlinesValid;
      const allJobsApproved = init.allJobsApproved && isJobApproved(order.job);

      const allBCDateConditionSatisfied =
        (order.type === serviceOrderType.BANK_CONFIRMATION && isBCDateConditionSatisfied(order)) ||
        order.type !== serviceOrderType.BANK_CONFIRMATION;

      return {
        isValid,
        allEmailsValid,
        allDeadlinesValid,
        allJobsApproved,
        allBCDateConditionSatisfied,
        isFinancialInstitutionValid
      };
    },
    {
      isValid: true,
      allEmailsValid: true,
      allDeadlinesValid: true,
      allJobsApproved: true,
      allBCDateConditionSatisfied: true,
      isFinancialInstitutionValid: true
    }
  );

  const {
    isValid,
    allEmailsValid,
    allDeadlinesValid,
    allJobsApproved,
    allBCDateConditionSatisfied,
    isFinancialInstitutionValid
  } = reducer;
  // Default error message
  let errorMessage = 'Please provide values for all the fields for selected service orders.';
  if (!allEmailsValid) {
    errorMessage = "Please enter valid email(s) for Client's contact person.";
  }
  if (!allDeadlinesValid) {
    errorMessage = 'You cannot set the deadline to be in the past';
  }
  if (!allJobsApproved) {
    errorMessage = 'All jobs have to be approved';
  }
  if (!allBCDateConditionSatisfied) {
    errorMessage =
      'bank confirmation deadline has to be at least 10 working day later than date to be confirmed';
  }
  return {
    isValid:
      orders.size > 0 &&
      isValid &&
      allEmailsValid &&
      allDeadlinesValid &&
      allJobsApproved &&
      allBCDateConditionSatisfied &&
      isFinancialInstitutionValid,
    error: errorMessage
  };
};

export const getLanguageById = (id: number): any =>
  communicationLanguages.find(language => language.id === id);
