// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { List } from 'immutable';

import Button from '../../../../common/components/Button';
import { SearchSelection } from '../../../../common/components/selectors';
import { type Assignee, type SearchMyTaxReturnParams } from '../../../types';
import {
  searchAssignee,
  updateAssignee,
  clearAssignee,
  searchTaxReturn
} from '../../../duck/HomePage';

import { type DropdownItem, type DropdownListType } from '../../../../common/types/list';
import { dataMappingToDropdown } from '../../../../common/utils/helper';

export const assigneeMappingToDropdown = (assignees: Array<Assignee>): DropdownListType =>
  assignees.map((assignee: Assignee) => ({
    label: `${assignee.name} - ${assignee.tigerId}`,
    value: assignee.id,
    data: assignee
  }));

const HorizontalDisplay = styled.div`
  display: flex;
  & > * {
    margin: 8px;
  }
`;

interface OwnProps {
  setIsPopUpOpen: (isOpen: boolean) => void;
}

interface StateProps {
  selectedAssignee: Assignee;
}

interface DispatchProps {
  searchAssignee: (name: string) => Promise<List<Assignee>>;
  updateAssignee: (assignee: Assignee) => void;
  clearAssignee: () => void;
  searchTaxReturn: (params: SearchMyTaxReturnParams) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps;

const SearchBar = ({ selectedAssignee, setIsPopUpOpen, ...dispatchProps }: Props) => {
  const searchAssignees = async (input: string): Promise<List<DropdownItem<Assignee>>> => {
    if (!input || input.length < 3) return Promise.resolve([]);
    try {
      const { value: assignees } = await dispatchProps.searchAssignee(input);
      return assignees ? dataMappingToDropdown(assignees) : [];
    } catch (err) {
      return [];
    }
  };

  return (
    <div>
      <h2>Search assignee</h2>
      <HorizontalDisplay>
        <SearchSelection
          type="assignee"
          placeholder="Search by name or TigerId"
          saveToStore={(target: DropdownItem<Assignee>) => {
            dispatchProps.updateAssignee(target.data);
          }}
          onClear={() => dispatchProps.clearAssignee()}
          isClearable
          fetchData={searchAssignees}
        />
        <Button
          primary
          solid
          data-cy="select-assignee-button"
          disabled={isEmpty(selectedAssignee)}
          onClick={async () => {
            await dispatchProps.searchTaxReturn({ assignee: selectedAssignee.id });
            setIsPopUpOpen(true);
          }}
        >
          Continue
        </Button>
      </HorizontalDisplay>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedAssignee: state.veromylly.homePage.selectedAssignee
});

const dispatchProps: DispatchProps = {
  searchAssignee,
  updateAssignee,
  clearAssignee,
  searchTaxReturn
};

export default connect(
  mapStateToProps,
  dispatchProps
)(SearchBar);
