// @flow
import React, { type Node } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';

import StepHeader, { type Props as StepHeaderProps } from './StepHeader';

const Container = styled.div`
  background-color: ${props => props.theme.color.background.white};
  margin: ${props => props.theme.space.inline.s};
  ${props =>
    props.pending &&
    css`
      &:before {
        display: block;
        background-color: ${props.theme.color.background.light};
        padding: ${props.theme.space.inline.m};
        content: 'Next steps:';
      }
      & + &:before {
        padding: 0;
        content: '';
      }
    `};
`;

Container.displayName = 'Container';

const Wrapper = styled.div`
  padding: ${props => props.theme.space.square.m};
`;

export type Props = StepHeaderProps & {
  theme: {},
  children: Node
};

const Step = ({ children, pending, active, theme, ...rest }: Props) => (
  <ThemeProvider theme={theme}>
    <Container pending={pending}>
      <StepHeader active={active} pending={pending} {...rest} />
      {active && <Wrapper>{children}</Wrapper>}
    </Container>
  </ThemeProvider>
);

export default Step;
