import styled from 'styled-components';

const HeaderSection = styled.div`
  display: inline-flex;
  width: 100%;
  & > *:first-child {
    flex-grow: 1;
  }
  & > *:last-child {
    flex-grow: 0;
    margin: auto;
    height: 100%;
  }
`;
export default HeaderSection;
