// @flow
import { OrderedMap } from 'immutable';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import PageHeader from '../common/components/canvas/PageHeader';
import { burgundy } from '../common/theme/theme';
import services from './services';
import SearchBar from './components/SearchBar';
import MyOrders from './components/MyOrders';
import SearchedOrderDialog from './components/SearchedOrderDialog';
import UploadDialog from './components/UploadDialog';
import { type ServiceOrder, type File } from './types';
import { defaultSort } from './utils';
import ConfirmDialog from './components/ConfirmDialog';
import SummaryDialog from './components/SummaryDialog';

const Container = styled.div`
  background-color: white;
  padding: 48px;
`;

type Props = {
  getMyOrders: Function,
  getFiles: (id: number) => void,
  unselectOrder: (id: number) => void,
  selectOrders: (orders: ServiceOrder[]) => void,
  myOrders: Array<ServiceOrder>,
  selectedOrders: Array<ServiceOrder>,
  files: OrderedMap<number, File[]>,
  resetState: Function
};

const MainPage = ({
  myOrders,
  getMyOrders,
  selectOrders,
  selectedOrders,
  unselectOrder,
  getFiles,
  files,
  resetState
}: Props) => {
  /*
   *loading states
   */
  const [isMyOrdersLoading, setIsMyOrdersLoading] = useState(false);
  const [isSearchedOrdersLoading, setIsSearchedOrdersLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  /*
   * Dialog states
   */
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isSearchDialogOpen, setSearchDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isSummaryDialogOpen, setSummaryDialogOpen] = useState(false);
  /*
   * Other states
   */
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [comment, setComment] = useState('');
  const [uploadedFileStatus, setUploadedFileStatus] = useState(OrderedMap());
  const [uploadedFileList, setUploadedFileList] = useState([]);

  const fetchMyOrders = async () => {
    setIsMyOrdersLoading(true);
    await getMyOrders();
    setIsMyOrdersLoading(false);
  };

  useEffect(() => {
    fetchMyOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (id: number) => {
    setSearchDialogOpen(true);
    setIsSearchedOrdersLoading(true);
    await services
      .searchOrders(id)
      .then((orders: ServiceOrder[]) => {
        setSearchedOrders(defaultSort(orders));
      })
      .catch(() => {})
      .then(() => setIsSearchedOrdersLoading(false));
  };

  const handleConfirmDialog = async () => {
    setConfirmDialogOpen(false);
    setSummaryDialogOpen(true);
    setUploadedFileStatus(
      OrderedMap(
        uploadedFileList.map(file => [
          file.name,
          { file, failedOrders: [], succeededOrders: [], completed: false }
        ])
      )
    );
    setIsFileUploaded(true);
    const modifiedFileName = name =>
      name
        .split('')
        .map((c, i) => (c === '.' && i !== name.lastIndexOf('.') ? '_' : c))
        .join('');
    selectedOrders.forEach((order, orderNum) => {
      if (comment) services.addInstruction(order.id, comment);
      uploadedFileList.forEach(async file => {
        const formData = new FormData();
        formData.append('upload', file, modifiedFileName(file.name));
        await services
          .addFiles(order.id, formData)
          .then(() =>
            setUploadedFileStatus(uploadedFiles =>
              uploadedFiles.updateIn([file.name, 'succeededOrders'], orders =>
                orders ? [...orders, order] : [order]
              )
            )
          )
          .catch(() =>
            setUploadedFileStatus(uploadedFiles =>
              uploadedFiles.updateIn([file.name, 'failedOrders'], orders =>
                orders ? [...orders, order] : [order]
              )
            )
          )
          .then(() => {
            if (orderNum === selectedOrders.length - 1)
              setUploadedFileStatus(uploadedFiles =>
                uploadedFiles.setIn([file.name, 'completed'], true)
              );
          });
      });
    });
  };

  const handleSummaryDialog = async () => {
    /*
     * Close all dialogs
     */
    setSummaryDialogOpen(false);
    setConfirmDialogOpen(false);
    setUploadDialogOpen(false);

    /*
     * Reset all states
     */
    setSearchedOrders([]);
    setComment('');
    setUploadedFileStatus(OrderedMap());
    setUploadedFileList([]);
    resetState();

    fetchMyOrders();
  };

  const toggleUploadDialog = () => setUploadDialogOpen(!isUploadDialogOpen);
  const toggleSearchDialog = () => setSearchDialogOpen(!isSearchDialogOpen);
  const toggleConfirmDialog = () => setConfirmDialogOpen(!isConfirmDialogOpen);
  return (
    <ThemeProvider theme={burgundy}>
      <>
        <PageHeader title="SDC Material Upload" />
        <Container>
          <SearchBar onSearch={handleSearch} />
          <MyOrders
            data={myOrders}
            getFiles={getFiles}
            onProceed={(orders: ServiceOrder[]) => {
              // Fetch files and select orders
              orders.forEach(order => getFiles(order.id));
              selectOrders(orders);
              // Open upload dialog directly
              toggleUploadDialog();
            }}
            loading={isMyOrdersLoading}
            toggleUploadDialog={toggleUploadDialog}
            selectOrders={selectOrders}
          />
        </Container>

        {/* DIALOGS */}
        <SearchedOrderDialog
          isOpen={isSearchDialogOpen}
          isLoading={isSearchedOrdersLoading}
          serviceOrders={searchedOrders}
          toggle={toggleSearchDialog}
          toggleUploadDialog={toggleUploadDialog}
          getFiles={getFiles}
          selectOrders={selectOrders}
        />
        <UploadDialog
          files={files}
          serviceOrders={selectedOrders}
          isOpen={isUploadDialogOpen}
          toggle={toggleUploadDialog}
          unselectOrder={unselectOrder}
          handleProceed={() => {
            setSearchDialogOpen(false);
            setConfirmDialogOpen(true);
          }}
          uploadedFileList={uploadedFileList}
          setUploadedFileList={setUploadedFileList}
          comment={comment}
          setComment={setComment}
        />
        <ConfirmDialog
          files={uploadedFileList}
          serviceOrders={selectedOrders}
          isOpen={isConfirmDialogOpen}
          toggle={toggleConfirmDialog}
          handleProceed={handleConfirmDialog}
          removeFile={file => setUploadedFileList(list => list.filter(f => f.name !== file.name))}
        />
        <SummaryDialog
          isOpen={isSummaryDialogOpen}
          toggle={() => setSummaryDialogOpen(!isSummaryDialogOpen)}
          fileStatus={uploadedFileStatus.valueSeq()}
          loading={isFileUploaded}
          handleProceed={handleSummaryDialog}
        />
      </>
    </ThemeProvider>
  );
};

export default MainPage;
