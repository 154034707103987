import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '../tooltip/Tooltip';

export const Th = styled.th`
  color: ${props => props.theme.color.heading.neutral};
  padding: ${props => (props.noPadding ? 0 : props.theme.space.squish.s)};
`;
export const CustomTh = styled(Th)`
  @media (max-width: 1550px) {
    padding: 8px !important;
  }
`;

export default (header, noPadding) => {
  const key = header.text || header;
  return (
    <CustomTh key={key} noPadding={noPadding}>
      {header.text ? (
        <Tooltip>
          <span>{header.text}</span>
          {header.hint && (
            <div>
              <InfoIcon data-cy="tooltip-info" />
              {header.hint()}
            </div>
          )}
        </Tooltip>
      ) : (
        header
      )}
    </CustomTh>
  );
};
