// @flow
import { Record } from 'immutable';
import { createAction } from 'redux-actions';
import { type GeneralState as State } from '../types/duck';
import { type ActionType } from '../../common/types/redux';
import service from '../services/general';

const defaultState = Record({
  isOnboardingFinished: false,
  isGeneralLoading: false
});

export const triggerInstruction = createAction('SET_GL_ONBOARDING_STATUS');
export const getOnboardingInfo = createAction(
  'GET_GL_ONBOARDING_STATUS',
  service.getGLOnboardingStatus
);

export const cleanGeneralLedgerDuck = createAction('CLEAN_GENERAL_LEDGER_DUCK');

export default function GeneralLedgerReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_GL_ONBOARDING_STATUS_REJECTED':
      return state.set('isGeneralLoading', false);
    case 'GET_GL_ONBOARDING_STATUS_PENDING':
      return state.set('isGeneralLoading', true);
    case 'SET_GL_ONBOARDING_STATUS':
      return state.update('isOnboardingFinished', isOnboardingFinished => !isOnboardingFinished);
    case 'GET_GL_ONBOARDING_STATUS_FULFILLED':
      return state.set('isOnboardingFinished', payload).set('isGeneralLoading', false);

    default:
      return state;
  }
}
