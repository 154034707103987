// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CheckCircle } from '@material-ui/icons';
import DialogBox from '../../../../../common/components/canvas/DialogBox';
import TextLabel from '../../../../../common/components/TextLabel';
import { SimpleSelect } from '../../../../../common/components/selectors';
import Button, { Buttons } from '../../../../../common/components/Button';
import {
  type Assignee,
  type TaxYear,
  type TaxReturns,
  type TaxReturn,
  type Client,
  type Language,
  type Country,
  type CreateMyTaxReturnParams
} from '../../../../types';
import { clearAssignee, createTaxReturn } from '../../../../duck/HomePage';
import { setErrorMessage } from '../../../../../common/duck/common';
import LanguageInfo from './LanguageInfo';
import ClientInfo from './ClientInfo';
import CountryInfo from './CountryInfo';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;

  > div:first-child {
    min-width: 150px;
  }
`;

const StyledButtons = styled(Buttons)`
  margin-top: 20px;
  min-width: 350px;
  padding: 16px 0;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
    color: green;
  }
  & > *:first-child {
    min-width: 200px;
  }
`;

const CheckCircleContainer = styled.div`
  min-width: 2em;
`;

interface OwnProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface StateProps {
  selectedAssignee: Assignee | null;
  taxYears: Array<TaxYear>;
  selectedAssigneeTaxDetails: TaxReturns;
}

interface DispatchProps {
  clearAssignee: () => void;
  setErrorMessage: (msg: string) => void;
  createTaxReturn: (request: CreateMyTaxReturnParams) => Promise<number>;
}

type Props = OwnProps & StateProps & DispatchProps;
const PopUp = ({
  isOpen,
  setIsOpen,
  selectedAssignee,
  taxYears,
  selectedAssigneeTaxDetails,
  ...dispatchProps
}: Props) => {
  const [selectedYear, setSelectedYear] = useState<TaxYear | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [existingTaxReturn, setExistingTaxReturn] = useState<TaxReturn | null>(null);
  const history = useHistory();

  const findExistingTaxReturn = (year: TaxYear | null) => {
    // Let's match only with year id
    const existingTax = selectedAssigneeTaxDetails.find(t => year && t.year.id === year.id);
    setExistingTaxReturn(existingTax);
    return existingTax;
  };

  const handleClientChange = (client: Client) => {
    setSelectedClient(client);
    findExistingTaxReturn(selectedYear);
  };

  const handleTaxYearChange = (year: TaxYear) => {
    setSelectedYear(year);
    const existingTax = findExistingTaxReturn(year);
    if (existingTax) {
      setSelectedLanguage(existingTax.language);
    }
  };

  const handleProceed = async () => {
    if (existingTaxReturn) {
      dispatchProps.clearAssignee();
      history.push(`/veromylly/${existingTaxReturn.id}`);
      return;
    }

    if (selectedClient && selectedYear && selectedLanguage && selectedCountry && selectedAssignee) {
      try {
        const { value: taxReturnId } = await dispatchProps.createTaxReturn({
          assignee: selectedAssignee.id,
          client: selectedClient.id,
          year: selectedYear.id,
          language: selectedLanguage.id,
          country: selectedCountry.id
        });
        history.push(`/veromylly/${taxReturnId}`);
        dispatchProps.clearAssignee();
      } catch (err) {
        setErrorMessage(`Failed to create new tax return due to error: ${JSON.stringify(err)}`);
      }
    }
  };

  return (
    <DialogBox
      isOpen={isOpen}
      hasCloseButton
      closeButtonOnClick={() => setIsOpen(false)}
      title="Select Tax Return"
      closeDialog={() => {}}
      ButtonElement={() => <></>}
      content={() => (
        <DialogWrapper>
          <Row>
            <TextLabel bold>Assignee:</TextLabel>
            <TextLabel data-cy="assignee-name">
              {selectedAssignee && selectedAssignee.name}
            </TextLabel>
          </Row>

          <Row>
            <TextLabel bold>Year:</TextLabel>
            <SelectContainer>
              <SimpleSelect
                name="tax-year"
                optionList={
                  taxYears &&
                  taxYears.map(y => ({
                    value: y,
                    label: y.name
                  }))
                }
                onChange={year => handleTaxYearChange(year.value)}
                value={selectedYear ? { label: selectedYear.name, value: selectedYear } : null}
              />

              <CheckCircleContainer>{existingTaxReturn && <CheckCircle />}</CheckCircleContainer>
            </SelectContainer>
          </Row>
          <Row>
            <TextLabel bold>Language:</TextLabel>
            <LanguageInfo
              existingTaxReturn={existingTaxReturn}
              selectedYear={selectedYear}
              selectedLanguage={selectedLanguage}
              updateLanguage={setSelectedLanguage}
            />
          </Row>
          <Row>
            <TextLabel bold>Client:</TextLabel>
            <ClientInfo
              existingTaxReturn={existingTaxReturn}
              selectedYear={selectedYear}
              selectedClient={selectedClient}
              selectedAssignee={selectedAssignee}
              handleClientChange={handleClientChange}
            />
          </Row>
          <Row>
            <TextLabel bold>Country:</TextLabel>
            <CountryInfo
              existingTaxReturn={existingTaxReturn}
              selectedYear={selectedYear}
              selectedClient={selectedClient}
              selectedCountry={selectedCountry}
              handleCountryChange={setSelectedCountry}
            />
          </Row>

          <StyledButtons>
            <Button
              data-cy="popup-proceed"
              primary
              solid
              round
              onClick={handleProceed}
              disabled={
                !(
                  existingTaxReturn ||
                  (selectedYear && selectedClient && selectedLanguage && selectedCountry)
                )
              }
            >
              {existingTaxReturn ? 'Continue tax return' : 'Create new tax return'}
            </Button>
            <Button
              round
              data-cy="popup-close"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
          </StyledButtons>
        </DialogWrapper>
      )}
    />
  );
};

const mapStateToProps = state => ({
  selectedAssignee: state.veromylly.homePage.selectedAssignee,
  taxYears: state.veromylly.homePage.taxYears,
  selectedAssigneeTaxDetails: state.veromylly.homePage.selectedAssigneeTaxDetails
});

const dispatchProps: DispatchProps = {
  clearAssignee,
  setErrorMessage,
  createTaxReturn
};

export default connect(
  mapStateToProps,
  dispatchProps
)(PopUp);
