// @flow
import React from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';

type Option = {
  id: string,
  label: string,
  value: string | boolean | number
};

type Props = {
  options: Array<Option>,
  selectedValue: string | boolean | number | Array<string | boolean | number>,
  onChange: Function,
  isMulti?: boolean
};

const CheckBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`;

const Checkboxes = ({ options, selectedValue, onChange, isMulti }: Props) => (
  <CheckBoxesWrapper>
    {options &&
      options.map(option => (
        <CheckBoxWrapper key={`checkbox-${option.id}`}>
          <Checkbox
            selected={
              isMulti && Array.isArray(selectedValue)
                ? selectedValue.includes(option.value)
                : selectedValue === option.value
            }
            onClick={() => {
              if (!isMulti) onChange(option.value);
              else if (Array.isArray(selectedValue) && !selectedValue.includes(option.value))
                onChange(selectedValue.concat(option.value));
              else
                onChange(
                  Array.isArray(selectedValue) && selectedValue.filter(e => e !== option.value)
                );
            }}
          />
          <div>{` ${option.label} `}</div>
        </CheckBoxWrapper>
      ))}
  </CheckBoxesWrapper>
);

Checkboxes.defaultProps = {
  isMulti: false
};

export default Checkboxes;
