// @flow
import React, { useState } from 'react';
import Sidebar from 'react-sidebar';
import { Close, Menu } from '@material-ui/icons';
import Button from './Button';

type Props = {
  sidebarContent: Object
};

const styles = {
  root: {
    position: undefined,
    zIndex: '20'
  },
  sidebar: {
    background: 'white',
    position: 'fixed',
    height: '100vh',
    top: '0',
    zIndex: '20'
  },
  content: {
    position: undefined,
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined
  },
  overlay: {
    position: undefined,
    top: undefined,
    left: undefined,
    right: undefined,
    bottom: undefined
  }
};

const SideBar = ({ sidebarContent }: Props) => {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  return (
    <Sidebar
      sidebar={
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-cy="close-sidebar-button"
              primary
              style={{ padding: '5px' }}
              onClick={() => {
                setSideBarOpen(false);
              }}
            >
              <Close />
            </Button>
          </div>
          {sidebarContent}
        </>
      }
      open={sidebarOpen}
      onSetOpen={setSideBarOpen}
      styles={styles}
    >
      <Button
        data-cy="open-sidebar-button"
        style={{ position: 'absolute', left: 0, top: '95px', padding: '10px' }}
        solid
        primary
        onClick={() => setSideBarOpen(true)}
      >
        <Menu />
      </Button>
    </Sidebar>
  );
};

export default SideBar;
