// @flow
import { get, put, post } from '../../common/utils/api';
import * as types from '../types';

type MyTaxReturnState = 'completed' | 'in_progress';

export default {
  fetchMyTaxReturn: async (state: MyTaxReturnState): Promise<Array<types.TaxReturn>> =>
    get(`veromylly/tax-return/my?state=${state}`),
  searchTaxReturn: async (param: types.SearchMyTaxReturnParams): Promise<Array<types.TaxReturn>> =>
    get('veromylly/tax-return', param),
  searchStaff: async (name: string): Promise<Array<types.Staff>> =>
    get('veromylly/staff', { name }),
  searchAssignee: async (
    searchString: string,
    limit: number = 25
  ): Promise<Array<types.Assignee>> =>
    get('veromylly/assignee', { search_string: searchString, limit }),
  fetchTaxYear: async (): Promise<Array<types.TaxYear>> => get('veromylly/tax-year'),
  fetchLanguage: async (): Promise<Array<types.Language>> => get('veromylly/language'),
  createTaxReturn: async (params: types.CreateMyTaxReturnParams): Promise<number> =>
    post('veromylly/tax-return', params),
  fetchTaxReturn: async (id: number): Promise<Array<types.TaxReturnWithData>> =>
    get(`veromylly/tax-return/${id}`),
  saveTaxReturn: async (id: number, taxReturn: types.TaxReturnWithData): Promise<void> =>
    put(`veromylly/tax-return/${id}`, taxReturn),
  sendForReview: async (taxReturnId: number): Promise<{ id: number, name: string }> =>
    post(`veromylly/tax-return/${taxReturnId}/state/review`),
  approveTaxReturn: async (taxReturnId: number): Promise<{ id: number, name: string }> =>
    post(`veromylly/tax-return/${taxReturnId}/state/approve`),
  rejectTaxReturn: async (taxReturnId: number): Promise<{ id: number, name: string }> =>
    post(`veromylly/tax-return/${taxReturnId}/state/reject`),
  updatePreparer: async (
    taxReturnId: number,
    preparer: { id: number, name: string }
  ): Promise<void> => put(`veromylly/tax-return/${taxReturnId}/preparer`, preparer),
  previewTaxForm: async (formId: number, params: any): Promise<string> =>
    post(`veromylly/preview/form/${formId}`, params),
  previewDocument: async (documentId: number): Promise<string> =>
    post(`veromylly/preview/document/${documentId}`),
  updateReviewer: async (taxReturnId: number, reviewer: number): Promise<void> =>
    put(`veromylly/tax-return/${taxReturnId}/reviewer`, `${reviewer}`),
  fetchDocuments: async (
    clientId: number,
    assigneeId: number,
    yearId: number
  ): Promise<Array<types.Document>> => get(`veromylly/document`, { clientId, assigneeId, yearId }),
  uploadDocuments: async (
    files: FileList,
    clientId: number,
    assigneeId: number,
    yearId: number,
    countryId: number
  ): Promise<Array<types.Document>> => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files.item(i));
    }
    return post(
      `veromylly/document/upload?clientId=${clientId}&assigneeId=${assigneeId}&yearId=${yearId}&countryId=${countryId}`,
      formData
    );
  },
  addNewDocument: async (
    templateId: string,
    documentName: string,
    clientId: number,
    assigneeId: number,
    yearId: number,
    languageId: number,
    countryId: number
  ): Promise<types.Document> =>
    post(`veromylly/document/new`, {
      templateId,
      documentName,
      clientId,
      assigneeId,
      yearId,
      languageId,
      countryId
    }),
  getExtractedQuestionnaireData: async (documentId: number): Promise<Array<types.Form>> =>
    get(`veromylly/document/${documentId}/questionnaire-data`),
  importExtractedData: async (taxReturnId: number, extractedData: Array<types.Form>) =>
    post(`veromylly/tax-return/${taxReturnId}/data-import`, extractedData),
  fetchDocumentTemplates: async (): Promise<Array<types.DocumentTemplate>> =>
    get(`veromylly/template/document`),
  fetchAssignee: async (assigneeId: number): Promise<Array<types.Assignee>> =>
    get(`veromylly/assignee/${assigneeId}`),
  completeTaxReturn: async (id: string) => post(`veromylly/tax-return/${id}/state/delivered`),
  previewPackage: async (id: string, guidList: Array<string>) =>
    post(`veromylly/preview/package/${id}`, [...guidList]),
  getPackageTemplate: async (id: string) => get(`veromylly/template/form/${id}`),
  fetchCountries: async () => get(`veromylly/country`)
};
