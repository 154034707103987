// @flow
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import DateRange from '@material-ui/icons/DateRange';
import TextLabel from '../TextLabel';

type Props = {
  label: string,
  selectedStartDate: Date,
  selectedEndDate: Date,
  saveDateToStore: (start: Date, end: Date) => {},
  focused: ('END_DATE' | 'START_DATE') | null,
  onFocusChange: (focusedInput: string) => {}
};

type SingleDateProps = {
  id: number | string,
  date: moment,
  onChange: Function,
  removeWeekends?: boolean,
  numberOfPastDayExcluded?: number,
  plusYear: number,
  excludedDates: Array<string>,
  showClearDate?: boolean
};

const DateSelection = styled.div`
  text-align: center;
  background: white;
  width: 60%;
  border: solid 1px ${props => props.theme.color.border.default};
  border-radius: ${props => props.theme.border.type.round};
  display: flex;
  padding: ${props =>
    props.noVerticalPadding ? props.theme.space.stack.s : props.theme.space.squish.s};
  & > *:first-child {
    flex-shrink: 1;
    background: white;
    margin: auto;
    color: ${props => props.theme.color.ui.primary};
  }
  & > *:last-child {
    color: ${props => props.theme.color.ui.primary};
    font-size: ${props => props.theme.fonts.size.body.s};
    text-align: center;
    outline: none;
    border: none;
    flex-grow: 1;
  }
  /* override external library */
  && {
    .CalendarDay__selected_span,
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      color: ${props => props.theme.color.text.dark};
      background: ${props => props.theme.color.ui.light};
      border: ${props => props.theme.color.ui.primary};
    }
  }
  .DateInput_input__focused {
    border-color: ${props => props.theme.color.ui.primary};
    background-color: ${props => props.theme.color.ui.light};
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${props => props.theme.color.ui.primary};
    border-color: ${props => props.theme.color.ui.primary};
  }
`;
const isDayExcluded = (date, numberOfPastDayExcluded) =>
  date.isBefore(
    moment()
      .add(numberOfPastDayExcluded, 'day')
      .endOf('day')
  );
export const isWeekend = (date: moment) => {
  const day = date.day();
  return day === 0 || day === 6;
};

const isExcludedDate = (date, excludedDates) =>
  excludedDates.indexOf(date.format('DD/MM/YYYY')) > 0;

export const plusAdditionalYears = (date: moment, removeWeekends: ?boolean, plusYear: number) => {
  const newDate = date.add('years', plusYear);
  if (removeWeekends) {
    if (newDate.day() === 0) return newDate.add('days', 1);
    else if (newDate.day() === 6) return newDate.add('days', 2);
    return newDate;
  }
  return newDate;
};

const CustomSingleDatePickerComponent = ({
  id,
  date,
  onChange,
  removeWeekends,
  numberOfPastDayExcluded,
  plusYear,
  excludedDates,
  showClearDate
}: SingleDateProps) => {
  const [focus, setFocus] = useState(null);
  const [shouldPlusYears, setShouldPlusYears] = useState(true);

  useEffect(() => {
    if (shouldPlusYears && plusYear) {
      setShouldPlusYears(false);
      const newDate = plusAdditionalYears(date, removeWeekends, plusYear);
      onChange(newDate);
    }
  }, [date, onChange, plusYear, removeWeekends, shouldPlusYears]);

  const outsideRangeCondition = day =>
    (removeWeekends && isWeekend(day)) ||
    (numberOfPastDayExcluded && isDayExcluded(day, numberOfPastDayExcluded)) ||
    (excludedDates.length > 0 && isExcludedDate(day, excludedDates));
  return (
    <DateSelection noVerticalPadding>
      <DateRange />
      <SingleDatePicker
        date={date}
        onDateChange={value => onChange(value)}
        focused={focus}
        onFocusChange={({ focused }) => setFocus(focused)}
        hideKeyboardShortcutsPanel
        showClearDate={showClearDate}
        noBorder
        readOnly
        placeholder="Select a date"
        displayFormat="DD/MM/YYYY"
        id={`single-date-picker-${id || ''}`}
        isOutsideRange={outsideRangeCondition}
      />
    </DateSelection>
  );
};
CustomSingleDatePickerComponent.defaultProps = {
  removeWeekends: false,
  excludePast: false,
  excludedDates: []
};

export const CustomSingleDatePicker = React.memo<SingleDateProps>(
  CustomSingleDatePickerComponent,
  (prev, next) => prev.date === next.date
);

const CustomDateRangePicker = ({
  label,
  saveDateToStore,
  selectedStartDate,
  selectedEndDate,
  focused,
  onFocusChange
}: Props) => (
  <div>
    <TextLabel>{label}</TextLabel>
    <DateSelection>
      <DateRange />
      <DateRangePicker
        data-cy="date-picker"
        startDateId="period_start_date"
        endDateId="period_end_date"
        focusedInput={focused}
        noBorder
        readOnly
        displayFormat="DD/MM/YYYY"
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel
        onDatesChange={({ startDate, endDate }) => saveDateToStore(startDate, endDate)}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        onFocusChange={input => onFocusChange(input)}
      />
    </DateSelection>
  </div>
);

export default CustomDateRangePicker;
