// @flow
import React from 'react';
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined
} from '@material-ui/icons';

import Button from '../Button';

type Props = {
  state?: number,
  selected?: boolean,
  onClick: Function,
  isDisabled?: boolean,
  color?: string
};

const Checkbox = ({ state, selected, onClick, isDisabled, color = '' }: Props) => (
  <Button data-cy="tableCheckbox" link size="none" onClick={onClick} disabled={isDisabled}>
    {(state === 0 || selected === false) && (
      <CheckBoxOutlineBlank style={{ color }} data-cy="unchecked-checkbox" />
    )}
    {state === 1 && (
      <IndeterminateCheckBoxOutlined style={{ color }} data-cy="indeterminate-checkbox" />
    )}
    {(state === 2 || selected === true) && (
      <CheckBoxOutlined style={{ color }} data-cy="checked-checkbox" />
    )}
  </Button>
);

Checkbox.defaultProps = {
  state: undefined,
  selected: undefined,
  isDisabled: false
};

export default Checkbox;
