/* eslint-disable react/no-array-index-key */
// @flow
import React from 'react';
import styled from 'styled-components';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress
} from '@material-ui/core';
import { type FileStatus } from '../types';
import DialogBox from '../../common/components/canvas/DialogBox';
import Button from '../../common/components/Button';
import TextLabel from '../../common/components/TextLabel';
import { DoneIcon, WarningIcon, FailedIcon } from '../../common/components/Icons';

const DialogWrapper = styled.div`
  min-heigth: 400px;
  display: flex;
  flex-direction: column;
`;
type Props = {
  isOpen: boolean,
  toggle: () => void,
  loading: boolean,
  fileStatus: FileStatus,
  handleProceed: Function
};
const SummaryDialog = ({ isOpen, toggle, fileStatus, loading, handleProceed }: Props) => (
  <DialogBox
    isOpen={isOpen}
    maxWidth="lg"
    title="Process summary"
    closeDialog={() => {}}
    ButtonElement={() => (
      <Button
        solid
        primary
        data-cy="summary-submit-button"
        disabled={!fileStatus.every(file => file.completed)}
        onClick={() => handleProceed()}
      >
        Close
      </Button>
    )}
    content={() => (
      <DialogWrapper data-cy="summary-dialog">
        <h3 data-cy="sdc-summary-title">
          SDC will update service order status once materials have been checked.
        </h3>
        {!fileStatus.every(file => file.completed) && (
          <p data-cy="sdc-summary-warning">
            <WarningIcon /> Closing the browser before file uploading is done may cause an
            interruption.
          </p>
        )}
        <div>
          {fileStatus &&
            fileStatus.map((file, i) => (
              <ExpansionPanel key={i}>
                <ExpansionPanelSummary>
                  <TextLabel data-cy={`summary-text-${i}`}>
                    {file.completed ? (
                      <>
                        {file.failedOrders && file.failedOrders.length === 0 ? (
                          <DoneIcon />
                        ) : (
                          <WarningIcon />
                        )}
                      </>
                    ) : (
                      <>
                        <CircularProgress /> {`${file.file && file.file.name} is being uploaded...`}
                      </>
                    )}
                    {file.completed &&
                      (file.failedOrders && file.failedOrders.length === 0
                        ? `${file.file && file.file.name} is uploaded successfully.`
                        : `${file.file &&
                            file.file.name} was failed to be uploaded to one or more orders.`)}
                  </TextLabel>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                  {file.failedOrders && file.failedOrders.length > 0 && (
                    <>
                      <h4>
                        <DoneIcon />
                        Successfully uploaded to:
                      </h4>
                      <TextLabel data-cy={`success-order-text-${i}`}>
                        {file.succeededOrders
                          .map(order => `${order.client.name} - ${order.soClass.name}`)
                          .join(', ')}
                      </TextLabel>
                    </>
                  )}
                  {file.failedOrders && file.failedOrders.length > 0 && (
                    <>
                      <h4>
                        {' '}
                        <FailedIcon />
                        Failed to upload to:
                      </h4>
                      <TextLabel data-cy={`fail-order-text-${i}`}>
                        {file.failedOrders
                          .map(order => `${order.client.name} - ${order.soClass.name}`)
                          .join(', ')}
                      </TextLabel>
                    </>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
        </div>
      </DialogWrapper>
    )}
  />
);

export default SummaryDialog;
