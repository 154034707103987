// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { type ActivityLogRow } from '../../../types/activityLog';
import TextLabel from '../../../../common/components/TextLabel';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import CsvInstruction from '../../../components/hints/CsvInstruction';
import getStatusLabel from '../../../utils/status';

type Props = {
  data: OrderedMap<ActivityLogRow>
};

const DownloadLink = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;
const IconWrapper = styled.span`
  color: ${props => props.theme.color.ui.primary};
  margin-top: 10px;
`;

const Download = ({ data }: Props) => {
  const [showCsvHint, toggleCsvHint] = useState(false);

  const getCsvData = logs => {

    const header = `PRID,Id,Parent Id,Ultimate Head Client Name,Head Client Name,Client Name,Client Number,Job Name,Job Code,Status,Created by,Created At,Has Aura Roll Forward,Aura Database Name\n`;
    const results = logs
      .valueSeq()
      .toArray()
      .map(log => log.toJS())
      .map(
        log =>
          `${log.client.prid},${log.id},${log.parentId},${log.client.ultimateHeadClientName.replaceAll(",", " ")},${log.client.headClientName.replaceAll(",", "")},${log.client.name.replaceAll(",", "")},${log.client.id},${log.job.name.replaceAll(",", "")},${log.job.id},${getStatusLabel(
            log.opportunityStatus,
            log.jobStatus,
            log.riskStatus,
            log.auraDatabaseStatus,
            log.auraLinkStatus,
            log.auraDatabase ? log.auraDatabase.id : -1
          )},${log.creator},${log.created},${log.auraDatabase ? 'Yes' : 'No'},${log.auraDatabase ? log.auraDatabase.name.replace(new RegExp(',', 'g'), ' ') : ''
          }`
      );
    return `${header}${results.join('\n')}`;
  };

  return (
    <>
      <DownloadLink
        data-cy="activity-download"
        onClick={() => toggleCsvHint(true)}
        href={`data:text/csv;charset=utf-8,${getCsvData(data)}`}
        download="download.csv"
      >
        <TextLabel clientName isLink>
          Export as excel file
        </TextLabel>
        <IconWrapper>
          <CloudDownloadIcon />
        </IconWrapper>
      </DownloadLink>
      <DialogBox
        title="Instructions: Activity logs in Excel"
        closeDialog={() => toggleCsvHint(false)}
        content={CsvInstruction}
        isOpen={showCsvHint}
      />
    </>
  );
};

export default Download;
