// @flow
import { axiosWrapper } from '@pwc-fi/ui-library';

const instance = axiosWrapper();

type Response = {
  statusCode: number,
  payload: string
};

export default {
  authorize: async (): Promise<Response> =>
    instance
      .get('/talentlink/authorize')
      .then(({ status: statusCode, data: payload }) => ({ statusCode, payload }))
};
