// @flow
import React from 'react';
import PageHeader from '../../common/components/canvas/PageHeader';
import Steps from '../../common/components/workflow/Steps';
import { linkNewTab } from '../../common/utils/helper';
import Search from './steps/search/container/SearchContainer';
import Clients from './steps/clients/container/ClientsContainer';
import Opportunity from './steps/opportunity/container/OpportunityContainer';

export default function AmlCheckPage() {
  return (
    <div>
      <PageHeader
        title="AML Check"
        subTitleHtml={{
          __html: `<p>PwC has the obligation for ongoing monitoring. If any concerns or suspicious elements occur, please contact your LoS RMP and/or SME.</p><p>Please see${linkNewTab(
            'Point',
            'https://pwceur.sharepoint.com/sites/ClientDeskPwcFinland/SitePages/AML%20Check%20Tooltip%20Guidance.aspx'
          )}for more information. Please inform Client Desk (fi_kyc@pwc.com) if any changes to the AML information occur while performing the work, eg. change in ownership.</p>`
        }}
      />
      <Steps onDone={isValid => {}}>
        <Search key="search" />
        <Clients key="clients" />
        <Opportunity key="opportunity" />
      </Steps>
    </div>
  );
}
