// @flow
import React, { Fragment } from 'react';
import Alink from '../components/Link';
import TextLabel from '../components/TextLabel';

const systemTypeMapping = () => (
  <Fragment>
    <TextLabel inlineSmall>
      You can find the instructions and the example data from
      <Alink
        href="https://drive.google.com/drive/folders/1g932FDo3LisP2BL_BtIRB5O7ECdR8e53"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </Alink>
    </TextLabel>
  </Fragment>
);

export default systemTypeMapping;
