// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Clear from '@material-ui/icons/Clear';
import Button from '../../../../../../common/components/Button';
import TextLabel from '../../../../../../common/components/TextLabel';
import { MenuWrapper, Menu } from '../../../../../../common/components/DropdownMenu';
import { MenuHeader } from '../../../../../../common/components/Menu';

const CustomTextLabel = styled(TextLabel)`
  padding: 2px 20px;
  font-size: ${props => props.theme.fonts.size.body.l};
`;

const CustomButton = styled(Button)`
  display: flex;
`;

type DeleteAreaProps = {
  onDelete: Function,
  dataCy: string,
  isDatasetFail: Function,
  createDataset: Function,
  isDatasetCreating: boolean
};
const DeleteSubsidiaries = ({
  onDelete,
  dataCy,
  isDatasetFail,
  createDataset,
  isDatasetCreating
}: DeleteAreaProps) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const handleDelete = () => {
    onDelete();
    setMenuVisibility(false);
  };
  return (
    <MenuWrapper onMouseLeave={() => setMenuVisibility(false)}>
      <Button
        disabled={isDatasetCreating}
        deleteSubsidiaries
        onClick={() => setMenuVisibility(true)}
        size="none"
        link
        data-cy={`delete-button1-${dataCy}`}
      >
        <MoreVertIcon />
      </Button>
      <Menu visible={menuVisibility}>
        <MenuHeader>
          {isDatasetFail ? (
            <CustomButton onClick={createDataset} size="none" link>
              <Clear />
              <CustomTextLabel>Recreate dataset</CustomTextLabel>
            </CustomButton>
          ) : (
            <CustomButton
              onClick={handleDelete}
              size="none"
              link
              data-cy={`delete-button2-${dataCy}`}
            >
              <Clear />
              <CustomTextLabel>Delete</CustomTextLabel>
            </CustomButton>
          )}

          <Button onClick={() => setMenuVisibility(false)} size="none" link>
            <MoreVertIcon />
          </Button>
        </MenuHeader>
      </Menu>
    </MenuWrapper>
  );
};

export default DeleteSubsidiaries;
