// @flow
import { Record, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import moment from 'moment';
import { type ActionType } from '../../common/types/redux';
import {
  type ServiceOrderState,
  NORMAL_FINANCIAL_INSTITUTION,
  EXTRA_FINANCIAL_INSTITUTION
} from '../types';
import services from '../services';
import { orderTypes, serviceOrderType } from '../constants';
import { getTypeLabel } from '../utils';
import { plusAdditionalYears } from '../../common/components/selectors/DatePicker';

const defaultState = Record({
  list: OrderedMap({}),
  types: orderTypes,
  selectedType: null,
  shouldSelectAll: true,
  canMassUpdate: false
});

export const getOrders = createAction('GET_ORDERS', services.fetchServiceOrders);
export const updateOrder = createAction('UPDATE_ORDER');
export const submitOrders = createAction('SUBMIT_ORDERS', services.submitServiceOrders);
export const resetServiceOrder = createAction('RESET_SERVICE_ORDER');
export const toggleSelectAll = createAction('TOGGLE_SELECT_ALL');

const Reducer = (state: ServiceOrderState = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_ORDERS_REJECTED': {
      return state.set('list', OrderedMap({}));
    }
    case 'GET_ORDERS_FULFILLED': {
      return state
        .set(
          'list',
          OrderedMap(
            payload
              .filter(
                order =>
                  order.type !== serviceOrderType.BANK_CONFIRMATION &&
                  order.type !== serviceOrderType.REPORT_LIABILITY_TESTING
              )
              .map(order => [
                order.id,
                {
                  ...order,
                  /*
                   * Financial institution fields provide input for 2 questions in Bank Confirmation
                   * so we split it here after fetching
                   */
                  financialInstitutionsType1:
                    order.financialInstitutions &&
                    order.financialInstitutions.filter(
                      o => o.type === NORMAL_FINANCIAL_INSTITUTION
                    ),
                  financialInstitutionsType2:
                    order.financialInstitutions &&
                    order.financialInstitutions.filter(o => o.type === EXTRA_FINANCIAL_INSTITUTION),
                  deadLine: !order.isRollForwarded
                    ? plusAdditionalYears(moment.utc(order.deadLine), true, 1)
                    : order.deadLine,
                  toBeConfirmed: !order.isRollForwarded
                    ? plusAdditionalYears(moment.utc(order.toBeConfirmed), false, 1)
                    : order.toBeConfirmed,
                  tradeRegistryExtraction: !order.isRollForwarded
                    ? plusAdditionalYears(moment.utc(order.tradeRegistryExtraction), true, 1)
                    : order.tradeRegistryExtraction,
                  typeLabel: getTypeLabel(orderTypes, order.type),
                  isSelected: false
                }
              ])
          )
        )
        .set('canMassUpdate', new Set(payload.map(so => so.type)).size === 1);
    }
    case 'UPDATE_ORDER': {
      const { id, key, value } = payload;
      // update a service order
      if (id && !key) return state.updateIn(['list', id], () => ({ ...value, id }));
      // update an object field of a SO
      if (Array.isArray(key))
        return id && key ? state.updateIn(['list', id, ...key], () => value) : state;
      // update a normal field of a SO
      return id && key ? state.updateIn(['list', id, key], () => value) : state;
    }
    case 'RESET_SERVICE_ORDER':
      return defaultState();
    case 'TOGGLE_SELECT_ALL':
      return state.set('shouldSelectAll', payload);
    default:
      return state;
  }
};

export default Reducer;
