// @flow
import React from 'react';
import { connect } from 'react-redux';
import { saveSessionClient, removeSessionClient, getSubsidiaries } from '../../../duck/dataset';
import { fetchJobList, removeSelectedJob } from '../../../duck/jobs';
import ClientSearch from './ClientSearch';

const ClientSearchContainer = (props: any) => {
  const { sessionClient } = props;
  const stepSummaryItems = [
    {
      title: 'Client selected',
      text: sessionClient ? sessionClient.name : ''
    }
  ];
  return <ClientSearch {...props} stepSummaryItems={stepSummaryItems} />;
};
export default connect(
  state => ({
    sessionClient: state.dataset.filesData.headClient,
    isJobListLoading: state.jobs.get('isJobListLoading'),
    selectedJob: state.jobs.selectedJob
  }),
  { saveSessionClient, removeSessionClient, fetchJobList, removeSelectedJob, getSubsidiaries }
)(ClientSearchContainer);
