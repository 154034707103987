// @flow
import React from 'react';
import styled from 'styled-components';
import AccountCircle from '@material-ui/icons/AccountCircle';
import TextLabel from '../../../../../common/components/TextLabel';

type Props = {
  id: string,
  leader: string,
  manager: string,
  isSubsidiary?: boolean
};

const CustomTextLabel = styled(TextLabel)`
  color: ${props =>
    props.isSubsidiary ? props.theme.colors.gray['600'] : props.theme.color.text.light};
  font-size: ${props => props.theme.fonts.size.body.s};
`;

const SubWrapper = styled.div`
  width: 50%;
  float: left;
`;

const IconWrapper = styled.div`
  margin: ${props => props.theme.space.inline.m};
  float: left;
`;

const LabelsWrapper = styled.div`
  float: left;
`;

const LeaderManagerInfo = ({ id, leader, manager, isSubsidiary }: Props) => (
  <>
    <SubWrapper>
      <IconWrapper>
        <AccountCircle />
      </IconWrapper>

      <LabelsWrapper>
        <CustomTextLabel noBottomPadding isSubsidiary={isSubsidiary} data-cy={`leader-${id}`}>
          {leader}
        </CustomTextLabel>
        <CustomTextLabel noTopPadding isSubsidiary={isSubsidiary}>
          Leader
        </CustomTextLabel>
      </LabelsWrapper>
    </SubWrapper>

    <SubWrapper>
      <IconWrapper>
        <AccountCircle />
      </IconWrapper>
      <LabelsWrapper>
        <CustomTextLabel noBottomPadding isSubsidiary={isSubsidiary} data-cy={`manager-${id}`}>
          {manager}
        </CustomTextLabel>
        <CustomTextLabel noTopPadding isSubsidiary={isSubsidiary}>
          Manager
        </CustomTextLabel>
      </LabelsWrapper>
    </SubWrapper>
  </>
);

LeaderManagerInfo.defaultProps = {
  isSubsidiary: false
};

export default LeaderManagerInfo;
