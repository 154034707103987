// @flow
import React, { useState } from 'react';
import Redo from '@material-ui/icons/Redo';
import { type JobList } from '../../../../../common/types/jobs';
import { type StepAction } from '../../../../../common/components/workflow/Steps';
import { type Subsidiaries as SubsidiariesType } from '../../../../types/subsidiary';
import Step, { type Props as StepProps } from '../../../../../common/components/workflow/Step';
import JobSelector from './JobSelector';
import { DatePicker } from '../../../../../common/components/selectors';
import ActionBar from '../../../../../common/components/workflow/ActionBar';
import { t } from '../../../../../common/translations/stringList';

type Props = StepProps & {
  onClickSave: () => StepAction,
  isLoading: boolean,
  selectedJob: Object,
  startDate: Date,
  endDate: Date,
  sessionClient: Object,
  jobList: JobList,
  datasetGroupId: String,
  headClientDatasetId: String,
  subsidiariesFileInfo: SubsidiariesType,
  selectedAccountSystem: Object,
  getAccountSystemList: Function,
  saveSelectedJobList: Function,
  removeSelectedJob: Function,
  saveDate: Function,
  createDatasetGroup: Function,
  createHeadClientDataset: Function,
  createSubsidiaryDataset: Function,
  getSubsidiaries: Function,
  resetAccountSystem: Function,
  onFocusChange: Function,
  inputFocused: ('START_DATE' | 'END_DATE') | null
};

const JobAndPeriod = ({
  theme,
  isLoading,
  selectedJob,
  startDate,
  endDate,
  sessionClient,
  jobList,
  datasetGroupId,
  headClientDatasetId,
  subsidiariesFileInfo,
  selectedAccountSystem,
  getAccountSystemList,
  saveSelectedJobList,
  removeSelectedJob,
  saveDate,
  createDatasetGroup,
  createHeadClientDataset,
  createSubsidiaryDataset,
  getSubsidiaries,
  resetAccountSystem,
  onClickSave,
  onFocusChange,
  inputFocused,
  ...rest
}: Props) => {
  const createHeadClientEssentials = async newDatasetGroupId => {
    await createHeadClientDataset({
      datasetGroupId: newDatasetGroupId || datasetGroupId,
      clientName: sessionClient.name,
      clientId: sessionClient.id,
      clientBusinessId: sessionClient.businessId,
      jobName: selectedJob.name,
      jobId: selectedJob.id,
      startDate,
      endDate
    });
  };

  const doEssentials = async (
    condition1: boolean,
    condition2: boolean,
    condition3: Function,
    condition4: boolean
  ) => {
    let newDatasetGroupId = {
      value: ''
    };
    if (condition4) {
      newDatasetGroupId = await createDatasetGroup({
        clientName: sessionClient.name,
        clientId: sessionClient.id,
        clientBusinessId: sessionClient.businessId,
        jobName: selectedJob.name,
        jobId: selectedJob.id
      });
    }

    if (condition1) getAccountSystemList();
    if (condition2) await createHeadClientEssentials(newDatasetGroupId.value);
    subsidiariesFileInfo.forEach(async elem => {
      if (condition3(elem)) {
        const data = {
          datasetGroupId: newDatasetGroupId.value || datasetGroupId,
          clientName: elem.get('name'),
          clientId: elem.get('id'),
          clientBusinessId: elem.get('businessId'),
          startDate,
          endDate
        };
        await createSubsidiaryDataset(data, elem.get('id'));
      }
    });
  };

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const handleProceedSuccess = async () => {
    if (shouldUpdate) {
      await doEssentials(true, true, () => true, true);
      setShouldUpdate(false);
      onClickSave();
      return;
    }
    await doEssentials(
      !selectedAccountSystem,
      !headClientDatasetId,
      elem => !subsidiariesFileInfo.getIn([elem.get('id'), 'datasetId']),
      !datasetGroupId
    );

    onClickSave();
  };

  const handleSaveSelectedJob = async job => {
    await saveSelectedJobList(job);
  };

  return (
    <Step
      title={t('jobAndPeriod', 'stepTitle')}
      subtitle={t('jobAndPeriod', 'stepSubTitle')}
      Icon={Redo}
      theme={theme}
      {...rest}
    >
      <JobSelector
        setShouldgetSubsidiaries={() => setShouldUpdate(true)}
        selectedJob={selectedJob}
        jobList={jobList}
        handleSaveSelectedJob={handleSaveSelectedJob}
        removeSelectedJob={removeSelectedJob}
      />
      <DatePicker
        saveDateToStore={saveDate}
        label={t('jobAndPeriod', 'datePickerLabel')}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        Datetype="startDate"
        focused={inputFocused}
        onFocusChange={onFocusChange}
      />
      <ActionBar
        status={
          selectedJob && endDate && startDate
            ? t('jobAndPeriod', 'dateSelectedLabel')
            : t('jobAndPeriod', 'noDateSelectedLabel')
        }
        isLoading={isLoading}
        proceed={{
          name: 'Proceed',
          callback: handleProceedSuccess,
          isDisabled: !selectedJob || !(endDate && startDate) || isLoading
        }}
      />
    </Step>
  );
};

export default JobAndPeriod;
