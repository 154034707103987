// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TaxFormFieldContainer } from '../TaxTabs';
import { type TaxReturn, type TaxReturnData, type Form, type Document } from '../../../../types';
import {
  updateExtractedFieldValue,
  getExtractedQuestionnaireData,
  importExtractedData
} from '../../../../duck/TaxReturnPage';

type ImportFormProps = {
  form: Form,
  index?: number,
  updateFieldValue: Function
};

const ImportForm = ({ form, index, updateFieldValue }: ImportFormProps) => (
  <>
    <h3>{index !== undefined ? `${form.get('name')}-${index + 1}` : form.get('name')}</h3>
    {form.get('subforms')
      ? form
          .get('subforms')
          .map((subform, subformIndex) => (
            <ImportForm
              key={subform.get('uuid')}
              form={subform}
              index={subformIndex}
              updateFieldValue={(value, ...path) =>
                updateFieldValue(value, 'subforms', subformIndex, ...path)
              }
            />
          ))
      : form.get('sections').map((section, sectionIndex) => (
          <Accordion key={section.get('name')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {section.get('name')}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                {section.get('formfields').map((field, fieldIndex) => (
                  <TaxFormFieldContainer
                    key={field.get('id')}
                    field={field}
                    updateFormFieldValue={value =>
                      updateFieldValue(
                        value,
                        'sections',
                        sectionIndex,
                        'formfields',
                        fieldIndex,
                        'value'
                      )
                    }
                  />
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
  </>
);

interface StateProps {
  taxReturn: TaxReturn;
  taxReturnData: TaxReturnData;
  unsavedChanges: boolean;
  documents: ImmutableList<Document>;
  extractedData: ImmutableList<Form>;
}

interface OwnProps {
  handleSave: () => Promise<void>;
  open: boolean;
  onClose: () => void;
}

interface DispatchProps {
  saveTaxReturn: Function;
  updateExtractedFieldValue: Function;
  getExtractedQuestionnaireData: Function;
  importExtractedData: Function;
}

type Props = StateProps & OwnProps & DispatchProps;

const ImportDataDialog = ({
  taxReturn,
  taxReturnData,
  unsavedChanges,
  documents,
  extractedData,
  handleSave,
  open,
  onClose,
  ...dispatchProps
}: Props) => {
  const [documentId, setDocumentId] = useState('');

  const handleDocumentChange = event => {
    setDocumentId(event.target.value);
    dispatchProps.getExtractedQuestionnaireData(event.target.value);
  };

  const handleImportData = async () => {
    // If there are unsaved changes, save them first
    if (unsavedChanges) {
      await handleSave();
    }
    await dispatchProps.importExtractedData(taxReturn.id, extractedData);
    onClose();
    setDocumentId('');
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Import questionnaire data</DialogTitle>
      <DialogContent>
        {documents && documents.size > 0 ? (
          <>
            <FormControl fullWidth>
              <InputLabel id="import-document-select-label">Document</InputLabel>
              <Select
                labelId="import-document-select-label"
                value={documentId}
                onChange={handleDocumentChange}
              >
                {documents.map(document => (
                  <MenuItem key={document.get('id')} value={document.get('id')}>
                    {document.get('filename') || document.get('name')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {extractedData
              ? extractedData.map((form, formIndex) => (
                  <ImportForm
                    key={form.get('uuid')}
                    form={form}
                    updateFieldValue={(value, ...path) =>
                      dispatchProps.updateExtractedFieldValue(value, formIndex, ...path)
                    }
                  />
                ))
              : null}
          </>
        ) : (
          'No importable documents found'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" onClick={handleImportData} disabled={!extractedData}>
          Import data
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    documents,
    extractedData
  } = state.veromylly.taxReturnPage;
  return {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    documents,
    extractedData
  };
};

const mapDispatch = {
  updateExtractedFieldValue,
  getExtractedQuestionnaireData,
  importExtractedData
};

export default connect(
  mapStateToProps,
  mapDispatch
)(ImportDataDialog);
