// @flow
import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Subsidiaries from './Subsidiaries';
import {
  saveSubsidiaryFile,
  removeSubsidiaryFile,
  turnOnLoading,
  removeSubsidiary,
  createSubsidiaryDataset
} from '../../../../../duck/dataset';

const SubsidiariesContainer = props => <Subsidiaries {...props} />;

export default connect(
  state => ({
    subsidiary: state.dataset.filesData.subsidiaries.filter((_, k) => k !== 'addedSubsidiaries'),
    contentFormats: state.accountSystem.selectedAccountSystem
      ? state.accountSystem.selectedAccountSystem.data.contentFormats
      : null,
    selectedAccountSystem: state.accountSystem.get('selectedAccountSystem'),
    addedSubsidiary: state.dataset.filesData.subsidiaries.get('addedSubsidiaries')
      ? state.dataset.filesData.subsidiaries.get('addedSubsidiaries')
      : Map(),
    filesData: state.dataset.filesData,
    startDate: state.dataset.get('startDate'),
    endDate: state.dataset.get('endDate'),
    datasetGroupId: state.dataset.get('filesData')
      ? state.dataset.get('filesData').datasetGroupId
      : ''
  }),
  {
    removeSubsidiary,
    saveSubsidiaryFile,
    removeSubsidiaryFile,
    turnOnLoading,
    createSubsidiaryDataset
  }
)(SubsidiariesContainer);