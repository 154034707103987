// @flow
import React from 'react';
import { type List as ListType } from 'immutable';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import SelectedList from '../shared/SelectedList';

type Props = {
  selectedFilesList: ListType<Object>,
  removeFile: Function,
  size?: string
};

const UploadedFiles = ({ selectedFilesList, removeFile, size }: Props) => (
  <SelectedList
    selectedList={selectedFilesList.map(item => ({
      name: item.fileName,
      id: item.fileId
    }))}
    type="Files"
    removeSelection={removeFile}
    Icon={VerticalAlignTop}
    size={size}
  />
);

UploadedFiles.defaultProps = {
  size: 'default'
};

export default UploadedFiles;
