// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { List } from 'immutable';
import { SearchSelection } from '../../../../../common/components/selectors';
import TextLabel from '../../../../../common/components/TextLabel';
import { type DropdownItem } from '../../../../../common/types/list';
import { type Client, type TaxReturn, type Country } from '../../../../types';
import { dataMappingToDropdown } from '../../../../../common/utils/helper';
import { getCountryList } from '../../../../duck/HomePage';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
    color: green;
  }
  & > *:first-child {
    min-width: 200px;
  }
`;

interface StateProps {
  countryList: Array<Country>;
}

interface DispatchProps {
  getCountryList: () => void;
}

interface OwnProps {
  existingTaxReturn: TaxReturn | null;
  selectedClient: Client | null;
  selectedCountry: Country | null;
  handleCountryChange: (newCountry: Country) => void;
}

type Props = StateProps & OwnProps;

const CountryInfo = ({
  countryList,
  existingTaxReturn,
  selectedClient,
  selectedCountry,
  handleCountryChange,
  ...dispatchProps
}: Props) => {
  useEffect(() => {
    if (!countryList.length) {
      dispatchProps.getCountryList();
    }
  }, [countryList, dispatchProps]);

  const getCountries = (input: string): Promise<Array<DropdownItem<Country>>> => {
    if (input.length < 2) return Promise.resolve([]);
    const filtered = countryList.filter((country: Country) =>
      country.name.toLowerCase().includes(input.toLowerCase())
    );
    return Promise.resolve(dataMappingToDropdown(filtered));
  };

  if (existingTaxReturn && existingTaxReturn.country) {
    return <TextLabel data-cy="country-info">{existingTaxReturn.country.name}</TextLabel>;
  } else if (selectedClient)
    return (
      <SelectContainer>
        <SearchSelection
          type="country"
          saveToStore={({ data }) => handleCountryChange(data)}
          deleteFromStore={undefined}
          noMinWidth
          placeholder="Assignment country"
          isClearable
          selectedList={List<Object>()}
          width="200px"
          fetchData={getCountries}
          onChange={undefined}
          value={selectedCountry ? { label: selectedCountry.name, value: selectedCountry } : null}
        />
      </SelectContainer>
    );
  return <></>;
};

const mapStateToProps = state => ({
  countryList: state.veromylly.homePage.countryList
});

const dispatchProps: DispatchProps = {
  getCountryList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CountryInfo);
