// @flow
import { Record, OrderedMap, Map } from 'immutable';
import { createAction } from 'redux-actions';
import { type ActivityLogState as State } from '../types/activityLog';
import { type ActionType } from '../../common/types/redux';

import service from '../services/activityLog';

const defaultState = Record({
  activityLogs: OrderedMap({}),
  isSearching: true
});

export const getActivityLogs = createAction('GET_ACTIVITY_LOGS', service.fetchActivityLogs);

export const updateAura = createAction('UPDATE_AURA', async payload => {
  if (payload.value)
    await service.linkAuraToRF(
      payload.isHeadClient ? payload.headClientId : payload.subsidiaryId,
      payload.value
    );
  return payload;
});

export default function ActivityLogReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_ACTIVITY_LOGS_PENDING': {
      return state.set('isSearching', true).set('activityLogs', OrderedMap({}));
    }
    case 'GET_ACTIVITY_LOGS_FULFILLED': {
      return state
        .set(
          'activityLogs',
          OrderedMap(
            payload.map(row => [
              row.id,
              Map({
                ...row,
                client: Map({
                  ...row.client,
                  subsidiaries: OrderedMap(
                    row.client.subsidiaries
                      ? row.client.subsidiaries.map(subsidiary => [
                          subsidiary.id,
                          Map({ ...subsidiary })
                        ])
                      : {}
                  )
                })
              })
            ])
          )
        )
        .set('isSearching', false);
    }
    case 'UPDATE_AURA_FULFILLED': {
      return payload.isHeadClient
        ? state.setIn(['activityLogs', payload.headClientId, 'auraDatabase'], payload.value)
        : state.setIn(
            ([
              'activityLogs',
              payload.headClientId,
              'client, subsidiaries',
              payload.subsidiaryId,
              'auraDatabase'
            ],
            payload.value)
          );
    }
    default:
      return state;
  }
}
