// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CommonTable from '../../../../common/components/react-table/CommonTable';
import { type TaxReturn } from '../../../types';
import { GlowButton } from '../../../../common/components/Button';
import { getMyCompletedList } from '../../../duck/HomePage';

const CompletedListContainer = styled.div`
  width: 100%;
`;

interface StateProps {
  completedList: Array<TaxReturn>;
}

interface DispatchProps {
  getMyCompletedList: () => Promise<void>;
}

type Props = StateProps & DispatchProps;

const CompletedList = ({ completedList, ...dispatchProps }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleLoading = async () => {
    try {
      setIsLoading(true);
      await dispatchProps.getMyCompletedList();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      Header: 'Assignee',
      id: 'assignee',
      accessor: 'assignee.name',
      Cell: table => {
        const taxReturn: TaxReturn = table.row.original;
        return (
          <GlowButton
            primary
            rounded
            data-cy="inspect-tax-return-button"
            onClick={() => history.push(`/veromylly/${taxReturn.id}`)}
          >
            {taxReturn.assignee.name}
          </GlowButton>
        );
      }
    },
    {
      Header: 'Year',
      accessor: 'year.name'
    }
  ];
  return (
    <CompletedListContainer data-cy="my-completed-list">
      <h2>My Completed List</h2>
      <CommonTable
        columns={columns}
        data={completedList}
        isLoading={isLoading}
        noResultsText="No results found"
        initialState={{
          sortBy: [{ id: 'assignee' }]
        }}
      />
    </CompletedListContainer>
  );
};

const mapStateToProps = state => ({
  completedList: state.veromylly.homePage.completedList
});

const dispatchProps: DispatchProps = {
  getMyCompletedList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(CompletedList);
