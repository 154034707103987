// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Build from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';

import Button from '../../../../../common/components/Button';
import TextLabel from '../../../../../common/components/TextLabel';
import { MenuWrapper, Menu } from '../../../../../common/components/DropdownMenu';
import { MenuHeader } from '../../../../../common/components/Menu';

const CustomTextLabel = styled(TextLabel)`
  padding: 2px 20px;
  font-size: ${props => props.theme.fonts.size.body.l};
`;

const CustomButton = styled(Button)`
  display: flex;
  align-self: end;
`;

const CustomMenuWrapper = styled(MenuWrapper)`
  align-self: end;
`;

type Props = {
  id: string
};
const DiagnosisLink = ({ id }: Props) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const history = useHistory();

  return (
    <div data-cy="diagnosis-menu">
      <CustomMenuWrapper onMouseLeave={() => setMenuVisibility(false)}>
        <Button
          data-cy="open-diagnosis-menu"
          onClick={() => setMenuVisibility(true)}
          size="none"
          link
        >
          <MoreVertIcon />
        </Button>
        <Menu visible={menuVisibility}>
          <MenuHeader>
            <CustomButton
              data-cy="diagnose-button"
              onClick={() => history.push(`/auditrf-diagnostic/${id}`)}
              size="none"
              link
            >
              <Build />
              <CustomTextLabel isLink>Diagnose</CustomTextLabel>
            </CustomButton>

            <CustomButton onClick={() => setMenuVisibility(false)} size="none" link>
              <MoreVertIcon />
            </CustomButton>
          </MenuHeader>
        </Menu>
      </CustomMenuWrapper>
    </div>
  );
};

export default DiagnosisLink;
