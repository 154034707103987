// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../../../common/components/TextLabel';

type Props = {
  clientName: string,
  job: string,
  isSubsidiary?: boolean,
  id: string
};

const CustomTextLabel = styled(TextLabel)`
  color: ${props =>
    props.isSubsidiary ? props.theme.colors.gray['600'] : props.theme.color.text.light};
`;

const ClientJobInfo = ({ clientName, job, isSubsidiary, id }: Props) => (
  <>
    <CustomTextLabel
      heading
      noTopPadding
      bold
      isSubsidiary={isSubsidiary}
      data-cy={`client-name-${id}`}
    >
      {clientName}
    </CustomTextLabel>

    <CustomTextLabel heading noTopPadding isSubsidiary={isSubsidiary} data-cy={`job-${id}`}>
      {job}
    </CustomTextLabel>
  </>
);

ClientJobInfo.defaultProps = {
  isSubsidiary: false
};

export default ClientJobInfo;
