export default prefix => {
  switch (prefix) {
    case 'GL':
      return 'General Ledger';
    case 'TB':
      return 'Trial Balance';
    case 'PL':
      return 'Profit and Loss';
    case 'BS':
      return 'Balance Sheet';
    default:
      return prefix;
  }
};
