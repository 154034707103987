// @flow
import React, { createContext, useState } from 'react';
import { type ServiceInfo } from '../types/serviceInfo';

type IServiceInfoContext = {
  infos: ServiceInfo[]
};

export const ServiceInfoContext = createContext<IServiceInfoContext>({
  infos: []
});

type Props = {
  defaultState: ServiceInfo[],
  children: any
};

export const ServiceInfoProvider = ({ children, defaultState }: Props) => {
  const [infos] = useState<ServiceInfo[]>(defaultState);
  return <ServiceInfoContext.Provider value={{ infos }}>{children}</ServiceInfoContext.Provider>;
};
