// @flow
import { get, post, remove } from '../../common/utils/api';
import { type GeneralLedgerActivityLogList } from '../../common/types/log';

export const errorItemCode = [20, 21, 22, 23];
export default {
  getDatasetGroup: async (): Promise<GeneralLedgerActivityLogList> => get('gl/dataset-group'),
  getDatasetByGroupId: async (datasetGroupId: string) =>
    get(`gl/dataset-group/${datasetGroupId}/dataset`),
  deleteDatasetGroup: async (datasetGroupId: string): Promise<string> =>
    remove(`gl/dataset-group/${datasetGroupId}`),
  createServiceOrder: async (param: any) => {
    const res = await post('gl/service-order', param);
    return { datasetGroupId: param.datasetGroupId, serviceOrderId: res.serviceOrderId };
  }
};
