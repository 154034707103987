// @flow
const getStatusLabel = (
  opportunityStatus: number,
  jobStatus: number,
  riskStatus: number,
  auraDatabaseStatus: number,
  auraLinkStatus: number,
  auraDatabaseId: number
) => {
  if (
    jobStatus === 2 &&
    ((auraDatabaseStatus === 2 && auraLinkStatus === 2) || auraDatabaseId === -1) // -1 = no database
  ) {
    return 'Completed';
  }
  if (jobStatus === 2 && auraDatabaseStatus === 1) {
    return 'Aura DB is pending to be created';
  }
  if (jobStatus === 2 && auraLinkStatus >= 1) {
    return 'Aura DB created and linking Aura DB to IPower job';
  }
  if (jobStatus === 2) return 'Waiting for Aura DB Archiving';
  if (jobStatus === 1) return 'Job is pending to be created';
  if (opportunityStatus === 2 && riskStatus >= 2 && jobStatus === 0) {
    return 'Verifying acceptance profile for the client';
  }
  if (opportunityStatus === 2 && riskStatus < 2) {
    return 'Pending for team to complete the acceptance profile';
  }

  return 'Request Submitted';
};

export default getStatusLabel;
