/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import Done from '@material-ui/icons/Done';
import { type SimpleDropdownItem } from '../../types/list';

export const DoneIcon = styled(Done)`
  && {
    width: 0.6em;
    height: 0.5em;
    color: ${props => props.theme.color.text.light};
    margin-right: 2px;
  }
`;

type Props = {
  options: Array<SimpleDropdownItem>,
  selectedItem: SimpleDropdownItem | null,
  onChange: Function,
  colorTheme?: string,
  customWidth?: string,
  icon?: Function,
  disabled?: boolean
};

const colorThemes = props => ({
  green: {
    backgroundColor: {
      active: props.theme.color.ui.success,
      inactive: props.theme.color.background.light
    },
    color: { active: props.theme.color.text.light, inactive: props.theme.color.icon.dark },
    borderColor: props.theme.color.icon.dark
  },
  orange: {
    backgroundColor: {
      active: props.theme.color.ui.primary,
      inactive: props.theme.color.background.light
    },
    color: { active: props.theme.color.text.light, inactive: props.theme.color.text.medium },
    borderColor: props.theme.color.ui.primary
  }
});

const takeColorThemesProperty = property => props =>
  colorThemes(props)[props.colorTheme][property][props.active ? 'active' : 'inactive'];

const TabsWrapper = styled.div`
  width: ${props => {
    if (props.customWidth === 'l') return `${90 * props.tabsCount}px`;
    if (props.customWidth === 'm') return `${60 * props.tabsCount}px`;
    return undefined;
  }}; 
  height: 30px;
  font-size: ${props => props.theme.fonts.size.body.s}
  border: solid 1px ${takeColorThemesProperty('borderColor')}
    
  border-radius: ${props => props.theme.border.type.round};
  margin: ${props => ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
  cursor: pointer;
  @media (max-width: 1550px) {
    margin: 0 !important;
  }
`;

const TabItem = styled.div`
  display: flex;
  justify-content: center;
  background: ${props =>
    props.disabled && props.active
      ? 'rgb(158, 152, 136)'
      : takeColorThemesProperty('backgroundColor')};
  box-shadow: ${props =>
    props.active
      ? 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)'
      : 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)'};
  color: ${takeColorThemesProperty('color')};
  padding: ${props => ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
  height: 100%;
  width: ${props => `${100 / props.tabsCount}%`};
  text-align: center;
  float: left;
`;

const TabsToggle = ({
  options,
  selectedItem,
  onChange,
  colorTheme,
  customWidth,
  icon,
  disabled
}: Props) =>
  options && (
    <TabsWrapper tabsCount={options.length} colorTheme={colorTheme} customWidth={customWidth}>
      {options.map(option => (
        <TabItem
          data-cy="tab-toggle"
          colorTheme={colorTheme}
          key={option.value}
          onClick={() => {
            if (!disabled) onChange(option);
          }}
          active={selectedItem && option.value === selectedItem.value}
          disabled={disabled}
          tabsCount={options.length}
        >
          {icon && selectedItem && option.value === selectedItem.value && icon()}
          {option.label}
        </TabItem>
      ))}
    </TabsWrapper>
  );

TabsToggle.defaultProps = {
  colorTheme: 'green',
  customWidth: 'l',
  icon: () => <DoneIcon />
};

export default TabsToggle;
