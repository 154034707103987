// @flow
import { type InputField, type ServiceOrderType } from '../types';

export const communicationLanguages = [
  {
    id: 1,
    value: 1,
    label: 'English'
  },
  {
    id: 2,
    value: 2,
    label: 'Finnish'
  },
  {
    id: 3,
    value: 3,
    label: 'Swedish'
  }
];

/*
 * enum types
 */

export const inputTypes = {
  SEARCH_SELECTION: 'SEARCH_SELECTION',
  SINGLE_DATE_PICKER: 'SINGLE_DATE_PICKER',
  CHECKBOXES: 'CHECKBOXES',
  TEXT_INPUT: 'TEXT_INPUT',
  DROPDOWN: 'DROPDOWN'
};

const tieOutScope = {
  FULL_TIE_OUT: 1,
  ONLY_COMPARISON: 2
};

const hasConcernTieOut = {
  YES: true,
  NO: false
};

const testOfDetailIncludedService = {
  REVENUE: 1,
  OTHER_OPERATING_ESPENSES: 2,
  LEGAL_EXPENSES: 3,
  MATERIALS_AND_SERVICES: 4,
  FIXES_ASSETS_INCREASES_AND_DECREASES: 5,
  SURL: 6
};

const reportLiabilityTestingIncludedService = {
  MATCHING_TRIAL_BALANCE: 1,
  ACCOUNT_RECEIVABLE_AGING_REPORT: 2,
  THIRD_PARTY_INVENTORY_REPORT: 3,
  INTERCOMPANY_ACCOUNT_MATCHING: 4,
  FIXES_ASSET_DEPRECIATION: 5
};

export const questionType = {
  ENGAGEMENT_TEAM: 1,
  DEAD_LINE: 2,
  TO_BE_CONFIRMED: 3,
  FINACIAL_INSTITUTION: 4,
  HAS_CONCERED_TIE_OUT: 5,
  TIE_OUT_SCOPE: 6,
  CLIENTCONTACTPERSON: 7,
  TEST_OF_DETAILED_INCLUDED_SERVICE: 8,
  REPORT_LIABILITY_TESTING_INCLUDED_SERVICE: 9,
  COMMUNICATION_LANGUAGE: 10,
  EXTRA_FINANCIAL_INSTITUTION: 11,
  CLIENT_CONTACT: 12,
  FAS_CHECKLIST: 13,
  DOKBOT: 14,
  AFS_REPORT_DATE: 15,
  AFS_REPORT_RUN_TIME: 16,
  CES_REPORT_COMPLETION_PHASE: 17,
  ONLY_CONFIRMATION_COM: 18,
  CLIENT_CONTACT_NAME: 19,
  CLIENT_CONTACT_EMAIL: 20
};

export const serviceOrderType = {
  ACCOUNT_PAYABLE: 1,
  ACOUNT_RECEIVABLES: 2,
  BANK_CONFIRMATION: 3,
  FINACIAL_STATEMENT: 4,
  INVENTORY_CONFIRMATION: 5,
  LEGAL_LETTER: 6,
  REPORT_LIABILITY_TESTING: 7,
  TEST_OF_DETAILS: 8,
  CES: 9
};

/*
 * id - Referenced in orderTypes
 * key - Key for a Service Order object from API response
 * type - Type of React component to be renderred
 * label - Label for the component
 * instruction - Tooltip text
 * placeholder - Text for Placeholder
 * options - Default options for Checkboxes or Dropdowns
 * optionMethod - Identifier for services method,
 * serviceName - Service name for fetching the options asynchronously
 */
export const inputFields: Array<InputField> = [
  {
    id: questionType.ENGAGEMENT_TEAM,
    key: 'engagementTeam',
    type: inputTypes.SEARCH_SELECTION,
    label: 'Orderer & other engagement team members',
    instruction:
      'Orderer and engagement team members are those members of engagement team that SDC will primarily be in contact with in all matters related to the Service Order',
    placeholder: '',
    serviceName: 'fetchStaff'
  },
  {
    id: questionType.DEAD_LINE,
    key: 'deadLine',
    type: inputTypes.SINGLE_DATE_PICKER,
    label: 'Deadline for the service order',
    removeWeekends: true,
    instruction: 'Please note that deadline cannot be on weekend or on Finnish national holiday',
    placeholder: ''
  },
  {
    id: questionType.TO_BE_CONFIRMED,
    key: 'toBeConfirmed',
    type: inputTypes.SINGLE_DATE_PICKER,
    label: 'Date to be confirmed',
    instruction: 'This is the date that the confirmation is obtained of',
    placeholder: ''
  },
  {
    id: questionType.FINACIAL_INSTITUTION,
    key: 'financialInstitutionsType1',
    type: inputTypes.SEARCH_SELECTION,
    label: 'Financial institutions (excl. Confirmation.com)',
    instruction:
      'Select and/or deselect Financial institutions to reflect your clients situation. Note. Financial institutions using Confirmation.com are in their own selection.',
    placeholder: '',
    serviceName: 'fetchFinancialInstitutions'
  },
  {
    id: questionType.HAS_CONCERED_TIE_OUT,
    key: 'hasConcernTieOut',
    type: inputTypes.CHECKBOXES,
    options: [
      {
        label: 'Yes',
        value: hasConcernTieOut.YES
      },
      {
        label: 'No',
        value: hasConcernTieOut.NO
      }
    ],
    label: 'Is it a group financial statement',
    instruction:
      'Please select whether this service order includes group financial statement or not',
    placeholder: ''
  },
  {
    id: questionType.TIE_OUT_SCOPE,
    key: 'tieOutScope',
    type: inputTypes.CHECKBOXES,
    options: [
      {
        label: 'Full tie-out',
        value: tieOutScope.FULL_TIE_OUT
      },
      {
        label: 'Only comparison',
        value: tieOutScope.ONLY_COMPARISON
      }
    ],
    label: 'Full tie-out or only comparison period tie-out',
    instruction:
      'Please select if this service order is for a full tie-out or only comparision period tie-out',
    placeholder: ''
  },
  {
    id: questionType.CLIENTCONTACTPERSON,
    key: 'clientContactPerson',
    label: "Client's contact person",
    type: inputTypes.TEXT_INPUT,
    instruction: 'Please enter the email SDC can use when contacting the client',
    placeholder: ''
  },

  {
    id: questionType.TEST_OF_DETAILED_INCLUDED_SERVICE,
    key: 'includedService',
    type: inputTypes.CHECKBOXES,
    options: [
      {
        label: 'Revenue',
        value: testOfDetailIncludedService.REVENUE
      },
      {
        label: 'Other operating expenses',
        value: testOfDetailIncludedService.OTHER_OPERATING_ESPENSES
      },
      {
        label: 'Legal expenses',
        value: testOfDetailIncludedService.LEGAL_EXPENSES
      },
      {
        label: 'Materials and services',
        value: testOfDetailIncludedService.MATERIALS_AND_SERVICES
      },
      {
        label: 'Fixed assets increases and decreases',
        value: testOfDetailIncludedService.FIXES_ASSETS_INCREASES_AND_DECREASES
      },
      {
        label: 'SURL',
        value: testOfDetailIncludedService.SURL
      }
    ],
    label: 'Target for test of details preparation',
    instruction: 'Please select the target for SDC to prepare test of details',
    placeholder: ''
  },
  {
    id: questionType.REPORT_LIABILITY_TESTING_INCLUDED_SERVICE,
    key: 'includedService',
    type: inputTypes.CHECKBOXES,
    options: [
      {
        label: 'Matching trial balance to general ledger',
        value: reportLiabilityTestingIncludedService.MATCHING_TRIAL_BALANCE
      },
      {
        label: 'Accounts receivable ageing report',
        value: reportLiabilityTestingIncludedService.ACCOUNT_RECEIVABLE_AGING_REPORT
      },
      {
        label: 'Third party inventory report',
        value: reportLiabilityTestingIncludedService.THIRD_PARTY_INVENTORY_REPORT
      },
      {
        label: 'Intercompany accounts matching',
        value: reportLiabilityTestingIncludedService.INTERCOMPANY_ACCOUNT_MATCHING
      },
      {
        label: 'Fixed assets’ depreciation (recalculation)',
        value: reportLiabilityTestingIncludedService.FIXES_ASSET_DEPRECIATION
      }
    ],
    label: 'Target for report liability testing',
    instruction: 'Select which test you need SDC to perform',
    placeholder: ''
  },
  {
    id: questionType.COMMUNICATION_LANGUAGE,
    key: 'communicationLanguage',
    type: inputTypes.DROPDOWN,
    options: communicationLanguages,
    label: 'Communication Language',
    instruction: 'Please select the language that you use when contacting the client',
    placeholder: ''
  },
  {
    id: questionType.EXTRA_FINANCIAL_INSTITUTION,
    key: 'financialInstitutionsType2',
    type: inputTypes.SEARCH_SELECTION,
    label: 'Confirmation.com - Financial Institutions',
    instruction:
      'Select and/or deselect Financial institutions to reflect your clients situation. Note. Select also if SDC should contact the client.',
    placeholder: '',
    serviceName: 'fetchExtraFinancialInstitutions'
  },
  {
    id: questionType.CLIENT_CONTACT,
    key: 'clientContact',
    type: inputTypes.CHECKBOXES,
    label:
      'Client contact (PCS Hki H, Häme, Jyväskylä, Kuopio, Pohjanmaa, Pohjois-Suomi and Turku)',
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ],
    instruction:
      'Information: If you belong to one of the mentioned teams, select Yes in case you want SDC to contact the client regarding all financial institutions.',
    placeholder: '',
    serviceName: ''
  },
  {
    id: questionType.ONLY_CONFIRMATION_COM,
    key: 'confirmationComClientContact',
    type: inputTypes.CHECKBOXES,
    label: 'Client contact (Only confirmation.com)',
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ],
    instruction:
      'Select Yes in case you want SDC to contact the client about Confirmation.com financial institutions.',
    placeholder: '',
    serviceName: ''
  },
  {
    id: questionType.FAS_CHECKLIST,
    key: 'fasChecklist',
    type: inputTypes.CHECKBOXES,
    label: 'Will SDC prepare the FAS checklist? (only for micro and small entities)',
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ],
    instruction: '',
    placeholder: '',
    serviceName: ''
  },
  {
    id: questionType.DOKBOT,
    key: 'dokbot',
    type: inputTypes.CHECKBOXES,
    label: `Will SDC prepare management representation letter and auditor's report drafts in Dokbot`,
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ],
    instruction: '',
    placeholder: '',
    serviceName: ''
  },
  {
    id: questionType.AFS_REPORT_DATE,
    key: 'afsReportDate',
    type: inputTypes.SINGLE_DATE_PICKER,
    label: 'AFS report',
    instruction:
      'Date when the engagement team wants the AFS-report (Audit Documentation report is part of CES service), multiple reports needs to be requested by email.',
    placeholder: ''
  },
  {
    id: questionType.AFS_REPORT_RUN_TIME,
    key: 'afsReportRunTime',
    type: inputTypes.TEXT_INPUT,
    label: 'What time period AFS report is needed',
    instruction: '',
    placeholder: ''
  },
  {
    id: questionType.CES_REPORT_COMPLETION_PHASE,
    key: 'cesReportCompletionPhase',
    type: inputTypes.SINGLE_DATE_PICKER,
    label: 'CES Report for Completion phase',
    instruction:
      'Date when the engagement team wants a report for the Aura Completion EGA, date mandatory in the PIE hierarchy. SDC will provide a report for Plan EGA after the SO roll forward is complete.',
    placeholder: ''
  },
  {
    id: questionType.CLIENT_CONTACT_NAME,
    key: 'clientContactName',
    label: 'Client contact name ',
    type: inputTypes.TEXT_INPUT,
    instruction: '',
    placeholder: ''
  },
  {
    id: questionType.CLIENT_CONTACT_EMAIL,
    key: 'clientContactEmail',
    label: "Client's contact email",
    type: inputTypes.TEXT_INPUT,
    instruction: 'Please enter the email SDC can use when contacting the client',
    placeholder: ''
  }
];

export const orderTypes: Array<ServiceOrderType> = [
  {
    id: serviceOrderType.ACCOUNT_PAYABLE,
    name: 'Accounts Payable confirmations',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TO_BE_CONFIRMED,
      questionType.CLIENTCONTACTPERSON
    ]
  },
  {
    id: serviceOrderType.ACOUNT_RECEIVABLES,
    name: 'Accounts Receivable confirmations',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TO_BE_CONFIRMED,
      questionType.CLIENTCONTACTPERSON
    ]
  },
  {
    id: serviceOrderType.BANK_CONFIRMATION,
    name: 'Bank Confirmations',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TO_BE_CONFIRMED,
      questionType.COMMUNICATION_LANGUAGE,
      questionType.EXTRA_FINANCIAL_INSTITUTION,
      questionType.FINACIAL_INSTITUTION,
      questionType.CLIENT_CONTACT,
      questionType.ONLY_CONFIRMATION_COM,
      questionType.CLIENT_CONTACT_NAME,
      questionType.CLIENT_CONTACT_EMAIL
    ]
  },
  {
    id: serviceOrderType.FINACIAL_STATEMENT,
    name: 'Financial Statement tie-out',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.HAS_CONCERED_TIE_OUT,
      questionType.TIE_OUT_SCOPE,
      questionType.DOKBOT,
      questionType.FAS_CHECKLIST
    ]
  },
  {
    id: serviceOrderType.INVENTORY_CONFIRMATION,
    name: 'Inventory confirmations',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TO_BE_CONFIRMED,
      questionType.CLIENTCONTACTPERSON
    ]
  },
  {
    id: serviceOrderType.LEGAL_LETTER,
    name: 'Legal letters',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TO_BE_CONFIRMED,
      questionType.CLIENTCONTACTPERSON
    ]
  },

  {
    id: serviceOrderType.TEST_OF_DETAILS,
    name: 'Test of Details',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.TEST_OF_DETAILED_INCLUDED_SERVICE
    ]
  },
  {
    id: serviceOrderType.REPORT_LIABILITY_TESTING,
    name: 'Testing of key reports',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.REPORT_LIABILITY_TESTING_INCLUDED_SERVICE
    ]
  },
  {
    id: serviceOrderType.CES,
    name: 'CES',
    inputFieldIds: [
      questionType.ENGAGEMENT_TEAM,
      questionType.DEAD_LINE,
      questionType.CES_REPORT_COMPLETION_PHASE,
      questionType.AFS_REPORT_RUN_TIME,
      questionType.AFS_REPORT_DATE
    ]
  }
];
