// @flow
import React from 'react';
import Info from '@material-ui/icons/Info';
import Tooltip from './Tooltip';

const TitleLabelWithTooltip = ({
  text,
  tooltip
}: {
  text: string,
  tooltip: { __html: string }
}) => (
  <Tooltip bold>
    <span>{text}</span>
    <div>
      <Info data-cy="tooltip-info" />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={tooltip} />
    </div>
  </Tooltip>
);

export default TitleLabelWithTooltip;
