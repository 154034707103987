import { withHandlers } from 'recompose';
import { Map } from 'immutable';
import JobTable from '../JobTable';

export default withHandlers({
  setAuraAsParentProperties: props => (parent, child) => {
    const { updateRollForward, rollForwards } = props;
    const parentRecord = rollForwards.get(parent.toString());
    const parentAura = Map.isMap(parentRecord.getIn(['datum', 'auraDatabase']))
      ? parentRecord.getIn(['datum', 'auraDatabase']).toJS()
      : parentRecord.getIn(['datum', 'auraDatabase']);

    updateRollForward(
      child,
      'auraDatabase',
      Map({
        ...parentAura,
        isAuraAsParent: true
      }),
      parentRecord.get('key')
    );
  },
  resetAuraAsParentProperties: props => (parent, child) => {
    const { updateRollForward, rollForwards } = props;
    const parentRecord = rollForwards.get(parent.toString());
    updateRollForward(
      child,
      'auraDatabase',
      {
        id: null,
        name: 'Not selected',
        option: 'Do_Nothing',
        isAuraAsParent: false
      },
      parentRecord.get('key')
    );
  }
})(JobTable);
