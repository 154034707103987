const px = {
  none: '0px',
  xs: '4px',
  s: '8px',
  m: '16px',
  l: '24px',
  xl: '32px'
};

const fonts = {
  family: {
    heading: 'Georgia',
    body: 'ArialMT',
    bold: 'Arial-BoldMT'
  },
  size: {
    heading: {
      s: '1em',
      m: '1.25em',
      l: '1.5em',
      xl: '2.25em'
    },
    body: {
      xs: '0.6em',
      s: '0.75em',
      m: '1em',
      l: '1.333em',
      xl: '1.777em'
    }
  }
};

const colors = {
  green: {
    '600': '#2C8646'
  },
  yellow: {
    '600': '#FFB600',
    '500': '#FFCC5E',
    '400': '#FFD376',
    '300': '#FFE2A4',
    '200': '#FFF0D2',
    '100': '#FFF8E8'
  },
  tangerine: {
    '600': '#EB8C00',
    '500': '#F2AF5B',
    '400': '#F3BB73',
    '300': '#F8D1A2',
    '200': '#FCE8D0',
    '100': '#FDF3E8'
  },
  orange: {
    '600': '#D04A02',
    '500': '#E08159',
    '400': '#E49371',
    '300': '#EEB7A0',
    '200': '#F6DBCF',
    '100': '#FBEDE7'
  },
  red: {
    '600': '#E0301E',
    '500': '#EA7065',
    '400': '#EE847B',
    '300': '#F4ADA8',
    '200': '#F9D6D4',
    '100': '#FCEAE9'
  },
  rose: {
    '600': '#D93954',
    '500': '#E67688',
    '400': '#E98999',
    '300': '#F1B0BA',
    '200': '#F8D8DD',
    '100': '#FBEBEE'
  },
  burgundy: {
    '600': '#A32120',
    '500': '#C06465',
    '400': '#C97A7B',
    '300': '#DBA7A6',
    '200': '#EED3D3',
    '100': '#F6E9E9'
  },
  maroon: {
    '600': '#602320',
    '500': '#916663',
    '400': '#A07C79',
    '300': '#C0A7A6',
    '200': '#E0D3D3',
    '100': '#EEE9E8'
  },
  gray: {
    '600': '#736B53',
    '500': '#9E9888',
    '400': '#ABA698',
    '300': '#C7C4BB',
    '200': '#E3E1DD',
    '100': '#F1F0EE'
  },
  neutral: {
    '1000': '#000000',
    '900': '#222222',
    '700': '#6D6E71',
    '600': '#999A9C',
    '500': '#A7A8AA',
    '400': '#C5C5C6',
    '300': '#E2E2E3',
    '200': '#F0F0F1',
    '100': '#F2F2F2',
    '0': '#FFFFFF'
  }
};

const saturation = {
  default: 700,
  primary: 600,
  neutral: 500,
  secondary: 400,
  lightish: 200,
  light: 100
};

const main = {
  fonts,
  colors,
  saturation,
  color: {
    heading: {
      dark: colors.neutral[1000],
      neutral: colors.gray[saturation.neutral],
      light: colors.neutral[0]
    },
    text: {
      dark: colors.neutral[1000],
      darkish: colors.neutral[900],
      neutral: colors.gray[saturation.neutral],
      light: colors.neutral[0]
    },
    background: {
      black: colors.neutral[1000],
      dark: colors.neutral[300],
      medium: colors.gray[200],
      light: colors.neutral[100],
      white: colors.neutral[0],
      transparent: 'transparent'
    },
    icon: {
      primary: colors.maroon[saturation.primary],
      dark: colors.neutral[700],
      light: colors.gray[100],
      disabled: colors.neutral[500]
    },
    ui: {
      primary: colors.orange[saturation.primary],
      secondary: colors.orange[saturation.secondary],
      default: colors.neutral[saturation.default],
      light: colors.orange[100],
      disabled: colors.neutral[400],
      error: colors.red[600],
      success: colors.green[600]
    },
    link: {
      default: colors.orange[saturation.default]
    },
    border: {
      default: colors.gray[200],
      white: colors.neutral[0]
    },
    transparent: {
      medium: 'rgba(0, 0, 0, 0.03)',
      light: 'rgba(0, 0, 0, 0.015)'
    }
  },
  space: {
    single: {
      ...px
    },
    square: {
      ...px
    },
    squish: {
      s: `${px.s} ${px.m}`,
      m: `${px.m} ${px.l}`,
      l: `${px.l} ${px.xl}`
    },
    stretch: {
      s: `${px.m} ${px.s}`,
      m: `${px.l} ${px.m}`,
      l: `${px.xl} ${px.l}`
    },
    stack: {
      xs: `0 ${px.xs}`,
      s: `0 ${px.s}`,
      m: `0 ${px.m}`,
      l: `0 ${px.l}`,
      xl: `0 ${px.xl}`
    },
    inline: {
      xs: `${px.xs} 0`,
      s: `${px.s} 0`,
      m: `${px.m} 0`,
      l: `${px.l} 0`,
      xl: `${px.xl} 0`
    }
  },
  border: {
    type: {
      default: '0',
      round: '3px'
    }
  }
};

export const orange = {
  ...main,
  color: {
    ...main.color,
    ui: {
      ...main.color.ui,
      primary: colors.orange[600],
      secondary: colors.orange[400],
      light: colors.orange[100]
    },
    icon: {
      ...main.color.icon,
      primary: colors.orange[saturation.primary],
      secondary: colors.orange[400]
    }
  }
};

export const rose = {
  ...main,
  color: {
    ...main.color,
    ui: {
      ...main.color.ui,
      primary: colors.rose[600],
      secondary: colors.rose[400],
      light: colors.rose[100]
    },
    icon: {
      ...main.color.icon,
      primary: colors.rose[saturation.primary]
    }
  }
};

export const burgundy = {
  ...main,
  color: {
    ...main.color,
    ui: {
      ...main.color.ui,
      primary: colors.burgundy[600],
      secondary: colors.burgundy[400],
      light: colors.burgundy[100]
    },
    icon: {
      ...main.color.icon,
      primary: colors.burgundy[saturation.primary]
    }
  }
};

export const maroon = {
  ...main,
  color: {
    ...main.color,
    ui: {
      ...main.color.ui,
      primary: colors.maroon[600],
      secondary: colors.maroon[400],
      light: colors.maroon[100]
    },
    icon: {
      ...main.color.icon,
      primary: colors.maroon[saturation.primary]
    }
  }
};

export const defaultSize = px.m;

export default main;
