import styled from 'styled-components';
import { BlockLinkButton } from './Button';

export const MenuButton = styled(BlockLinkButton)`
  margin: ${props => props.theme.space.inline.l};
  text-align: left;
  &:last-child {
    margin-bottom: auto;
  }

  color: ${props => (props.disabled ? props.theme.colors.gray[200] : 'inherit')};
  strong {
    color: ${props =>
      props.disabled ? props.theme.colors.gray[300] : props.theme.colors.gray[600]};
    font-family: ${props => props.theme.fonts.family.bold};
    font-size: ${props => props.theme.fonts.size.body.l};
    display: block;
  }
  small {
    color: ${props =>
      props.disabled ? props.theme.colors.gray[200] : props.theme.colors.gray[400]};
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
