// @flow
import React, { useState, useEffect, useCallback } from 'react';
import * as BaseJoi from 'joi-browser';
import Extension from 'joi-date-extensions';
import styled from 'styled-components';
import PersonPin from '@material-ui/icons/PersonPin';
import Button, { LinkButton } from '../../../../common/components/Button';
import type {
  GetContactPerson,
  PostContactPerson,
  ApiPutContactPerson
} from '../../../../common/types/client';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import AddPersonDialog from './AddPersonDialog';
import AddNewPersonDialog from './AddNewPersonDialog';
import ProgressBar from '../../../../common/components/ProcessBar';
import { mapJoiErrorsToValidationMessages, formatContactPersonName } from '../../../utils/helper';

const ProgressBarWrapper = styled.div` && {
  border: 1px solid ${props => props.theme.color.border.default}
  border-radius:${props => props.theme.border.type.round}
  padding: ${props => `${props.theme.space.single.s} ${props.theme.space.single.xl}`}
  display: flex;
  background-color: white;
 
  > :first-child {
    width: 100%;
  }

  > :last-child {
    white-space: nowrap;
  }
}`;

const Joi = BaseJoi.extend(Extension);

type Props = {
  isLoadingModal: boolean,
  persons: Array<GetContactPerson>,
  personTypeText: string,
  personTypeTextShort: string,
  isUbo: boolean,
  updatePersonStatus: Function,
  putContactPersons: Function,
  postContactPerson: Function,
  clientId: number
};

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0 ${props => props.theme.space.single.m};
  list-style-type: none;

  li {
    flex-basis: 33%;
  }
  button {
    padding: ${props =>
      `${props.theme.space.single.s} ${props.theme.space.single.s} ${props.theme.space.single.m}`};

    svg {
      position: absolute;
      color: ${props => props.theme.color.ui.primary};
    }
  }
`;

const postContactPersonDataInitial = isUbo => {
  const personDataInitial = {
    lastName: '',
    firstName: '',
    dateOfBirth: { day: '', month: '', year: '' },
    nationality: null
  };
  return isUbo ? personDataInitial : { ...personDataInitial, authorization: null };
};

const postUboDataSchema = Joi.object().keys({
  lastName: Joi.string().required(),
  firstName: Joi.string().required(),
  dateOfBirth: Joi.date()
    .format('YYYY-MM-DD')
    .required(),
  nationality: Joi.string().required()
});

const postRepresentativeDataSchema = postUboDataSchema.keys({
  authorization: Joi.number()
    .integer()
    .required(),
  nationality: Joi.string().allow(null)
});

const putUboDataSchema = Joi.array()
  .min(1)
  .items({
    personId: Joi.number().required()
  });

const putRepresentativeDataSchema = Joi.array()
  .length(1)
  .items({
    personId: Joi.number().required(),
    authorization: Joi.number()
      .integer()
      .required()
  });

const PersonSelectionGrid = ({
  clientId,
  isLoadingModal,
  persons,
  personTypeText,
  personTypeTextShort,
  isUbo,
  updatePersonStatus,
  putContactPersons,
  postContactPerson
}: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogPage, setDialogPage] = useState<number>(1);
  /** The latest selection is saved in the local state, to keep the input text field in sync with the selection.
   * This way we can clear the text field, when we deselect a person or close the modal. */
  const [dialogLatestSelection, setDialogLatestSelection] = useState<GetContactPerson | null>(null);
  const [putContactPersonsData, setPutContactPersonsData] = useState<
    Array<GetContactPerson | null>
  >([]);
  const [postContactPersonData, setPostContactPersonData] = useState<PostContactPerson>(
    postContactPersonDataInitial(isUbo)
  );
  const clearModal = useCallback(() => {
    setDialogIsOpen(false);
    setPutContactPersonsData([]);
    setDialogLatestSelection(null);
    setPostContactPersonData(postContactPersonDataInitial(isUbo));
    setDialogPage(1);
  }, [isUbo]);

  useEffect(() => {
    if (!isLoadingModal) clearModal();
  }, [isLoadingModal, clearModal]);

  const apiPutContactPersons: Array<ApiPutContactPerson | null> = putContactPersonsData.map(
    person =>
      person &&
      (isUbo
        ? { personId: person.id }
        : { personId: person.id, authorization: person.authorization })
  );

  const postFormValidation = Joi.validate(
    {
      ...postContactPersonData,
      dateOfBirth: [
        postContactPersonData.dateOfBirth.year,
        postContactPersonData.dateOfBirth.month,
        postContactPersonData.dateOfBirth.day
      ].join('-')
    },
    isUbo ? postUboDataSchema : postRepresentativeDataSchema
  );

  const putFormValidation = Joi.validate(
    apiPutContactPersons,
    isUbo ? putUboDataSchema : putRepresentativeDataSchema,
    { abortEarly: false }
  );

  const putValidationErrorMessages =
    putFormValidation.error &&
    mapJoiErrorsToValidationMessages(putFormValidation.error.details, 'contactPersonError');

  return (
    <StyledList>
      {persons &&
        persons.map(person => (
          <li key={formatContactPersonName(person.firstName, person.lastName)}>
            {person.isActive ? (
              <LinkButton
                primary={!person.isFromAsently}
                success={person.isFromAsently}
                bold
                large
                onClick={() => {
                  updatePersonStatus(person, false);
                }}
              >
                {formatContactPersonName(person.firstName, person.lastName)}
                <PersonPin />
              </LinkButton>
            ) : (
              <LinkButton
                large
                onClick={() => {
                  updatePersonStatus(person, true);
                }}
              >
                {formatContactPersonName(person.firstName, person.lastName)}
              </LinkButton>
            )}
          </li>
        ))}
      <li key="representative-other-option">
        <LinkButton primary large onClick={() => setDialogIsOpen(true)}>
          {`+ add new ${personTypeTextShort}`}
        </LinkButton>
      </li>
      <DialogBox
        title={`Add ${dialogPage === 2 ? 'new ' : ''}${personTypeText}`}
        titleInfo={`Note! If you add a new ${personTypeTextShort}, remember to also select him/her as a  ${personTypeTextShort}`}
        ButtonElement={
          isLoadingModal
            ? ({ onClick, disabled }: { onClick: Function, disabled: boolean }) => (
                <ProgressBarWrapper>
                  <ProgressBar />
                  <Button solid onClick={onClick} disabled={disabled} data-cy="dialog-box-button">
                    Submit
                  </Button>
                </ProgressBarWrapper>
              )
            : // eslint-disable-next-line react/no-unused-prop-types
              ({ onClick, disabled }: { onClick: Function, disabled: boolean }) => (
                <Button
                  primary
                  solid
                  onClick={onClick}
                  disabled={disabled}
                  data-cy="dialog-box-button"
                >
                  Submit
                </Button>
              )
        }
        closeDialog={() => {
          if (dialogPage === 2) {
            const { day, month, year } = postContactPersonData.dateOfBirth;
            postContactPerson(
              clientId,
              {
                ...postContactPersonData,
                dateOfBirth:
                  day &&
                  month &&
                  year &&
                  new Date(Date.UTC(Number(year), Number(month) - 1, Number(day))).toISOString()
              },
              isUbo
            );
          } else {
            putContactPersons(clientId, apiPutContactPersons, isUbo);
          }
        }}
        buttonIsDisabled={
          isLoadingModal || (dialogPage === 2 ? postFormValidation.error : putFormValidation.error)
        }
        content={() =>
          dialogPage === 2
            ? AddNewPersonDialog({
                contactPersonData: postContactPersonData,
                setContactPersonData: setPostContactPersonData,
                isUbo
              })
            : AddPersonDialog({
                searchType: personTypeText,
                contactPersonsData: putContactPersonsData,
                setContactPersonsData: setPutContactPersonsData,
                latestSelection: dialogLatestSelection,
                setLatestSelection: setDialogLatestSelection,
                setDialogPage,
                isMultiSelect: isUbo,
                validationErrorMessages: putValidationErrorMessages || {}
              })
        }
        isOpen={dialogIsOpen}
        maxWidth="md"
        dialogStyle={{ overflowY: 'visible' }}
        contentStyle={{ minWidth: '750px', minHeight: '150px', overflowY: 'visible' }}
        scroll="body"
        hasCloseButton
        closeButtonOnClick={() => clearModal()}
      />
    </StyledList>
  );
};

export default PersonSelectionGrid;
