import styled, { css } from 'styled-components';

export const ClientName = styled.span`
  color: ${props =>
    props.isDisabled ? props.theme.color.ui.disabled : props.theme.color.ui.primary};
  display: block;
  font-family: ${props => props.theme.fonts.family.bold};
  font-size: 18px;
  letter-spacing: -0.2px;
`;

export const ClientId = styled.span`
  font-size: 14px;
  letter-spacing: -0.2px;
`;

export const Td = styled.td`
  && {
    position: relative;
    padding: ${props => (props.noPadding ? '0' : props.theme.space.single.m)};
  }
`;

export default styled.tr`
  color: ${props =>
    props.isDisabled ? props.theme.color.icon.disabled : props.theme.color.text.darkish};
  & > *: first-child {
    border-left: ${props =>
      props.isChildren
        ? `${props.theme.color.ui.primary} ${props.theme.space.single.s} solid`
        : 'unset'};
  }
  td {
    background-color: ${props =>
      props.isChildren ? props.theme.color.background.white : 'inherit'};
  }
  ${props =>
    props.hasChildren
      ? css`
          background-color: ${props.theme.color.background.white};
        `
      : css`
          &:nth-of-type(2n + 1) {
            background-color: ${props.theme.color.transparent.light};
          }
          &:nth-of-type(2n) {
            background-color: ${props.theme.color.transparent.medium};
          }
        `};
`;
