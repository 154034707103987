// @flow
import { type TeamList, type Team } from '../../common/types/team';
import { get, put } from '../../common/utils/api';

export default {
  fetchTeamList: (inputValue: string): Promise<Array<Team>> => get(`/team?name=${inputValue}`),
  fetchDefaultTeam: async (jobId: number): Promise<TeamList> =>
    get(`/gl/team/default?jobId=${jobId}`),
  submitTeamList: async (datasetId: number, team: Team): Promise<Array<Team>> =>
    put(`/gl/dataset-group/${datasetId}/team`, team),
  putQueueStd: async (datasetGroupId: number): Promise<string> =>
    put(`/gl/dataset-group/${datasetGroupId}/queue-std`, datasetGroupId),
  putQueueApp: async (datasetGroupId: number): Promise<string> =>
    put(`/gl/dataset-group/${datasetGroupId}/queue-app`, datasetGroupId),
  putQueueAll: async (datasetGroupId: number): Promise<string> =>
    put(`/gl/dataset-group/${datasetGroupId}/queue-all`, datasetGroupId)
};
