// @flow
import React from 'react';
import ReactSwitch from 'react-switch';
import theme from '../theme/theme';

type Props = {
  checked: boolean,
  onChange: Function,
  isDisabled?: boolean
};

const Switch = ({ checked, onChange, isDisabled }: Props) => (
  <ReactSwitch
    checked={checked}
    onChange={onChange}
    onColor={theme.color.ui.success}
    onHandleColor={theme.colors.neutral[0]}
    offColor={theme.colors.neutral[500]}
    offHandleColor={theme.colors.neutral[0]}
    handleDiameter={24}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={24}
    width={48}
    className="react-switch"
    disabled={isDisabled}
  />
);
Switch.defaultProps = {
  isDisabled: false
};

export default Switch;
