// @flow
import { type Node } from 'react';
import { TeaLoader } from '@pwc-fi/ui-library';

/**
 * Common
 */
import Home from './general-page/HomePage';
import ServiceDown from './common/components/service-down';
import { type ServiceInfo } from './common/types/serviceInfo';

/**
 * General Ledger
 */
import GLHome from './general-ledger/components/GeneralLedgerContainer';
import GLSummary from './general-ledger/components/summary/SubmitPageContainer';
import AccountMapping from './general-ledger/components/account-mapping/AccountMappingContainer';
import GLActivityLog from './general-ledger/components/activity-log/ActivityLogContainer';

/**
 * Roll Forward
 */
import RFHome from './roll-forward/components/RollForwardPage';
import RFActivityLog from './roll-forward/activity-log/ActivityLogContainer';
import SubmittedPage from './roll-forward/components/container/SubmittedPageContainer';

/**
 * AML Check
 */
import AMLHome from './aml-check/components/AmlCheckPage';

/**
 * SDC Service Order
 */
import ServiceOrder from './service-order/ServiceOrder';
import ServiceOrderSubmittedPage from './service-order/summary/SummaryPageContainer';

/**
 * SDC Service Order
 */
import AuditRFDiagnosticSummaries from './auditrf-diagnostic/AuditRFDiagnosticSummaries';
import AuditRFDiagnosis from './auditrf-diagnostic/AuditRFDiagnosis';

/**
 * Veromylly
 */
import VeromyllyHome from './veromylly/steps/home-page/Home';
import TaxReturn from './veromylly/steps/tax-return-page/TaxReturnContainer';

/**
 * SDC-upload
 */
import SDCMainPage from './sdc-upload/MainPageContainer';

/**
 * TalentLink sync tool
 */
import TalentLinkHome from './talentlink/steps/Home';
import TalentLinkCompleted from './talentlink/steps/Completed';

type routeType = {
  exact: boolean,
  path: string,
  component: any => Node,
  serviceName?: string,
  notification?: string
};
type appRoutesType = Array<routeType>;

export const defaultRoutes = (): appRoutesType => [
  {
    exact: false,
    path: '/',
    component: TeaLoader,
    serviceName: 'Service Portal',
    notification: ''
  }
];

const getGLRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/general-ledger',
    component: serviceInfo.disabled ? ServiceDown : GLHome,
    serviceName: 'GL Data Platform',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/general-ledger/success',
    component: serviceInfo.disabled ? ServiceDown : GLSummary,
    serviceName: 'GL Data Platform',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/general-ledger/account-mapping',
    component: serviceInfo.disabled ? ServiceDown : AccountMapping,
    serviceName: 'GL Data Platform',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/general-ledger/activity-log',
    component: serviceInfo.disabled ? ServiceDown : GLActivityLog,
    serviceName: 'GL Data Platform',
    notification: serviceInfo.notification || ''
  }
];

const getRFRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/roll-forward/submitted',
    component: serviceInfo.disabled ? ServiceDown : SubmittedPage,
    serviceName: 'Audit Engagement Roll-forward',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/roll-forward/activity-log',
    component: serviceInfo.disabled ? ServiceDown : RFActivityLog,
    serviceName: 'Audit Engagement Roll-forward',
    notification: serviceInfo.notification || ''
  },

  {
    exact: false,
    path: '/roll-forward',
    // $FlowFixMe
    component: serviceInfo.disabled ? ServiceDown : RFHome,
    serviceName: 'Audit Engagement Roll-forward',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/auditrf-diagnostic',
    component: serviceInfo.disabled ? ServiceDown : AuditRFDiagnosticSummaries,
    serviceName: 'Audit Roll-forward Diagnostic',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/auditrf-diagnostic/:rollforwardId',
    component: serviceInfo.disabled ? ServiceDown : AuditRFDiagnosis
  }
];

const getAMLRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: false,
    path: '/aml-check',
    component: serviceInfo.disabled ? ServiceDown : AMLHome,
    serviceName: 'AML Check',
    notification: serviceInfo.notification || ''
  }
];

const getSORoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/service-order',
    component: serviceInfo.disabled ? ServiceDown : ServiceOrder,
    serviceName: 'Roll-Forward Service Order',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/service-order/submitted',
    component: ServiceOrderSubmittedPage
  }
];

const getVeromyllyRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/veromylly',
    component: serviceInfo.disabled ? ServiceDown : VeromyllyHome,
    serviceName: 'Veromylly',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/veromylly/:taxReturnId',
    component: serviceInfo.disabled ? ServiceDown : TaxReturn,
    notification: serviceInfo.notification || ''
  }
];

const getSDCUploadRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/sdc-upload',
    component: serviceInfo.disabled ? ServiceDown : SDCMainPage,
    serviceName: 'SDC Material Upload',
    notification: serviceInfo.notification || ''
  }
];

const getTalentLinkRoutes = (serviceInfo: ServiceInfo): appRoutesType => [
  {
    exact: true,
    path: '/talentlink',
    component: serviceInfo.disabled ? ServiceDown : TalentLinkHome,
    serviceName: 'TalentLink',
    notification: serviceInfo.notification || ''
  },
  {
    exact: true,
    path: '/talentlink/completed',
    component: serviceInfo.disabled ? ServiceDown : TalentLinkCompleted,
    notification: serviceInfo.notification || ''
  }
];

/**
 * Internal route configuration.
 *
 * (Keys from DB configuration)
 */
const internalRoutes = {
  rf: getRFRoutes,
  gl: getGLRoutes,
  aml: getAMLRoutes,
  sorf: getSORoutes,
  veromylly: getVeromyllyRoutes,
  sdcUpload: getSDCUploadRoutes,
  talentLink: getTalentLinkRoutes
};

export const getAppRoutes = (list: Array<ServiceInfo>): appRoutesType => {
  const isAppDown = !list || list.every(info => info.disabled);
  const routes = [
    {
      exact: !isAppDown,
      path: '/',
      component: isAppDown ? ServiceDown : Home,
      serviceName: 'Service Portal',
      notification: ''
    }
  ];

  if (isAppDown) return routes;

  return list
    .filter(item => !item.external)
    .reduce(
      (init, item) =>
        item && internalRoutes[item.key] ? init.concat(internalRoutes[item.key](item)) : init,
      routes
    );
};
