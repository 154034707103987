// @flow
import moment from 'moment';
import { type ServiceOrder } from '../types';

export const defaultSort = (orders: ServiceOrder[]): ServiceOrder[] =>
  orders
    ? orders.sort((a, b) => {
        if (a.deadline === b.deadline) {
          if (a.client.name > b.client.name) return 1;
          return -1;
        } else if (a.deadline > b.deadline) return 1;
        return -1;
      })
    : [];

export const deadlineFilterType = {
  // Compare deadline moment with date text
  deadlineText: (rows: any, id: number, filterValue: string) =>
    rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? moment(rowValue)
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        : true;
    })
};
