// @flow
import { Record, type RecordOf, Map, OrderedMap } from 'immutable';
import type Moment from 'moment';
import moment from 'moment';
import { createAction } from 'redux-actions';
import { type ActionType } from '../../common/types/redux';
import service from '../services/dataset';

import { type datasetGroup, type dataset } from '../../common/types/dataset';
import { type FilesData } from '../../common/types/files';
import activityLogService from '../services/activitylog';
import { fetchSubsidiaries } from '../services/subsidiaries';

type State = RecordOf<{
  startDate: Moment,
  endDate: Moment,
  name: string,
  datasetStatus: number,
  serviceOrderId: number,
  isDatasetGroupLoading: boolean,
  isDatasetLoading: boolean,
  isFileLoading: boolean,
  filesData: FilesData
}>;

/*
 * If current date is before 1.7 of this year, default dates are last year. Else, default dates are this year
 */

const haveFinancialYearPassed = moment().isAfter(moment(`${moment().year()}-07-01`));
const start = haveFinancialYearPassed
  ? moment().startOf('year')
  : moment()
      .startOf('year')
      .subtract(1, 'years');

const end = haveFinancialYearPassed
  ? moment().endOf('year')
  : moment()
      .endOf('year')
      .subtract(1, 'years');

const convertUtcToLocal = (date: Date) =>
  moment(date)
    .utc(date)
    .local();
const defaultState = Record({
  startDate: convertUtcToLocal(start),
  endDate: end,
  name: '',
  datasetStatus: undefined,
  serviceOrderId: undefined,
  isDatasetGroupLoading: false,
  isDatasetLoading: false,
  isFileLoading: false,
  filesData: {
    files: [],
    datasetGroupId: '',
    headClient: {
      dataFailures: {
        GL: Map({}),
        TB: Map({}),
        PL: Map({}),
        BS: Map({})
      },
      datasetId: '',
      datasetStatus: undefined,
      GL: Map({}),
      TB: Map({}),
      PL: Map({}),
      BS: Map({})
    },
    subsidiaries: Map({})
  }
});

export const createServiceOrderFromSummary = createAction('RESET_DATASET_STATUS', param =>
  activityLogService.createServiceOrder(param)
);

export const saveDate = createAction('SAVE_SELECTED_DATE', (startDate: Date, endDate: Date) => ({
  startDate,
  endDate
}));

// *actions for headClient
export const createDatasetGroup = createAction('CREATE_DATASET_GROUP', (data: datasetGroup) =>
  service.createDatasetGroup(data)
);

export const createHeadClientDataset = createAction('CREATE_HEADCLIENT_DATASET', (data: dataset) =>
  service.createDataset(data)
);

export const saveHeadClientFile = createAction(
  'SAVE_HEADCLIENT_FILE',
  async (datasetId: string, contentFormat: Object, file: any) => {
    const fileId = await service.uploadFile(datasetId, contentFormat, file);
    const fileInfo = {
      fileName: file.name,
      fileId
    };
    return {
      fileInfo,
      contentFormatType: contentFormat.type,
      fileId,
      file
    };
  }
);

export const removeHeadClientFile = createAction(
  'REMOVE_HEADCLIENT_FILE',
  async (datasetId: string, contentFormat: Object, file: any) => {
    const isSuccess = await service.deleteFile(datasetId, file.id);
    return {
      isSuccess,
      contentFormatType: contentFormat.type,
      file
    };
  }
);

export const createSubsidiaryDataset = createAction(
  'CREATE_SUBSIDIARY_DATASET',
  async (data: dataset, clientId: string) => {
    const datasetId = await service.createDataset(data);
    return {
      datasetId,
      clientId
    };
  }
);

export const saveSubsidiaryFile = createAction(
  'SAVE_SUBSIDIARY_FILE',
  async (datasetId: string, contentFormat: Object, file: any, clientId: string) => {
    const fileId = await service.uploadFile(datasetId, contentFormat, file, clientId);
    const fileInfo = {
      fileName: file.name,
      fileId
    };
    return {
      fileInfo,
      contentFormatType: contentFormat.type,
      fileId,
      clientId
    };
  }
);

export const removeSubsidiaryFile = createAction(
  'REMOVE_SUBSIDIARY_FILE',
  async (clientId: string, datasetId: string, contentFormatType: string, file: any) => {
    const isSuccess = await service.deleteFile(datasetId, file.id);
    return {
      isSuccess,
      clientId,
      contentFormatType,
      file
    };
  }
);

export const turnOnLoading = createAction('TURN_ON_LOADING');

export const saveSessionClient = createAction(
  'SAVE_SESSION_CLIENT',
  selectedClient => selectedClient
);

export const removeSessionClient = createAction('REMOVE_SESSION_CLIENT');
export const getSubsidiaries = createAction('GET_SUBSIDIARIES', clientId =>
  fetchSubsidiaries(clientId)
);
export const removeSubsidiary = createAction('REMOVE_SUBSIDIARY', async (datasetId, clientId) => {
  const isSuccess = await service.deleteDataset(datasetId);
  return { isSuccess, clientId };
});
export const addSubsidiary = createAction('ADD_SUBSIDIARY');

export const getDataset = createAction('GET_DATASET', async (isHeadClient, clientId, datasetId) => {
  const result = await service.fetchDataset(datasetId, isHeadClient, clientId);
  return {
    isHeadClient,
    clientId,
    status: result.status,
    files: result.files
  };
});

export const clearFilesList = createAction('CLEAR_FILES_LIST');

export const resetGettingDataset = createAction('RESET_GETTING_DATASET');

export default function GeneralLedgerReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_SESSION_CLIENT': {
      return state
        .updateIn(['filesData', 'headClient'], list => ({ ...list, ...payload }))
        .setIn(['filesData', 'datasetGroupId'], '');
    }
    case 'REMOVE_SESSION_CLIENT': {
      return state.setIn(['filesData', 'headClient'], {
        dataFailures: {
          GL: Map({}),
          TB: Map({}),
          PL: Map({}),
          BS: Map({})
        },
        datasetId: '',
        GL: Map({}),
        TB: Map({}),
        PL: Map({}),
        BS: Map({})
      });
    }
    case 'SAVE_SELECTED_DATE': {
      return state.set('startDate', payload.startDate).set('endDate', payload.endDate);
    }
    case 'CREATE_DATASET_GROUP_REJECTED': {
      return state
        .updateIn(['filesData', 'datasetGroupId'], () => '')
        .set('isDatasetGroupLoading', false);
    }
    case 'CREATE_HEADCLIENT_DATASET_REJECTED': {
      return state
        .updateIn(['filesData', 'headClient', 'datasetStatus'], () => 'fail')
        .set('isDatasetLoading', false);
    }
    case 'SAVE_HEADCLIENT_FILE_REJECTED': {
      if (payload.response && payload.response.config && payload.response.config.data) {
        const config = payload.response.config.data;

        const data = {
          contentFormatType: config.get('contentFormatType'),
          file: config.get('File')
        };

        const fileInfo = {
          fileName: data.file.name,
          file: data.file
        };

        return data.contentFormatType && fileInfo.fileName
          ? state
              .updateIn(
                [
                  'filesData',
                  'headClient',
                  'dataFailures',
                  data.contentFormatType,
                  fileInfo.fileName
                ],
                () => fileInfo
              )
              .set('isFileLoading', false)
              .updateIn(
                ['filesData', 'headClient', data.contentFormatType, 'isLoading'],
                loading => loading - 1
              )
          : state;
      }

      return state.set('isFileLoading', false);
    }

    case 'SAVE_SUBSIDIARY_FILE_REJECTED': {
      const config = payload.response.config.data;

      const data = {
        contentFormatType: config.get('contentFormatType'),
        clientId: config.get('clientId'),
        file: config.get('File')
      };

      const fileInfo = {
        fileName: data.file.name,
        file: data.file
      };

      return data.contentFormatType && fileInfo.fileName
        ? state
            .updateIn(
              ['filesData', 'subsidiaries', data.clientId, data.contentFormatType, 'isLoading'],
              loading => loading - 1
            )
            .updateIn(
              [
                'filesData',
                'subsidiaries',
                data.clientId,
                'dataFailures',
                data.contentFormatType,
                fileInfo.fileName
              ],
              () => fileInfo
            )
            .set('isFileLoading', false)
        : state;
    }

    case 'PROCCED_DATASET_REJECTED':
    case 'CREATE_DATASET_GROUP_PENDING': {
      return state.set('isDatasetGroupLoading', true);
    }
    case 'CREATE_SUBSIDIARY_DATASET_PENDING':
    case 'CREATE_HEADCLIENT_DATASET_PENDING': {
      return state.set('isDatasetLoading', true);
    }

    case 'SET_DATASET_STATUS_FULFILLED': {
      return payload.status && payload.files
        ? state.set('datasetStatus', payload.status).set('files', payload.files)
        : state;
    }

    case 'RESET_DATASET_STATUS_FULFILLED': {
      return state.set('datasetStatus', 0).set('serviceOrderId', payload.serviceOrderId);
    }

    case 'CREATE_DATASET_GROUP_FULFILLED': {
      return state
        .setIn(['filesData', 'datasetGroupId'], payload)
        .setIn(['filesData', 'headClient', 'GL'], Map())
        .setIn(['filesData', 'headClient', 'TB'], Map())
        .setIn(['filesData', 'headClient', 'PL'], Map())
        .setIn(['filesData', 'headClient', 'BS'], Map())
        .setIn(['filesData', 'headClient', 'dataFailures'], {
          GL: Map({}),
          TB: Map({}),
          PL: Map({}),
          BS: Map({})
        })
        .set('isDatasetGroupLoading', false);
    }
    case 'CREATE_HEADCLIENT_DATASET_FULFILLED': {
      return state
        .setIn(['filesData', 'headClient', 'isGettingDataset'], true)
        .updateIn(['filesData', 'headClient', 'datasetId'], () => payload)
        .set('isDatasetLoading', false);
    }
    case 'SAVE_HEADCLIENT_FILE_FULFILLED': {
      return payload.contentFormatType && payload.fileId && payload.fileInfo.fileName
        ? state
            .updateIn(
              ['filesData', 'headClient', payload.contentFormatType, payload.fileId],
              () => payload.fileInfo
            )
            .deleteIn([
              'filesData',
              'headClient',
              'dataFailures',
              payload.contentFormatType,
              payload.fileInfo.fileName
            ])
            .set('isFileLoading', false)
            .updateIn(
              ['filesData', 'headClient', payload.contentFormatType, 'isLoading'],
              loading => loading - 1
            )
        : state;
    }
    case 'GET_SUBSIDIARIES_FULFILLED': {
      return state.setIn(
        ['filesData', 'subsidiaries'],
        OrderedMap(payload.map(el => [el.id, Map({ ...el })]))
      );
    }
    case 'CREATE_SUBSIDIARY_DATASET_REJECTED': {
      if (payload.response && payload.response.config && payload.response.config.data) {
        const data = JSON.parse(payload.response.config.data);
        return state.setIn(['filesData', 'subsidiaries', data.clientId, 'datasetStatus'], 'fail');
      }
      return state;
    }
    case 'CREATE_SUBSIDIARY_DATASET_FULFILLED': {
      return payload.clientId
        ? state
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'isGettingDataset'], true)
            .updateIn(
              ['filesData', 'subsidiaries', payload.clientId, 'datasetId'],
              () => payload.datasetId
            )
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'clientId'], payload.clientId)
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'datasetStatus'], 1)
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'GL'], Map())
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'TB'], Map())
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'PL'], Map())
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'BS'], Map())
            .setIn(['filesData', 'subsidiaries', payload.clientId, 'dataFailures'], Map())
            .set('isDatasetLoading', false)
        : state;
    }
    case 'ADD_SUBSIDIARY': {
      return payload.id
        ? state
            .updateIn(['filesData', 'subsidiaries', payload.id], list =>
              list ? list.concat(Map(payload)) : Map(payload)
            )
            .updateIn(['filesData', 'subsidiaries', 'addedSubsidiaries', payload.id], list =>
              list ? list.concat(Map(payload)) : Map(payload)
            )
        : state;
    }
    case 'SAVE_SUBSIDIARY_FILE_FULFILLED': {
      return payload.clientId &&
        payload.contentFormatType &&
        payload.fileId &&
        payload.fileInfo.fileName
        ? state
            .updateIn(
              [
                'filesData',
                'subsidiaries',
                payload.clientId,
                payload.contentFormatType,
                payload.fileId
              ],
              () => payload.fileInfo
            )
            .set('isFileLoading', false)
            .deleteIn([
              'filesData',
              'subsidiaries',
              payload.clientId,
              'dataFailures',
              payload.contentFormatType,
              payload.fileInfo.fileName
            ])
            .updateIn(
              [
                'filesData',
                'subsidiaries',
                payload.clientId,
                payload.contentFormatType,
                'isLoading'
              ],
              loading => loading - 1
            )
        : state;
    }
    case 'REMOVE_HEADCLIENT_FILE_FULFILLED': {
      return payload.contentFormatType && payload.file.id
        ? state.deleteIn(['filesData', 'headClient', payload.contentFormatType, payload.file.id])
        : state;
    }

    case 'REMOVE_SUBSIDIARY_FULFILLED': {
      return payload.clientId
        ? state
            .deleteIn(['filesData', 'subsidiaries', payload.clientId])
            .deleteIn(['filesData', 'subsidiaries', 'addedSubsidiaries', payload.clientId])
        : state;
    }
    case 'REMOVE_SUBSIDIARY_FILE_FULFILLED': {
      return payload.clientId && payload.contentFormatType && payload.file.id
        ? state.deleteIn([
            'filesData',
            'subsidiaries',
            payload.clientId,
            payload.contentFormatType,
            payload.file.id
          ])
        : state;
    }
    case 'GET_DATASET_FULFILLED': {
      if (payload.isHeadClient) {
        return state
          .setIn(['filesData', 'headClient', 'isGettingDataset'], false)
          .updateIn(['filesData', 'headClient', 'datasetStatus'], () => payload.status)
          .updateIn(['filesData', 'files'], list =>
            list ? list.concat(payload.files) : payload.files
          );
      }
      return state
        .setIn(['filesData', 'subsidiaries', payload.clientId, 'isGettingDataset'], false)
        .updateIn(
          ['filesData', 'subsidiaries', payload.clientId, 'datasetStatus'],
          () => payload.status
        )
        .updateIn(['filesData', 'files'], list =>
          list ? list.concat(payload.files) : payload.files
        );
    }
    case 'GET_DATASET_REJECTED': {
      if (payload.response && payload.response.config && payload.response.config.params) {
        const { params } = payload.response.config;
        if (params.isHeadClient) {
          return state.setIn(['filesData', 'headClient', 'isGettingDataset'], false);
        }
        return state.setIn(
          ['filesData', 'subsidiaries', params.clientId, 'isGettingDataset'],
          false
        );
      }
      return state;
    }
    case 'RESET_GETTING_DATASET': {
      if (payload.isHeadClient) {
        return state.setIn(['filesData', 'headClient', 'isGettingDataset'], true);
      }
      return payload.clientId
        ? state.setIn(['filesData', 'subsidiaries', payload.clientId, 'isGettingDataset'], true)
        : state;
    }
    case 'CLEAR_FILES_LIST': {
      return state.setIn(['filesData', 'files'], []);
    }
    case 'CLEAN_GENERAL_LEDGER_DUCK': {
      return defaultState();
    }
    case 'TURN_ON_LOADING': {
      const loadingPath = ['filesData', ...payload, 'isLoading'];
      return loadingPath ? state.updateIn(loadingPath, list => (list ? list + 1 : 1)) : state;
    }

    default:
      return state;
  }
}
