/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LanguageIcon from '@material-ui/icons/Language';
import Button from '../../../../common/components/Button';
import { SimpleSelect } from '../../../../common/components/selectors';
import { updateLanguage } from '../../../duck/TaxReturnPage';
import { getLanguageList } from '../../../duck/HomePage';
import { type Language } from '../../../types';

interface StateProps {
  selectedLanguage: Language;
  languageList: Array<Language>;
}

interface DispatchProps {
  setSelectedLanguage: () => Promise<void>;
  getLanguageList: () => Promise<void>;
}

type Props = StateProps & DispatchProps;
const LanguageSelect = ({ selectedLanguage, languageList, ...dispatchProps }: Props) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  useEffect(() => {
    dispatchProps.getLanguageList();
  }, []);
  return !isDropDownOpen ? (
    <Button data-cy="sidebar-language-button" solid primary onClick={() => setIsDropDownOpen(true)}>
      <LanguageIcon /> {selectedLanguage.name}
    </Button>
  ) : (
    <SimpleSelect
      name="language"
      optionList={languageList.map(y => ({
        value: y,
        label: y.name
      }))}
      onChange={language => {
        dispatchProps.setSelectedLanguage(language.value);
        setIsDropDownOpen(false);
      }}
      value={selectedLanguage ? { label: selectedLanguage.name, value: selectedLanguage } : null}
    />
  );
};

const mapStateToProps = state => ({
  selectedLanguage: state.veromylly.taxReturnPage.taxReturn.language,
  languageList: state.veromylly.homePage.languageList
});

const dispatchProps = {
  setSelectedLanguage: updateLanguage,
  getLanguageList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(LanguageSelect);
