// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';

import { type TaxReturn, taxReturnStates, type UserRole, userRoles } from '../../../../types';
import Button from '../../../../../common/components/Button';

interface StateProps {
  taxReturn: TaxReturn;
  userRole: UserRole;
  unsavedChanges: boolean;
}

interface OwnProps {
  onClick: Function;
}

type Props = StateProps & OwnProps;

const Reject = ({ taxReturn, userRole, unsavedChanges, onClick }: Props) => (
  <Button
    data-cy="sidebar-review-button"
    solid
    primary
    disabled={
      userRole === userRoles.PREPARER ||
      unsavedChanges ||
      taxReturn.state.name === taxReturnStates.REJECTED
    }
    onClick={onClick}
    title={
      unsavedChanges
        ? `All changes must be saved before proceeding`
        : `Request changes from the preparer`
    }
  >
    <Close />
    {taxReturn.state.name === taxReturnStates.REJECTED ? 'Rejected' : 'Reject'}
  </Button>
);

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn,
  userRole: state.veromylly.taxReturnPage.userRole,
  unsavedChanges: state.veromylly.taxReturnPage.unsavedChanges
});

export default connect(
  mapStateToProps,
  {}
)(Reject);
