// @flow
import React from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import { type AccountMapper } from '../../../types/accountMapping';
import Button from '../../../../common/components/Button';

const ButtonContainer = styled.div`
  padding: 0.5em;
  display: flex;
  border-color: ${props => props.theme.border.white};
  justify-content: flex-end;
  button {
    margin: ${props => props.theme.space.stretch.m};
  }
  a {
    margin: ${props => props.theme.space.stretch.m};
  }
`;
type Props = {
  datasetGroupId: string,
  accountMappingList: OrderedMap<AccountMapper>,
  saveAccountMappingList: Function,
  getAccountMappingList: Function,
  toggleMessage: Function
};
const CustomButtonContainer = ({
  datasetGroupId,
  accountMappingList,
  saveAccountMappingList,
  getAccountMappingList,
  toggleMessage
}: Props) => {
  const handleSave = async () => {
    await saveAccountMappingList(
      datasetGroupId,
      accountMappingList
        .valueSeq()
        .toArray()
        .map(accountMapping => ({
          fsliMappingId: accountMapping.fsliMappingId,
          fsli: accountMapping.fsli,
          customFSLI: accountMapping.customFSLI
        }))
    );
    toggleMessage(true);
    await getAccountMappingList(datasetGroupId);
  };
  return (
    <ButtonContainer>
      <Button data-cy="mapping-save" primary solid rounded isLarge onClick={handleSave}>
        Save
      </Button>
    </ButtonContainer>
  );
};

export default CustomButtonContainer;
