import { connect } from 'react-redux';
import AddSubsidiary from './AddSubsidiary';
import { addSubsidiary, createSubsidiaryDataset } from '../../../../../duck/dataset';

export default connect(
  state => ({
    subsidiaries: state.dataset.filesData.subsidiaries,
    client: state.dataset.filesData.headClient,
    contentFormats: state.accountSystem.selectedAccountSystem.data.contentFormats,
    startDate: state.dataset.get('startDate'),
    endDate: state.dataset.get('endDate'),
    datasetGroupId: state.dataset.get('filesData')
      ? state.dataset.get('filesData').datasetGroupId
      : ''
  }),

  { addSubsidiary, createSubsidiaryDataset }
)(AddSubsidiary);
