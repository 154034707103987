// @flow
import React from 'react';
import { connect } from 'react-redux';
import { addClient, removeClient, toggleClientSelection } from '../../duck/filters';
import { getOrders, toggleSelectAll } from '../../duck/orders';
import ClientsList from './ClientsList';

const Container = (props: any) => {
  const { selectedLeader, clientsList } = props;
  const isLeaderType = selectedLeader && selectedLeader.size > 0;
  const clients = clientsList.valueSeq().toList();

  /*
   * Skip step if not searched by leader
   */

  let selectedItems = [];
  if (isLeaderType && clients) {
    selectedItems = clients
      .filter(client => client.isSelected)
      .map(client =>
        client.subsidiaries.length > 0
          ? `${client.name} + ${client.subsidiaries.length} subsidiaries`
          : client.name
      )
      .join('\n');
  }

  const stepSummaryItems = [
    {
      title: 'Client(s) Selected',
      text: selectedItems
    }
  ];

  return <ClientsList clients={clients} stepSummaryItems={stepSummaryItems} {...props} />;
};

const ClientSelectionContainer = connect(
  state => ({
    clientsList: state.serviceOrder.filters.selectedClients,
    selectedLeader: state.serviceOrder.filters.selectedLeader
  }),
  { addClient, removeClient, toggleClientSelection, getOrders, toggleSelectAll }
)(Container);

export default ClientSelectionContainer;
