// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../../common/components/TextLabel';
import SearchBoxContainer from '../../../../common/components/SearchBoxContainer';
import { SimpleSelect } from '../../../../common/components/selectors';
import Line from '../../../../common/components/Line';
import TitleLabelWithTooltip from '../../../../common/components/tooltip/TitleLabelWithTooltip';
import InfoIconTooltip from '../../../../common/components/tooltip/InfoIconTooltip';
import StyledCheckbox from '../../../../common/components/checkbox/StyledCheckbox';
import TextInput, { TextArea } from '../../../../common/components/TextInput';
import ErrorMessageLabel from '../../../../common/components/ErrorMessageLabel';
import type { GetContactPerson } from '../../../../common/types/client';
import type { KycRoundPut } from '../../../../common/types/kyc';
import StyledContentContainer from '../../../../common/components/StyledContentContainer';
import {
  allGroundsForUbo,
  allLanguages,
  mapCodeToGroundForUbo,
  mapLanguageCodeToSimpleDropdownItem
} from '../../../utils/helper';
import PersonSelectionGrid from './PersonSelectionGrid';

type Props = {
  isLoadingModal: boolean,
  groundForService: string,
  groundForUbo: number,
  ubos: Array<GetContactPerson>,
  representatives: Array<GetContactPerson>,
  updateUboStatus: Function,
  updateRepresentativeStatus: Function,
  deselectAllUbos: Function,
  changeGroundForUbo: Function,
  changeGroundForService: Function,
  putContactPersons: Function,
  postContactPerson: Function,
  clientId: number,
  changeContactInfoName: Function,
  changeContactInfoEmail: Function,
  changeContactInfoLanguage: Function,
  kycRound: KycRoundPut,
  didReceiveGroundForService: boolean,
  showContactInformation: boolean,
  setUboChanged: Function,
  setRiskChanged: Function,
  setAmlChanged: Function,
  setAdditionalInfoMessage: Function,
  validationErrorMessages: { [string]: { message: string } }
};

const InfoLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: ${props => props.theme.space.squish.s};
  background-color: ${props => props.theme.color.ui.light};
  font-weight: bold;
`;

const SimpleSelectWrapper = styled.div`
  & > div {
    width: 60%;
    display: inline-block;
  }
  & > input {
    width: 60%;
  }
`;

const StyledSearchBoxContainer = styled(SearchBoxContainer)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.space.inline.m};
`;

const TextAreaWrapper = styled.div`
  padding: ${props => props.theme.space.square.s};

  > span {
    padding-left: ${props => props.theme.space.single.s};
  }

  > textarea {
    height: 150px;
    &:nth-child(2) {
      margin-top: ${props => props.theme.space.single.s};
    }
  }
`;

const InfoLabelWithTooltip = ({ text, tooltip }: { text: string, tooltip: { __html: string } }) => (
  <InfoLabelWrapper>
    <TitleLabelWithTooltip text={text} tooltip={tooltip} />
  </InfoLabelWrapper>
);

const UBODescription = styled(TextLabel)`
  color: ${props => props.theme.color.ui.error};
`;

export default function ContentArea({
  clientId,
  isLoadingModal,
  groundForService,
  groundForUbo,
  ubos,
  representatives,
  updateUboStatus,
  updateRepresentativeStatus,
  deselectAllUbos,
  changeGroundForUbo,
  changeGroundForService,
  putContactPersons,
  postContactPerson,
  changeContactInfoName,
  changeContactInfoEmail,
  changeContactInfoLanguage,
  kycRound,
  didReceiveGroundForService,
  showContactInformation,
  setUboChanged,
  setRiskChanged,
  setAmlChanged,
  setAdditionalInfoMessage,
  validationErrorMessages
}: Props) {
  const { name, email, language } = kycRound.clientContactInformation;
  const { isUboChanged, isAmlChanged, isRiskChanged, message } = kycRound.additionalInformation;

  const {
    groundForServiceError,
    representativesError,
    groundForUboError,
    ubosError,
    nameError,
    emailError,
    languageError,
    messageError
  } = validationErrorMessages;

  const updateMessageField = (uboChanged, amlChanged, riskChanged) => {
    if (!uboChanged && !amlChanged && !riskChanged) setAdditionalInfoMessage('');
  };

  return (
    <StyledContentContainer>
      <div data-cy="opportunities-content-area-ground-for-service">
        <InfoLabelWithTooltip
          text="Ground for Service"
          tooltip={{
            __html:
              '<p>Please write a description of grounds for the use of PwC’s services and products provided by PwC. E.g. Tax return, Due Diligence etc.</p>'
          }}
        />
        <StyledSearchBoxContainer>
          <SimpleSelectWrapper>
            <TextInput
              disabled={didReceiveGroundForService}
              value={groundForService}
              onChange={e => changeGroundForService(e.target.value)}
            />
            {groundForServiceError && (
              <ErrorMessageLabel>{groundForServiceError.message}</ErrorMessageLabel>
            )}
          </SimpleSelectWrapper>
        </StyledSearchBoxContainer>
      </div>

      <div data-cy="opportunities-content-area-representative">
        <InfoLabelWithTooltip
          text="Client Representative"
          tooltip={{
            __html:
              '<p>Click the name of the person(s) to select or unselect them as the client representatives for this Opportunity.</p> <p>If you cannot find your representative listed you can add one by clicking “Add new representative”. You can add one representative at a time.</p>'
          }}
        />
        <TextLabel>
          Select / unselect / add client representative for this opportunity
          {representativesError && (
            <ErrorMessageLabel>
              {representativesError.message}. Name of the person(s) you have chosen as client
              representative(s) will turn red.
            </ErrorMessageLabel>
          )}
        </TextLabel>
        {representatives.some(person => person.isFromAsently) && (
          <UBODescription bold>
            Client Representative(s) has been pre-selected. Information of the client
            representative(s) selected is brought automatically from an electrically signed
            engagement letter when electronic ID is used.
          </UBODescription>
        )}
        <PersonSelectionGrid
          persons={representatives.filter(
            r => !(!r.isFromAsently && representatives.filter(r1 => r1.id === r.id).length > 1)
          )}
          personTypeText="client representative"
          personTypeTextShort="representative"
          isUbo={false}
          updatePersonStatus={updateRepresentativeStatus}
          putContactPersons={putContactPersons}
          postContactPerson={postContactPerson}
          isLoadingModal={isLoadingModal}
          clientId={clientId}
        />
      </div>

      <div data-cy="opportunities-content-area-ground-for-ubo">
        <InfoLabelWrapper>Ultimate Beneficial Owners (UBO)</InfoLabelWrapper>
        <StyledSearchBoxContainer>
          <TextLabel>
            <TitleLabelWithTooltip
              text="Ground for UBO"
              tooltip={{
                __html:
                  '<p>Ground for UBO is shown as identified earlier. If you are aware that this information is not correct, please change the Ground for UBO and if needed/known update the identified UBOs in the below field.</p> <p>Note Exempt is used for clients who are deemed to be listed in a regulated market  and for private individual clients.</p>'
              }}
            />
          </TextLabel>
          <SimpleSelectWrapper>
            <SimpleSelect
              value={mapCodeToGroundForUbo(groundForUbo)}
              optionList={allGroundsForUbo}
              onChange={e => {
                if (e.value !== groundForUbo) {
                  changeGroundForUbo(e.value);
                  deselectAllUbos();
                }
              }}
              placeholder="Select ground for UBO"
            />
            {groundForUboError && (
              <ErrorMessageLabel>{groundForUboError.message}</ErrorMessageLabel>
            )}
          </SimpleSelectWrapper>
        </StyledSearchBoxContainer>
        {groundForUbo !== 7 && (
          <div data-cy="opportunities-content-area-ubo">
            <Line />
            <TextLabel>
              <TitleLabelWithTooltip
                text="Current identified UBOs"
                tooltip={{
                  __html:
                    '<p>Currently identified UBOs are shown in red. If the UBO information is incorrect, please indicate removal of UBOs by unselecting the name and/or adding the missing UBOs by clicking “Add new UBO”. You can add multiple UBOs at a time.</p>'
                }}
              />
              {ubosError && <ErrorMessageLabel>{ubosError.message}</ErrorMessageLabel>}
            </TextLabel>
            {(groundForUbo === 3 || groundForUbo === 6) && (
              <UBODescription bold>
                If the ground for UBO is CEO or Board of Directors, and the client is a Finnish
                limited liability company, limited liability housing company, or mutual real estate
                company; persons (UBOs) information have been updated automatically from
                Kauppalehti. Despite the functionality, always check that the ground for UBO is
                correct.
              </UBODescription>
            )}
            <TextLabel>
              Select / unselect / add UBO. Unselected persons will be marked for removal.
              {ubosError && (
                <ErrorMessageLabel>
                  Name of the person(s) you have chosen as client UBO(s) will turn red.
                </ErrorMessageLabel>
              )}
            </TextLabel>
            <PersonSelectionGrid
              persons={ubos}
              personTypeText="UBO"
              personTypeTextShort="UBO"
              isUbo
              updatePersonStatus={updateUboStatus}
              putContactPersons={putContactPersons}
              postContactPerson={postContactPerson}
              isLoadingModal={isLoadingModal}
              clientId={clientId}
            />
          </div>
        )}
        <div data-cy="opportunities-content-area-checkbox-ubo">
          <StyledCheckbox
            checked={isUboChanged}
            onChange={(event, checked) => {
              setUboChanged(checked);
              updateMessageField(checked, isRiskChanged, isAmlChanged);
            }}
          />
          <span>I want to report additional changes in the UBO information</span>
          <InfoIconTooltip
            tooltip={{
              __html:
                '<p>Tick this box if you wish to inform other changes regarding the UBO information.</p>'
            }}
          />
        </div>
      </div>

      {showContactInformation && (
        <div data-cy="opportunities-content-area-contact-information">
          <InfoLabelWithTooltip
            text="Client Contact Information"
            tooltip={{
              __html:
                '<p>Fill in the contact information for client contact for Client Desk to be able to contact the client to request more information.</p> <p>In case of sensitive Opportunities the contact person can be a member of the client team who will communicate with the client.</p>'
            }}
          />
          <TextLabel stack>
            <p>Client Desk sends KYC information enquiry to this person, as needed.</p>
            <p>
              In case of sensitive services, the contact person named here can be a client team
              member and then the team is responsible for client correspondence.
            </p>
          </TextLabel>
          <StyledSearchBoxContainer>
            <TextLabel bold>Client Contact Name</TextLabel>
            <SimpleSelectWrapper>
              <TextInput value={name} onChange={e => changeContactInfoName(e.target.value)} />
              {nameError && <ErrorMessageLabel>{nameError.message}</ErrorMessageLabel>}
            </SimpleSelectWrapper>
          </StyledSearchBoxContainer>
          <StyledSearchBoxContainer>
            <TextLabel bold>Client Contact Email</TextLabel>
            <SimpleSelectWrapper>
              <TextInput value={email} onChange={e => changeContactInfoEmail(e.target.value)} />
              {emailError && <ErrorMessageLabel>{emailError.message}</ErrorMessageLabel>}
            </SimpleSelectWrapper>
          </StyledSearchBoxContainer>
          <StyledSearchBoxContainer>
            <TextLabel bold>Language</TextLabel>
            <SimpleSelectWrapper>
              <SimpleSelect
                value={language ? mapLanguageCodeToSimpleDropdownItem(language) : null}
                optionList={allLanguages}
                onChange={e => changeContactInfoLanguage(e.value)}
              />
              {languageError && <ErrorMessageLabel>{languageError.message}</ErrorMessageLabel>}
            </SimpleSelectWrapper>
          </StyledSearchBoxContainer>
        </div>
      )}

      <div>
        <InfoLabelWrapper>Other</InfoLabelWrapper>
        <div data-cy="opportunities-content-area-checkbox-risk" style={{ marginTop: '4px' }}>
          <StyledCheckbox
            checked={isRiskChanged}
            onChange={(event, checked) => {
              setRiskChanged(checked);
              updateMessageField(isUboChanged, checked, isAmlChanged);
            }}
          />
          <span>
            I want to report additional AML related risks such as unusual organisation structure or
            business model
          </span>
          <InfoIconTooltip
            tooltip={{
              __html:
                '<p>Tick the box if there are additional AML risks related to the client such as unusual transactions, organisation structure, business model or unconventional reason for using PwC’s services.</p>'
            }}
          />
        </div>
        <div data-cy="opportunities-content-area-checkbox-aml">
          <StyledCheckbox
            checked={isAmlChanged}
            onChange={(event, checked) => {
              setAmlChanged(checked);
              updateMessageField(isUboChanged, isRiskChanged, checked);
            }}
          />
          <span>I want to report additional information regarding this AML check</span>
          <InfoIconTooltip
            tooltip={{
              __html:
                '<p>To report additional information please tick the box and clarify in more detail in your own words.</p>'
            }}
          />
        </div>
        {(isUboChanged || isAmlChanged || isRiskChanged) && (
          <TextAreaWrapper data-cy="opportunities-content-area-additional-information">
            {messageError && <ErrorMessageLabel>{messageError.message}</ErrorMessageLabel>}
            <TextArea
              value={message}
              onChange={e => setAdditionalInfoMessage(e.target.value)}
              placeholder="Please specify..."
            />
          </TextAreaWrapper>
        )}
      </div>
    </StyledContentContainer>
  );
}
