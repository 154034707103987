// @flow
import React from 'react';
import styled from 'styled-components';
import RowLeft, { type RowLeftProps } from './RowLeft';
import RowRight, { type RowRightProps } from './RowRight';
import { FlexDisplay } from './StyledComponent';

const Container = styled(FlexDisplay)`
  margin-bottom: 1em;
`;

const Row = ({
  clientName,
  subsidiaryCount,
  job,
  createdDate,
  startDate,
  endDate,
  fileCount,
  haveFailedFiles,
  haveAllDatasetsFailed,
  serviceOrderId,
  groupDatasetId,
  isQlikSenseAvailable,
  isMappingAvailable,
  goToAccountMapping,
  goToGeneralLedger,
  files,
  deleteDatasetGroup,
  createServiceOrder,
  isDatasetLoading
}: RowLeftProps & RowRightProps) => (
  <Container
    data-cy={`activity-log-${clientName
        ? clientName
          .toLowerCase()
          .split(' ')
          .join('-')
        : ''
      }`}
  >
    <RowLeft
      clientName={clientName}
      subsidiaryCount={subsidiaryCount}
      job={job}
      groupDatasetId={groupDatasetId}
      createdDate={createdDate}
      startDate={startDate}
      endDate={endDate}
    />
    <RowRight
      clientName={clientName}
      fileCount={fileCount}
      haveFailedFiles={haveFailedFiles}
      haveAllDatasetsFailed={haveAllDatasetsFailed}
      serviceOrderId={serviceOrderId}
      isQlikSenseAvailable={isQlikSenseAvailable}
      isMappingAvailable={isMappingAvailable}
      goToAccountMapping={goToAccountMapping}
      goToGeneralLedger={goToGeneralLedger}
      files={files}
      isDatasetLoading={isDatasetLoading}
      groupDatasetId={groupDatasetId}
      deleteDatasetGroup={deleteDatasetGroup}
      createServiceOrder={createServiceOrder}
    />
  </Container>
);

export default Row;