// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeneralLedger from './GeneralLedger';
import { triggerInstruction, getOnboardingInfo, cleanGeneralLedgerDuck } from '../duck/general';

const GeneralLedgerContainer = (props: any) => {
  const { history } = props;
  const goToActivityLog = () => {
    history.push('/general-ledger/activity-log');
  };

  return <GeneralLedger goToActivityLog={goToActivityLog} {...props} />;
};

export default connect(
  state => ({
    isOnboardingFinished: state.glGeneral.get('isOnboardingFinished'),
    isGeneralLoading: state.glGeneral.get('isGeneralLoading')
  }),
  {
    triggerInstruction,
    getOnboardingInfo,
    cleanGeneralLedgerDuck
  }
)(GeneralLedgerContainer);
