// @flow
import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';
import styled, { ThemeProvider } from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import PageHeader from '../../../common/components/canvas/PageHeader';
import TaxReturnInfo from './components/TaxReturnInfo';
import {
  type TaxReturn,
  type TaxReturnData,
  type UserRole,
  type Document,
  type DocumentTemplate,
  userRoles,
  taxReturnSteps
} from '../../types';
import SideBar from '../../../common/components/SideBar';
import { rose } from '../../../common/theme/theme';
import SideBarContent from './components/SideBarContent';
import OperatingWindow from './OperatingWindow';
import StatusBar from '../../../common/components/StatusBar';

const Container = styled.div`
  background-color: white;
  padding: 48px;
`;

const stepIndex = {
  [taxReturnSteps.PREPARE]: 0,
  [taxReturnSteps.REVIEW]: 1,
  [taxReturnSteps.PACKAGE]: 2
};

const getStepList = (userRole: string) => {
  if (userRole === userRoles.PREPARER) {
    return [
      { id: taxReturnSteps.PREPARE, name: 'Prepare tax return' },
      { id: taxReturnSteps.REVIEW, name: 'Send for review' },
      { id: taxReturnSteps.PACKAGE, name: 'Package' }
    ];
  }
  if (userRole === userRoles.REVIEWER) {
    return [
      { id: taxReturnSteps.PREPARE, name: 'Wait for preparation' },
      { id: taxReturnSteps.REVIEW, name: 'Review tax return' },
      { id: taxReturnSteps.PACKAGE, name: 'Package' }
    ];
  }
  return [];
};

const isDraftState = (stateName: string) => stateName.toLowerCase().includes('draft');

const isReviewState = (stateName: string) => stateName.toLowerCase().includes('review');

const isPackageState = (stateName: string) => stateName.toLowerCase().includes('approved');

const isCompleteState = (stateName: string) => stateName.toLowerCase().includes('delivered');

const getActiveStep = (stateName: string) => {
  if (isCompleteState(stateName)) {
    return taxReturnSteps.PACKAGE;
  }
  if (isPackageState(stateName)) {
    return taxReturnSteps.PACKAGE;
  }
  if (isDraftState(stateName)) {
    return taxReturnSteps.PREPARE;
  }
  if (isReviewState(stateName)) {
    return taxReturnSteps.REVIEW;
  }
  return taxReturnSteps.PREPARE;
};

const getDisabled = (stateName: string) => {
  if (isDraftState(stateName)) {
    return { [taxReturnSteps.REVIEW]: true, [taxReturnSteps.PACKAGE]: true };
  }
  if (isReviewState(stateName)) {
    return { [taxReturnSteps.PACKAGE]: true };
  }
  return {};
};

const getCompleted = (stateName: string) => {
  if (isDraftState(stateName)) {
    return {};
  }
  if (isReviewState(stateName)) {
    return { [taxReturnSteps.PREPARE]: true };
  }
  if (isPackageState(stateName)) {
    return {
      [taxReturnSteps.PREPARE]: true,
      [taxReturnSteps.REVIEW]: true
    };
  }
  if (isCompleteState(stateName)) {
    return {
      [taxReturnSteps.PREPARE]: true,
      [taxReturnSteps.REVIEW]: true,
      [taxReturnSteps.PACKAGE]: true
    };
  }
  return {};
};
type TaxReturnRoute = {
  params: { taxReturnId: number }
};

type Props = {
  history: any,
  match: TaxReturnRoute,
  taxReturn?: TaxReturn,
  taxReturnData?: TaxReturnData,
  unsavedChanges?: boolean,
  userRole?: UserRole,
  documents?: ImmutableList<Document>,
  documentTemplates?: ImmutableList<DocumentTemplate>,
  fetchTaxReturn: Function,
  updateFormFieldValue: Function,
  addComment: Function,
  previewTaxForm: Function,
  previewDocument: Function,
  updateReviewer: Function,
  saveTaxReturn: Function,
  sendForReview: Function,
  approveTaxReturn: Function,
  rejectTaxReturn: Function,
  fetchDocuments: Function,
  fetchDocumentTemplates: Function,
  fetchAssignee: Function,
  previewPackage: Function,
  completeTaxReturn: Function,
  loading: any
};

const preventNavigation = event => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = '';
};

const TaxReturnPage = ({
  history,
  match,
  taxReturn,
  taxReturnData,
  unsavedChanges,
  userRole,
  documents,
  documentTemplates,
  fetchTaxReturn,
  updateFormFieldValue,
  addComment,
  previewTaxForm,
  previewDocument,
  updateReviewer,
  saveTaxReturn,
  sendForReview,
  approveTaxReturn,
  rejectTaxReturn,
  fetchDocuments,
  fetchDocumentTemplates,
  fetchAssignee,
  previewPackage,
  completeTaxReturn,
  loading
}: Props) => {
  const { taxReturnId } = match.params;
  const [activeStep, setActiveStep] = useState(taxReturnSteps.PREPARE);
  const [completed, setCompleted] = useState<{ [key: string]: boolean }>({});
  const [disabled, setDisabled] = useState<{ [key: string]: boolean }>({});
  const [stepList, setStepList] = useState([]);
  const handleStep = step => () => setActiveStep(step);

  const handleFetchTaxReturn = async () => {
    try {
      await fetchTaxReturn(taxReturnId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchTaxReturn();
    fetchDocumentTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unsavedChanges) {
      window.addEventListener('beforeunload', preventNavigation);
    }
  }, [unsavedChanges]);

  useEffect(() => {
    if (userRole) {
      setStepList(getStepList(userRole));
    }
    if (taxReturn && taxReturn.state && taxReturn.state.name) {
      setActiveStep(getActiveStep(taxReturn.state.name));
      setDisabled(getDisabled(taxReturn.state.name));
      setCompleted(getCompleted(taxReturn.state.name));
    }
  }, [userRole, taxReturn]);

  useEffect(() => {
    if (taxReturn && taxReturn.client && taxReturn.assignee && taxReturn.year) {
      fetchDocuments(taxReturn.client.id, taxReturn.assignee.id, taxReturn.year.id);
    }

    if (taxReturn && taxReturn.assignee) {
      fetchAssignee(taxReturn.assignee.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxReturn]);

  return (
    <ThemeProvider theme={rose}>
      <>
        <Prompt
          message="You have not saved the changes you made to the tax return."
          when={unsavedChanges}
        />
        <StatusBar loading={loading} />
        <PageHeader title={`Veromylly ${taxReturn ? `(${taxReturn.state.name})` : ''}`} />
        {taxReturn && (
          <>
            <SideBar
              sidebarContent={
                <SideBarContent
                  handleSave={async () => {
                    await saveTaxReturn(taxReturn, taxReturnData);
                    window.removeEventListener('beforeunload', preventNavigation);
                    return Promise.resolve();
                  }}
                />
              }
            />
            <TaxReturnInfo
              handleSave={async () => {
                await saveTaxReturn(taxReturn, taxReturnData);
                window.removeEventListener('beforeunload', preventNavigation);
                return Promise.resolve();
              }}
            />
            <Stepper nonLinear activeStep={stepIndex[activeStep]}>
              {stepList.map(step => (
                <Step key={step.id}>
                  <StepButton
                    data-cy={`step-${step.id}`}
                    onClick={handleStep(step.id)}
                    completed={completed[step.id]}
                    disabled={disabled[step.id]}
                  >
                    {step.name}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Container>
              <OperatingWindow
                activeStep={activeStep}
                addComment={(comment, ...path) =>
                  addComment(
                    {
                      author:
                        userRole === userRoles.REVIEWER && taxReturn.reviewer
                          ? taxReturn.reviewer.name
                          : taxReturn.preparer.name,
                      ...comment
                    },
                    ...path
                  )
                }
                // previewPackage={cardOrder => previewPackage(taxReturn.id, cardOrder)} // FIXME
              />
            </Container>
          </>
        )}
      </>
    </ThemeProvider>
  );
};

export default TaxReturnPage;
