// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Add, Flag } from '@material-ui/icons';
import { List } from 'immutable';

import { type TaxReturn, type Country } from '../../../types';
import Button from '../../../../common/components/Button';
import SearchSelection from '../../../../common/components/selectors/SearchSelection';
import { callOnKeypress, dataMappingToDropdown } from '../../../../common/utils/helper';
import { type DropdownItem } from '../../../../common/types/list';
import { updateCountry } from '../../../duck/TaxReturnPage';
import { getCountryList } from '../../../duck/HomePage';

interface StateProps {
  taxReturn: TaxReturn;
  countryList: Array<Country>;
}

interface DispatchProps {
  getCountryList: () => Promise<void>;
  updateCountry: (newCountry: Country) => void;
}

type Props = StateProps & DispatchProps;

const AssignmentCountry = ({
  countryList,
  taxReturn: { country, id },
  ...dispatchProps
}: Props) => {
  const [isCountrySearchOpen, setIsCountrySearchOpen] = useState(false);

  useEffect(() => {
    const cancelSearch = (event: KeyboardEvent) =>
      callOnKeypress(event, 27, () => setIsCountrySearchOpen(false));
    document.addEventListener('keydown', cancelSearch, false);
    return () => {
      document.removeEventListener('keydown', cancelSearch, false);
    };
  }, []);

  useEffect(() => {
    if (countryList && !countryList.length) {
      dispatchProps.getCountryList();
    }
  }, [countryList, dispatchProps]);

  const fetchCountryList = (input: string): Promise<Array<DropdownItem<Country>>> => {
    if (input.length < 2) return Promise.resolve([]);
    try {
      const filtered = countryList.filter((item: Country) =>
        item.name.toLowerCase().includes(input.toLowerCase())
      );
      return Promise.resolve(dataMappingToDropdown(filtered));
    } catch (err) {
      return Promise.resolve([]);
    }
  };

  return isCountrySearchOpen ? (
    <SearchSelection
      type="country"
      saveToStore={({ data }) => dispatchProps.updateCountry(data)}
      deleteFromStore={undefined}
      noMinWidth
      placeholder="Assignment country"
      isClearable
      selectedList={List<Object>()}
      width="250px"
      fetchData={fetchCountryList}
      onChange={() => setIsCountrySearchOpen(false)}
      value={country ? country.name : ''}
    />
  ) : (
    <Button
      data-cy="sidebar-country-button"
      solid
      primary
      onClick={() => setIsCountrySearchOpen(true)}
    >
      {country ? (
        <>
          <Flag />
          {country.name}
        </>
      ) : (
        <>
          <Add />
          Set assignment country
        </>
      )}
    </Button>
  );
};

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn,
  countryList: state.veromylly.homePage.countryList
});

const dispatchProps: DispatchProps = {
  updateCountry,
  getCountryList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(AssignmentCountry);
