export default {
  signer: {
    get: () => `employee/signer`
  },
  onBoarding: {
    get: () => 'rf/onboarding'
  },
  auraDatabase: {
    get: () => 'aura-database'
  },
  client: {
    get: () => 'client'
  },
  audit: {
    get: () => 'rf/audit/client',
    getJobList: () => `rf/audit/job`
  },
  rollForward: {
    post: () => 'rf/roll-forward',
    put: () => 'rf/roll-forward',
    putAura: (rollforwardId, auraDbId) =>
      `rf/roll-forward/${rollforwardId}/aura-database/${auraDbId}`,
    getHistory: () => 'rf/roll-forward/history'
  }
};
