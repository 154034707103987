// @flow
import React from 'react';
import { SimpleSelect } from './selectors';
import { type SimpleDropdownItem } from '../types/list';

type Props = {
  selectedType: SimpleDropdownItem,
  onChange: Function,
  additionalValues: Array<SimpleDropdownItem>
};

export const searchTypeList: Array<SimpleDropdownItem> = [
  {
    value: 1,
    label: 'Leader / Manager'
  },
  {
    value: 2,
    label: 'Client'
  }
];

const LeaderOrClientDropdown = ({ selectedType, onChange, additionalValues }: Props) => (
  <SimpleSelect
    onChange={onChange}
    value={selectedType}
    optionList={[...searchTypeList, ...additionalValues]}
  />
);

LeaderOrClientDropdown.defaultProps = {
  additionalValues: []
};

export default LeaderOrClientDropdown;
