// @flow
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import services from '../services';

// Needs to be disabled because of the way state is modified in reducers. Under the hood it's
// still immutable.
/* eslint no-param-reassign: 0 */

type State = { loading: boolean, message: string };

const initialState: State = { loading: false, message: '' };

const importTLData = createAsyncThunk('talentlink/import', async () => {
  const response = await services.authorize();
  return response.payload;
});

const talentlinkSlice = createSlice({
  name: 'talentlink',
  initialState,
  reducers: {
    setLoading(state: State, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMessage(state: State, action: PayloadAction<string>) {
      state.message = action.payload;
    }
  },
  extraReducers: {
    [importTLData.fulfilled]: (state: State, action: PayloadAction<string>) => {
      state.loading = false;
      state.message = '';
      window.open(action.payload, '_blank').focus();
    },
    [importTLData.rejected]: (state: State, action: PayloadAction<string>) => {
      state.loading = false;
      state.message = action.payload;
    },
    [importTLData.pending]: (state: State, action: PayloadAction<string>) => {
      state.loading = true;
      state.message = 'Authorizing...';
    }
  }
});

export const importData = importTLData;
export const { setLoading, setMessage } = talentlinkSlice.actions;

export default talentlinkSlice.reducer;
