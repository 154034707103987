// @flow
import { Record, List } from 'immutable';
import { createAction } from 'redux-actions';
import { type TeamState as State } from '../types/duck';
import { type TeamList, type Team } from '../../common/types/team';
import { type DropdownItem } from '../../common/types/list';
import { type ActionType } from '../../common/types/redux';
import service from '../services/team';

const defaultState = Record({
  selectedTeamList: List([]),
  isTeamLoading: false,
  isSucess: false
});

export const fetchDefaultTeam = createAction('FETCH_DEFAULT_TEAM', jobId =>
  service.fetchDefaultTeam(jobId)
);
export const submitFinalTeamList = createAction(
  'SUBMIT_FINAL_TEAM_LIST',
  (datasetId: number, teamList: TeamList) => service.submitTeamList(datasetId, teamList)
);
export const cleanSuccessMsg = createAction('CLEAN_SUCESS_MSG');
export const removeTeamFromSelected = createAction('REMOVE_TEAM', (i: Team) => i.id);
export const saveToTeamList = createAction('SAVE_TO_TEAM_LIST', (i: Team & DropdownItem<any>) => ({
  name: i.label,
  id: i.value,
  sourceSystem: i.sourceSystem
}));
export const putQueueStd = createAction('PUT_QUEUE_STD', (datasetGroupId: number) =>
  service.putQueueStd(datasetGroupId)
);

export const putQueueApp = createAction('PUT_QUEUE_APP', (datasetGroupId: number) =>
  service.putQueueApp(datasetGroupId)
);

export const putQueueAll = createAction('PUT_QUEUE_ALL', (datasetGroupId: number) =>
  service.putQueueAll(datasetGroupId)
);

export default function GeneralLedgerReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_DEFAULT_TEAM_FULFILLED': {
      if (!payload || state.get('selectedTeamList').some(team => team.id === payload.id))
        return state.set('isTeamLoading', false);
      return state
        .update('selectedTeamList', team => team.concat(payload))
        .set('isTeamLoading', false);
    }
    case 'CLEAN_SUCESS_MSG': {
      return state.set('isSucess', false);
    }
    case 'SUBMIT_FINAL_TEAM_LIST_FULFILLED': {
      return state.set('isTeamLoading', false).set('isSucess', true);
    }

    case 'PUT_QUEUE_ALL_REJECTED':
    case 'PUT_QUEUE_ALL_FULFILLED':
    case 'SUBMIT_FINAL_TEAM_LIST_REJECTED':
    case 'FETCH_DEFAULT_TEAM_REJECTED': {
      return state.set('isTeamLoading', false);
    }

    case 'PUT_QUEUE_ALL_PENDING':
    case 'SUBMIT_FINAL_TEAM_LIST_PENDING':
    case 'FETCH_DEFAULT_TEAM_PENDING': {
      return state.set('isTeamLoading', true);
    }

    case 'REMOVE_TEAM': {
      return state.update('selectedTeamList', list => list.filter(item => item.id !== payload));
    }
    case 'SAVE_TO_TEAM_LIST': {
      return state.update('selectedTeamList', list => list.push(payload));
    }
    case 'SAVE_SELECTED_JOB':
    case 'CLEAN_GENERAL_LEDGER_DUCK': {
      return defaultState();
    }

    default:
      return state;
  }
}
