import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 634px;
  margin: 8em auto;
  background: ${props => props.theme.color.background.white};
  padding: ${props => props.theme.space.square.xl};
  display: flex;
  flex-direction: column;
  > svg {
    align-self: center;
    font-size: 5em;
  }
  > h1 {
    text-align: center;
    font-size: 24px;
  }
  > p {
    text-align: center;
  }
`;

export default Wrapper;
