import React from 'react';
import Alink from '../../../common/components/Link';

export default () => (
  <div>
    <span>
      The new self-service GL data analysis solution supports several source system formats that
      have been tested with actual data and produces reliable Qlik Sense reports.
    </span>
    <br />
    <br />
    <span>
      As clients may have different versions of accounting software and varying settings it is
      possible that some files cannot be loaded automatically. In case loading validation fails you
      can either contact your nearest Data Specialist for assistance or submit your data to SDC for
      at the end of this process.
    </span>
    <br />
    <br />
    <span>
      We will keep improving the solution and add support for more source formats and variations
      over time.
    </span>
    <br />
    <br />
    <span>
      {' See the'}
      <Alink
        href="https://drive.google.com/drive/folders/1g932FDo3LisP2BL_BtIRB5O7ECdR8e53"
        target="_blank"
        rel="noopener noreferrer"
      >
        instructions
      </Alink>
      {' for more information on supported systems and updates to them.'}
    </span>
  </div>
);
