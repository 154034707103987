// @flow
import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { CalendarToday, Info } from '@material-ui/icons';
import PageHeader from '../../common/components/canvas/PageHeader';
import { orange } from '../../common/theme/theme';

const Container = styled(Paper)`
  padding: 24px;
`;

const TextWrapper = styled(Grid)`
  align-self: center;
  & p {
    font-size: 1rem;
  }
`;

const Divider = styled(Grid)`
  padding: 6px 0px;
`;

const Completed = () => (
  <ThemeProvider theme={orange}>
    <>
      <PageHeader title="Consent was given successfully!" />
      <Container elevation={0} square>
        <Grid container>
          <Grid item xs={1}>
            <CalendarToday fontSize="large" />
          </Grid>
          <TextWrapper item xs={11} align="center">
            <Typography variant="body1" align="left">
              Your TalentLink bookings will be visible in your Google Calendar under My calendars
              -&gt; TalentLink after the next integration run at 6:30 or 16:30. The calendar will
              only appear when you have upcoming bookings in TalentLink.
            </Typography>
            <br />
            <Typography variant="body1" align="left">
              The bookings are updated twice a day at 6:30 and 16:30. Past
              bookings will not get imported or updated
            </Typography>
          </TextWrapper>
          <Divider item xs={12} />
          <Grid xs={1}>
            <Info fontSize="large" />
          </Grid>
          <TextWrapper xs={11} align="center">
            <Typography variant="body1" align="left">
              For more instructions please go to TalentLink & Google Calendar integration{' '}
              <a href="https://pwceur.sharepoint.com/sites/FI-xLoS-Culture-of-Continuous-Development-FI/SitePages/TalentLink-%26-Google-calendar-integration.aspx">
                Point site
              </a>
            </Typography>
          </TextWrapper>
        </Grid>
      </Container>
    </>
  </ThemeProvider>
);

export default Completed;
