// @flow
import React from 'react';
import StatusSummaryTable from './components/StatusSummaryTable';

const AuditRFDiagnosticSummaries = () => (
  <>
    <StatusSummaryTable />
  </>
);

export default AuditRFDiagnosticSummaries;
