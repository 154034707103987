// @flow
import React from 'react';
import styled from 'styled-components';
import Info from '@material-ui/icons/Info';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import { SearchSelection } from '../../../../common/components/selectors';
import Tooltip from '../../../../common/components/tooltip/Tooltip';
import JobSelectionHint from './hints/JobSelection';

type Props = {
  jobName: string,
  jobId: string,
  fetchData: Function,
  changeJobs: Function
};

const StyledJobSelection = styled.div`
  display: inline-flex;
  && {
    svg {
      color: ${props => props.theme.color.ui.primary};
    }
  }
`;

const JobSelection = ({ jobName, jobId, fetchData, changeJobs }: Props) => (
  <StyledJobSelection>
    <SearchSelection
      key="database_list"
      value={{
        label: jobName || 'No default jobname selected',
        childDatum: jobId || 'no-default'
      }}
      fetchData={() => fetchData()}
      saveToStore={changeJobs}
      deleteFromStore={() => {}}
      selectedList={null}
      isSearchable={false}
      type="job"
      Icon={BusinessCenter}
      placeholder="Select your job here"
    />
    {!jobName && (
      <Tooltip>
        <div>
          <Info />
          <JobSelectionHint />
        </div>
      </Tooltip>
    )}
  </StyledJobSelection>
);

export default JobSelection;
