// @flow
import React from 'react';
import { connect } from 'react-redux';
import { getActivityLogs, updateAura } from '../duck/activityLog';
import ActivityLog from './components/ActivityLog';
import getStatusLabel from '../utils/status';

const ActivityLogContainer = (props: any) => <ActivityLog {...props} />;

export default connect(
  state => ({
    activityLogs: state.activityRF
      .get('activityLogs')
      .map(order =>
        order.set(
          'status',
          getStatusLabel(
            order.get('opportunityStatus'),
            order.get('jobStatus'),
            order.get('riskStatus'),
            order.get('auraDatabaseStatus'),
            order.get('auraLinkStatus'),
            order.getIn(['auraDatabase', 'id']) || -1
          )
        )
      ),
    isSearching: state.activityRF.get('isSearching')
  }),
  { getActivityLogs, updateAura }
)(ActivityLogContainer);
