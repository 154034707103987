// @flow
import React from 'react';
import styled from 'styled-components';
import DialogBox from '../../common/components/canvas/DialogBox';
import { type ServiceOrder } from '../types';
import SearchedOrderList from './SearchedOrderList';

const DialogWrapper = styled.div`
  min-heigth: 400px;
  display: flex;
  flex-direction: column;
`;

type Props = {
  isOpen: boolean,
  isLoading: boolean,
  serviceOrders: ServiceOrder[],
  toggle: () => void,
  toggleUploadDialog: () => void,
  getFiles: (id: number) => void,
  selectOrders: (orders: ServiceOrder[]) => void
};
const SearchedOrderDialog = ({
  isOpen,
  isLoading,
  toggle,
  toggleUploadDialog,
  getFiles,
  selectOrders,
  serviceOrders
}: Props) => (
  <DialogBox
    isOpen={isOpen}
    hasCloseButton
    maxWidth="lg"
    closeButtonOnClick={() => toggle()}
    title="Search Result"
    closeDialog={() => {}}
    ButtonElement={() => <></>}
    content={() => (
      <DialogWrapper data-cy="searched-order-dialog">
        <SearchedOrderList
          data={serviceOrders}
          getFiles={getFiles}
          loading={isLoading}
          selectOrders={selectOrders}
          onProceed={(orders: ServiceOrder[]) => {
            // Fetch files and select orders
            orders.forEach(order => getFiles(order.id));
            selectOrders(orders);
            // Open upload dialog
            toggleUploadDialog();
          }}
        />
      </DialogWrapper>
    )}
  />
);

export default SearchedOrderDialog;
