import en from './en';
import fi from './fi';

const Language = {
  Finnish: 'FI',
  English: 'EN'
};

const lists = {
  [Language.Finnish]: fi,
  [Language.English]: en
};

class StringList {
  constructor(language = Language.English) {
    this.stringList = lists[language];
  }

  setLanguage = language => {
    if (language && lists[language.toUpperCase()] !== undefined) {
      this.stringList = lists[language.toUpperCase()];
    }
  };

  t = (stringId, stringSubId) => {
    if (!stringSubId) {
      return this.stringList[stringId] ? this.stringList[stringId] : 'INVALID_STRING';
    }

    return this.stringList[stringId][stringSubId]
      ? this.stringList[stringId][stringSubId]
      : 'INVALID_STRING';
  };
}

export const stringList = new StringList();
// eslint-disable-next-line
export const t = stringList.t;
