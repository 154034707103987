// @flow
import { Record, type RecordOf, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { OpportunityOrderedMap, OpportunityQuery } from '../../common/types/opportunity';
import type { ActionType } from '../../common/types/redux';

type DefaultState = RecordOf<{
  opportunities: OpportunityOrderedMap,
  isLoading: boolean,
  searchQuery: OpportunityQuery
}>;

const defaultState = Record({
  opportunities: OrderedMap(),
  isLoading: false,
  searchQuery: {}
});

export const getOpportunities = createAction('GET_OPPORTUNITIES', query =>
  apiService.getOpportunities(query).then(data => ({
    opportunities: data,
    query
  }))
);

export const changeOpportunityStatus = createAction('CHANGE_OPPORTUNITY_STATUS', item => item);

export default function opportunitiesReducer(
  state: DefaultState = defaultState(),
  action: ActionType
) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_OPPORTUNITIES_PENDING':
      return state.set('isLoading', true);
    case 'GET_OPPORTUNITIES_REJECTED':
      return state.set('isLoading', false);
    case 'GET_OPPORTUNITIES_FULFILLED':
      return state
        .set('opportunities', payload.opportunities)
        .set('searchQuery', payload.query)
        .set('isLoading', false);
    case 'CHANGE_OPPORTUNITY_STATUS':
      return state.setIn(['opportunities', payload.key, 'isEnabled'], payload.isEnabled);
    default:
      return state;
  }
}
