// @flow
import React from 'react';
import styled from 'styled-components';
import { type List } from 'immutable';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import { type ServiceOrder } from '../types';
import { Box, BoxItem } from '../../common/components/canvas/BoxView';
import Button, { Buttons } from '../../common/components/Button';
import Wrapper from '../../common/components/Wrapper';
import { DoneIcon } from '../../common/components/Icons';
import Alink from '../../common/components/Link';

type Props = {
  summary: List<ServiceOrder>,
  resetServiceOrder: Function,
  history: any
};

const SubText = styled.p`
  text-align: left !important;
`;

const SubmmittedPage = ({ summary, history, resetServiceOrder }: Props) => (
  <Wrapper>
    <DoneIcon />
    <h1>Service Orders have now been submitted</h1>
    <SubText>
      {`Please note that if the client has some restrictions that SDC needs to be aware of, send a
      message to `}
      <Alink href="mailto: fi_sdc_finland@pwc.com">@SDC Finland</Alink>
      {` as soon as
      possible.`}
    </SubText>
    <Box Icon={QueryBuilder} header="Summary of the submitted Service Orders">
      {summary.valueSeq().map(order => (
        <BoxItem
          Icon={DoneIcon}
          key={order.client.id}
          name={`${order.client.name} - ${order.typeLabel || ''}${order.additionalInformation &&
            ` - ${order.additionalInformation}`}`}
        />
      ))}
    </Box>
    <Buttons>
      <Button
        primary
        solid
        round
        onClick={() => {
          resetServiceOrder();
          history.push('/service-order');
        }}
      >
        Start new Service Order
      </Button>
      <Button
        primary
        round
        onClick={() => {
          resetServiceOrder();
          history.push('/');
        }}
      >
        Back to Start Page
      </Button>
    </Buttons>
  </Wrapper>
);

export default SubmmittedPage;
