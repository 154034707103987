// @flow
import { post, get, remove } from '../../common/utils/api';
import { type datasetGroup, type dataset } from '../../common/types/dataset';

export default {
  createDatasetGroup: (data: datasetGroup): Promise<Object> => post('/gl/dataset-group', data),
  createDataset: (data: dataset): Promise<Object> =>
    post(`/gl/dataset-group/${data.datasetGroupId}/dataset`, data),
  fetchDataset: (datasetId: string, isHeadClient: boolean, clientId: string): Promise<Object> =>
    get(`/gl/dataset/${datasetId}`, { isHeadClient, clientId }),
  uploadFile: async (
    datasetId: string,
    contentFormat: Object,
    file: any,
    clientId?: string
  ): Promise<string> => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('contentFormatId', contentFormat.id);
    formData.append('contentFormatType', contentFormat.type);
    formData.append('fileName', file.name);
    formData.append('clientId', clientId || '');

    const uploadResult = await post(
      `/gl/dataset/${datasetId}/file?contentFormatId=${contentFormat.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 300000
      }
    );
    return uploadResult;
  },
  deleteFile: (datasetId: string, fileId: string): Promise<string> =>
    remove(`gl/dataset/${datasetId}/file/${fileId}`),
  deleteDataset: (datasetId: string): Promise<string> => remove(`/gl/dataset/${datasetId}`),
  isReadyToProcess: (datasetGroupId: string): Promise<boolean> =>
    get(`/gl/dataset-group/${datasetGroupId}/IsReadyToProcess`)
};
