// @flow
import React from 'react';
import { isEmpty } from 'lodash';
import { List } from 'immutable';
import styled from 'styled-components';
import PersonPin from '@material-ui/icons/PersonPin';
import service from '../../../services/api';
import { SimpleSelect, SearchSelection } from '../../../../common/components/selectors';
import Expander from '../../../../common/components/Expander';
import TextLabel from '../../../../common/components/TextLabel';
import { LinkButton } from '../../../../common/components/Button';
import ErrorMessageLabel from '../../../../common/components/ErrorMessageLabel';
import InfoIconTooltip from '../../../../common/components/tooltip/InfoIconTooltip';
import { linkNewTab } from '../../../../common/utils/helper';
import type { GetContactPerson } from '../../../../common/types/client';
import {
  allAuthorizationTypes,
  mapCodeToAuthorizationType,
  mapContactPersonToSimpleDropdownItem,
  contactPersonDataMappingToDropdown,
  formatContactPersonName
} from '../../../utils/helper';

type Props = {
  searchType: string,
  contactPersonsData: Array<GetContactPerson | null>,
  setContactPersonsData: Function,
  latestSelection: GetContactPerson | null,
  setLatestSelection: Function,
  setDialogPage: Function,
  isMultiSelect: boolean,
  validationErrorMessages: { [string]: { message: string } }
};

const ButtonWrapper = styled.div`
  padding: ${props =>
    `${props.theme.space.single.l} 0 ${props.theme.space.single.m} ${props.theme.space.single.s}`};
`;

const ContentWrapper = styled.div`
  > div:first-child {
    display: inline-block;
  }
`;

const SimpleSelectContainer = styled.div`
  width: 400px;
  display: inline-block;
`;

export default function AddPersonDialog({
  searchType,
  contactPersonsData,
  setContactPersonsData,
  validationErrorMessages,
  latestSelection,
  setLatestSelection,
  setDialogPage,
  isMultiSelect
}: Props) {
  const getOptions = async input => {
    if (input.length < 3) return null;
    return service.getContactPersons(input).then(data => contactPersonDataMappingToDropdown(data));
  };

  const { authorizationError, contactPersonError } = validationErrorMessages;

  return (
    <ContentWrapper>
      <SearchSelection
        key={searchType}
        value={latestSelection && mapContactPersonToSimpleDropdownItem(latestSelection)}
        placeholder={`Search ${searchType} by name`}
        fetchData={getOptions}
        saveToStore={person => {
          if (!contactPersonsData.find(selected => selected && selected.id === person.value)) {
            setLatestSelection(person.data);
            if (isMultiSelect) setContactPersonsData(contactPersonsData.concat(person.data));
            else setContactPersonsData([person.data]);
          }
        }}
        deleteFromStore={person => {
          setLatestSelection(null);
          setContactPersonsData(
            contactPersonsData.filter(selected => selected && selected.id !== person.id)
          );
        }}
        selectedList={
          contactPersonsData.length
            ? List(
                contactPersonsData.map(
                  contactPerson =>
                    contactPerson && {
                      ...contactPerson,
                      name: formatContactPersonName(contactPerson.firstName, contactPerson.lastName)
                    }
                )
              )
            : null
        }
        isSearchable
        type={searchType}
        Icon={PersonPin}
      />
      {contactPersonError && (
        <ErrorMessageLabel style={{ display: 'inline-block' }}>
          <div>{`Please select ${searchType}`}</div>
          <div>
            {`You can ${
              isMultiSelect ? `submit multiple ${searchType}s` : `only submit one ${searchType}`
            } at a time.`}
          </div>
        </ErrorMessageLabel>
      )}
      <Expander isOpen={!isMultiSelect && contactPersonsData && contactPersonsData.length}>
        <SimpleSelectContainer>
          <TextLabel bold noTopPadding>
            Authorization to Sign *
            <InfoIconTooltip
              tooltip={{
                __html: `<p>Please see more detailed guidance and examples in${linkNewTab(
                  'SharePoint',
                  'https://pwceur.sharepoint.com/sites/ClientDeskPwcFinland/SitePages/AML%20Check%20Tooltip%20Guidance.aspx'
                )}</p>`
              }}
            />
          </TextLabel>
          <SimpleSelect
            onChange={target => {
              const selectedPerson = contactPersonsData[0];
              if (selectedPerson)
                setContactPersonsData([{ ...selectedPerson, authorization: target.value }]);
            }}
            optionList={allAuthorizationTypes}
            value={
              contactPersonsData[0] && contactPersonsData[0].authorization
                ? mapCodeToAuthorizationType(contactPersonsData[0].authorization)
                : null
            }
          />
        </SimpleSelectContainer>
        {authorizationError && (
          <ErrorMessageLabel>Please select an authorization type</ErrorMessageLabel>
        )}
      </Expander>
      {isEmpty(validationErrorMessages) ? (
        <TextLabel>{`Click submit to save the person${isMultiSelect ? '(s)' : ''}`}</TextLabel>
      ) : (
        <ButtonWrapper>
          <LinkButton primary large underline onClick={() => setDialogPage(2)}>
            {`I cannot find my ${searchType} above. Click to create a new ${searchType}.`}
          </LinkButton>
        </ButtonWrapper>
      )}
    </ContentWrapper>
  );
}
