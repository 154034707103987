/* eslint-disable no-nested-ternary */
// @flow
import React, { memo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Info from '@material-ui/icons/Info';
import { type ServiceOrder, type InputField } from '../../types';
import TextLabel from '../../../common/components/TextLabel';
import Tooltip from '../../../common/components/tooltip/Tooltip';
import { CustomSingleDatePicker } from '../../../common/components/selectors/DatePicker';
import TextInput from '../../../common/components/TextInput';
import Checkboxes from '../../../common/components/checkbox/Checkboxes';
import { SearchSelection, SimpleSelect } from '../../../common/components/selectors';
import { dataMappingToDropdown } from '../../../common/utils/helper';
import { inputTypes, serviceOrderType, questionType } from '../../constants';
import { getLanguageById } from '../../utils';
import services from '../../services';
import holidayList from '../../constants/holidayList';

type Props = {
  order: ServiceOrder,
  inputFields: Array<InputField>,
  updateOrder: Function
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  > div {
    padding: ${props => props.theme.space.single.m};
  }
  background-color: ${props => props.theme.color.background.light};
`;

const SubWrapper = styled.div`
  > div,
  input {
    width: 100%;
  }
  input {
    padding: ${props => props.theme.space.single.s};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const renderSwitch = (order: ServiceOrder, field: InputField, onChange: Function) => {
  const { id } = order;
  const { type, key, options, placeholder, serviceName, isMulti, removeWeekends } = field;
  const value = order[key];

  const mapData = data => {
    if (serviceName === 'fetchStaff') return dataMappingToDropdown(data);
    return dataMappingToDropdown(data);
  };

  const fetchOptions = (input: any) => {
    if (serviceName && services[serviceName] && input && input.length >= 3) {
      return services[serviceName](input)
        .then(data => (data ? mapData(data) : []))
        .catch(() => []);
    }
    return Promise.resolve(null);
  };

  const saveSelection = values => {
    const newValues = values.map(item => item.data);
    onChange({
      id,
      key,
      value: newValues
    });
  };

  switch (type) {
    case inputTypes.SINGLE_DATE_PICKER: {
      // if order is bank confirmation, allows choosing date of "Date to be confirmed" from last year also
      const EXCLUDE_PAST_YEAR = -364;
      const EXCLUDE_FIRST_FOURTEEN_DAY = 14;
      const EXCLUDE_PAST = 1;
      let numberOfPastDayExcluded = EXCLUDE_PAST;

      if (order.type === serviceOrderType.BANK_CONFIRMATION) {
        if (field.id === questionType.TO_BE_CONFIRMED) numberOfPastDayExcluded = EXCLUDE_PAST_YEAR;
        if (field.id === questionType.DEAD_LINE)
          numberOfPastDayExcluded = EXCLUDE_FIRST_FOURTEEN_DAY;
      }

      return order.isRollForwarded ? (
        <TextLabel>{value ? moment(value).format('DD/MM/YYYY') : 'No date chosen'}</TextLabel>
      ) : (
        <CustomSingleDatePicker
          id={`${key}-${id}`}
          removeWeekends={removeWeekends}
          numberOfPastDayExcluded={numberOfPastDayExcluded}
          plusYear={0}
          date={value && moment.utc(value)}
          showClearDate={
            field.id === questionType.CES_REPORT_COMPLETION_PHASE ||
            field.id === questionType.AFS_REPORT_DATE
          }
          onChange={newValue =>
            onChange({
              id,
              key,
              value: newValue
            })
          }
          excludedDates={holidayList}
        />
      );
    }

    case inputTypes.TEXT_INPUT:
      return order.isRollForwarded ? (
        <TextLabel>{value || 'No information'}</TextLabel>
      ) : (
        <TextInput
          data-cy={key}
          value={value || ''}
          placeholder={placeholder}
          onChange={event =>
            onChange({
              id,
              key,
              value: event.target.value
            })
          }
        />
      );
    case inputTypes.CHECKBOXES: {
      if (!options) return null;

      const checkboxOptions = options.map((option, index) => ({
        id: `${index}-${id}`,
        label: option.label,
        value: option.value
      }));

      const selectedOption = options.find(option => option.value === value);
      return order.isRollForwarded ? (
        <TextLabel>
          {options && selectedOption ? selectedOption.label : 'No option selected'}
        </TextLabel>
      ) : (
        <Checkboxes
          options={checkboxOptions}
          selectedValue={value}
          isMulti={isMulti}
          onChange={newValue =>
            onChange({
              id,
              key,
              value: newValue
            })
          }
        />
      );
    }

    case inputTypes.SEARCH_SELECTION: {
      // eslint-disable-next-line no-nested-ternary
      if (order.isRollForwarded)
        return value && value.length > 0 ? (
          value.map(v => <TextLabel key={`${key}-${v.id}`}>{v.name}</TextLabel>)
        ) : (
          <TextLabel>No information</TextLabel>
        );
      return (
        <SearchSelection
          noPadding
          noMinWidth
          value={
            value &&
            value.length > 0 &&
            // filter out value of different financial insitutition type
            mapData(value)
          }
          saveToStore={saveSelection}
          deleteFromStore={saveSelection}
          type={`${id}-${type}`}
          fetchData={input => fetchOptions(input)}
          placeholder={placeholder}
          showInlineSelection
          width="100%"
        />
      );
    }
    case inputTypes.DROPDOWN: {
      return order.isRollForwarded ? (
        <TextLabel>{value ? getLanguageById(value).label : 'No information'}</TextLabel>
      ) : (
        <SimpleSelect
          onChange={language =>
            onChange({
              id,
              key,
              value: language.id
            })
          }
          value={getLanguageById(value)}
          optionList={options}
        />
      );
    }
    default:
      return null;
  }
};

const input = (field: InputField, order: ServiceOrder, onChange: Function) => (
  <SubWrapper key={field.id}>
    <TitleWrapper>
      <TextLabel bold inlineSmall>
        {field.label}
      </TextLabel>
      {field.instruction && (
        <Tooltip>
          <div>
            <Info />
            <div>{field.instruction}</div>
          </div>
        </Tooltip>
      )}
    </TitleWrapper>
    {renderSwitch(order, field, onChange)}
  </SubWrapper>
);

const OrderInputs = ({ order, inputFields, updateOrder }: Props) => {
  const onChange = params => {
    updateOrder(params);
  };
  return (
    <Wrapper>{inputFields && inputFields.map(field => input(field, order, onChange))}</Wrapper>
  );
};

export default memo <
  Props >
  (OrderInputs, (prevProps, nextProps) => prevProps.order === nextProps.order);
