// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Clear from '@material-ui/icons/Clear';
import Button from '../../../../common/components/Button';
import TextLabel from '../../../../common/components/TextLabel';
import { MenuWrapper, Menu } from '../../../../common/components/DropdownMenu';
import { MenuHeader } from '../../../../common/components/Menu';
import DialogBox from '../../../../common/components/canvas/DialogBox';

const CustomTextLabel = styled(TextLabel)`
  padding: 2px 20px;
  font-size: ${props => props.theme.fonts.size.body.l};
`;

const CustomButton = styled(Button)`
  display: flex;
  align-self: flex-start;
`;

const ButtonContainer = styled.div`
  padding: 0.5em;
  display: flex;
  border-color: ${props => props.theme.border.white};
  justify-content: flex-end;
  button {
    margin: 4px;
  }
`;

type DeleteAreaProps = {
  onDelete: Function,
  clientName: string
};
const DeleteDatasetGroup = ({ onDelete, clientName }: DeleteAreaProps) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const handleDelete = () => {
    onDelete();
    setMenuVisibility(false);
    toggleModal(false);
  };
  const handleCancel = () => {
    setMenuVisibility(false);
    toggleModal(false);
  };
  return (
    <>
      <DialogBox
        title="Delete"
        closeDialog={() => toggleModal(false)}
        content={() => (
          <TextLabel> Are you sure you want to delete the dataset for {clientName}?</TextLabel>
        )}
        ButtonElement={() => (
          <ButtonContainer>
            <Button rounded primary solid onClick={handleDelete}>
              Yes
            </Button>
            <Button rounded primary onClick={handleCancel}>
              Cancel
            </Button>
          </ButtonContainer>
        )}
        isOpen={showModal}
      />
      <MenuWrapper onMouseLeave={() => setMenuVisibility(false)}>
        <Button onClick={() => setMenuVisibility(true)} size="none" link>
          <MoreVertIcon />
        </Button>
        <Menu visible={menuVisibility}>
          <MenuHeader>
            <div>
              <TextLabel noTopPadding clientName bold isLink>
                {clientName}
              </TextLabel>
              <CustomButton onClick={() => toggleModal(true)} size="none" link>
                <Clear />
                <CustomTextLabel isLink>Delete dataset</CustomTextLabel>
              </CustomButton>
            </div>

            <CustomButton onClick={() => setMenuVisibility(false)} size="none" link>
              <MoreVertIcon />
            </CustomButton>
          </MenuHeader>
        </Menu>
      </MenuWrapper>
    </>
  );
};

export default DeleteDatasetGroup;
