// @flow

import { type JobType } from '../../../../../common/types/jobs';

import { type DropdownItem } from '../../../../../common/types/list';

type JobDropdown = DropdownItem<any> & JobType;

export default (dropdown: JobDropdown) => ({
  id: dropdown.value,
  name: dropdown.label,
  clientId: dropdown.clientId
});
