const statuses = [
  {
    value: 0,
    label: 'Request Submitted'
  },
  {
    value: 1,
    label: 'Pending for team to complete the acceptance profile'
  },
  {
    value: 2,
    label: 'Verifying acceptance profile for the client'
  },
  {
    value: 3,
    label: 'Job is pending to be created'
  },
  {
    value: 4,
    label: 'Waiting for Aura DB Archiving'
  },
  {
    value: 5,
    label: 'Aura DB is pending to be created'
  },
  {
    value: 6,
    label: 'Aura DB created and linking Aura DB to IPower job'
  },
  {
    value: 7,
    label: 'Completed'
  }
];
export default statuses;
