// @flow
import { Record, type RecordOf } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import { type ActionType } from '../../common/types/redux';

type State = RecordOf<{
  isOnboardingFinished: boolean
}>;

const defaultState = Record({
  isOnboardingFinished: true
});

export const getOnboardingStatus = createAction(
  'GET_ONBOARDING_STATUS',
  apiService.getOnboardingStatus
);

export const setOnboardingStatus = createAction('SET_ONBOARDING_STATUS', status => status);

export default function OnboardingReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_ONBOARDING_STATUS_PENDING':
      return state.set('isOnboardingFinished', true);
    case 'GET_ONBOARDING_STATUS_REJECTED':
      return state.set('isOnboardingFinished', false);
    case 'GET_ONBOARDING_STATUS_FULFILLED':
      return state.set('isOnboardingFinished', payload);
    case 'SET_ONBOARDING_STATUS':
      return state.set('isOnboardingFinished', payload);
    default:
      return state;
  }
}
