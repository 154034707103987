/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
import XLSX from 'xlsx';
import chardet from 'chardet'; // https://www.npmjs.com/package/chardet

/**
 * CSV parser for populating a list of Custom FSLIs
 */
export const parseFsliCsv = csvString =>
  csvString
    .replace(new RegExp(';', 'g'), ',')
    .split('\n')
    .map(row => row.split(','))
    .map((item, index) => ({
      id: index + 1,
      name: item[0] ? item[0] : '',
      type: item[1] ? item[1] : ''
    }));

/**
 * CSV parser for restoring the old FSLI values
 */
export const parseRestoreCSV = csvString =>
  csvString
    .replace(new RegExp(';', 'g'), ',')
    .split('\n')
    .map(row => row.split(','))
    .map((item, index) => ({
      id: index + 1,
      AccountNumber: item[0] ? Number(item[0]) : '',
      AccountName: item[1] ? item[1] : '',
      FSLI: item[2] ? item[2] : '',
      CustomFSLI: item[3] ? item[3] : ''
    }))
    .slice(1);

/**
 * Excel parser for restoring the old FSLI values
 */
const parseRestoreExcel = text => {
  const workbook = XLSX.read(text, {
    type: 'binary'
  });
  const result =
    workbook.SheetNames && workbook.SheetNames[0]
      ? XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]])
      : [];
  /**
   * Excel parser can return the column keys with spaces e.g. "  Account Number ",
   * So we need to trim the trailing & ending spaces in keys.
   */
  const trimmedResult = result.map(row => {
    const newRow = {};
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const property in row) {
      if (property.indexOf('Number') > -1) {
        newRow.accountNumber = String(row[property]);
      } else if (property.indexOf('Name') > -1) {
        newRow.accountName = row[property];
      } else if (property.indexOf('Custom') > -1) {
        newRow.customFSLI = row[property];
      } else if (property.indexOf('FSLI') > -1) {
        newRow.fsli = row[property];
      }
    }
    return newRow;
  });
  return trimmedResult;
};

// Split file in chunks to facilitate the parsing and database operations and avoid timeouts
export const splitFile = (datasetid, contentFormat, accountingSystem, file, clientId) => {
  // File encoding is needed when reading the file later on. UTF-8 is the default setting
  let fileEncoding = 'UTF-8';

  // Initial read of file to detect the file encoding
  const fileEncodingReader = new FileReader()
  const fileEncodingPromise = new Promise((resolve, reject) => {
    fileEncodingReader.onerror = () => {
      fileEncodingReader.abort();
      reject(new DOMException('Problem detecting file encoding.'));
    };

    fileEncodingReader.onload = evt => {
      // The chardet package detects the file encoding
      const array = new Uint8Array(evt.target.result);
      // Only use the first 1024 bytes to detect the file encoding. Using the entire file affects the performance
      const detectionArray = array.slice(0, 1024)
      const chardetEnc = chardet.detect(detectionArray)
      if (chardetEnc) {
        fileEncoding = chardetEnc
      }
      resolve(fileEncoding)
    }
  });
  fileEncodingReader.readAsArrayBuffer(file)

  return fileEncodingPromise.then(() => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem reading input file.'));
      };

      fileReader.onload = evt => {
        const splittedFiles = []; // a list of all the new files (chunks) to be created from the original file
        let newChunkFileName;
        let newChunkFile;
        let data; // form data to be sent to processing
        const noLines = 74000; // ~10MB
        const lines = evt.target.result.split(/[\r\n]+/).filter(elem => elem);
        const header = lines[0];
        const noFiles = Math.ceil((lines.length - 1) / noLines); // number of file chunks; remove header from line count
        let newFile = ''; // the new file being constructed
        newFile += header + '\n';
        let count = 1; // current file chunk

        // Read file line by line
        for (let line = 1; line < lines.length; line += 1) {
          newFile += lines[line] + '\n';
          if (line % noLines === 0) {
            newChunkFileName = 'Part ' + count + ' of ' + noFiles + ' - ' + file.name;
            newChunkFile = new File([newFile], newChunkFileName, { type: "text/plain;charset=UTF-8" });

            data = {
              datasetid: datasetid,
              contentFormat: contentFormat,
              accountingSystem: accountingSystem,
              file: newChunkFile,
              clientId: clientId
            };
            splittedFiles.push(data);

            newFile = '';
            newFile += header + '\n';
            count += 1;
          }
        }

        // Create the last file only if there is more remaining data
        // Do not create a new file that only has a header. This will give validation error
        if (count <= noFiles) {
          newChunkFileName = 'Part ' + count + ' of ' + noFiles + ' - ' + file.name;
          newChunkFile = new File([newFile], newChunkFileName, { type: "text/plain;charset=UTF-8" });

          data = {
            datasetid: datasetid,
            contentFormat: contentFormat,
            accountingSystem: accountingSystem,
            file: newChunkFile,
            clientId: clientId
          };
          splittedFiles.push(data);
        }

        resolve(splittedFiles);
      };
      // Read the file with the correct encoding
      // Otherwise not all characters will be recognized and the validation will fail
      fileReader.readAsText(file, fileEncoding);
    });
  })
};

const fileReader = (type, file, callback) => {
  const isCsv = file.type.indexOf('csv') !== -1 || file.name.indexOf('csv') !== -1;
  const isExcel = file.type.indexOf('xls') !== -1 || file.name.indexOf('xls') !== -1;
  const error =
    type === 'custom-fsli'
      ? 'Please upload a correct CSV (.csv) file.'
      : 'Please upload a correct CSV (.csv) or Excel (.xls, .xlsx) file.';
  let result = [];

  if (!isCsv && !isExcel) {
    callback({
      success: false,
      error,
      result
    });
    return;
  }

  const reader = new FileReader();
  reader.onload = event => {
    if (event.target && event.target.result) {
      if (isCsv) {
        result =
          type === 'custom-fsli'
            ? parseFsliCsv(event.target.result)
            : parseRestoreCSV(event.target.result);
      } else if (isExcel && type === 'restore-fsli') {
        result = parseRestoreExcel(event.target.result);
      }
    }
    callback({
      success: true,
      error: '',
      result
    });
  };

  if (isCsv) {
    reader.readAsText(file);
  } else {
    reader.readAsBinaryString(file);
  }
};

export default fileReader;
