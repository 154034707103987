// @flow
import { List, type List as ListT } from 'immutable';
import moment, { type Moment } from 'moment';
import type {
  DropdownListType,
  DropdownArrayType,
  DropdownItem,
  SimpleDropdownItem,
  SimpleDropdownListType,
  SimpleDropdownArrayType,
  SelectionItem
} from '../types/list';

export const mapToDropdownItem = (i: SelectionItem) => ({ label: i.name, value: i.id, data: i });
export const mapToSimpleDropdownItem = (i: SelectionItem) => ({
  label: i.name,
  value: i.id
});
export const mapToSelectionItem = (i: SimpleDropdownItem) => ({
  name: i.label,
  id: i.value
});

export const mapArrayToSelectionItems = (a: Array<SimpleDropdownItem>): Array<SelectionItem> =>
  a.map(mapToSelectionItem);

export const dataMappingToDropdown = (
  list: Array<Object> | ListT<Object>
): DropdownListType | DropdownArrayType => list.map(mapToDropdownItem);

export const dataMappingToSimpleDropdown = (
  list: Array<Object> | ListT<Object>
): SimpleDropdownListType | SimpleDropdownArrayType => list.map(mapToSimpleDropdownItem);

export const dropdownTransfer = (dropdownItem: DropdownItem<any>) => ({
  ...dropdownItem.data
});

export const mapDropdownTransfer = (list: Array<Object>): Array<Object> =>
  list.map(dropdownTransfer);

export const fileFormatTransfer = (files: Array<Object> | List<Object>) =>
  files.reduce((list, item) => {
    list.append('files', item);
    return list;
  }, new FormData());

export const textDateCreator = (date: Moment) => moment(date).format('DD/MM/YYYY');
// Return the time as local time of the user
export const textDateAndTimeCreator = (date: Moment) =>
  moment(date)
    .utc(date)
    .local()
    .format('DD/MM/YYYY HH:mm');

export const duration = (startDate: Moment, endDate: Moment) => endDate.diff(startDate, 'days');

export const datafilter = (list: ListT<Object>, inputValue: string) =>
  inputValue
    ? list.filter(item => item.name.toUpperCase().includes(inputValue.toUpperCase()))
    : list;

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const linkNewTab = (text: string, url: string): string =>
  `<a href=${url} rel="noopener noreferrer" target="_blank">${text}</a>`;

export const allMonths: Array<SimpleDropdownItem> = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

export const callOnKeypress = (event: KeyboardEvent, keycode: number, callback: Function) => {
  if (event.keyCode === keycode) {
    callback();
  }
};
