/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useEffect } from 'react';
import { useSortBy, useTable, useFilters, useGlobalFilter } from 'react-table';
import styled from 'styled-components';
import LoadingBar from '../workflow/LoadingBar';
import { DefaultColumnFilter } from './Filter';

export const Table = styled.table`
  border-collapse: collapse;
  background: white;
  border-radius: 3px 3px 0 0;
  overflow: visible;
  width: 100%;
  margin: 0 auto;
  position: relative;

  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  .highlight-row:hover {
    background-color: #e0dede;
  }

  .selectable:hover {
    cursor: pointer;
  }
`;

export const THead = styled.thead`
  background: ${props => props.theme.color.ui.primary};
  color: #fff;
`;

export const Th = styled.th`
  padding: 17px;
  text-align: left;
`;

export const Td = styled.td`
  padding: 12px;
  text-align: left;
`;

export const NoResults = styled.div`
  padding: 12px;
  text-align: center;
`;

export const LoadingBarContainer = styled.div`
  && > div {
    margin: 0;
  }
`;

type Props = {
  columns: Array<Object>,
  data: Array<Object>,
  isLoading?: boolean,
  noResultsText?: string,
  initialState?: Object,
  highlight?: boolean,
  selectable?: boolean,
  onRowClick?: (row: any) => {},
  haveFilter?: boolean,
  onFilterChange?: Function,
  filterTypes?: Object,
  cyName?: string
};

const Row = styled.span`
  width: 30px;
  padding: 5px 0;
`;

const FilterContainer = styled.span`
  vertical-align: middle;
  cursor: pointer;
`;

const CommonTable = ({
  columns,
  data,
  isLoading,
  noResultsText,
  initialState,
  highlight,
  selectable,
  onRowClick,
  haveFilter = false,
  onFilterChange = () => {},
  filterTypes,
  cyName = ''
}: Props) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const allFilterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
      ...filterTypes
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns || [],
      data: data || [],
      initialState: initialState || {},
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes: allFilterTypes
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );

  const handleRowClick = row => {
    if (!onRowClick) return;
    onRowClick(row);
  };

  useEffect(() => {
    //  filters changed
    onFilterChange(rows);
  }, [rows.length]);

  const showNoResults = (): boolean =>
    data && data.length === 0 && !isLoading && Boolean(noResultsText);

  return (
    <>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers &&
                headerGroup.headers.map(column => (
                  <Th
                    data-cy={`${cyName || 'common'}-table-header-${
                      typeof column.Header === 'string' ? column.Header.toLowerCase() : 'unknown'
                    }-column`}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    {haveFilter && (
                      <FilterContainer>
                        {column.canFilter ? column.render('Filter') : null}
                      </FilterContainer>
                    )}
                  </Th>
                ))}
            </tr>
          ))}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row.original)}
                data-cy={`${cyName || 'common'}-table-row`}
                className={`${highlight ? 'highlight-row' : ''} ${selectable ? 'selectable' : ''}`}
              >
                {row.cells &&
                  row.cells.map(cell => (
                    <Td {...cell.getCellProps()}>
                      <Row>{cell.render('Cell')}</Row>
                    </Td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {isLoading && (
        <LoadingBarContainer>
          <LoadingBar />
        </LoadingBarContainer>
      )}
      {showNoResults() && <NoResults data-cy="table-no-results">{noResultsText}</NoResults>}
    </>
  );
};

export default CommonTable;
