// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList, RecordOf } from 'immutable';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { type TaxReturn, type DocumentTemplate, type Assignee } from '../../../../types';
import { addNewDocument } from '../../../../duck/TaxReturnPage';

interface StateProps {
  taxReturn: TaxReturn;
  documentTemplates?: ImmutableList<DocumentTemplate>;
  assignee?: RecordOf<Assignee>;
}

type OwnProps = {
  open: boolean,
  onClose: Function
};

interface DispatchProps {
  addNewDocument: Function;
}

type Props = StateProps & OwnProps & DispatchProps;

const AddNewDocumentDialog = ({
  taxReturn,
  documentTemplates,
  assignee,
  open,
  onClose,
  ...dispatchProps
}: Props) => {
  const [templateId, setTemplateId] = useState('');
  const [documentName, setDocumentName] = useState('');

  const handleTemplateChange = event => {
    if (!documentTemplates) {
      return;
    }
    setTemplateId(event.target.value);
    const templateName = documentTemplates
      .find(template => template.get('id') === event.target.value)
      .get('name');
    const assigneeName = assignee
      ? `${assignee.get('lastName')} ${assignee.get('firstName')}`
      : taxReturn.assignee.name;
    if (templateName.includes(taxReturn.year.name)) {
      setDocumentName(`${templateName} ${assigneeName}`);
    } else {
      setDocumentName(`${templateName} ${taxReturn.year.name} ${assigneeName}`);
    }
  };

  const handleAddNewDocument = () => {
    dispatchProps.addNewDocument(
      templateId,
      documentName,
      taxReturn.client.id,
      taxReturn.assignee.id,
      taxReturn.year.id,
      taxReturn.language.id,
      taxReturn.country.id
    );
    onClose();
    setTemplateId('');
    setDocumentName('');
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Add new document from templates</DialogTitle>
      <DialogContent>
        {documentTemplates ? (
          <>
            <FormControl fullWidth>
              <InputLabel id="template-select-label">Template</InputLabel>
              <Select
                labelId="template-select-label"
                value={templateId}
                onChange={handleTemplateChange}
              >
                {documentTemplates.map(template => (
                  <MenuItem key={template.get('id')} value={template.get('id')}>
                    {template.get('name')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Document name"
              value={documentName}
              onChange={event => setDocumentName(event.target.value)}
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" onClick={handleAddNewDocument} disabled={!templateId}>
          Add document
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { taxReturn, documentTemplates, assignee } = state.veromylly.taxReturnPage;
  return {
    taxReturn,
    documentTemplates,
    assignee
  };
};

const mapDispatch = {
  addNewDocument
};

export default connect(
  mapStateToProps,
  mapDispatch
)(AddNewDocumentDialog);
