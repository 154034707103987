import React from 'react';
import styled from 'styled-components';
import Link from './Link';

const StyledFooter = styled.footer`
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: ${props => props.theme.space.single.m};
  margin-top: auto;
  background-color: ${props => props.theme.color.background.white};
`;

const Footer = () => (
  <StyledFooter>
    © 2017 - 2020 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its
    member firms, each of which is a separate legal entity.
    <br />
    Please see
    <Link href="https://www.pwc.com/structure" rel="noopener noreferrer" target="_blank">
      www.pwc.com/structure
    </Link>
    for further details.
    <hr />
    <div>
      <Link
        target="_blank"
        href="https://docs.google.com/forms/d/1kAXUWbJ2aB4_J4AGYOJ5k9kHbPuOfnyjLShUGChLmm8/edit?ts=5c175712"
      >
        Feedback
      </Link>
    </div>
  </StyledFooter>
);

export default Footer;
