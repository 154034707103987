// @flow
import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import ProgressBar from '../ProcessBar';

const Bar = styled.div`
  border: 1px solid ${props => props.theme.color.border.default}
  border-radius:${props => props.theme.border.type.round}
  padding: ${props => `${props.theme.space.single.s} ${props.theme.space.single.xl}`}
  display: flex;

  margin-top: ${props => props.theme.space.single.l};

  *{
    flex-grow: 1;
    text-align: center;
    flex-shrink: 0;
    margin: auto;
    color: ${props => props.theme.color.text.neutral}
  }
  & > *:first-child{
    flex-grow: 0;
    color: ${props => props.theme.color.ui.primary}
  }
  & > *:last-child{
    flex-grow: 0;
    color: ${props => props.theme.color.text.light};
  };
`;

type actionButtonType = {
  name: string,
  callback: Function,
  isDisabled?: boolean
};

type Props = {
  status: string,
  proceed: actionButtonType,
  optional?: actionButtonType,
  isLoading?: boolean
};

const ActionBar = ({ status, proceed, optional, isLoading }: Props) => (
  <Bar>
    {optional && !optional.isDisabled ? (
      <Button onClick={optional.callback} link data-cy="action-optional-button">
        {optional.name}
      </Button>
    ) : (
      <div />
    )}
    {isLoading ? <ProgressBar /> : <div data-cy="action-status">{status}</div>}
    <Button
      data-cy="action-process-button"
      onClick={proceed.callback}
      primary={!proceed.isDisabled && !isLoading}
      solid
      disabled={proceed.isDisabled || isLoading}
    >
      {proceed.name}
    </Button>
  </Bar>
);

ActionBar.defaultProps = {
  optional: undefined,
  isLoading: false
};

export default ActionBar;
