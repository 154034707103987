import styled from 'styled-components';

export default styled.div`
  cursor: ${props => (props.isLink ? 'pointer' : 'default')};
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.color
      ? props.color
      : // eslint-disable-next-line no-nested-ternary
      props.heading
      ? props.theme.color.heading.light
      : props.clientName
      ? props.theme.color.ui.primary
      : props.theme.color.text.neutral};
  size: ${props => {
    if (props.big) return props.theme.fonts.size.body.m;
    if (props.heading) return props.theme.fonts.size.heading.xl;
    return props.theme.fonts.size.body.s;
  }};
  padding: ${props => {
    if (props.stack) return props.theme.space.stack.s;
    if (props.noPadding) return 0;
    if (props.noBottomPadding)
      return `${props.theme.space.single.m} ${props.theme.space.single.s} 0`;
    if (props.noTopPadding) return `0 ${props.theme.space.single.s} ${props.theme.space.single.m}`;
    if (props.inlineSmall) return props.theme.space.inline.s;
    if (props.inline) return props.theme.space.inline.m;
    return props.theme.space.stretch.s;
  }};

  cursor: ${props => (props.isLink ? 'pointer' : 'default')};

  .highlight{
    cursor: pointer;
    color: ${props => props.theme.color.ui.primary};
  }
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.i ? 'italic' : 'normal')};
  svg {
    color: ${props => props.theme.color.ui.primary}
    vertical-align: middle;
    margin: ${props => props.theme.space.stack.xs};
  }
`;
