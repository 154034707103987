// @flow
import React from 'react';
import { isEmpty } from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import { type List } from 'immutable';
import styled from 'styled-components';
import PersonPin from '@material-ui/icons/PersonPin';
import TextLabel from '../TextLabel';
import { type DropdownListType, type DropdownItem } from '../../types/list';
import SelectedList from './shared/SelectedList';

type Props = {
  value?: Object | null,
  saveToStore: Function,
  deleteFromStore: Function,
  // name of selected
  selectedList: List<Object> | null,
  // type of search
  type: string,
  Icon?: string,
  fetchData: (
    inputValue: string
  ) => Promise<List<DropdownItem<any>> | Array<DropdownItem<any>> | null>,
  isSearchable?: boolean,
  placeholder: string,
  note?: string,
  onChange?: Function,
  onClear?: Function,
  width: string,
  noMinWidth: boolean,
  showInlineSelection?: boolean,
  noPadding?: boolean,
  isClearable?: boolean
};
// override external library
const StyledAsyncSelect = styled(AsyncSelect)`
  .react-select__control {
    background-color: white;
    border: none;
    box-shadow: none;
    .is-selected {
      background-color: red;
    }
  }
  .react-select__option--is-selected {
    background: ${props => props.theme.color.ui.primary};
  }
  .react-select__option {
    &:hover,
    &:focus {
      background: ${props => props.theme.color.ui.light};
    }
  }
  .react-select__multi-value__label {
    white-space: normal;
  }
`;

const SearchBox = styled.div`
  background: white;
  width: ${props => (props.width ? props.width : '60%')};
  min-width: ${props => (props.noMinWidth ? 0 : '400px')};
  border: solid 1px ${props => props.theme.color.border.default};
  border-radius: ${props => props.theme.border.type.round};
  display: flex;
  padding: ${props =>
    !props.noPadding && ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
  & > *:first-child {
    flex-shrink: 1;
    background: white;
    margin: auto;
    color: ${props => props.theme.color.ui.primary};
  }
  & > *:last-child {
    flex-grow: 1;
  }
`;

const SearchSelection = ({
  fetchData,
  saveToStore,
  deleteFromStore,
  selectedList,
  type,
  Icon,
  isSearchable,
  placeholder,
  note,
  value,
  onChange,
  width,
  noMinWidth,
  showInlineSelection,
  noPadding,
  isClearable,
  onClear
}: Props) => {
  const handleChange = async (selectedOption: DropdownListType) => {
    if (!isEmpty(selectedOption) || showInlineSelection) {
      await saveToStore(selectedOption);
      if (onChange) onChange();
    }
    // Trigger on clear if none selected
    if (isEmpty(selectedOption) && onClear) onClear();
  };

  return (
    <div>
      <SearchBox
        width={width}
        noMinWidth={noMinWidth}
        noPadding={noPadding}
        data-cy={`search-bar-${type.toLowerCase()}`}
      >
        {Icon && <Icon />}
        <StyledAsyncSelect
          inputId={`${Math.random()}`}
          value={value}
          isMulti={showInlineSelection}
          classNamePrefix="react-select"
          defaultOptions
          loadOptions={(inputValue: string) => fetchData(inputValue)}
          onChange={handleChange}
          placeholder={placeholder}
          isSearchable={isSearchable}
          noOptionsMessage={() => isSearchable && 'Type at least three letters to search'}
          isClearable={isClearable}
        />
      </SearchBox>
      {selectedList && selectedList.size > 0 ? (
        <SelectedList
          Icon={Icon || PersonPin}
          selectedList={selectedList}
          type={type}
          removeSelection={deleteFromStore}
        />
      ) : (
        note && <TextLabel>{note}</TextLabel>
      )}
    </div>
  );
};

SearchSelection.defaultProps = {
  isSearchable: true,
  note: '',
  value: undefined,
  Icon: undefined,
  showInlineSelection: false,
  onChange: () => {},
  onClear: () => {},
  noPadding: false,
  isClearable: false
};

export default SearchSelection;
