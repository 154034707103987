// @flow
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { List } from 'immutable';
import CommonTable from '../../../../common/components/react-table/CommonTable';
import { type TaxReturn, type Staff } from '../../../types';
import { GlowButton } from '../../../../common/components/Button';
import SearchSelection from '../../../../common/components/selectors/SearchSelection';
import { callOnKeypress, dataMappingToDropdown } from '../../../../common/utils/helper';
import { updatePreparer, searchStaff, getMyTodoList } from '../../../duck/HomePage';

const TodoListContainer = styled.div``;

const orderTaxReturns = (taxReturns: TaxReturn[]) =>
  taxReturns.sort((a, b) => a.state.id - b.state.id);

interface StateProps {
  todoList: Array<TaxReturn>;
}

interface DispatchProps {
  getMyTodoList: () => Promise<void>;
  updatePreparer: (taxReturnId: number, preparer: Staff) => Promise<void>;
  searchStaff: (input: string) => Promise<List<Staff>>;
}

interface OwnProps {
  fetchTodo: Function;
}

type Props = StateProps & DispatchProps & OwnProps;

const TodoList = ({ todoList, fetchTodo, ...dispatchProps }: Props) => {
  const [editRowId, setEditRowId] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = async () => {
    try {
      setIsLoading(true);
      await dispatchProps.getMyTodoList();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'Assignee',
        accessor: 'assignee.name',
        Cell: table => {
          const taxReturn: TaxReturn = table.row.original;
          return (
            <GlowButton
              primary
              rounded
              data-cy="continue-tax-return-button"
              onClick={() => history.push(`/veromylly/${taxReturn.id}`)}
            >
              {taxReturn.assignee.name}
            </GlowButton>
          );
        }
      },
      {
        Header: 'Client',
        accessor: 'client.name'
      },
      {
        Header: 'Year',
        accessor: 'year.name'
      },
      {
        Header: 'Status',
        accessor: 'state.name'
      },
      {
        Header: 'Preparer',
        accessor: 'preparer.name',
        Cell: table => {
          const { id: taxReturnId, preparer } = table.row.original;
          return editRowId === table.row.id ? (
            <SearchSelection
              type="preparer"
              saveToStore={async ({ data: prep }) => {
                setEditRowId(-1);
                setIsLoading(true);
                await dispatchProps.updatePreparer(taxReturnId, prep);
                await handleLoading();
              }}
              deleteFromStore={() => {}}
              noMinWidth
              placeholder=""
              isClearable
              selectedList={List<Object>()}
              width="100%"
              fetchData={input => {
                // eslint-disable-next-line no-array-constructor
                if (input.length < 3) return Promise.resolve(Array<Staff>());
                return dispatchProps
                  .searchStaff(input)
                  .then(({ value: staff }) => (staff ? dataMappingToDropdown(staff) : []));
              }}
              onChange={undefined}
              value={preparer.name}
            />
          ) : (
            <GlowButton
              primary
              rounded
              data-cy="reassign-tax-return-button"
              onClick={() => {
                setEditRowId(table.row.id);
              }}
            >
              {preparer.name}
            </GlowButton>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editRowId]
  );

  useEffect(() => {
    const cancelEdit = (event: KeyboardEvent) => callOnKeypress(event, 27, () => setEditRowId(-1));
    document.addEventListener('keydown', cancelEdit, false);
    return () => {
      document.removeEventListener('keydown', cancelEdit, false);
    };
  }, []);

  return (
    <TodoListContainer data-cy="my-todo-list">
      <h2>My Todo List</h2>
      <CommonTable
        columns={columns}
        data={orderTaxReturns(todoList)}
        isLoading={isLoading}
        noResultsText="No results found"
      />
    </TodoListContainer>
  );
};

const mapStateToProps = state => ({
  todoList: state.veromylly.homePage.todoList
});

const dispatchProps: DispatchProps = {
  updatePreparer,
  searchStaff,
  getMyTodoList
};

export default connect(
  mapStateToProps,
  dispatchProps
)(TodoList);
