// @flow
import { withStateHandlers } from 'recompose';

export type WithMenu = {
  menuVisibility: boolean,
  openMenu: Function,
  closeMenu: Function,
  toggleMenu: Function
};

export default withStateHandlers(
  ({ menuVisibility = false }) => ({
    menuVisibility
  }),
  {
    openMenu: () => () => ({
      menuVisibility: true
    }),
    closeMenu: () => () => ({
      menuVisibility: false
    }),
    toggleMenu: ({ menuVisibility }) => () => ({
      menuVisibility: !menuVisibility
    })
  }
);
