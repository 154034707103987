// @flow
import React from 'react';
import SuccessPageContainer from './success/SuccessPageContainer';
import Wrapper from '../../../common/components/Wrapper';
import Button, { Buttons } from '../../../common/components/Button';

type Props = {
  history: Function,
  cleanGeneralLedgerDuck: Function
};

const SubmitPage = ({ history, cleanGeneralLedgerDuck }: Props) => (
  <Wrapper>
    <SuccessPageContainer history={history} />
    <Buttons>
      <Button
        data-cy="start-again"
        primary
        solid
        round
        onClick={() => {
          cleanGeneralLedgerDuck();
          history.push('/general-ledger');
        }}
      >
        Start a new General Ledger
      </Button>
      <Button
        data-cy="go-to-frontpage"
        primary
        round
        onClick={() => {
          cleanGeneralLedgerDuck();
          history.push('/');
        }}
      >
        Back to Start Page
      </Button>
    </Buttons>
  </Wrapper>
);

export default SubmitPage;
