/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
// @flow
import React, { useState } from 'react';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Folder from '@material-ui/icons/Folder';
import Launch from '@material-ui/icons/Launch';
import TextLabel from '../../../../common/components/TextLabel';
import ProgressBar from '../../../../common/components/ProcessBar';
import Alink from '../../../../common/components/Link';
import {
  SubSection,
  FlexSpaceBetween,
  CustomTextLabel,
  HoverLink,
  GridDisplay,
  CustomLink
} from './StyledComponent';
import FilesListDialog from '../../common/FilesListDialog';
import DeleteDatasetGroup from './DeleteDatasetGroup';
import ServiceRequest from '../../../../common/components/service-req/ServiceRequest';

export type RowRightProps = {
  fileCount: number,
  haveFailedFiles: boolean,
  haveAllDatasetsFailed: boolean,
  isQlikSenseAvailable: boolean,
  isMappingAvailable: boolean,
  goToAccountMapping: Function,
  goToGeneralLedger: Function,
  files: Array<any>,
  serviceOrderId: number,
  groupDatasetId: string,
  deleteDatasetGroup: Function,
  createServiceOrder: Function,
  clientName: string,
  isDatasetLoading: boolean
};

const RowRight = ({
  fileCount,
  haveFailedFiles,
  haveAllDatasetsFailed,
  isQlikSenseAvailable,
  isMappingAvailable,
  goToAccountMapping,
  goToGeneralLedger,
  files,
  serviceOrderId,
  groupDatasetId,
  deleteDatasetGroup,
  createServiceOrder,
  clientName,
  isDatasetLoading
}: RowRightProps) => {
  const [showModal, toggleModal] = useState(false);
  const [showRequestModal, toggleRequestModal] = useState(false);
  return (
    <SubSection>
      {isDatasetLoading ? (
        <ProgressBar />
      ) : (
        <>
          <FlexSpaceBetween>
            <CustomTextLabel data-cy={`${clientName}-warning-text`}>
              {/** No Files */}
              {fileCount === 0 && <>Dataset created - no files uploaded</>}

              {/** Some files with failed status */}
              {fileCount !== 0 &&
                haveFailedFiles &&
                (serviceOrderId ? (
                  <>The SDC service order has been sent.</>
                ) : (
                  <>
                    <b>Warning!</b> One or more files could not be validated. Make sure your files are in the
                    correct format.
                  </>
                ))}

              {/** No Failed Files  */}
              {fileCount !== 0 &&
                !haveFailedFiles &&
                (isQlikSenseAvailable ? (
                  <>The report is ready in Qlik Sense.</>
                ) : (
                  <>
                    The report will soon be ready in Qlik Sense. See the
                    <HoverLink href="https://docs.google.com/presentation/d/1Lb0b4l6l6PaW7IFjAUFqJkeRtUCOwolkrOH5eKNOc74/edit#slide=id.p1">
                      instructions
                    </HoverLink>
                    {' for more information.'}
                  </>
                ))}
            </CustomTextLabel>
            <DeleteDatasetGroup
              onDelete={() => deleteDatasetGroup(groupDatasetId)}
              clientName={clientName}
            />
          </FlexSpaceBetween>
          <GridDisplay>
            {fileCount !== 0 && (
              <>
                <CustomLink onClick={() => toggleModal(true)}>
                  <Folder />
                  <TextLabel isLink clientName data-cy={`${clientName}-open-file-details`}>
                    File details
                  </TextLabel>
                </CustomLink>

                {isQlikSenseAvailable ? (
                  <TextLabel clientName isLink>
                    <QueryBuilder />
                    <Alink href="https://qliksense.pwc.fi/" target="_blank">
                      View report in Qlik Sense
                    </Alink>
                  </TextLabel>
                ) : haveAllDatasetsFailed ? (
                  <TextLabel>The Qlik Sense report cannot be generated, there are no valid datasets.</TextLabel>
                ) :
                  <TextLabel>The report is not ready for Qlik Sense yet.</TextLabel>
                }
                {haveAllDatasetsFailed ? (
                  <TextLabel>The FSLI mappings cannot be generated, there are no valid datasets.</TextLabel>
                ) : isMappingAvailable ? (
                  <TextLabel onClick={goToAccountMapping} clientName isLink>
                    <Launch />
                    Trial balance account mapping
                  </TextLabel>
                ) :
                  <TextLabel>The account mapping is not available yet.</TextLabel>
                }
              </>
            )}
            {files && files.length > 0 && (
              <FilesListDialog
                files={files}
                showModal={showModal}
                closeModal={() => toggleModal(false)}
              />
            )}
            <ServiceRequest
              createNewDataset={() => goToGeneralLedger()}
              isOpen={showRequestModal}
              requestName="DatasetGroup"
              createServiceOrder={message =>
                createServiceOrder({ datasetGroupId: groupDatasetId, message })
              }
              closeDialog={() => toggleRequestModal(false)}
            />
          </GridDisplay>
        </>
      )}
    </SubSection>
  );
};

export default RowRight;