// @flow
import React from 'react';
import styled from 'styled-components';
import { MoreVert } from '@material-ui/icons';
import { MenuWrapper, Menu as MenuBody } from '../DropdownMenu';
import { MenuHeader, MenuButton } from '../Menu';
import TextLabel from '../TextLabel';
import Button from '../Button';

type ItemProps = {
  title: string,
  subtitle?: string,
  disabled: boolean,
  Icon?: any,
  onClick: Function
};

type Props = {
  header?: string,
  HeaderIcon?: any,
  headerOnClick?: Function,
  ToggleElement: any,
  items?: Array<ItemProps>,
  isVisible: boolean,
  onShow: Function,
  onHide: Function,
  children?: any,
  align?: string
};

const CustomButton = styled(Button)`
  padding: ${props => props.theme.space.stretch.s};
`;

const Menu = ({
  header,
  HeaderIcon,
  headerOnClick,
  ToggleElement,
  items,
  isVisible,
  onShow,
  onHide,
  children,
  align
}: Props) => (
  <MenuWrapper noPadding align={align}>
    <ToggleElement onClick={onShow} />
    {isVisible && (
      <MenuBody visible={isVisible}>
        <MenuHeader>
          {HeaderIcon && (
            <CustomButton onClick={headerOnClick} size="none" link>
              <HeaderIcon />
            </CustomButton>
          )}
          <TextLabel clientName isLink>
            {header}
          </TextLabel>
          <CustomButton onClick={onHide} size="none" link>
            <MoreVert />
          </CustomButton>
        </MenuHeader>
        {items &&
          items.map(item => (
            <MenuButton
              key={item.title}
              disabled={item.disabled}
              onClick={() => (item.onClick ? item.onClick() : {})}
              withIcon={item.Icon}
            >
              {item.Icon && <item.Icon />}
              <div>
                <strong>{item.title}</strong>
                {item.subtitle && <small>{item.subtitle}</small>}
              </div>
            </MenuButton>
          ))}
        {children}
      </MenuBody>
    )}
  </MenuWrapper>
);

Menu.defaultProps = {
  header: '',
  HeaderIcon: null,
  headerOnClick: () => {},
  items: [],
  children: <></>,
  align: 'right'
};

export default Menu;
