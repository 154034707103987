import { connect } from 'react-redux';
import SubmittedPage from '../SubmittedPage';
import { resetRollForward } from '../../duck/rollForward';

export default connect(
  state => ({
    summary: state.audit.audits
      .filter(({ key }) => state.rollForward.rollForwards.getIn([key.toString(), 'state']) > 0)
      .reduce((sum, value, key) => {
        sum.push(value.get('datum'));
        value
          .getIn(['datum', 'children'])
          .filter(
            (child, childkey) =>
              state.rollForward.rollForwards.getIn([
                key.toString(),
                'datum',
                'children',
                childkey.toString(),
                'state'
              ]) > 0
          )
          .forEach(child => sum.push(child.get('datum')));
        return sum;
      }, [])
  }),
  { resetRollForward }
)(SubmittedPage);
