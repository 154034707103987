// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import type { DateObject } from '../../../../common/types/client';

type InputProps = {
  value: string,
  placeholder: string,
  maxLength: number,
  setDate: Function,
  onFinishedTyping: Function
};

type Props = {
  date: DateObject,
  setDate: Function
};

const InputWrapper = styled.div`
  padding: ${props => props.theme.space.stack.s};
  border: solid 1px ${props => props.theme.color.border.default};
  border-radius: ${props => props.theme.border.type.round};
  background-color: white;

  input {
    padding: ${props => props.theme.space.stretch.s};

    border: none;
    width: 50px;
    outline: none;
    font-size: ${props => props.theme.fonts.size.body.m};

    &:nth-of-type(3) {
      width: 100px;
    }
  }
`;

const Input = ({ value, placeholder, maxLength, setDate, onFinishedTyping }: InputProps) => (
  <input
    type="text"
    value={value}
    maxLength={maxLength}
    placeholder={placeholder}
    onChange={e => {
      setDate(e.target.value);
      if (e.target.value.length === maxLength) {
        onFinishedTyping(e);
      }
    }}
  />
);

const InputDatePicker = ({ date, setDate }: Props) => {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [dateYear, setDateYear] = useState<string>(date.year);
  const [dateMonth, setDateMonth] = useState<string>(date.month);
  const [dateDay, setDateDay] = useState<string>(date.day);

  const onFinishedTyping = event => {
    const { form } = event.target;
    const nextElement = form.elements[Array.prototype.indexOf.call(form, event.target) + 1];
    if (nextElement) nextElement.select();
  };

  useEffect(() => {
    if (shouldUpdate) {
      setDate({ day: dateDay, month: dateMonth, year: dateYear });
      setShouldUpdate(false);
    }
  }, [dateDay, dateMonth, dateYear, setDate, shouldUpdate]);

  return (
    <InputWrapper data-cy="input-date-picker">
      <form>
        <Input
          value={dateDay}
          placeholder="DD"
          maxLength={2}
          setDate={e => {
            setDateDay(e);
            setShouldUpdate(true);
          }}
          onFinishedTyping={onFinishedTyping}
        />
        <span>/</span>
        <Input
          value={dateMonth}
          placeholder="MM"
          maxLength={2}
          setDate={e => {
            setDateMonth(e);
            setShouldUpdate(true);
          }}
          onFinishedTyping={onFinishedTyping}
        />
        <span>/</span>
        <Input
          value={dateYear}
          placeholder="YYYY"
          maxLength={4}
          setDate={e => {
            setDateYear(e);
            setShouldUpdate(true);
          }}
          onFinishedTyping={onFinishedTyping}
        />
      </form>
    </InputWrapper>
  );
};

export default InputDatePicker;
