// @flow
import { Record, type RecordOf } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { ActionType } from '../../common/types/redux';

type DefaultState = RecordOf<{
  isOnboardingFinished: boolean,
  loading: number
}>;

const defaultState = Record({
  isOnboardingFinished: false,
  loading: 0
});

export const getOnboardingInfo = createAction(
  'GET_RF_ONBOARDING_STATUS',
  apiService.getOnboardingStatus
);
export const triggerInstruction = createAction('SET_RF_ONBOARDING_STATUS');

export default function generalReducer(state: DefaultState = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_AUDITS_PENDING':
    case 'GET_SUBSIDARIES_PENDING':
    case 'GET_ROLLFORWARDS_PENDING':
    case 'SUBMIT_ROLL_FORWARDS_PENDING':
    case 'GET_CLIENTS_JOBLIST_PENDING':
    case 'GET_RF_ONBOARDING_STATUS_PENDING':
      return state.update('loading', loading => loading + 1);

    case 'GET_AUDITS_REJECTED':
    case 'GET_ROLLFORWARDS_REJECTED':
    case 'SUBMIT_ROLL_FORWARDS_REJECTED':
    case 'GET_CLIENTS_JOBLIST_REJECTED':
    case 'GET_CLIENTS_JOBLIST_FULFILLED':
    case 'GET_RF_ONBOARDING_STATUS_REJECTED':
      return state.update('loading', loading => loading - 1);

    case 'GET_SUBSIDARIES_FULFILLED':
      return state.update('loading', loading => loading - 1);

    case 'GET_ROLLFORWARDS_FULFILLED':
      return state.update('loading', loading => loading - 1);

    case 'SUBMIT_ROLL_FORWARDS_FULFILLED':
      return state.update('loading', loading => loading - 1);

    case 'GET_AUDITS_FULFILLED':
      return state.update('loading', loading => loading - 1);

    case 'GET_RF_ONBOARDING_STATUS_FULFILLED':
      return state.set('isOnboardingFinished', payload).update('loading', loading => loading - 1);

    case 'SET_RF_ONBOARDING_STATUS':
      return state.update('isOnboardingFinished', isOnboardingFinished => !isOnboardingFinished);

    case 'RESET_ROLLFORWARD':
      return defaultState();

    default:
      return state;
  }
}
