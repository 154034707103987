// @flow
import React from 'react';
import { connect } from 'react-redux';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import FilesUpload from './HeadClient';
import { selectAccountSystem } from '../../../../../duck/accountSystem';
import {
  saveHeadClientFile,
  removeHeadClientFile,
  turnOnLoading
} from '../../../../../duck/dataset';

const FileUploadContainer = props => <FilesUpload {...props} />;

export default connect(
  state => ({
    headClient: state.dataset.filesData.headClient,
    accountSystemList: state.accountSystem.get('accountSystemList'),
    selectedAccountSystem: state.accountSystem.get('selectedAccountSystem'),
    filesData: state.dataset.filesData,
    Icon: VerticalAlignTop,
    datasetId: state.dataset.filesData.headClient.datasetId,
    isLoading: state.dataset.isDatasetGroupLoading || state.dataset.isDatasetLoading
  }),
  { selectAccountSystem, saveHeadClientFile, removeHeadClientFile, turnOnLoading }
)(FileUploadContainer);
