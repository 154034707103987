// @flow
import React from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuWrapper, Menu } from '../../../../common/components/DropdownMenu';
import { MenuHeader } from '../../../../common/components/Menu';
import Button from '../../../../common/components/Button';
import TextLabel from '../../../../common/components/TextLabel';

const CustomTextLabel = styled(TextLabel)`
  padding: 2px 20px;
  font-size: ${props => props.theme.fonts.size.body.l};
  cursor: pointer;
`;

const CustomButton = styled(Button)`
  display: flex;
  cursor: pointer;
`;

type AddColumnProps = {
  onMouseLeave: Function,
  visible: boolean,
  onClick: Function,
  toggleFsli: Function,
  text: string,
  type?: string
};
const ToggleCustomColumn = ({
  onMouseLeave,
  visible,
  onClick,
  toggleFsli,
  text,
  type
}: AddColumnProps) => (
  <MenuWrapper noPadding onMouseLeave={onMouseLeave}>
    <Button
      data-cy={`toggle-menu-link-${type || 'type-not-specified'}`}
      onClick={onClick}
      size="none"
      link
    >
      <MoreVertIcon />
    </Button>
    <Menu data-cy={`toggle-menu-${type || 'type-not-specified'}`} visible={visible}>
      <MenuHeader>
        <CustomButton
          data-cy={`toggle-menu-add-${type || 'type-not-specified'}`}
          onClick={toggleFsli}
          size="none"
          link
        >
          <CustomTextLabel>{text}</CustomTextLabel>
        </CustomButton>
        <Button onClick={onMouseLeave} size="none" link>
          <MoreVertIcon />
        </Button>
      </MenuHeader>
    </Menu>
  </MenuWrapper>
);

ToggleCustomColumn.defaultProps = {
  type: 'type-not-specified'
};

export default ToggleCustomColumn;
