// @flow
import styled from 'styled-components';

const ActionItems = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    color: ${props => props.theme.color.icon.default};
  }
`;

export default ActionItems;
