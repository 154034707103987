// @flow
import React from 'react';
import { type List as ListType } from 'immutable';
import styled from 'styled-components';

import UploadZone from './UploadZone';
import UploadedFiles from './UploadedFiles';
import ErrorFileUpload from './ErrorFileUpload';

type Props = {
  selectedFilesList?: ListType<Object> | null,
  rejectedFilesList?: ListType<Object> | null,
  saveFile?: Function,
  removeFile?: Function,
  size?: string,
  disabled?: boolean,
  isLoading?: boolean,
  title?: string
};

const Container = styled.div`
  background: ${props =>
    props.disabled ? props.theme.color.ui.disabled : props.theme.color.ui.light};
  width: ${props => (props.size === 'small' && '100%') || '35%'};
  margin: ${props => props.size === 'small' && props.theme.space.stack.s};
  border: 2px dashed ${props => props.theme.color.border.default};
`;

/**
 * - For a smaller FileSelector, we display the upload zone and
 * the selected files inside a same container.
 * - For a default sized FileSelector, we display the upload zone and
 * the selected files separately.
 * - If no size is provided, the default FileSelector will be rendered.
 */
const FileSelector = ({
  selectedFilesList,
  rejectedFilesList,
  saveFile,
  removeFile,
  size,
  disabled,
  isLoading,
  title
}: Props) =>
  size === 'small' ? (
    <Container size={size} disabled={disabled}>
      <UploadZone
        size={size}
        saveFile={saveFile}
        disabled={disabled}
        isLoading={!!isLoading}
        dataCy={title && `file-upload-${title}`}
      />
      {selectedFilesList && selectedFilesList.size > 0 && (
        <UploadedFiles selectedFilesList={selectedFilesList} removeFile={removeFile} size={size} />
      )}
      {rejectedFilesList && rejectedFilesList.size > 0 && (
        <ErrorFileUpload rejectedFilesList={rejectedFilesList} />
      )}
    </Container>
  ) : (
    <>
      <UploadZone
        dataCy={
          title &&
          `file-upload-${title
            .toLowerCase()
            .split(' ')
            .join('-')}`
        }
        saveFile={saveFile}
        disabled={disabled}
        isLoading={!!isLoading}
      />
      {rejectedFilesList && rejectedFilesList.size > 0 && (
        <ErrorFileUpload rejectedFilesList={rejectedFilesList} />
      )}
      {selectedFilesList && selectedFilesList.size > 0 && (
        <UploadedFiles selectedFilesList={selectedFilesList} removeFile={removeFile} />
      )}
    </>
  );

FileSelector.defaultProps = {
  selectedFilesList: null,
  rejectedFilesList: null,
  saveFile: () => {},
  removeFile: () => {},
  size: 'default',
  disabled: false,
  isLoading: false,
  title: 'none'
};

export default FileSelector;
