const en = {
  fileUpload: {
    label: 'Drag and drop file(s) or select them from your computer by clicking this box.',
    iPowerWarning: 'Please note that the new clients you add will not be added to iPower.',
    addNewSubsidiary: '+ Add a new subsidiary',
    cancelSubsidiaryAdding: '- Cancel adding a client',
    noSubsidiaryReminder:
      ' No subsidiary for the current client. You can add more subsidiaries and upload files for them.'
  },
  jobAndPeriod: {
    stepTitle: 'Job and dataset period',
    stepSubTitle: 'Select a job and dataset period',
    datePickerLabel: 'Dataset period',
    noDateSelectedLabel: 'Select job and dataset period above to proceed',
    dateSelectedLabel: 'Job and dataset period selected'
  },
  jobSelector: {
    searchSelectionPlaceholder: 'Select the job from the dropdown menu',
    noJobListWarning:
      'The selected client does not have any active jobs in Ipower. Please select another client or create a job for this client.'
  }
};
export default en;
