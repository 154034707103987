// @flow
import { List, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';
import { get, put, post } from '../../common/utils/api';
import type { Signers } from '../types/signer';
import type { JobList } from '../../common/types/jobs';
import type { Hierarchy } from '../../common/types/table';
import type { RollForward, RollForwards } from '../types/rollForward';
import type { AuraDatabases } from '../types/auraDatabase';
import type { GetAuditsQuery } from '../types/audit';
import { initHierarchy } from '../../common/utils/tableItem';
import endpoint from '../constants/endpoint';

const auraDatabase = new schema.Entity('auraDatabase');

// clientId:{clientId: string, jobId: string}
const initJob = new schema.Entity(
  'jobList',
  {},
  {
    idAttribute: 'id',
    processStrategy: value => ({
      clientId: value.id,
      jobId: value.jobId
    })
  }
);

const initJobList = new schema.Array(initJob);
const auraDatabases = new schema.Array(auraDatabase);

type GetRollForwardsParam = {
  clientId: string,
  jobId: string
};

export default {
  getSigner: async (): Promise<Signers> => {
    const signers = await get(endpoint.signer.get());
    return List(signers);
  },
  getOnboardingStatus: async (): Promise<boolean> => get(endpoint.onBoarding.get()),
  getClient: async (input: any): Promise<Signers> => {
    const clients = await get(endpoint.client.get(), input);
    return List(clients);
  },

  // This function returns two value,
  // hierarchy is for UI rendering the auditor list
  // jobList is prepate the jobId list sendint to backend when user submit the form
  getAudits: async (query: GetAuditsQuery | {}, preSelected: boolean): Promise<Hierarchy> => {
    const data = await get(endpoint.audit.get(), query);

    const list = List(
      data
        .filter(d => (d.hasSubsidiaries && d.children) || !d.hasSubsidiaries)
        .map(d => ({
          ...d,
          children: d.children.map(child => ({
            ...child,
            periodEndMonth: child.periodEndMonth - 1
          })),
          periodEndMonth: d.periodEndMonth - 1
        }))
    );
    // generate a key value pair for jobList
    const normalized = normalize(data, initJobList);
    return {
      hierarchy: initHierarchy(list, preSelected),
      jobList: OrderedMap(normalized.entities.jobList)
    };
  },

  getRollForwards: async (params: Array<GetRollForwardsParam>): Promise<RollForwards> => {
    const data = await post(endpoint.rollForward.post(), params);
    return initHierarchy(
      List(
        data.map(client => ({
          ...client,
          children: client.children.map(child => ({
            ...child,
            headClientId: client.id
          })),
          auraDatabase: {
            ...client.auraDatabase,
            isAuraAsParent: !client.auraDatabase || !client.auraDatabase.id
          }
        }))
      ),
      true
    );
  },
  getAuraDatabases: async (name: string): Promise<AuraDatabases> => {
    const data = await get(endpoint.auraDatabase.get(), { name });
    const normalized = normalize(data, auraDatabases);
    return Map(normalized.entities.auraDatabase);
  },
  getJobList: async (clientIds: string[]): Promise<Map<String, JobList>> => {
    const data: Map<String, JobList> = await post(endpoint.audit.getJobList(), clientIds);
    return data;
  },
  submitRollForwards: async (rfs: RollForward[]) => put(endpoint.rollForward.put(), rfs)
};
