// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { type Document } from '../../../../types';
import { addExistingDocument } from '../../../../duck/TaxReturnPage';

interface StateProps {
  documents: ImmutableList<Document>;
}

type OwnProps = {
  open: boolean,
  onClose: Function
};

interface DispatchProps {
  addExistingDocument: Function;
}

type Props = StateProps & OwnProps & DispatchProps;

const AddExistingDocumentDialog = ({ documents, open, onClose, ...dispatchProps }: Props) => {
  const [documentId, setDocumentId] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [sectionName, setSectionName] = useState('');

  const handleDocumentChange = event => {
    setDocumentId(event.target.value);
    const document = documents.find(doc => doc.get('id') === event.target.value);
    setSelectedDocument(document);
    setSectionName(document.get('name'));
  };

  const handleAddExistingDocument = () => {
    dispatchProps.addExistingDocument(documentId, sectionName);
    onClose();
    setDocumentId('');
    setSelectedDocument(null);
    setSectionName('');
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Add existing document</DialogTitle>
      <DialogContent>
        {documents && documents.size > 0 ? (
          <>
            <FormControl fullWidth>
              <InputLabel id="document-select-label">Document</InputLabel>
              <Select
                labelId="document-select-label"
                value={documentId}
                onChange={handleDocumentChange}
              >
                {documents.map(document => (
                  <MenuItem key={document.get('id')} value={document.get('id')}>
                    {document.get('filename') || document.get('name')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Section name"
              value={sectionName}
              onChange={event => setSectionName(event.target.value)}
            />
            {selectedDocument ? (
              <>
                <p>{`Document name: ${selectedDocument.get('name')}`}</p>
                <p>{`Category: ${selectedDocument.get('category')}`}</p>
              </>
            ) : null}
          </>
        ) : (
          'No addable documents found'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" onClick={handleAddExistingDocument} disabled={!documentId}>
          Add document
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { taxReturnData, documents } = state.veromylly.taxReturnPage;
  if (!documents || documents.size === 0) {
    return {
      documents: ImmutableList([])
    };
  }
  const addedDocumentIds = taxReturnData.get('documents').map(section => section.get('id'));
  return {
    documents: documents.filter(document => !addedDocumentIds.includes(document.get('id')))
  };
};

const mapDispatch = {
  addExistingDocument
};

export default connect(
  mapStateToProps,
  mapDispatch
)(AddExistingDocumentDialog);
