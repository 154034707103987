// @flow
import { Record, List, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import { type ActionType } from '../../common/types/redux';
import { type ServiceOrderState } from '../types';
import service from '../services';

const defaultState = Record({
  selectedLeader: List([]),
  selectedClients: OrderedMap({})
});

export const setLeader = createAction('SET_LEADER');
export const clearLeader = createAction('CLEAR_LEADER');
export const getClients = createAction('GET_CLIENTS', (name, engagementContactName) =>
  service.fetchClients(name, engagementContactName)
);

export const addClient = createAction('ADD_CLIENT');
export const addHeadClient = createAction('ADD_HEAD_CLIENT');
export const removeClient = createAction('REMOVE_CLIENT');
export const clearClients = createAction('CLEAR_CLIENTS');
export const toggleClientSelection = createAction('TOGGLE_CLIENT_SELECTION');

const Reducer = (state: ServiceOrderState = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_LEADER': {
      return state.set('selectedLeader', List([payload]));
    }
    case 'CLEAR_LEADER': {
      return state.set('selectedLeader', List([]));
    }
    case 'ADD_CLIENT': {
      return payload.id
        ? state.setIn(['selectedClients', payload.id], { ...payload, isSelected: true })
        : state;
    }
    case 'ADD_HEAD_CLIENT': {
      return state.set(
        'selectedClients',
        OrderedMap([
          [payload.id, { ...payload, isSelected: true }],
          ...payload.subsidiaries.map(s => [s.id, { ...s, isSelected: true }])
        ])
      );
    }
    case 'GET_CLIENTS_REJECTED': {
      return state.set('selectedClients', OrderedMap({}));
    }
    case 'GET_CLIENTS_FULFILLED': {
      return state.set(
        'selectedClients',
        OrderedMap(
          payload.map(client => [
            client.id,
            {
              ...client,
              isSelected: false
            }
          ])
        )
      );
    }
    case 'TOGGLE_CLIENT_SELECTION': {
      return payload.id
        ? state.setIn(['selectedClients', payload.id, 'isSelected'], payload.value)
        : state;
    }
    case 'REMOVE_CLIENT': {
      return payload.id ? state.deleteIn(['selectedClients', payload.id]) : state;
    }
    case 'CLEAR_CLIENTS': {
      return state.set('selectedClients', OrderedMap({}));
    }
    default:
      return state;
  }
};

export default Reducer;
