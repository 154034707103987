// @flow
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Home, Assignment, Save } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Map } from 'immutable';

import { type TaxReturn, type TaxReturnData, type UserRole } from '../../../types';
import Button from '../../../../common/components/Button';
import Reviewer from './Reviewer';
import SideBarAction from './SideBarAction';
import {
  fetchTaxReturn,
  saveTaxReturn,
  updateReviewer,
  sendForReview,
  approveTaxReturn,
  rejectTaxReturn
} from '../../../duck/TaxReturnPage';
import LanguageSelect from './LanguageSelect';
import AssignmentCountry from './AssignmentCountry';

const SideBarColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 250px;

  & > button {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-top: 2px;
  }
`;

interface DispatchProps {
  fetchTaxReturn: Function;
  saveTaxReturn: Function;
  updateReviewer: Function;
  sendForReview: Function;
  approveTaxReturn: Function;
  rejectTaxReturn: Function;
}

interface StateProps {
  taxReturn: TaxReturn;
  unsavedChanges: boolean;
  userRole: UserRole;
  taxReturnData: TaxReturnData;
  loading: Map<{ isLoading: boolean, msg: string }>;
}

interface OwnProps {
  handleSave: () => Promise<void>;
}

type Props = StateProps & DispatchProps & OwnProps;

const SideBarContent = ({
  taxReturn,
  unsavedChanges,
  userRole,
  taxReturnData,
  loading,
  handleSave,
  ...dispatchProps
}: Props) => {
  const history = useHistory();
  const isLoading = useMemo(() => (loading ? loading.isLoading : false), [loading]);
  return (
    <SideBarColumn data-cy="sidebar-container">
      <Button
        data-cy="sidebar-home-button"
        solid
        primary
        onClick={() => history.push('/veromylly')}
      >
        <Home /> Home
      </Button>
      <Button data-cy="sidebar-preparer-button" solid primary style={{ cursor: 'default' }}>
        <Assignment /> {taxReturn.preparer.name}
      </Button>
      <AssignmentCountry />
      <LanguageSelect />
      <Reviewer taxReturn={taxReturn} updateReviewer={updateReviewer} />
      <SideBarAction />
      <Button
        data-cy="sidebar-save-button"
        solid
        primary
        disabled={isLoading || !unsavedChanges}
        onClick={async () => {
          await handleSave();
          await dispatchProps.fetchTaxReturn(parseInt(taxReturn.id, 10));
        }}
      >
        <Save />
        Save
      </Button>
    </SideBarColumn>
  );
};

const mapStateToProps = state => {
  const {
    taxReturn,
    taxReturnData,
    unsavedChanges,
    userRole,
    loading
  } = state.veromylly.taxReturnPage;
  return {
    taxReturn,
    unsavedChanges,
    userRole,
    taxReturnData,
    loading
  };
};

const mapDispatch = {
  fetchTaxReturn,
  saveTaxReturn,
  updateReviewer,
  sendForReview,
  approveTaxReturn,
  rejectTaxReturn
};

export default connect(
  mapStateToProps,
  mapDispatch
)(SideBarContent);
