// @flow
import React from 'react';
import Info from '@material-ui/icons/Info';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import TextLabel from '../../../../../common/components/TextLabel';
import { DoneIcon } from '../../../../../common/components/Icons';

export default () => (
  <div data-cy="client-search-hint">
    <TextLabel data-cy="client-search-hint-header">
      <h3>
        Nice! You have done all the possible roll-forwards for now.
        <DoneIcon style={{ fontSize: 'xx-large' }} />
      </h3>
    </TextLabel>

    <ExpansionPanel>
      <ExpansionPanelSummary>
        <TextLabel data-cy="client-search-hint-info" style={{ cursor: 'pointer' }}>
          <Info /> Not seeing the client(s) to roll-forward?
        </TextLabel>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <TextLabel bold>Ensure the following:</TextLabel>
        <ol>
          <li>
            <TextLabel>
              Are <b>BOTH</b> the client and the ultimate head client (UHC) in Channel 1?
              <br />- If not, contact the IPower data quality to fix the issue.
            </TextLabel>
          </li>
          <li>
            <TextLabel>
              Have <b>BOTH</b> the client and ultimate head client financial year end dates passed?
              <br />- The roll-forward can be started when the end dates have been passed.
            </TextLabel>
          </li>
          <li>
            <TextLabel>
              Are there jobs to roll-forward for each client?
              <br /> - Subsidiaries must be rolled at the same time with their ultimate head client.
              <br /> - UHC must be rolled together with the subsidiaries.
            </TextLabel>
          </li>
        </ol>
        <TextLabel>
          Please use <b>Activity log</b> to check roll-forwards from last 12 months.
        </TextLabel>
        <TextLabel>
          If you have ensured that above is not the case, please raise a ticket in Service Now.
        </TextLabel>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </div>
);
