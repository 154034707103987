// @flow
import React from 'react';
import { connect } from 'react-redux';

import AccountMapping from './components/AccountMapping';
import {
  getAccountMappingList,
  saveAccountMappingList,
  restoreAccountMappingList,
  setFSLI,
  updateCustomFsli,
  removeCustomColumn,
  toggleCustomColumn,
  toggleCustomInput,
  clearAccountMapping
} from '../../duck/accountMapping';

const AccountMappingContainer = (props: any) => {
  /**
   * Read datasetGroupId from the URL
   */
  let datasetGroupId = '';
  const { history } = props;
  if (history.location && history.location.search) {
    const { search } = history.location;
    datasetGroupId = search.split('=') && search.split('=')[1] ? search.split('=')[1] : '';
  }

  const goToActivityLog = () => {
    history.push('/general-ledger/activity-log');
  };

  return (
    <AccountMapping goToActivityLog={goToActivityLog} datasetGroupId={datasetGroupId} {...props} />
  );
};

export default connect(
  state => ({
    isLoading: state.accountMapping.get('isLoading'),
    isSaving: state.accountMapping.get('isSaving'),
    isSavingSuccess: state.accountMapping.get('isSavingSuccess'),
    accountMappingList: state.accountMapping.get('accountMappingList'),
    showCustomColumn: state.accountMapping.get('showCustomColumn'),
    showCustomInput: state.accountMapping.get('showCustomInput'),
    isFSLIMappingQueued: state.accountMapping.get('isFSLIMappingQueued'),
    isRejected: state.accountMapping.get('isRejected')
  }),
  {
    getAccountMappingList,
    saveAccountMappingList,
    restoreAccountMappingList,
    setFSLI,
    updateCustomFsli,
    removeCustomColumn,
    toggleCustomColumn,
    toggleCustomInput,
    clearAccountMapping,
  }
)(AccountMappingContainer);
