// @flow
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { type SimpleDropdownItem } from '../../types/list';

type Props = {
  options: Array<SimpleDropdownItem>,
  onChange: Function,
  placeholder?: string,
  noPadding?: boolean
};

const Wrapper = styled.div`
  width: ${props => (props.customWidth ? props.customWidth : '260px')};
  background: ${props => props.theme.color.background.white};
  border: solid 1px ${props => props.theme.color.border.default};
  border-radius: ${props => props.theme.border.type.round};
  padding: ${props =>
    !props.noPadding && ` ${props.theme.space.single.s} ${props.theme.space.single.m}`};
`;

// override external library
const StyledSelect = styled(Select)`
  .select__control {
    background-color: ${props => props.theme.color.background.white};
    border: none;
    box-shadow: none;
  }
  .select__multi-value__label {
    color: ${props => props.theme.color.background.white};
    background: ${props => props.theme.color.ui.primary};
    font-size: ${props => props.theme.fonts.size.body.xs};
  }
`;

const MultiSelect = ({ options, onChange, placeholder, noPadding }: Props) => (
  <Wrapper noPadding={noPadding} data-cy="multi-select">
    <StyledSelect
      placeholder={placeholder}
      classNamePrefix="select"
      options={options}
      onChange={onChange}
      isMulti
    />
  </Wrapper>
);

MultiSelect.defaultProps = {
  placeholder: 'Select ...',
  noPadding: false
};

export default MultiSelect;
