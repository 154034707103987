// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Business from '@material-ui/icons/Business';
import ClientsTable from './ClientsTable';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import InfoLabel from '../../../../common/components/InfoLabel';
import DialogBox from '../../../../common/components/canvas/DialogBox';
import Alink from '../../../../common/components/Link';
import type { KycRoundIdentifiers } from '../../../../common/types/kyc';
import type {
  OpportunityOrderedMap,
  OpportunityWithKey
} from '../../../../common/types/opportunity';

type ClientsProps = {
  isLoading: number,
  opportunities: OpportunityOrderedMap,
  changeOpportunityStatus: Function,
  getKycRound: Function,
  onClickSave: Function
};

type Props = StepProps & ClientsProps;

const DialogBoxContentWrapper = styled.div`
  > div {
    padding-bottom: ${props => props.theme.space.single.m};
    width: 555px;
  }
`;

export default function Clients({
  opportunities,
  changeOpportunityStatus,
  theme,
  active,
  done,
  pending,
  isLoading,
  onClickSave,
  onClickEdit,
  getKycRound
}: Props) {
  const [selectedClient, setSelectedClient] = useState<OpportunityWithKey | null>(null);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);

  useEffect(() => {
    if (active) window.scrollTo(0, 0);
  }, [active]);

  useEffect(() => {
    setSelectedClient(null);
  }, [opportunities]);

  return (
    <Step
      title="Clients"
      subtitle="Select client for AML Check"
      Icon={Business}
      theme={theme}
      active={active}
      done={done}
      pending={pending}
      stepSummaryItems={[
        { title: 'Client selected', text: selectedClient ? selectedClient.client.name : '' }
      ]}
      onClickEdit={onClickEdit}
    >
      <DialogBox
        title="Missing AML Information"
        closeDialog={() => {
          if (selectedClient)
            changeOpportunityStatus({
              key: selectedClient.key,
              isEnabled: false
            });
          setShowDialogBox(false);
        }}
        content={() => (
          <DialogBoxContentWrapper>
            <div>
              Previous AML information is missing for this existing client. Please follow AML
              identification instructions in
              <Alink
                href="notes://FI-HELAPP001/802578960043DE10/9BE7A583CF282EAB85257EA500464990/770E3A6D2D64CA4EC22578F8002738FA7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Matrisk (20. Anti-Money laundering)
              </Alink>
              in order to perform AML procedures for this client.
            </div>
            <div>For any questions, please contact Client Desk (fi_kyc@pwc.com).</div>
          </DialogBoxContentWrapper>
        )}
        isOpen={showDialogBox}
      />
      {opportunities.size === 0 ? (
        <InfoLabel text="There are no open AML Checks for this selection." />
      ) : (
        <>
          <InfoLabel text="Select one client at a time. Please note that you can only submit the information, it is not possible to save and continue later." />
          <ClientsTable
            opportunities={opportunities}
            onSelectClient={client => {
              setSelectedClient(client);
            }}
            selectedClient={selectedClient}
            isLoading={!!isLoading}
          />
          <ActionBar
            status={
              selectedClient
                ? 'Press ’Proceed’ to continue to next step'
                : 'Select a client to proceed'
            }
            isLoading={!!isLoading}
            proceed={{
              name: 'Proceed',
              callback: async () => {
                if (selectedClient) {
                  const kycRound = await getKycRound(
                    ({
                      clientId: selectedClient.client.id,
                      opportunityId: selectedClient.id,
                      opportunityName: selectedClient.name
                    }: KycRoundIdentifiers)
                  );
                  if (kycRound.value.kycStaticInformation.amlClassification) onClickSave();
                  else setShowDialogBox(true);
                }
              },
              isDisabled: !selectedClient
            }}
          />
        </>
      )}
    </Step>
  );
}
