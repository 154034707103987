// @flow
import styled from 'styled-components';

const PageContent = styled.div`
  h2 {
    padding-left: ${props => props.theme.space.single.s};
  }
  h2:not(:first-of-type) {
    padding-top: ${props => props.theme.space.single.l};
  }
`;
export default PageContent;
