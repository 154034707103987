// @flow
import { List } from 'immutable';
import { get, put, post } from '../../common/utils/api';
import type {
  ClientList,
  ApiGetContactPerson,
  ApiPutContactPerson,
  ApiPostContactPerson,
  GetContactPersonList
} from '../../common/types/client';
import type {
  KycRoundGet,
  ApiKycRoundGet,
  KycRoundIdentifiers,
  KycRoundPut
} from '../../common/types/kyc';
import type { EmployeeList } from '../../common/types/employee';
import type { LosList } from '../../common/types/los';
import type {
  ApiOpportunity,
  OpportunityOrderedMap,
  OpportunityQuery
} from '../../common/types/opportunity';
import { dataMappingToSimpleDropdown } from '../../common/utils/helper';
import { initOpportunityOrderedMap } from '../../common/utils/tableItem';

export default {
  getOnboardingStatus: async (): Promise<Boolean> => {
    const onboardingStatus = await get('aml/onboarding');
    return onboardingStatus;
  },
  getEmployees: async (input: string): Promise<EmployeeList> => {
    const employees = await get('aml/employee', { name: input });
    return List(employees);
  },
  getClients: async (input: string): Promise<ClientList> => {
    const clients = await get('aml/client', { name: input, status: 'active' });
    return List(clients);
  },
  getLineOfServices: async (): Promise<LosList> => {
    const lineOfServices = await get('lineOfService');
    return List(dataMappingToSimpleDropdown(lineOfServices));
  },
  getOpportunities: async (query: OpportunityQuery): Promise<OpportunityOrderedMap> => {
    const opportunityData: Array<ApiOpportunity> = await get('aml/opportunity', query);

    const updatedOpportunityData = opportunityData.map(data => ({
      ...data,
      client: {
        ...data.client,
        periodEndMonth: data.client.periodEndMonth && data.client.periodEndMonth - 1
      }
    }));

    return initOpportunityOrderedMap(List(updatedOpportunityData));
  },
  getKycRound: async (identifiers: KycRoundIdentifiers): Promise<KycRoundGet> => {
    const { clientId, opportunityId } = identifiers;
    const kycRound: ApiKycRoundGet = await get(
      `aml/kycRound/client/${clientId}/opportunity/${opportunityId}`
    );
    return kycRound;
  },
  getContactPersons: async (input: string): Promise<GetContactPersonList> => {
    const contactPersons: Array<ApiGetContactPerson> = await get('aml/contactPerson', {
      name: input
    });
    return List(
      contactPersons.map(contactPerson => ({
        ...contactPerson,
        isActive: false
      }))
    );
  },
  putContactPersons: async (
    clientId: number,
    contactPersons: Array<ApiPutContactPerson>
  ): Promise<Array<ApiGetContactPerson>> => {
    const res = await put(`aml/client/${clientId}/contactPerson`, contactPersons);
    return res;
  },
  postContactPerson: async (
    clientId: number,
    contactPerson: ApiPostContactPerson
  ): Promise<ApiGetContactPerson> => {
    const res = await post(`aml/client/${clientId}/contactPerson`, contactPerson);
    return res;
  },
  putKycRound: async (identifiers: KycRoundIdentifiers, kycRound: KycRoundPut) => {
    const { clientId, opportunityId } = identifiers;
    await put(`aml/kycRound/client/${clientId}/opportunity/${opportunityId}`, kycRound);
  }
};
