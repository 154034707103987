/* eslint-disable react/destructuring-assignment */
// @flow
import React, { PureComponent, Fragment } from 'react';
import { get } from 'lodash';
import Table from '../../common/Table';
import AuraDatabasesHint from './hints/AuraDatabase';

import Row from './Row';

import type { RollForwards } from '../../../types/rollForward';
import type { AuraDatabases } from '../../../types/auraDatabase';

const headers = [
  'Client name',
  'Job memo required',
  {
    text: 'Auditor signing the opinion is selected as company (PwC) or single person'
  },
  {
    text: 'Roll-forward Aura database',
    hint: AuraDatabasesHint
  }
];
const indicators = [
  {
    name: 'Client Info',
    size: '2'
  },
  {
    name: 'Job Memo',
    size: '1'
  },
  { name: 'Auditor selection criteria', size: '1' },
  {
    name: 'Aura',
    size: '2'
  }
];

const changeChildPropertyWithFilter = (
  children,
  filter,
  onPropertyChange,
  property,
  subProperty,
  newState,
  headClientKey
) => {
  children
    .filter(c => c.datum.getIn(filter))
    .forEach(({ key: childKey }) =>
      onPropertyChange(childKey, property, subProperty, newState, null, headClientKey)
    );
};

const toggleAsParent = (setAsParent, resetAsParent) => (parent: string, child: string) => (
  isAsParent: boolean
) => (isAsParent ? setAsParent(parent, child) : resetAsParent(parent, child));

export type Props = {
  rollForwards: RollForwards,
  selectClient: Function,
  selectClientPartially: Function,
  auraDatabases: AuraDatabases,
  getAuraDatabases: Function,
  updateRollForward: Function,
  updateRollForwardSubProperty: Function,
  deselectedRollForward: Function,
  toggleExpandRollForward: Function,
  setAuraAsParentProperties: Function,
  resetAuraAsParentProperties: Function,
  selectSubsidiary: Function,
  deselectSubsidiary: Function
};

class JobsTable extends PureComponent<Props> {
  toggleAuraAsParent = toggleAsParent(
    this.props.setAuraAsParentProperties,
    this.props.resetAuraAsParentProperties
  );

  parentOnChange = (key: any, property: any, subProperty: any, newState: any, children: any) => {
    this.onPropertyChange(key, property, subProperty, newState);
    if (property === 'auraDatabase') {
      changeChildPropertyWithFilter(
        children,
        ['auraDatabase', 'isAuraAsParent'],
        this.onPropertyChange,
        property,
        subProperty,
        {
          ...newState,
          isAuraAsParent: true
        },
        key
      );
    }
  };

  onPropertyChange = (
    key: any,
    property: any,
    subProperty: any,
    newState: any,
    _: any = null,
    headClientKey?: any = null
  ): any => {
    const { updateRollForward, updateRollForwardSubProperty } = this.props;
    if (subProperty) {
      updateRollForwardSubProperty(key, property, subProperty, newState, headClientKey);
    } else updateRollForward(key, property, newState, headClientKey);
  };

  render() {
    const {
      rollForwards,
      auraDatabases,
      getAuraDatabases,
      selectClientPartially,
      selectClient,
      deselectedRollForward,
      toggleExpandRollForward,
      selectSubsidiary,
      deselectSubsidiary
    } = this.props;

    return (
      <Fragment>
        <Table
          getKey={datum => datum.getIn(['datum', 'id'])}
          getParentKey={datum => datum.getIn(['datum', 'headClientId'])}
          getChildList={datum => datum.getIn(['datum', 'children'])}
          getSubsidiariesStatus={datum =>
            datum.getIn(['datum', 'children']) && datum.getIn(['datum', 'children']).size > 0
          }
          getChildren={datum => get(datum, ['children'])}
          hasEmptyJobList={datum => datum.some(child => !child.getIn(['datum', 'jobList']))}
          data={rollForwards}
          selectClient={selectClient}
          selectClientPartially={selectClientPartially}
          selectedSubsidiary={selectSubsidiary}
          deselectedHeadClient={deselectedRollForward}
          deselectedChild={deselectSubsidiary}
          indicators={indicators}
          headers={headers}
          toggleExpand={toggleExpandRollForward}
          toggleExpandSubsidiary={(key, childKey) => toggleExpandRollForward(childKey)}
        >
          {({ key, datum, expanded, hasChildren, Actions, children }) => (
            <Fragment key={key}>
              <Row
                hasChildren={hasChildren}
                id={key}
                datum={datum}
                Actions={Actions}
                childs={children}
                onChange={this.parentOnChange}
                auraDatabases={auraDatabases}
                getAuraDatabases={getAuraDatabases}
                toggleAuraAsParent={() => {}}
              />
              {expanded &&
                children.map(({ key: childKey, datum: childDatum, Actions: ChildAction }) => (
                  <Row
                    isChildren
                    key={childKey}
                    id={childKey}
                    datum={childDatum}
                    Actions={ChildAction}
                    auraDatabases={auraDatabases}
                    getAuraDatabases={getAuraDatabases}
                    onChange={this.onPropertyChange}
                    toggleAuraAsParent={this.toggleAuraAsParent(key, childKey)}
                    headClientKey={key}
                  />
                ))}
            </Fragment>
          )}
        </Table>
      </Fragment>
    );
  }
}

export default JobsTable;
