import styled from 'styled-components';

const style = props => `
  width: ${props.customWidth ? props.customWidth : 'default'}
  padding: ${props.theme.space.square.m};
  font-size: ${props.theme.fonts.size.body.m};
  border: solid 1px ${props.theme.color.border.default};
  border-radius: ${props.theme.border.type.round};
  outline: none;

  &:disabled {
    color: ${props.theme.color.text.neutral};
  }
`;

export const TextArea = styled.textarea`
  ${props => style(props)}
  width: 100%;
  height: 100%;
  resize: ${props => (!props.resize ? 'none' : null)};
`;

const TextInput = styled.input`
  ${props => style(props)}
`;

export default TextInput;
