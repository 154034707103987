// @flow
import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import CloudUpload from '@material-ui/icons/CloudUpload';
import TextLabel from '../../TextLabel';
import { t } from '../../../translations/stringList';
import ProcessBar from '../../ProcessBar';

type Props = {
  saveFile: Function,
  size?: string,
  disabled?: boolean,
  isLoading: boolean,
  dataCy?: string
};

// override external library
const CustomDropzone = styled(Dropzone)`
  background: ${props => (props.size === 'small' ? 'inherit' : props.theme.color.ui.light)};
  width: ${props => (props.size === 'small' ? '100%' : '40%')};
  padding: ${props =>
    (props.size === 'small' && props.theme.space.squish.s) || props.theme.space.squish.m};
  margin-bottom: ${props => (props.size === 'small' && '4px') || '8px'};
  border: ${props =>
    props.size === 'small' ? '' : `2px dashed ${props.theme.color.border.default}`};
  text-align: center;
`;

const UploadZone = ({ saveFile, size, disabled, isLoading, dataCy }: Props) => {
  const handleFilesDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) return;
    acceptedFiles.forEach(file => {
      saveFile(file);
    });
  };

  return (
    <CustomDropzone disabled={disabled} size={size} data-cy={dataCy} onDrop={handleFilesDrop}>
      <CloudUpload />
      <TextLabel>{t('fileUpload', 'label')}</TextLabel>
      {isLoading && <ProcessBar />}
    </CustomDropzone>
  );
};

UploadZone.defaultProps = {
  size: 'default',
  disabled: false,
  dataCy: 'none'
};

export default UploadZone;
