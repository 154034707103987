import styled from 'styled-components';
import TextLabel from '../../../../common/components/TextLabel';
import Alink from '../../../../common/components/Link';

export const FlexDisplay = styled.div`
  display: flex;
`;
export const FlexAlignCenter = styled(FlexDisplay)`
  align-items: center;
`;
export const FlexSpaceBetween = styled(FlexDisplay)`
  justify-content: space-between;
`;
export const GridDisplay = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;
export const HoverLink = styled(Alink)`
  cursor: pointer;
`;
export const CustomLink = styled(HoverLink)`
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.space.single.s};
`;
export const SubSection = styled.div`
  width: 50%;
  padding: 1em;
  background: ${props =>
    props.left ? props.theme.color.ui.primary : props.theme.color.background.white};
  color: ${props =>
    props.left ? props.theme.color.background.light : props.theme.color.text.neutral};
`;
export const CustomTextLabel = styled(TextLabel)`
  color: ${props =>
    props.left ? props.theme.color.background.light : props.theme.color.text.neutral};
`;
export const Span = styled.span`
  font-size: ${props => props.theme.fonts.size.heading.s};
  font-weight: bold;
`;
