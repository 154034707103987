import React from 'react';

export default () => (
  <div>
    Client or engagement restrictions: The user engagement team must understand whether any
    restrictions apply relating to the use of SDCs, or the way in which work at the SDC must be
    managed. Restrictions may be imposed by the client (e.g., as set out in the engagement letter)
    or by local laws and regulations and may, e.g., prohibit the use of a SDC (whether in-territory
    or multi-territory), or prohibit the use of SDCs for certain types of work, or relate to client
    competitor restrictions. If such restrictions exist, it is the ultimate responsibility of the
    user engagement team to identify them.
  </div>
);
