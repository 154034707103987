// @flow
import React from 'react';
import styled from 'styled-components';
import { MoodBad } from '@material-ui/icons';

import { SimpleSelect } from '../../../../../common/components/selectors';
import TextLabel from '../../../../../common/components/TextLabel';
import { type TaxYear, type TaxReturn, type Client, type Assignee } from '../../../../types';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
    color: green;
  }
  & > *:first-child {
    min-width: 200px;
  }
`;

interface OwnProps {
  existingTaxReturn: TaxReturn | null;
  selectedYear: TaxYear | null;
  selectedClient: Client | null;
  selectedAssignee: Assignee | null;
  handleClientChange: (newClient: Client) => void;
}

type Props = OwnProps;

const ClientInfo = ({
  existingTaxReturn,
  selectedYear,
  selectedClient,
  selectedAssignee,
  handleClientChange
}: Props) => {
  if (existingTaxReturn) {
    return <TextLabel data-cy="client-info">{existingTaxReturn.client.name}</TextLabel>;
  } else if (selectedYear) {
    return selectedAssignee && selectedAssignee.clients && selectedAssignee.clients.length > 0 ? (
      <SelectContainer>
        <SimpleSelect
          name="client"
          optionList={selectedAssignee.clients.map(c => ({ label: c.name, value: c }))}
          onChange={c => handleClientChange(c.value)}
          value={selectedClient ? { label: selectedClient.name, value: selectedClient } : null}
        />
      </SelectContainer>
    ) : (
      <TextLabel>
        No clients found <MoodBad />
      </TextLabel>
    );
  }
  return <></>;
};

export default ClientInfo;
