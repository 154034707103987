// @flow
import React from 'react';
import Checkbox from '../checkbox/Checkbox';
import Row, { Td } from './TableRow';
import TableHeader, { Th } from './TableHeader';
import StyledTable from '../StyledTable';
import ActionItems from './ActionItems';
import type { OpportunityWithKey, OpportunityOrderedMap } from '../../types/opportunity';

type Props = {
  data: OpportunityOrderedMap,
  headers: Array<string>,
  children: Function,
  onSelectItem: Function,
  selectedClient: OpportunityWithKey | null,
  isLoading: boolean
};

export default function SimpleTable({
  children,
  headers,
  data,
  onSelectItem,
  selectedClient,
  isLoading
}: Props) {
  const createActionColumns = (item: OpportunityWithKey) => () => {
    const isSelected = !!selectedClient && selectedClient.key === item.key;

    return (
      <Td>
        <ActionItems>
          <Checkbox
            isDisabled={isLoading || !item.isEnabled}
            selected={isSelected}
            onClick={() => onSelectItem(isSelected ? null : item)}
          />
        </ActionItems>
      </Td>
    );
  };

  return (
    <StyledTable data-cy="simple-table">
      <thead>
        <Row>
          <Th noPadding />
          {headers.map(TableHeader)}
          <Th noPadding />
        </Row>
      </thead>
      <tbody>
        {data.valueSeq().map(item =>
          children({
            key: item.key,
            item,
            Actions: createActionColumns(item)
          })
        )}
      </tbody>
    </StyledTable>
  );
}
