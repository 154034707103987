/* eslint-disable no-nested-ternary */
// @flow
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { List as ImmutableList } from 'immutable';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import AccordionActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { debounce } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { connect } from 'react-redux';
import AddNewDocumentDialog from './dialogs/AddNewDocumentDialog';
import AddExistingDocumentDialog from './dialogs/AddExistingDocumentDialog';
import {
  type FormField,
  type Comment,
  type Section,
  type Form,
  type DocumentSection,
  type TaxReturnData,
  type FormFieldType,
  type UserRole,
  type Document,
  type DocumentTemplate,
  type Language,
  commentTypes,
  userRoles
} from '../../../types';
import TextLabel from '../../../../common/components/TextLabel';
import { addNewTaxForm, deleteTaxForm } from '../../../duck/TaxReturnPage';

const StyledFormField = styled(Grid)`
  margin-top: ${props => props.theme.space.single.s};
`;

const StyledTextField = styled(TextField)`
  margin-left: ${props => props.theme.space.single.m};
  width: 100%;
  input {
    padding: ${props => props.theme.space.single.s};
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
  }
`;

const StyledTextArea = styled(TextField)`
  margin: ${props => props.theme.space.single.s};
  padding-right: ${props => props.theme.space.single.m};
  width: 100%;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin-left: ${props => props.theme.space.single.s};
`;

const SubsectionHeader = styled.h3`
  margin-top: ${props => props.theme.space.single.xl};
  margin-bottom: ${props => props.theme.space.single.s};
`;

const SubsectionText = styled.h4`
  margin-top: ${props => props.theme.space.single.l};
  margin-bottom: ${props => props.theme.space.single.s};
`;

const StyledWarningIcon = styled(WarningIcon)`
  color: orange;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: green;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: red;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${props => props.color};
`;

const convertFromEnToFi = value => (value && value.length > 0 ? value.replace('.', ',') : '');

const convertFromFiToEn = value => (value && value.length > 0 ? value.replace(',', '.') : '');

const validateValue = (value: any, type: FormFieldType) => {
  if (type === 'number' && value !== undefined && isNaN(convertFromFiToEn(value))) {
    return 'Must be a number';
  }
  if (type === 'date' && !!value && !moment(value, 'YYYY-MM-DD', true).isValid()) {
    return 'Invalid date';
  }
  return null;
};

type FieldProps = { field: FormField, updateFormFieldValue: Function, language: Language };
const TaxFormField = ({ field, updateFormFieldValue, language }: FieldProps) => {
  const [inputValue, setInputValue] = useState(field.get('value'));
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateFormFieldValue = useCallback(
    debounce(value => updateFormFieldValue(value === '' ? null : value), 300),
    []
  );
  const onValueChange = value => {
    const updateValue = field.get('type') === 'number' ? convertFromFiToEn(value) : value;
    setInputValue(updateValue);
    debounceUpdateFormFieldValue(updateValue);
  };

  useEffect(() => {
    setErrorMessage(validateValue(inputValue, field.get('type')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  // A workaround for now, only update if imported
  // Not using field as trigger to avoid unneccessary update
  useEffect(() => {
    setInputValue(field.get('value'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.get('imported')]);

  const convertedValue = value => {
    let newValue = value;
    if (!isNaN(convertFromFiToEn(value)) && !isFieldChanged) {
      // formula to round to 2 decimal places
      newValue = String(Math.round((Number(newValue) + Number.EPSILON) * 100) / 100);
    }

    if (language && language.name === 'Finnish') newValue = convertFromEnToFi(newValue);
    else newValue = convertFromFiToEn(newValue);
    return newValue;
  };

  return (
    <>
      {field.get('preheader') !== null ? (
        <SubsectionHeader> {field.get('preheader')} </SubsectionHeader>
      ) : null}
      {field.get('pretext') !== null ? (
        <SubsectionText> {field.get('pretext')} </SubsectionText>
      ) : null}
      <StyledFormField container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={3}>
          {field.get('name')}
        </Grid>
        <Grid item xs={9}>
          {field.get('type') === 'boolean' ? (
            <Checkbox
              checked={inputValue === null ? false : inputValue}
              disabled={!!field.get('formula')}
              color="primary"
              onChange={event => onValueChange(event.target.checked)}
            />
          ) : (
            <StyledTextField
              value={
                inputValue === null
                  ? ''
                  : field.get('type') === 'number'
                  ? convertedValue(inputValue)
                  : inputValue
              }
              disabled={!!field.get('formula')}
              type={field.get('type') === 'date' ? 'date' : 'text'}
              variant="outlined"
              backgroundColor={
                field.get('formula')
                  ? 'LightGrey'
                  : field.get('imported')
                  ? 'LightGoldenRodYellow'
                  : 'White'
              }
              onChange={event => {
                setIsFieldChanged(true);
                onValueChange(event.target.value);
              }}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        </Grid>
      </StyledFormField>
    </>
  );
};
export const TaxFormFieldContainer = connect(state => ({
  language: state.veromylly.taxReturnPage.taxReturn.language
}))(TaxFormField);

type CommentListProps = { comments: ImmutableList<Comment> };
const TaxCommentList = ({ comments }: CommentListProps) => (
  <List>
    {comments.map((comment, commentIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={commentIndex}>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <StyledAvatar
              color={
                // eslint-disable-next-line no-nested-ternary
                comment.get('type') === commentTypes.COMMENT
                  ? 'gray'
                  : comment.get('type') === commentTypes.APPROVAL
                  ? 'green'
                  : 'red'
              }
            >
              {// eslint-disable-next-line no-nested-ternary
              comment.get('type') === commentTypes.COMMENT ? (
                <AccountBoxIcon />
              ) : comment.get('type') === commentTypes.APPROVAL ? (
                <AssignmentTurnedInIcon />
              ) : (
                <AssignmentLateIcon />
              )}
            </StyledAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${comment.get('author')} ${
              // eslint-disable-next-line no-nested-ternary
              comment.get('type') === commentTypes.COMMENT
                ? 'left a comment'
                : comment.get('type') === commentTypes.APPROVAL
                ? 'approved this section'
                : 'requested changes'
            }`}
            secondary={
              comment.get('content') ? (
                <span>
                  {comment
                    .get('content')
                    .split('\n')
                    .map((line, lineIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={lineIndex}>
                        {line} <br />
                      </span>
                    ))}
                </span>
              ) : null
            }
          />
        </ListItem>
      </div>
    ))}
  </List>
);

const getExpandIcon = (section: Section, userRole?: UserRole) => {
  if (section.get('rejected')) {
    return <StyledErrorIcon />;
  }
  if (section.get('activated') && section.get('approved')) {
    return <StyledCheckCircleIcon />;
  }
  if (section.get('activated') && !section.get('approved') && userRole === userRoles.REVIEWER) {
    return <StyledWarningIcon />;
  }
  return <ExpandMoreIcon />;
};

type SectionProps = {
  section: Section,
  userRole?: UserRole,
  updateFormFieldValue: Function,
  addComment: Function
};
const TaxSection = ({ section, userRole, updateFormFieldValue, addComment }: SectionProps) => {
  const [commentInputHidden, setCommentInputHidden] = useState(true);
  const [commentContent, setCommentContent] = useState(null);
  const [commentType, setCommentType] = useState(
    userRole === userRoles.REVIEWER ? commentTypes.APPROVAL : commentTypes.COMMENT
  );
  const onCommentContentChange = content => setCommentContent(content === '' ? null : content);
  const toggleCommentInput = () => {
    if (commentInputHidden) {
      setCommentType(commentTypes.COMMENT);
    }
    if (!commentInputHidden && userRole === userRoles.REVIEWER) {
      setCommentType(commentTypes.APPROVAL);
    }
    setCommentInputHidden(!commentInputHidden);
  };
  const submitComment = () => {
    if (commentInputHidden && commentType === commentTypes.APPROVAL) {
      addComment({ type: commentType });
    } else {
      addComment({ type: commentType, content: commentContent });
    }
    setCommentInputHidden(true);
    setCommentContent(null);
    setCommentType(userRole === userRoles.REVIEWER ? commentTypes.APPROVAL : commentTypes.COMMENT);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={getExpandIcon(section, userRole)}>
        {section.get('name')}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {section
            .get('formfields')
            .map((field, index) =>
              !field.get('hidden') ? (
                <TaxFormFieldContainer
                  key={field.get('id')}
                  field={field}
                  updateFormFieldValue={value =>
                    updateFormFieldValue(value, 'formfields', index, 'value')
                  }
                />
              ) : null
            )}
        </Grid>
      </AccordionDetails>
      <TaxCommentList comments={section.get('comments')} />
      <div hidden={commentInputHidden}>
        <Divider />
        <StyledTextArea
          value={commentContent === null ? '' : commentContent}
          onChange={event => onCommentContentChange(event.target.value)}
          placeholder="Leave a comment"
          multiline
          rows={4}
          variant="outlined"
        />
        {userRole === userRoles.REVIEWER ? (
          <StyledRadioGroup
            row
            value={commentType}
            onChange={event => setCommentType(event.target.value)}
          >
            <FormControlLabel
              value={commentTypes.COMMENT}
              control={<Radio color="primary" />}
              label="Comment"
            />
            <FormControlLabel
              value={commentTypes.APPROVAL}
              control={<Radio color="primary" />}
              label="Approve"
            />
            <FormControlLabel
              value={commentTypes.REJECTION}
              control={<Radio color="primary" />}
              label="Request changes"
            />
          </StyledRadioGroup>
        ) : null}
      </div>
      <Divider />
      <AccordionActions>
        <Button size="small" onClick={toggleCommentInput}>
          {commentInputHidden ? 'Comment' : 'Cancel'}
        </Button>
        {!(userRole === userRoles.PREPARER && commentInputHidden) ? (
          <Button size="small" color="primary" onClick={submitComment}>
            {// eslint-disable-next-line no-nested-ternary
            commentType === commentTypes.APPROVAL
              ? 'Approve'
              : commentType === commentTypes.REJECTION
              ? 'Request changes'
              : 'Submit'}
          </Button>
        ) : null}
      </AccordionActions>
    </Accordion>
  );
};

type FormProps = {
  form: Form,
  userRole?: UserRole,
  updateFormFieldValue: Function,
  addComment: Function,
  previewTaxForm: Function,
  addNewForm?: Function,
  deleteForm?: Function,
  formIndex?: string,
  tabIndex?: string,
  parentFormIndex?: string
};
const TaxForm = ({
  form,
  formIndex,
  tabIndex,
  userRole,
  updateFormFieldValue,
  addComment,
  previewTaxForm,
  addNewForm,
  deleteForm,
  parentFormIndex
}: FormProps) => (
  <div data-cy={`form-${formIndex || ''}`}>
    <h3>
      {form.get('name')}
      {Number(formIndex) > 0 && parentFormIndex !== undefined && userRole === userRoles.PREPARER && (
        <>
          {' | '}
          <Link
            href="#"
            data-cy={`delete-form-button-${formIndex || ''}`}
            onClick={event => {
              event.preventDefault();
              if (deleteForm)
                deleteForm([tabIndex, 'forms', parentFormIndex, 'subforms', formIndex]);
            }}
          >
            Delete
          </Link>
        </>
      )}
      {form.get('pdfable') ? (
        <>
          {' | '}
          <Link
            href="#"
            onClick={event => {
              event.preventDefault();
              previewTaxForm(form.get('id'));
            }}
          >
            Preview
          </Link>
        </>
      ) : null}
      {form.get('addable') && userRole === userRoles.PREPARER && (
        <>
          {' | '}
          <Link
            href="#"
            data-cy="add-form-button"
            onClick={event => {
              event.preventDefault();
              if (addNewForm) {
                addNewForm(
                  [tabIndex, 'forms', formIndex, 'subforms'],
                  'name',
                  form.get('addable').get(0)
                );
              }
            }}
          >
            Add {form.get('addable').get(0)}
          </Link>
        </>
      )}
    </h3>
    {form.get('subforms')
      ? form.get('subforms').map((subform, index) => (
          <TaxFormContainer
            key={
              subform.get(
                'uuid'
              ) /* Front-end should make sure that duplicate subforms have diferent name */
            }
            tabIndex={tabIndex}
            parentFormIndex={formIndex}
            formIndex={index}
            form={subform}
            userRole={userRole}
            updateFormFieldValue={(value, ...path) =>
              updateFormFieldValue(value, 'subforms', index, ...path)
            }
            addComment={(comment, ...path) => {
              addComment(comment, 'subforms', index, ...path);
            }}
            previewTaxForm={previewTaxForm}
          />
        ))
      : form
          .get('sections')
          .map((section, index) =>
            !section.get('hidden') ? (
              <TaxSection
                key={section.get('name') /* Section name should be unique within a form */}
                section={section}
                userRole={userRole}
                updateFormFieldValue={(value, ...path) =>
                  updateFormFieldValue(value, 'sections', index, ...path)
                }
                addComment={(comment, ...path) => addComment(comment, 'sections', index, ...path)}
              />
            ) : null
          )}
  </div>
);

TaxForm.defaultProps = {
  addNewForm: () => {},
  deleteForm: () => {}
};

const TaxFormContainer = connect(
  () => ({}),
  { addNewForm: addNewTaxForm, deleteForm: deleteTaxForm }
)(TaxForm);
type DocumentSectionProps = {
  section: DocumentSection,
  document?: Document,
  userRole?: UserRole,
  addComment: Function,
  previewDocument: Function
};
const TaxDocumentSection = ({
  section,
  document,
  userRole,
  addComment,
  previewDocument
}: DocumentSectionProps) => {
  const [commentInputHidden, setCommentInputHidden] = useState(true);
  const [commentContent, setCommentContent] = useState(null);
  const [commentType, setCommentType] = useState(
    userRole === userRoles.REVIEWER ? commentTypes.APPROVAL : commentTypes.COMMENT
  );
  const onCommentContentChange = content => setCommentContent(content === '' ? null : content);
  const toggleCommentInput = () => {
    if (commentInputHidden) {
      setCommentType(commentTypes.COMMENT);
    }
    if (!commentInputHidden && userRole === userRoles.REVIEWER) {
      setCommentType(commentTypes.APPROVAL);
    }
    setCommentInputHidden(!commentInputHidden);
  };
  const submitComment = () => {
    if (commentInputHidden && commentType === commentTypes.APPROVAL) {
      addComment({ type: commentType });
    } else {
      addComment({ type: commentType, content: commentContent });
    }
    setCommentInputHidden(true);
    setCommentContent(null);
    setCommentType(userRole === userRoles.REVIEWER ? commentTypes.APPROVAL : commentTypes.COMMENT);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={getExpandIcon(section, userRole)}>
        {section.get('name')}
      </AccordionSummary>
      <AccordionDetails>
        {document ? (
          <Grid container direction="column">
            <StyledFormField container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={3}>
                <TextLabel bold inlineSmall>
                  Name
                </TextLabel>
              </Grid>
              <Grid item xs={9}>
                {section.get('name')}
              </Grid>
            </StyledFormField>
            {document.get('filename') ? (
              <StyledFormField
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <TextLabel bold inlineSmall>
                    Filename
                  </TextLabel>
                </Grid>
                <Grid item xs={9}>
                  {document.get('filename')}
                </Grid>
              </StyledFormField>
            ) : null}
            <StyledFormField container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={3}>
                <TextLabel bold inlineSmall>
                  Category
                </TextLabel>
              </Grid>
              <Grid item xs={9}>
                {document.get('category')}
              </Grid>
            </StyledFormField>
          </Grid>
        ) : (
          'The document linked cannot be found. Either it is deleted or its metadata has been modified so that it does not belong to this tax return anymore. Please check the list of related documents.'
        )}
      </AccordionDetails>
      <TaxCommentList comments={section.get('comments')} />
      <div hidden={commentInputHidden}>
        <Divider />
        <StyledTextArea
          value={commentContent === null ? '' : commentContent}
          onChange={event => onCommentContentChange(event.target.value)}
          placeholder="Leave a comment"
          multiline
          rows={4}
          variant="outlined"
        />
        {userRole === userRoles.REVIEWER ? (
          <StyledRadioGroup
            row
            value={commentType}
            onChange={event => setCommentType(event.target.value)}
          >
            <FormControlLabel
              value={commentTypes.COMMENT}
              control={<Radio color="primary" />}
              label="Comment"
            />
            <FormControlLabel
              value={commentTypes.APPROVAL}
              control={<Radio color="primary" />}
              label="Approve"
            />
            <FormControlLabel
              value={commentTypes.REJECTION}
              control={<Radio color="primary" />}
              label="Request changes"
            />
          </StyledRadioGroup>
        ) : null}
      </div>
      <Divider />
      <AccordionActions>
        {document ? (
          <>
            <Button size="small" onClick={event => previewDocument(document.get('id'))}>
              Preview
            </Button>
            {document.get('viewUrl') ? (
              <Button size="small" onClick={event => window.open(document.get('viewUrl'))}>
                View
              </Button>
            ) : null}
            {document.get('editUrl') ? (
              <Button size="small" onClick={event => window.open(document.get('editUrl'))}>
                Edit
              </Button>
            ) : null}
          </>
        ) : null}

        <Button size="small" onClick={toggleCommentInput}>
          {commentInputHidden ? 'Comment' : 'Cancel'}
        </Button>
        {!(userRole === userRoles.PREPARER && commentInputHidden) ? (
          <Button size="small" color="primary" onClick={submitComment}>
            {// eslint-disable-next-line no-nested-ternary
            commentType === commentTypes.APPROVAL
              ? 'Approve'
              : commentType === commentTypes.REJECTION
              ? 'Request changes'
              : 'Submit'}
          </Button>
        ) : null}
      </AccordionActions>
    </Accordion>
  );
};

type TabProps = {
  data: TaxReturnData,
  userRole?: UserRole,
  documents?: ImmutableList<Document>,
  documentTemplates?: ImmutableList<DocumentTemplate>,
  updateFormFieldValue: Function,
  addComment: Function,
  previewTaxForm: Function,
  previewDocument: Function
};
const TaxTabs = ({
  data,
  userRole,
  documents,
  documentTemplates,
  updateFormFieldValue,
  addComment,
  previewTaxForm,
  previewDocument
}: TabProps) => {
  const [addNewDocumentOpen, setAddNewDocumentOpen] = useState(false);
  const [addExistingDocumentOpen, setAddExistingDocumentOpen] = useState(false);
  return (
    <Tabs>
      <TabList>
        {data.get('tabs').map(tab => (
          <Tab key={tab.get('name')}>{tab.get('name') /* Tab name should be unique */}</Tab>
        ))}
        <Tab>Documents</Tab>
      </TabList>
      {data.get('tabs').map((tab, tabIndex) => (
        <TabPanel key={tab.get('name') /* Tab name should be unique */}>
          {tab.get('forms').map((form, formIndex) => (
            <TaxFormContainer
              key={form.get('uuid') /* Form ID should be unique within a tab */}
              tabIndex={tabIndex}
              formIndex={formIndex}
              form={form}
              userRole={userRole}
              updateFormFieldValue={(value, ...path) =>
                updateFormFieldValue(value, 'tabs', tabIndex, 'forms', formIndex, ...path)
              }
              addComment={(comment, ...path) =>
                addComment(comment, 'tabs', tabIndex, 'forms', formIndex, ...path)
              }
              previewTaxForm={previewTaxForm}
            />
          ))}
        </TabPanel>
      ))}
      <TabPanel>
        <>
          <h3>
            Documents
            {documentTemplates && documentTemplates.size > 0 ? (
              <>
                {' | '}
                <Link
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    setAddNewDocumentOpen(true);
                  }}
                >
                  Add new document
                </Link>
                <AddNewDocumentDialog
                  open={addNewDocumentOpen}
                  onClose={() => setAddNewDocumentOpen(false)}
                />
              </>
            ) : null}
            {documents && documents.size > 0 ? (
              <>
                {' | '}
                <Link
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    setAddExistingDocumentOpen(true);
                  }}
                >
                  Add existing document
                </Link>
                <AddExistingDocumentDialog
                  open={addExistingDocumentOpen}
                  onClose={() => setAddExistingDocumentOpen(false)}
                />
              </>
            ) : null}
          </h3>
          {data.get('documents') ? (
            <>
              {data.get('documents').map((documentSection, documentIndex) => (
                <TaxDocumentSection
                  key={documentSection.get('uuid')}
                  section={documentSection}
                  document={
                    documents &&
                    documents.find(document => document.get('id') === documentSection.get('id'))
                  }
                  userRole={userRole}
                  addComment={comment => addComment(comment, 'documents', documentIndex)}
                  previewDocument={previewDocument}
                />
              ))}
            </>
          ) : null}
        </>
      </TabPanel>
    </Tabs>
  );
};

export default TaxTabs;
