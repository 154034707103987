// @flow
import React from 'react';
import Clear from '@material-ui/icons/Clear';
import { DoneIcon, WarningIcon } from '../../common/components/Icons';
import { type Dataset } from '../types/dataset';

const STATUS_TO_FILTER = [1, 2, 3];

const datasetStatusList = [
  {
    id: 1,
    text: 'Dataset Created'
  },
  {
    id: 2,
    text: 'File(s) Uploaded'
  },
  {
    id: 3,
    text: 'File(s) Validated'
  },
  {
    id: 4,
    text: 'Ready for Processing'
  },
  {
    id: 5,
    text: 'Standardizing Data'
  },
  {
    id: 6,
    text: 'Data Standardized'
  },
  {
    id: 7,
    text: 'Validating Business Logic'
  },
  {
    id: 8,
    text: 'Business Logic Validated'
  },
  {
    id: 9,
    text: 'Loading to App Schema'
  },
  {
    id: 10,
    text: 'Loaded to App Schema'
  },
  {
    id: 20,
    text: 'Files(s) Validation Failed'
  }
];

const fileStatusList = [
  {
    id: 1,
    text: 'Not Processed'
  },
  {
    id: 2,
    text: 'File Imported'
  },
  {
    id: 3,
    text: 'Validation Failed'
  },
  {
    id: 4,
    text: 'File Deleted'
  }
];

export const getStatusText = (id: number) => {
  const status = datasetStatusList.find(st => st.id === id);
  return status ? status.text : '';
};

export const getFileStatusText = (id: number) => {
  const status = fileStatusList.find(st => st.id === id);
  return status ? status.text : '';
};

export const getFileStatusIcon = (status: number) => {
  if (status === 2) {
    return <DoneIcon />;
  } else if (status === 4) {
    return <Clear />;
  }
  return <WarningIcon />;
};

/**
 * Filter out the unnecessary datasets with status (1,2,3)
 */
export const filterDatasets = (datasets: Array<Dataset>): Array<Dataset> =>
  datasets.filter(dataset => STATUS_TO_FILTER.indexOf(dataset.status) === -1);
