// @flow
import React from 'react';
import { connect } from 'react-redux';
import FileUpload from './components/FileUpload';
import { setErrorMessage } from '../../../../common/duck/common';
import prefixMapping from '../../../utils/accountSystemMapping';
import { getTotalFileCount } from '../../../duck/helpers';
import { fetchDefaultTeam } from '../../../duck/team';

const FileUploadContainer = (props: any) => {
  const { filesData, selectedAccountSystem } = props;
  const fileTypes = selectedAccountSystem
    ? selectedAccountSystem.data.contentFormats.map(el => el.type)
    : [];
  const customedFileCount = key => getTotalFileCount(key, filesData);
  const stepSummaryItems = fileTypes.map(type => ({
    title: prefixMapping(type),
    text: `${customedFileCount(type)} files upload`
  }));

  return (
    <FileUpload {...props} fileCount={customedFileCount} stepSummaryItems={stepSummaryItems} />
  );
};
export default connect(
  state => ({
    isLoading:
      state.dataset.isDatasetGroupLoading ||
      state.dataset.isDatasetLoading ||
      state.dataset.isFileLoading,
    headClientName: state.dataset.filesData.headClient.name,
    selectedAccountSystem: state.accountSystem.get('selectedAccountSystem'),
    subsidiaryCount: state.dataset.filesData.subsidiaries.filter(
      (_, k) => k !== 'addedSubsidiaries'
    ).size,
    filesData: state.dataset.filesData,
    datasetGroupId: state.dataset.filesData.datasetGroupId,
    selectedJobId: state.jobs.selectedJob ? state.jobs.selectedJob.id : null
  }),
  { setErrorMessage, fetchDefaultTeam }
)(FileUploadContainer);
