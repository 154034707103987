// @flow
import { Map } from 'immutable';

import type { Audits, SelectedAudits } from '../types/audit';
import type { Hierarchy } from '../../common/types/table';

export const getSelectedNameList = (list: Audits, selecedList: SelectedAudits) => {
  const auditlist = selecedList.map((item, index) => list.find(i => i.client.id === index));
  return auditlist
    .slice(0, 5)
    .reduce((nameList, i) => nameList.concat(`${nameList ? ',' : ''} ${i.client.name}`), '')
    .concat(auditlist.size > 5 ? ` + ${auditlist.size - 5}` : '');
};

export const getSelectedList = (list: Hierarchy) => {
  const submistList = list.reduce((selectedList, item) => {
    if (item.state > 0) {
      selectedList.push(item.set('state', 2));
    }

    if (item.getIn(['datum', 'children']).size > 0) {
      const childSelected = item
        .getIn(['datum', 'children'])
        .filter(child => child.get('state') > 0);
      childSelected.forEach(i => selectedList.push(i));
    }
    return selectedList;
  }, []);
  return submistList.reduce((acc, item) => acc.set(item.getIn(['datum', 'id']), item), Map());
};
