// @flow
import React from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import { type ActivityLogRow } from '../../../types/activityLog';
import TextLabel from '../../../../common/components/TextLabel';
import LoadingBar from '../../../../common/components/workflow/LoadingBar';
import Download from './Download';

type Props = {
  isSearching: boolean,
  infoMessage: string,
  activityLogs: OrderedMap<ActivityLogRow>
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomTextLabel = styled(TextLabel)`
  color: ${props => props.theme.color.text.dark};
`;

const ResultsInfo = ({ isSearching, infoMessage, activityLogs }: Props) => (
  <>
    {isSearching && <LoadingBar />}
    <Wrapper>
      <CustomTextLabel bold data-cy="row-count">
        {infoMessage}
        {isSearching && 'Fetching your search results ...'}
        {!isSearching && !infoMessage && `Found ${activityLogs.size} result(s) for your search.`}
      </CustomTextLabel>
      {activityLogs.size > 0 && <Download data={activityLogs} />}
    </Wrapper>
  </>
);
export default ResultsInfo;
