// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextLabel from '../../../../common/components/TextLabel';
import DocumentListDialog from './dialogs/DocumentListDialog';
import ImportDataDialog from './dialogs/ImportDataDialog';
import { type TaxReturn, type Document } from '../../../types';

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Column = styled.span`
  display: flex;
  flex-direction: column;
`;

const TextField = styled(TextLabel)`
  && {
    display: flex;
`;

interface StateProps {
  taxReturn: TaxReturn;
  documents?: ImmutableList<Document>;
}

interface OwnProps {
  handleSave: () => Promise<void>;
}

type Props = StateProps & OwnProps;

const TaxReturnInfo = ({ taxReturn, documents, handleSave }: Props) => {
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);
  const [importDataDialogOpen, setImportDataDialogOpen] = useState(false);
  return (
    <Container data-cy="tax-return-info">
      <Column>
        <TextField noPadding>
          <b>Year:</b> &nbsp;{taxReturn.year.name}
        </TextField>
        <TextField noPadding>
          <b>Client:</b> &nbsp;{taxReturn.client.name}
        </TextField>
        <TextField noPadding>
          <b>Assignee:</b> &nbsp;{taxReturn.assignee.name}
        </TextField>
      </Column>
      <Column>
        <Grid container spacing={8}>
          {process.env.NODE_ENV !== 'production' && (
            <Grid item>
              <Button variant="outlined" onClick={() => setImportDataDialogOpen(true)}>
                Import
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button variant="outlined" onClick={() => setDocumentsDialogOpen(true)}>
              Documents
            </Button>
          </Grid>
        </Grid>
        <ImportDataDialog
          handleSave={handleSave}
          open={importDataDialogOpen}
          onClose={() => setImportDataDialogOpen(false)}
        />
        <DocumentListDialog
          open={documentsDialogOpen}
          onClose={() => setDocumentsDialogOpen(false)}
        />
      </Column>
    </Container>
  );
};

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn,
  documents: state.veromylly.taxReturnPage.documents
});

export default connect(
  mapStateToProps,
  {}
)(TaxReturnInfo);
