// @flow
import moment from 'moment';
import countryList from 'country-list';
import type { SimpleDropdownItem, DropdownListType } from '../../common/types/list';
import type { GetContactPerson, GetContactPersonList } from '../../common/types/client';

export const allGroundsForUbo: Array<SimpleDropdownItem> = [
  { value: 1, label: 'Direct or indirect ownership' },
  { value: 3, label: 'CEO' },
  { value: 6, label: 'Board of Directors' },
  { value: 2, label: 'Other party de facto using authority' },
  { value: 4, label: 'Board of Directors and members of the board of governors' },
  { value: 7, label: 'Exempt' }
];

export const allAuthorizationTypes: Array<SimpleDropdownItem> = [
  { value: 1, label: 'Statutory representative' },
  { value: 2, label: 'Registered authorisation' },
  { value: 3, label: 'Specific authorisation' },
  { value: 4, label: 'Other authority' }
];

export const allLanguages: Array<SimpleDropdownItem> = [
  { value: 1, label: 'Finnish' },
  { value: 2, label: 'English' },
  { value: 3, label: 'Swedish' }
];

export const allAmlClassifications = [
  { id: 1, name: 'Simplified' },
  { id: 2, name: 'Normal' },
  { id: 3, name: 'Enhanced' }
];

export const errorMessages = {
  groundForServiceError: 'Enter a ground for service',
  representativesError: 'Select or add at least one representative',
  groundForUboError: 'Select a ground for UBO',
  ubosError: 'Select or add at least one UBO or click the checkbox to report UBO changes',
  nameError: 'Enter a name',
  emailError: 'Enter a valid email address',
  languageError: 'Select a language',
  messageError: 'Enter the additional information',
  contactPersonError: 'Please select a contact person',
  authorizationError: 'Please select an authorization type'
};

export const mapCodeToGroundForUbo = (code: number): SimpleDropdownItem | typeof undefined =>
  allGroundsForUbo.find(ground => ground.value === code);

export const mapCodeToAuthorizationType = (code: number): SimpleDropdownItem | typeof undefined =>
  allAuthorizationTypes.find(authorization => authorization.value === code);

export const mapCodeToAmlClassification = (code: number): string => {
  const amlClassification = allAmlClassifications.find(
    classification => classification.id === code
  );
  return amlClassification ? amlClassification.name : '';
};

export const mapCountryNameToSimpleDropdownItem = (name: string): SimpleDropdownItem => ({
  value: name,
  label: name
});

export const allCountriesList = countryList
  .getNames()
  .map(country => mapCountryNameToSimpleDropdownItem(country));

export const mapLanguageCodeToSimpleDropdownItem = (
  code: number
): SimpleDropdownItem | typeof undefined => allLanguages.find(language => language.value === code);

export const mapJoiErrorsToValidationMessages = (
  joiValidationErrorDetails: Array<{
    path: Array<string>
  }>,
  defaultError?: string
) =>
  joiValidationErrorDetails.reduce((acc, cur) => {
    const key = cur.path.length ? `${cur.path[cur.path.length - 1]}Error` : defaultError;
    if (key) acc[key] = { message: errorMessages[key] };
    return acc;
  }, {});

export const formatContactPersonName = (firstName: string, lastName: string): string =>
  `${lastName}, ${firstName}`;

export const mapContactPersonToSimpleDropdownItem = (
  contactPerson: GetContactPerson
): SimpleDropdownItem => ({
  label: formatContactPersonName(contactPerson.firstName, contactPerson.lastName),
  value: contactPerson.id
});

export const contactPersonDataMappingToDropdown = (
  contactPersonList: GetContactPersonList
): DropdownListType =>
  contactPersonList.map(item => ({
    label: `${formatContactPersonName(item.firstName, item.lastName)} (${moment(
      item.dateOfBirth
    ).format('YYYY')})`,
    value: item.id,
    data: item
  }));
