// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import styled from 'styled-components';
import Button from '../Button';
import { linkStyle } from '../Link';

type Props = {
  title: string,
  subTitle?: string,
  subTitleHtml?: { __html: string } | null,
  getLog?: Function,
  activityLogTitle?: string,
  isHomePage?: boolean,
  optionalAction?: {
    title: string,
    icon: any,
    onClick: Function
  } | null
};

const Container = styled.div`
  max-width: ${props => (props.extraButton ? '60%' : '80%')};
`;

const SubTitleWrapper = styled.p`
  a {
    ${props => linkStyle(props)}
  }
`;

const MarginButton = styled(Button)`
  margin-right: 8px;
`;

const PageHeader = ({
  title,
  subTitle,
  subTitleHtml,
  isHomePage,
  getLog,
  activityLogTitle,
  optionalAction
}: Props) => (
  <div data-cy="page-header">
    <Grid container direction="row" alignItems="center" justify="space-between">
      {/* Title and subtitle */}
      <Container extraButton={optionalAction && optionalAction.title}>
        <div>
          <h1>{title}</h1>
        </div>
        {subTitle && (isHomePage ? <h3>{subTitle}</h3> : <p>{subTitle}</p>)}
        {subTitleHtml && <SubTitleWrapper dangerouslySetInnerHTML={subTitleHtml} />}
      </Container>
      <div>
        {optionalAction && optionalAction.title && (
          <MarginButton
            data-cy="optional-action"
            color="orange"
            rounded
            solid
            onClick={() => optionalAction.onClick()}
          >
            {optionalAction.icon && <optionalAction.icon />}
            {optionalAction.title}
          </MarginButton>
        )}
        {getLog && (
          <Button data-cy="go-to-activity" color="orange" rounded onClick={() => getLog()}>
            <AccessTimeIcon />
            {activityLogTitle}
          </Button>
        )}
      </div>
    </Grid>
  </div>
);

PageHeader.defaultProps = {
  activityLogTitle: 'View activity log',
  subTitle: '',
  subTitleHtml: null,
  getLog: null,
  isHomePage: false,
  optionalAction: null
};

export default PageHeader;
