// @flow
import React, { useState, useEffect } from 'react';
import { List, OrderedMap } from 'immutable';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PageHeader from '../../../../common/components/canvas/PageHeader';
import ProgressBar from '../../../../common/components/ProcessBar';
import Button from '../../../../common/components/Button';
import HeaderSection from '../../../../common/components/HeaderSection';
import Row from './Row';
import { textDateCreator } from '../../../../common/utils/helper';

type Props = {
  history: any,
  getActivityLogList: Function,
  isLoading: boolean,
  activityLogList: OrderedMap,
  getDataset: Function,
  goToAccountMapping: Function,
  goToGeneralLedger: Function,
  deleteDatasetGroup: Function,
  createServiceOrder: Function,
  setDatasetLoading: Function
};
const ActivityLog = ({
  history,
  activityLogList,
  getActivityLogList,
  getDataset,
  isLoading,
  goToAccountMapping,
  goToGeneralLedger,
  deleteDatasetGroup,
  createServiceOrder,
  setDatasetLoading
}: Props) => {
  const [shouldFetchDataset, setShouldFetchDataset] = useState(true);
  useEffect(() => {
    (async () => {
      await getActivityLogList();
      setShouldFetchDataset(true);
    })();
  }, [getActivityLogList, setShouldFetchDataset]);
  useEffect(() => {
    if (shouldFetchDataset && activityLogList.size > 0 && !isLoading) {
      activityLogList
        .filter(datasetGroup => !datasetGroup.datasets)
        .map(datasetGroup => datasetGroup.id)
        .forEach(id => {
          setDatasetLoading({ id });
          getDataset(id);
        });
      setShouldFetchDataset(false);
    }
  }, [
    getDataset,
    activityLogList,
    setShouldFetchDataset,
    shouldFetchDataset,
    deleteDatasetGroup,
    isLoading,
    setDatasetLoading
  ]);
  return (
    <>
      <HeaderSection>
        <PageHeader
          getLog={null}
          title="General Ledger and Trial balance upload Activity Log"
          subTitle="Check all your general ledger history"
        />
        <Button
          data-cy="back-button"
          primary
          solid
          rounded
          onClick={() => history.push('/general-ledger')}
        >
          <ArrowBack />
          Back to service page
        </Button>
      </HeaderSection>
      {isLoading && <ProgressBar />}
      {activityLogList.size > 0 &&
        !isLoading &&
        activityLogList.toList().map(log => {
          const datasetsWithFiles =
            log.datasets && log.datasets.size > 0
              ? log.datasets.filter(dataset => dataset.files && dataset.files.length > 0)
              : List([]);

          const fileCount = datasetsWithFiles.reduce(
            (sum, dataset) => sum + dataset.files.length,
            0
          );
          // * No fail files = At least one file imported (status 2) and the rest is either status 2 or 4
          const haveFileImported = datasetsWithFiles.some(dataset => dataset.files.some(file => file.status === 2))
          // At least one dataset has one file with status other than 2 or 4 
          const haveFileNotImportedOrDeleted = datasetsWithFiles.some(
            dataset =>
              !dataset.files.every(file => file.status === 2 || file.status === 4)
          );
          // If all datasets have at least one failed file, then the Qlik Sense report should not be generated
          // This is used for Qlik Sense status check messages
          const haveAllDatasetsFailed = datasetsWithFiles.every(
            dataset => dataset.files.some(file => file.status !== 2 && file.status !== 4)
          );
          // If there is not even one file imported or there are datasets with invalid files, then we have failed files
          const haveFailedFiles = !haveFileImported || haveFileNotImportedOrDeleted
          const isQlikSenseAvailable = datasetsWithFiles.some(dataset => dataset.status === 10);
          const isMappingAvailable = datasetsWithFiles.every(dataset => dataset.status >= 6);
          const files =
            datasetsWithFiles.size > 0
              ? datasetsWithFiles.reduce(
                (totalFiles, dataset) => [
                  ...totalFiles,
                  ...dataset.files.map(file => ({
                    ...file,
                    fileName: file.name
                  }))
                ],
                []
              )
              : [];

          return (
            <Row
              key={log.id}
              clientName={log.clientName}
              subsidiaryCount={log.datasetCount - 1}
              job={log.jobName}
              createdDate={textDateCreator(log.created)}
              startDate={textDateCreator(log.startDate)}
              endDate={textDateCreator(log.endDate)}
              serviceOrderId={log.serviceOrderId}
              fileCount={fileCount}
              isDatasetLoading={!log.datasets || (log.datasets && log.datasets.get('isLoading'))}
              haveFailedFiles={haveFailedFiles}
              haveAllDatasetsFailed={haveAllDatasetsFailed}
              isQlikSenseAvailable={isQlikSenseAvailable}
              isMappingAvailable={isMappingAvailable}
              groupDatasetId={log.id}
              goToAccountMapping={() => goToAccountMapping(log.id)}
              goToGeneralLedger={goToGeneralLedger}
              files={files}
              deleteDatasetGroup={deleteDatasetGroup}
              createServiceOrder={createServiceOrder}
            />
          );
        })}
    </>
  );
};

export default ActivityLog;