// @flow
import React from 'react';
import styled from 'styled-components';
import { LabelWrapper, CustomHeading, CustomTextLabel } from './Common';
import AuraSelector from '../../../../components/common/AuraSelectorContainer';
import Alink from '../../../../../common/components/Link';

type Props = {
  id: number,
  name: string,
  setAuraDatabase: Function
};

const CustomLink = styled(Alink)`
  font-size: ${props => props.theme.fonts.size.body.s};
  :hover {
    cursor: pointer;
  }
`;

const AuraInfo = ({ id, name, setAuraDatabase }: Props) => (
  <>
    <CustomHeading noPadding>Link in Aura DB</CustomHeading>
    <LabelWrapper>
      {id !== -1 ? (
        <CustomTextLabel data-cy={`auraDB-name-${id}`}>{name}</CustomTextLabel>
      ) : (
        <>
          <CustomTextLabel>No</CustomTextLabel>
          <AuraSelector onChange={setAuraDatabase} noPadding id={id}>
            {({ openMenu }) => (
              <CustomLink onClick={openMenu} data-cy={`auraDB-link${id}`}>
                Roll-forward in Aura--&gt;
              </CustomLink>
            )}
          </AuraSelector>
        </>
      )}
    </LabelWrapper>
  </>
);

export default AuraInfo;
