import styled from 'styled-components';

const Tooltip = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: ${props => (props.flexstart ? 'flex-start' : 'center')};
  position: relative;
  & > span {
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  }
  & > div {
    align-items: center;
    &:hover {
      & > div {
        display: block;
      }
    }
    & > div {
      color: ${props => props.theme.color.heading.neutral}
      font-size: ${props => props.theme.fonts.size.body.s};
      display: none;
      z-index: 10;
      left:0;
      position: absolute;
      top: ${props => (props.flexstart ? null : '100%')};
      background-color: white;
      border: 1px solid ${props => props.theme.color.border.default};
      padding: ${props => props.theme.space.squish.m};
      border-radius: 9px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
      min-width:300px;
      text-align: left;
      ul {
        padding-left: 2em;
      }
      p {
        margin: 0;
        font-weight: normal;
      }
      p:not(:first-child) {
        margin-top: ${props => props.theme.space.single.s};
      }
    }
    svg {
    margin:${props => props.theme.space.stack.s};
    color: ${props => {
      if (props.warning) return props.theme.color.ui.primary;
      if (props.success) return props.theme.color.ui.success;
      return props.theme.color.heading.neutral;
    }};
  }
  }
`;

export default Tooltip;
