import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import Clients from '../Clients';
import {
  selectClient,
  deselectedClient,
  deselectedSubsidiary,
  selectClientPartially,
  selectedSubsidiary,
  toggleExpandSubsidiary,
  toggleExpand,
  changeJobs,
  changeChildJobs
} from '../../../../duck/audit';
import {
  getRollForwards,
  selectRollForwards,
  selectSubsidiary as selectSubsidiaryRollForward,
  saveSelectedAudits,
  updateRollForward
} from '../../../../duck/rollForward';
import { getOnboardingInfo } from '../../../../duck/general';
import { getSelectedList } from '../../../../utils/helper';

export default compose(
  connect(
    state => ({
      audits: state.audit.get('audits'),
      jobList: state.audit.get('jobList'),
      selectedRollForwards: state.rollForward.get('selectedRollForwards'),
      loading: state.rfGeneral.get('loading'),
      selectedSigner: state.search.get('selectedTarget')
    }),
    {
      deselectedClient,
      deselectedSubsidiary,
      selectedSubsidiary,
      selectClient,
      getRollForwards,
      selectRollForwards,
      selectSubsidiaryRollForward,
      getOnboardingInfo,
      selectClientPartially,
      toggleExpandSubsidiary,
      toggleExpand,
      saveSelectedAudits,
      changeJobs,
      changeChildJobs,
      updateRollForward
    }
  ),
  withProps(({ audits }) => ({
    selectedList: getSelectedList(audits)
  }))
)(Clients);
