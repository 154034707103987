// @flow
import React from 'react';
import { List } from 'immutable';
import PersonPin from '@material-ui/icons/PersonPin';
import Event from '@material-ui/icons/Event';
import Step, { type Props as StepProps } from '../../../../common/components/workflow/Step';
import ActionBar from '../../../../common/components/workflow/ActionBar';
import { SearchSelection } from '../../../../common/components/selectors';
import LeaderOrClientDropdown from '../../../../common/components/LeaderOrClientDropdown';
import ExtraSpace from '../../../../common/components/ExtraSpace';
import SearchBoxContainer from '../../../../common/components/SearchBoxContainer';
import TextLabel from '../../../../common/components/TextLabel';
import Expander from '../../../../common/components/Expander';
import {
  mapArrayToSelectionItems,
  mapToDropdownItem,
  allMonths
} from '../../../../common/utils/helper';
import type {
  SimpleDropdownItem,
  DropdownListType,
  SelectionItem
} from '../../../../common/types/list';

type Props = StepProps & {
  onClickSave: () => {},
  process: Function,
  getOptions: Function,
  selectedTarget: DropdownListType,
  latestSelectionTarget: SelectionItem,
  addTarget: Function,
  removeTarget: Function,
  selectedFinancialYearEnd?: Array<SimpleDropdownItem>,
  latestSelectionFinancialYearEnd: SimpleDropdownItem | null,
  addFinancialYearEnd: Function,
  removeFinancialYearEnd: Function,
  resetAudits: Function,
  loading: number,
  searchType: SimpleDropdownItem,
  onChangeSearchType: Function,
  isBasicFilterExist: boolean,
  resetSelectTarget: Function
};

export default ({
  onClickSave,
  process,
  getOptions,
  selectedTarget,
  latestSelectionTarget,
  resetSelectTarget,
  addTarget,
  removeTarget,
  selectedFinancialYearEnd,
  latestSelectionFinancialYearEnd,
  addFinancialYearEnd,
  removeFinancialYearEnd,
  theme,
  resetAudits,
  loading,
  searchType,
  onChangeSearchType,
  ...rest
}: Props) => (
  <Step
    title="Search"
    subtitle="First, search for the clients by using different filters (e.g.leader/manager, financial year end, client)"
    Icon={PersonPin}
    theme={theme}
    {...rest}
  >
    <ExtraSpace>
      <SearchBoxContainer>
        <LeaderOrClientDropdown
          selectedType={searchType}
          onChange={e => {
            onChangeSearchType(e);
            if (e !== searchType) resetSelectTarget();
          }}
        />
        <SearchSelection
          key={searchType.label}
          value={latestSelectionTarget && mapToDropdownItem(latestSelectionTarget)}
          placeholder={`Start typing to begin search for a ${searchType.label.toLocaleLowerCase()} by name`}
          fetchData={getOptions}
          saveToStore={addTarget(searchType.value)}
          deleteFromStore={removeTarget(searchType.value)}
          selectedList={selectedTarget}
          isSearchable
          note="Note! You can select multiple targets at a time"
          type="targets"
          Icon={PersonPin}
        />
      </SearchBoxContainer>
      {/* No fincial year end selection showed if selected as Client type */}
      {selectedTarget && selectedTarget.size > 0 && searchType.value !== 2 && (
        <Expander data-cy="financial-year-end-expander" isOpen={selectedTarget.size}>
          <SearchBoxContainer>
            <TextLabel bold>Financial Year End (optional)</TextLabel>
            <SearchSelection
              key="financial-year-end"
              value={latestSelectionFinancialYearEnd}
              placeholder="Select a Financial Year End month"
              fetchData={() => Promise.resolve(allMonths)}
              saveToStore={addFinancialYearEnd}
              deleteFromStore={removeFinancialYearEnd}
              selectedList={
                selectedFinancialYearEnd
                  ? List(mapArrayToSelectionItems(selectedFinancialYearEnd))
                  : null
              }
              isSearchable={false}
              note="Note! You can select multiple months at a time"
              type="Financial Year End"
              Icon={Event}
            />
          </SearchBoxContainer>
        </Expander>
      )}
      <ActionBar
        status={
          selectedTarget.size < 1
            ? `Select a ${searchType.label.toLocaleLowerCase()} to proceed`
            : 'Press ’Proceed’ to continue to next step'
        }
        isLoading={!!loading}
        proceed={{
          name: 'Proceed',
          callback: async () => {
            await process();
            onClickSave();
          },
          isDisabled: !selectedTarget.size
        }}
      />
    </ExtraSpace>
  </Step>
);
