import styled from 'styled-components';

const SearchBoxContainer = styled.div`
  background: white;
  display: flex;
  & > *:first-child {
    min-width: 150px;
    max-width: 250px;
    flex-grow: 1;
    height: 100%;
    margin-right: ${props => props.theme.space.single.l};
  }
  & > *:last-child {
    min-width: 400px;
    max-width: 600px;
    flex-grow: 2;
  }
`;

export default SearchBoxContainer;
