// @flow
import { Record, OrderedMap } from 'immutable';

import { createAction } from 'redux-actions';
import { type ActionType } from '../../common/types/redux';
import { type ClientList } from '../../common/types/client';
import { type RollForwardDiagnosis, type StatusSummary } from '../types';
import services from '../services';

type State = OrderedMap<{
  statusSummaries: StatusSummary[],
  foundClients: ClientList,
  selectedClients: ClientList,
  diagnosis?: OrderedMap<string, RollForwardDiagnosis>
}>;

const defaultState = Record({
  statusSummaries: [],
  foundClients: [],
  selectedClients: [],
  diagnosis: null
});

export const getStatusSummaries = createAction(
  'GET_STATUS_SUMMARIES',
  (clientIds: number[], onlyErrors: boolean = false, onlyFailedBC: boolean = false) =>
    services.getStatusSummaries(clientIds, onlyErrors, onlyFailedBC)
);

export const findClientsByName = createAction(
  'FIND_CLIENTS_BY_NAME',
  (clientSearchString: string) => services.findClientsByName(clientSearchString)
);

export const selectClients = createAction('SELECT_CLIENTS');

export const getDiagnosis = createAction('GET_DIAGNOSIS', (rollforwardId: string) =>
  services.getDiagnosis(rollforwardId)
);

const Reducer = (state: State = defaultState(), action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_STATUS_SUMMARIES_FULFILLED': {
      return state.set('statusSummaries', payload.map(p => ({ ...p, ...p.status })));
    }
    case 'FIND_CLIENTS_BY_NAME_FULFILLED': {
      return state.set('foundClients', payload);
    }
    case 'SELECT_CLIENTS': {
      return state.set('selectedClients', payload);
    }
    case 'GET_DIAGNOSIS_FULFILLED': {
      return state.set('diagnosis', payload);
    }
    default:
      return state;
  }
};

export default Reducer;
