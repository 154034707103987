// @flow
import React, { Component, type Node } from 'react';
import { List, type List as ListT } from 'immutable';
import main, { rose, burgundy, orange, maroon } from '../../theme/theme';

export type StepAction = (i: number | typeof undefined) => void;

type StepsState = {
  done: boolean
};

type Props = {
  onDone: Function,
  children: Array<Node>
};

type State = {
  currentStep: number,
  steps: ListT<StepsState>
};
export const themesByStep = [orange, rose, burgundy, maroon];

class Steps extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { children } = this.props;

    this.state = {
      currentStep: 0,
      steps: List(React.Children.map(children, () => ({ done: false })))
    };
  }

  onSave = (i: number): void => {
    const { steps } = this.state;
    const nextStep = i + 1;
    const newSteps = steps.map((s, index) => (index === i ? { done: true } : s));
    if (nextStep < steps.size) {
      this.setState(() => ({
        currentStep: nextStep,
        steps: newSteps
      }));
    } else {
      const { onDone } = this.props;
      onDone(newSteps.every(step => step.done));
    }
  };

  moveToStep = (step: number): void => {
    const { steps } = this.state;

    this.setState({
      currentStep: step,
      steps: steps.map((s, index) => (index >= step ? { done: false } : s))
    });
  };

  onEdit = (i: number): void => {
    this.setState(state => ({
      currentStep: i,
      steps: state.steps.map((step, key) => ({
        step,
        done: key < i
      }))
    }));
  };

  render() {
    const { children } = this.props;
    const { currentStep, steps } = this.state;
    return (
      <div>
        {React.Children.map(children, (child, i) => {
          const active = currentStep === i;
          const step = steps.get(i);
          if (step) {
            const { done } = step;
            return React.cloneElement(child, {
              key: child.key,
              active,
              done,
              pending: !(active || done),
              theme: themesByStep[i % themesByStep.length] || main,
              onClickSave: () => this.onSave(i),
              onClickEdit: () => this.onEdit(i),
              moveToStep: this.moveToStep
            });
          }
          return null;
        })}
      </div>
    );
  }
}

export default Steps;
