// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import SimpleTable from '../../../../common/components/table/SimpleTable';
import Row, { ClientName, ClientId, Td } from '../../../../common/components/table/TableRow';
import type {
  OpportunityWithKey,
  OpportunityOrderedMap
} from '../../../../common/types/opportunity';

const headers = [
  'Client Name',
  'Financial Year End',
  'Risk Created',
  'Opportunity',
  'Engagement Leader',
  'Engagement Manager'
];

type Props = {
  opportunities: OpportunityOrderedMap,
  onSelectClient: Function,
  selectedClient: OpportunityWithKey | null,
  isLoading: boolean
};

export default function ClientsTable({
  opportunities,
  onSelectClient,
  selectedClient,
  isLoading
}: Props) {
  return (
    <SimpleTable
      headers={headers}
      data={opportunities}
      onSelectItem={onSelectClient}
      selectedClient={selectedClient}
      isLoading={isLoading}
    >
      {({ item, key, Actions }) => {
        const { name, leader, manager, riskCreated, client, isEnabled } = item;
        const { name: clientName, businessId, periodEndMonth } = client;

        return (
          <Fragment key={key}>
            <Row isDisabled={!isEnabled}>
              <Actions />
              <Td>
                <ClientName isDisabled={!isEnabled}>{clientName}</ClientName>
                <ClientId>{businessId}</ClientId>
              </Td>
              <Td>{periodEndMonth != null && moment.months(periodEndMonth)}</Td>
              <Td>{moment(riskCreated).format('DD/MM/YYYY')}</Td>
              <Td>{name}</Td>
              <Td minWidth="150px">{leader}</Td>
              <Td minWidth="150px">{manager}</Td>
              <Td noPadding />
            </Row>
          </Fragment>
        );
      }}
    </SimpleTable>
  );
}
