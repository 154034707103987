// @flow
import { Record, type RecordOf, OrderedMap, Map, fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { RollForwards } from '../types/rollForward';
import type { Hierarchy } from '../../common/types/table';
import type { ActionType } from '../../common/types/redux';

type DefaultState = RecordOf<{
  selectedRollForwards: Hierarchy,
  rollForwards: RollForwards
}>;

const defaultState = Record({
  selectedRollForwards: OrderedMap(),
  rollForwards: Map()
});

// rollForward actions
export const saveSelectedAudits = createAction('SAVE_SELECTED_AUDITS', audits => audits);

export const deselectedRollForward = createAction('DESELECT_ROLLFORWARDS', key => key);
export const selectRollForwards = createAction('SELECT_ROLLFORWARDS', rfs => rfs);
export const selectRollForwardsPartially = createAction(
  'SELECT_ROLLFORWARDS_PARTIALLY',
  rfs => rfs
);
export const deselectSubsidiary = createAction('DESELECT_RF_SUBSIDIARY', (headClientKey, key) => ({
  headClientKey,
  key
}));
export const selectSubsidiary = createAction('SELECT_RF_SUBSIDIARY', (headClientKey, key) => ({
  headClientKey,
  key
}));

export const resetRollForward = createAction('RESET_ROLLFORWARD', () => undefined);

export const getRollForwards = createAction('GET_ROLLFORWARDS', params =>
  apiService.getRollForwards(params)
);

export const updateRollForward = createAction(
  'UPDATE_ROLLFORWARD',
  (key, property, value, headClientKey) => ({
    key,
    property,
    value,
    headClientKey
  })
);
export const updateRollForwardSubProperty = createAction(
  'UPDATE_ROLLFORWARD_SUB_PROPERTY',
  (key, property, subProperty, value, headClientKey) => ({
    key,
    property,
    subProperty,
    value,
    headClientKey
  })
);

export const submitRollForwards = createAction(
  'SUBMIT_ROLL_FORWARDS',
  apiService.submitRollForwards
);

export const toggleExpandRollForward = createAction(
  'SELECT_TOGGLE_ROLLFORWARD',
  headClientId => headClientId
);

export default function rollForwardReducer(
  state: DefaultState = defaultState(),
  action: ActionType
) {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_SELECTED_AUDITS':
      return state.set('selectedRollForwards', payload);

    case 'RESET_AUDITS':
      return state.set('selectedRollForwards', defaultState().selectedRollForwards);

    case 'GET_ROLLFORWARDS_FULFILLED': {
      return state.set('rollForwards', fromJS(payload.toJS()));
    }

    case 'SELECT_ROLLFORWARDS':
      return state.setIn(['rollForwards', payload.toString(), 'state'], 2);

    case 'SELECT_ROLLFORWARDS_PARTIALLY':
      return state.setIn(['rollForwards', payload.toString(), 'state'], 1);

    case 'DESELECT_ROLLFORWARDS':
      return state.setIn(['rollForwards', payload.toString(), 'state'], 0);

    case 'SELECT_RF_SUBSIDIARY':
      return state.setIn(
        [
          'rollForwards',
          payload.headClientKey.toString(),
          'datum',
          'children',
          payload.key.toString(),
          'state'
        ],
        2
      );

    case 'DESELECT_RF_SUBSIDIARY':
      return state.setIn(
        [
          'rollForwards',
          payload.headClientKey.toString(),
          'datum',
          'children',
          payload.key.toString(),
          'state'
        ],
        0
      );

    case 'UPDATE_ROLLFORWARD': {
      return payload.headClientKey
        ? state.setIn(
            [
              'rollForwards',
              payload.headClientKey.toString(),
              'datum',
              'children',
              payload.key.toString(),
              'datum',
              payload.property
            ],
            payload.value
          )
        : state.setIn(
            ['rollForwards', payload.key.toString(), 'datum', payload.property],
            payload.value
          );
    }

    case 'UPDATE_ROLLFORWARD_SUB_PROPERTY':
      return payload.headClientKey
        ? state.setIn(
            [
              'rollForwards',
              payload.headClientKey.toString(),
              'datum',
              'children',
              payload.key.toString(),
              'datum',
              payload.property,
              payload.subProperty
            ],
            payload.value
          )
        : state.setIn(
            [
              'rollForwards',
              payload.key.toString(),
              'datum',
              payload.property,
              payload.subProperty
            ],
            payload.value
          );
    case 'SELECT_TOGGLE_ROLLFORWARD':
      return state.updateIn(['rollForwards', payload.toString(), 'expanded'], e => !e);

    case 'RESET_ROLLFORWARD':
      return defaultState();

    default:
      return state;
  }
}
