// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import Button from '../../common/components/Button';
import { SearchSelection } from '../../common/components/selectors';
import { type DropdownItem } from '../../common/types/list';
import { dataMappingToDropdown } from '../../common/utils/helper';
import services from '../services';
import { type Client } from '../types';

const HorizontalDisplay = styled.div`
  display: flex;
  & > * {
    margin: 8px;
  }
`;

type Props = {
  onSearch: number => Promise<void>
};

const SearchBar = ({ onSearch }: Props) => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const searchClients = async (input: string) => {
    if (!input || input.length < 3) return Promise.resolve([]);
    return services
      .searchClients(input)
      .then(d => (d ? dataMappingToDropdown(d) : d))
      .catch(() => []);
  };
  return (
    <>
      <h2>Search Orders</h2>
      <HorizontalDisplay>
        <SearchSelection
          type="client"
          placeholder="Start typing to search clients by name"
          saveToStore={(target: DropdownItem<Client>) => {
            setSelectedClient(target.data);
          }}
          onClear={() => setSelectedClient(null)}
          isClearable
          fetchData={searchClients}
        />
        <Button
          primary
          solid
          data-cy="search-client-orders-button"
          disabled={isEmpty(selectedClient)}
          onClick={async () => selectedClient && onSearch(selectedClient.id)}
        >
          Search
        </Button>
      </HorizontalDisplay>
    </>
  );
};

export default SearchBar;
