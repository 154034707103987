// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAsyncDebounce } from 'react-table';
import SearchIcon from '@material-ui/icons/Search';
import Button from '../Button';
import TextInput from '../TextInput';

const StyledTextInput = styled(TextInput)`
  padding: 8px;
  margin: 8px 0;
  width: 100px;
`;

const StyledSelect = styled.select`
  padding: ${props => props.theme.space.square.s};
  font-size: ${props => props.theme.fonts.size.body.s};
  border: ${props => `solid 1px ${props.theme.color.border.default}`};
  border-radius: ${props => props.theme.border.type.round};
  margin: 8px 0;
`;

type FilterToggleProps = {
  onOpen: Function,
  children: any
};

// Toggle component to show and hide filter
const FilterToggle = ({ onOpen, children }: FilterToggleProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <>
      <SearchIcon
        onClick={() => {
          setIsFilterOpen(prev => !prev);
          if (isFilterOpen) onOpen();
        }}
      />
      {isFilterOpen && children}
    </>
  );
};

type GlobalFilterProps = {
  preGlobalFilteredRows: any,
  globalFilter: any,
  setGlobalFilter: any
};
// Define a default UI for filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}: GlobalFilterProps) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(v => {
    setGlobalFilter(v || undefined);
  }, 200);

  return (
    <span>
      Search:{' '}
      <StyledTextInput
        data-cy="general-search-bar"
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
};

type DefaultColumnFilterProps = {
  column: {
    filterValue: any,
    preFilteredRows: any,
    setFilter: any
  }
};

// Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter }
}: DefaultColumnFilterProps) => (
  <FilterToggle onOpen={() => setFilter(undefined)}>
    <div>
      <StyledTextInput
        data-cy="column-default-filter"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder="Filter..."
      />
    </div>
  </FilterToggle>
);

type SelectColumnFilterProps = {
  column: {
    filterValue: any,
    preFilteredRows: any,
    setFilter: any,
    id: any
  }
};
// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}: SelectColumnFilterProps) => {
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const o = new Set();
    preFilteredRows.forEach(row => {
      o.add(row.values[id]);
    });
    return [...o.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <FilterToggle onOpen={() => setFilter(undefined)}>
      <div>
        <StyledSelect
          data-cy="column-select-filter"
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </StyledSelect>
      </div>
    </FilterToggle>
  );
};

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}: SelectColumnFilterProps) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let minVal = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let maxVal = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      minVal = Math.min(row.values[id], minVal);
      maxVal = Math.max(row.values[id], maxVal);
    });
    return [minVal, maxVal];
  }, [id, preFilteredRows]);

  return (
    <>
      <StyledTextInput
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <Button onClick={() => setFilter(undefined)}>Off</Button>
    </>
  );
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export const NumberRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}: SelectColumnFilterProps) => {
  const [min, max] = React.useMemo(() => {
    let minVal = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let maxVal = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      minVal = Math.min(row.values[id], minVal);
      maxVal = Math.max(row.values[id], maxVal);
    });
    return [minVal, maxVal];
  }, [id, preFilteredRows]);

  return (
    <div>
      <StyledTextInput
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
      />
      to
      <StyledTextInput
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
      />
    </div>
  );
};
