// @flow
import { Record, OrderedMap } from 'immutable';
import { createAction } from 'redux-actions';
import { type ActivityState as State } from '../types/duck';
import { type ActionType } from '../../common/types/redux';
import service from '../services/activitylog';
import { filterDatasets } from '../utils/status';

const defaultState = Record({
  activityLogList: OrderedMap({}),
  isActivityLogLoading: false
});

export const getActivityLogList = createAction('GET_ACTIVITY_LOG', () => service.getDatasetGroup());
export const createServiceOrder = createAction('SET_DATASET_GROUP_STATUS', param =>
  service.createServiceOrder(param)
);
export const getDataset = createAction('GET_DATASET_ACTIVITY_LOG', async id => {
  const datasets = await service.getDatasetByGroupId(id);
  return { datasets, id };
});
export const setDatasetLoading = createAction('SET_DATASET_LOADING');
export const deleteDatasetGroup = createAction('DELETE_DATASET_GROUP', async datasetGroup => {
  await service.deleteDatasetGroup(datasetGroup);
  return datasetGroup;
});

export default function ActivityLogReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_ACTIVITY_LOG_FULFILLED': {
      return state
        .set('activityLogList', OrderedMap(payload.map(el => [el.id, el])))
        .set('isActivityLogLoading', false);
    }
    case 'DELETE_DATASET_GROUP_FULFILLED': {
      return payload ? state.removeIn(['activityLogList', payload]) : state;
    }
    case 'SET_DATASET_LOADING': {
      return payload.id
        ? state.setIn(['activityLogList', payload.id, 'datasets'], OrderedMap({ isLoading: true }))
        : state;
    }
    case 'GET_ACTIVITY_LOG_PENDING': {
      return state.set('isActivityLogLoading', true);
    }
    case 'GET_DATASET_ACTIVITY_LOG_FULFILLED': {
      const datasets = filterDatasets(payload.datasets);
      return payload.id
        ? state.updateIn(['activityLogList', payload.id, 'datasets'], list =>
            list
              ? list.concat(OrderedMap([...datasets.map(el => [el.id, el]), ['isLoading', false]]))
              : OrderedMap([...datasets.map(el => [el.id, el]), ['isLoading', false]])
          )
        : state;
    }
    case 'SET_DATASET_GROUP_STATUS_FULFILLED': {
      const { datasetGroupId, serviceOrderId } = payload;
      return state.setIn(['activityLogList', datasetGroupId, 'serviceOrderId'], serviceOrderId);
    }
    default:
      return state;
  }
}
