import FileDownload from 'js-file-download';
import { axiosWrapper } from '@pwc-fi/ui-library';

const instance = axiosWrapper();

export const get = (endPoint, params = {}) => instance.get(endPoint, { params }).then(r => r.data);
export const put = (endPoint, body = {}) => instance.put(endPoint, body).then(r => r.data);
export const post = (endPoint, data = {}, config?) =>
  instance.post(endPoint, data, config).then(r => r.data);
export const remove = (endPoint, body = {}) => instance.delete(endPoint, body).then(r => r.data);
export const download = (endPoint, fileName) =>
  instance.get(endPoint, { timeout: 300000 }).then(r => FileDownload(r.data, fileName));

export default instance;
