// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import Step, { type Props as StepProps } from '../../../../../common/components/workflow/Step';
import { type StepAction } from '../../../../../common/components/workflow/Steps';
import TextLabel from '../../../../../common/components/TextLabel';
import HeadClientContainer from './head-client/HeadClientContainer';
import SubsidiariesContainer from './subsidiaries/SubsidiariesContainer';
import ActionBar from '../../../../../common/components/workflow/ActionBar';
import CustomTabs, { type ContainerProps } from './CustomTabs';
import service from '../../../../services/dataset';

type MainStepProps = StepProps &
  ContainerProps & {
    onClickSave: () => StepAction,
    isLoading: boolean,
    datasetGroupId: string,
    fileCount: Function,
    fetchDefaultTeam: Function,
    selectedJobId?: number
  };
export const Container = styled.div`
  padding: ${props => props.theme.space.single.xl};
  border: 1px solid ${props => props.theme.color.border.default};
`;

const InfoText = styled(TextLabel)`
  text-align: center;
  margin-top: 24px;
`;

const FileUpload = ({
  onClickSave,
  isLoading,
  headClientName,
  selectedAccountSystem,
  subsidiaryCount,
  theme,
  done,
  setErrorMessage,
  datasetGroupId,
  fileCount,
  selectedJobId,
  fetchDefaultTeam,
  ...rest
}: MainStepProps) => {
  const [tab, setTab] = useState('head');
  const [isProcessing, setIsProcessing] = useState(false);

  const getCurrentTab = () => {
    if (tab === 'subsidiaries' && selectedAccountSystem) {
      return tab;
    }
    return 'head';
  };
  const actionBarStatus =
    // eslint-disable-next-line no-nested-ternary
    selectedAccountSystem && fileCount('GL') !== 0
      ? selectedAccountSystem.data.contentFormats.length === 2
        ? `${fileCount('GL')} general ledger and ${fileCount('TB')} trial balance file(s) uploaded`
        : `${fileCount('GL')} general ledger, ${fileCount('BS')} balance sheet  and ${fileCount(
            'PL'
          )} profit loss file(s) uploaded`
      : 'Upload GL and TB files to proceed';
  return (
    <Step
      title="General Ledger and Trial Balance files"
      subtitle="Upload General Ledger and Trial Balance files"
      Icon={VerticalAlignTop}
      theme={theme}
      done={done}
      {...rest}
    >
      <CustomTabs
        headClientName={headClientName}
        subsidiaryCount={subsidiaryCount}
        selectedAccountSystem={selectedAccountSystem}
        theme={theme}
        handleTabChange={setTab}
        mode={tab}
        setErrorMessage={setErrorMessage}
      />
      <Container>
        {getCurrentTab() === 'head' ? <HeadClientContainer /> : <SubsidiariesContainer />}
      </Container>
      {isProcessing && (
        <InfoText clientName>The processing of files can take couple of minutes.</InfoText>
      )}
      <ActionBar
        status={actionBarStatus}
        isLoading={isLoading || isProcessing}
        proceed={{
          name: 'Proceed',
          callback: async () => {
            setIsProcessing(true);

            const intervalVar = setInterval(() => {
              service.isReadyToProcess(datasetGroupId).then(result => {
                if (result) {
                  clearInterval(intervalVar);

                  // hide loader
                  setIsProcessing(false);
                  // fetch default job
                  if (selectedJobId) fetchDefaultTeam(selectedJobId);

                  // proceed to next step
                  onClickSave();
                }
              });
            }, 3000);
          },
          isDisabled: isLoading || !selectedAccountSystem || fileCount('GL') === 0
        }}
      />
    </Step>
  );
};

export default FileUpload;
