// @flow
import React from 'react';
import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import {
  type TaxReturn,
  type TaxReturnData,
  type UserRole,
  type Document,
  type DocumentTemplate,
  userRoles,
  taxReturnSteps
} from '../../types';
import TaxTabs from './components/TaxTabs';
import PreparePackage from './components/PreparePackage';
import { updateFormFieldValue, previewTaxForm, previewDocument } from '../../duck/TaxReturnPage';

const isDraftState = (stateName: string) => stateName.toLowerCase().includes('draft');

interface OwnProps {
  activeStep: string;
  addComment: Function;
}

interface StateProps {
  taxReturn: TaxReturn;
  taxReturnData: TaxReturnData;
  userRole?: UserRole;
  documents?: ImmutableList<Document>;
  documentTemplates?: ImmutableList<DocumentTemplate>;
}

interface DispatchProps {
  updateFormFieldValue: Function;
  previewTaxForm: Function;
  previewDocument: Function;
}

type Props = OwnProps & StateProps & DispatchProps;

const OperatingWindow = ({
  taxReturn,
  taxReturnData,
  userRole,
  documents,
  documentTemplates,
  activeStep,
  addComment,
  ...dispatchProps
}: Props) => {
  if (activeStep === taxReturnSteps.PACKAGE) {
    return userRole === userRoles.PREPARER ? (
      <PreparePackage />
    ) : (
      `Waiting for packaging from ${taxReturn.preparer ? taxReturn.preparer.name : 'preparer'}`
    );
  }
  if (userRole === userRoles.PREPARER) {
    if (activeStep === taxReturnSteps.PREPARE) {
      return (
        <TaxTabs
          data={taxReturnData}
          userRole={userRole}
          documents={documents}
          documentTemplates={documentTemplates}
          updateFormFieldValue={dispatchProps.updateFormFieldValue}
          addComment={addComment}
          previewTaxForm={(formId: number) =>
            dispatchProps.previewTaxForm(formId, taxReturn, taxReturnData)
          }
          previewDocument={dispatchProps.previewDocument}
        />
      );
    }
    if (activeStep === taxReturnSteps.REVIEW) {
      return (
        <h3>
          Waiting for reviews from {taxReturn.reviewer ? taxReturn.reviewer.name : 'reviewer'}
        </h3>
      );
    }
    return null;
  }
  if (userRole === userRoles.REVIEWER) {
    if (activeStep === taxReturnSteps.PREPARE) {
      return isDraftState(taxReturn.state.name) ? (
        <h3>Waiting for preparation from {taxReturn.preparer.name}</h3>
      ) : (
        <h3>Tax return submitted for review</h3>
      );
    }
    if (activeStep === taxReturnSteps.REVIEW) {
      return (
        <TaxTabs
          data={taxReturnData}
          userRole={userRole}
          documents={documents}
          documentTemplates={documentTemplates}
          updateFormFieldValue={dispatchProps.updateFormFieldValue}
          addComment={addComment}
          previewTaxForm={(formId: number) =>
            dispatchProps.previewTaxForm(formId, taxReturn, taxReturnData)
          }
          previewDocument={dispatchProps.previewDocument}
        />
      );
    }
  }
  return null;
};

const mapStateToProps = state => ({
  taxReturn: state.veromylly.taxReturnPage.taxReturn,
  taxReturnData: state.veromylly.taxReturnPage.taxReturnData,
  userRole: state.veromylly.taxReturnPage.userRole,
  documents: state.veromylly.taxReturnPage.documents,
  documentTemplates: state.veromylly.taxReturnPage.documentTemplates
});

const dispatchProps: DispatchProps = {
  updateFormFieldValue,
  previewTaxForm,
  previewDocument
};

export default connect(
  mapStateToProps,
  dispatchProps
)(OperatingWindow);
