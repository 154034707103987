import styled from 'styled-components';

const StyledContentContainer = styled.div`
  background-color: white;
  padding: ${props => props.theme.space.square.s};
  border: 1px solid ${props => props.theme.color.border.default};
  & > * {
    margin-bottom: ${props => props.theme.space.single.l};
  }
`;

export default StyledContentContainer;
