// @flow
import { type RecordOf, type OrderedMap } from 'immutable';

export type Client = {
  id: number,
  name: string,
  code: string,
  subsidiaries: Array<Client>
};

export type Job = {
  id: number | string,
  name: string,
  state: string
};

export type StaffMember = {
  id: number,
  name: string
};

export const NORMAL_FINANCIAL_INSTITUTION: 1 = 1;
export const EXTRA_FINANCIAL_INSTITUTION: 2 = 2;

export type FinancialInstitution = {
  id: number,
  name: string,
  type: typeof NORMAL_FINANCIAL_INSTITUTION | typeof EXTRA_FINANCIAL_INSTITUTION
};

type EngagementTeam = {
  id: number,
  name: string
};

type IncludedServicesType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type ServiceOrder = {
  id: number,
  isRollForwarded: boolean,
  type: number,
  additionalInformation: string,
  deadLine: Date,
  toBeConfirmed: Date,
  tradeRegistryExtraction: Date,
  includedServices: IncludedServicesType,
  clientContactPerson: string,
  hasConcernTieOut: boolean,
  tieOutScope: string,
  client: {
    id: number,
    name: string,
    state: string
  },
  clientContact: boolean,
  job: Job,
  financialInstitutions: Array<FinancialInstitution>,
  financialInstitutionsType1: Array<FinancialInstitution>,
  financialInstitutionsType2: Array<FinancialInstitution>,
  engagementTeam: Array<EngagementTeam>,
  typeLabel?: string,
  isSelected?: boolean,
  dokbot: boolean,
  fasChecklist: boolean,
  afsReportDate: Date,
  afsReportRunTime: string,
  cesReportCompletionPhase: Date
};

export type ServiceOrderType = {
  id: number,
  name: string,
  inputFieldIds?: Array<number>
};

export type ServiceOrderFilter = {
  clientIds: Array<number>,
  types: Array<number>,
  fetchSubsidiaries: boolean
};

export type ServiceOrderState = RecordOf<{
  selectedLeader: OrderedMap<StaffMember>,
  selectedClients: OrderedMap<Client>,
  clientsList: OrderedMap<Client>,
  serviceOrders: OrderedMap<ServiceOrder>,
  selectedServiceOrders: OrderedMap<ServiceOrder>
}>;

export type InputField = {
  id: number,
  key: string,
  type: string,
  label: string,
  instruction: string,
  placeholder: string,
  options?: Array<{
    label: string,
    value: string | boolean | number
  }>,
  serviceName?: string,
  isMulti?: boolean,
  removeWeekends?: boolean
};
