import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { resetError } from '../../duck/common';
import MessageBar from '../workflow/MessageBar';

export default compose(
  connect(
    state => ({
      status: state.common.error,
      errorMessages: state.common.errorMessages
    }),
    { onClose: resetError }
  ),
  withProps(({ errorMessages }) => ({
    message: errorMessages === '' ? 'Oops...something went wrong! Please try again' : errorMessages
  }))
)(MessageBar);
