// @flow
import { Record, List } from 'immutable';
import { createAction } from 'redux-actions';
import { type JobList } from '../../common/types/jobs';
import { type JobState as State } from '../types/duck';
import { type ActionType } from '../../common/types/redux';
import service from '../services/jobs';

const jobListDefaultState: JobList = List([]);

const defaultState = Record({
  jobList: jobListDefaultState,
  selectedJob: null,
  isJobListLoading: false
});

export const fetchJobList = createAction('FETCH_JOB_LIST', clientId =>
  service.fetchJobsData(clientId)
);

export const saveSelectedJobList = createAction('SAVE_SELECTED_JOB', dropdownItem => dropdownItem);

export const removeSelectedJob = createAction('REMOVE_SELECTED_JOB', dropdownItem => dropdownItem);

export default function GeneralLedgerReducer(state: State = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_JOB_LIST_PENDING': {
      return state.set('isJobListLoading', true);
    }
    case 'FETCH_JOB_LIST_REJECTED': {
      return state.set('isJobListLoading', false);
    }
    case 'FETCH_JOB_LIST_FULFILLED': {
      return state.set('jobList', payload).set('isJobListLoading', false);
    }
    case 'SAVE_SELECTED_JOB': {
      return state.set('selectedJob', payload);
    }
    case 'REMOVE_SELECTED_JOB': {
      return state.set('selectedJob', null);
    }

    case 'CLEAN_GENERAL_LEDGER_DUCK': {
      return defaultState();
    }

    default:
      return state;
  }
}
