// @flow
import React, { PureComponent } from 'react';
import { MoreVert, Publish } from '@material-ui/icons';
import styled from 'styled-components';
import Button from '../../../../common/components/Button';
import { MenuWrapper, Menu } from '../../../../common/components/DropdownMenu';
import { MenuHeader, MenuButton } from '../../../../common/components/Menu';

import type { WithMenu } from '../../../utils/containerUtils';

type Props = WithMenu & {
  companyName: string,
  sameAuraDatabaseAsParent: boolean,
  toggleAuraAsParent: Function
};

const HeaderTitle = styled.span`
  color: ${props => props.theme.color.ui.primary};
  white-space: normal;
`;

class ColumnOptions extends PureComponent<Props> {
  render() {
    const {
      menuVisibility,
      openMenu,
      closeMenu,
      companyName,
      sameAuraDatabaseAsParent,
      toggleAuraAsParent
    } = this.props;
    return (
      <MenuWrapper onMouseLeave={closeMenu} width="300px" noPadding>
        <Button onClick={openMenu} size="none" link>
          <MoreVert />
        </Button>
        <Menu visible={menuVisibility} width="300px">
          <MenuHeader>
            <HeaderTitle>{companyName}</HeaderTitle>
            <Button onClick={closeMenu} size="none" link>
              <MoreVert />
            </Button>
          </MenuHeader>
          <MenuButton
            disabled={sameAuraDatabaseAsParent}
            onClick={() => {
              toggleAuraAsParent(true);
              closeMenu();
            }}
            withIcon
          >
            <Publish />
            <div>
              <strong>Same aura database as parent</strong>
              <small>Reset values to match Head Client</small>
            </div>
          </MenuButton>
        </Menu>
      </MenuWrapper>
    );
  }
}

export default ColumnOptions;
