import { get } from 'lodash';

export const getPadding = (common = 'm') => props => {
  const size = props.size || common;
  if (props.padding) {
    return props.theme.space[props.padding][size];
  }
  return props.theme.space.square[size];
};

export const getBorderRadius = () => props =>
  props.theme.border.type[props.rounded ? 'round' : 'default'];

export const getColor = (color, saturation) => props => {
  const s = props.theme.saturation[saturation || 'primary'];
  return get(props.theme.colors, [color, s], 'currentColor');
};
