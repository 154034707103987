// @flow
import { Record, type RecordOf } from 'immutable';
import { createAction } from 'redux-actions';
import apiService from '../services/api';
import type { ActionType } from '../../common/types/redux';
import type { LosList } from '../../common/types/los';

type DefaultState = RecordOf<{
  lineOfServices: LosList
}>;

const defaultState = Record({
  lineOfServices: []
});

export const getLineOfServices = createAction('GET_LINE_OF_SERVICES', apiService.getLineOfServices);

export default function losReducer(state: DefaultState = defaultState(), action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case 'GET_LINE_OF_SERVICES_FULFILLED':
      return state.set('lineOfServices', payload);
    default:
      return state;
  }
}
