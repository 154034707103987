// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../common/components/TextLabel';
import Checkbox from '../../../common/components/checkbox/Checkbox';

type Props = {
  isSelected: boolean,
  clientName: string,
  subsidiaryCount: number,
  leader?: string,
  onChange: Function,
  id: string
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 30% auto;
`;
const ClientInfo = styled.div`
  background-color: ${props => props.theme.color.ui.light};
  padding-left: ${props => props.theme.space.single.xl};
`;
const LeaderInfo = styled.div`
  background-color: ${props => props.theme.color.background.light};
  padding-left: ${props => props.theme.space.single.xl};
`;

const Row = ({ isSelected, clientName, subsidiaryCount, leader, onChange, id }: Props) => (
  <Wrapper>
    <Checkbox selected={isSelected} onClick={() => onChange(!isSelected)} />
    <ClientInfo>
      <TextLabel bold data-cy={`client-${id}`}>
        {clientName}
        {leader && !!subsidiaryCount && ` + ${subsidiaryCount} subsidiaries`}
      </TextLabel>
    </ClientInfo>
    {leader && (
      <LeaderInfo>
        <TextLabel bold data-cy={`leader-${id}`}>{`Engagement Leader: ${leader}`}</TextLabel>
      </LeaderInfo>
    )}
  </Wrapper>
);

export default Row;
