// @flow
import { connect } from 'react-redux';
import Orders from './Orders';
import { getOrders, updateOrder, submitOrders, toggleSelectAll } from '../../duck/orders';

const Container = connect(
  state => ({
    ordersList: state.serviceOrder.orders.list.valueSeq().toList(),
    types: state.serviceOrder.orders.types,
    selectedType: state.serviceOrder.orders.selectedType,
    selectedClients: state.serviceOrder.filters.selectedClients
      .filter(client => client.isSelected)
      .valueSeq()
      .toList(),
    shouldSelectAll: state.serviceOrder.orders.shouldSelectAll,
    canMassUpdate: state.serviceOrder.orders.canMassUpdate
  }),
  { getOrders, updateOrder, submitOrders, toggleSelectAll }
)(Orders);

export default Container;
