// @flow
import React from 'react';

import { CloudDownload } from '@material-ui/icons';
import PageHeader from '../common/components/canvas/PageHeader';
import Steps from '../common/components/workflow/Steps';
import { linkNewTab } from '../common/utils/helper';
import LeaderClientSearch from './components/search/SearchContainer';
import ClientsList from './components/clients-list/ClientsListContainer';
import OrdersList from './components/service-orders/OrdersContainer';
import service from './services';

type Props = {
  history: any
};

const ServiceOrder = ({ history }: Props) => {
  const done = () => {
    history.push('/service-order/submitted');
  };
  return (
    <div>
      <PageHeader
        title="SDC Service Order Roll-Forward"
        subTitleHtml={{
          __html: `<p>You are able to roll-forward previous SDC Service Orders using this service. This service includes most common SDC Service Order types (see the listing of Service Orders which can be rolled forward ${linkNewTab(
            'here',
            'https://docs.google.com/document/d/1lLkV_9jh7PuJnVxfG6IAR2Vxx5aNlyoQaDOYpgAnl9s/edit'
          )}). Please note to be able to roll-forward Service Orders, iPower job needs to be open to be able to link the service order to job.</p>`
        }}
        getLog={service.getFinishedServiceOrders}
        activityLogTitle="Download Finished Service Orders"
        optionalAction={{
          onClick: service.getErrorServiceOrders,
          title: 'Download Error Service Orders',
          icon: CloudDownload
        }}
      />
      <Steps onDone={done}>
        <LeaderClientSearch key="leader-client-search" />
        <ClientsList key="clients-selection" />
        <OrdersList key="orders-list" />
      </Steps>
    </div>
  );
};

export default ServiceOrder;
