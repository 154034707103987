/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import TextLabel from '../../../../../common/components/TextLabel';

export type ContainerProps = {
  headClientName: string,
  subsidiaryCount: number,
  selectedAccountSystem: Object,
  handleTabChange: Function,
  setErrorMessage: Function,
  mode: string
};

const Tab = styled.button`
  width: 50%;
  background: ${props =>
    props.disable
      ? props.theme.color.ui.disabled
      : props.active
      ? props.theme.color.ui.light
      : props.theme.color.background.light};
  font-size: ${props => props.theme.fonts.size.body.m};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
const CustomTextLabel = styled(TextLabel)`
  color: ${props =>
    props.disable
      ? props.theme.color.background.white
      : props.active
      ? props.theme.color.ui.primary
      : props.theme.color.text.neutral};
`;

const CustomTabs = ({
  subsidiaryCount,
  headClientName,
  selectedAccountSystem,
  handleTabChange,
  mode,
  setErrorMessage
}: ContainerProps) => {
  const subsidiaryOnClick = () => {
    if (selectedAccountSystem) handleTabChange('subsidiaries');
    else
      setErrorMessage(
        'Cannot proceed to subsidaries tab if subsidary number is 0 or accounting system is not selected.'
      );
  };
  return (
    <>
      <Tab
        type="button"
        role="textbox"
        onClick={() => handleTabChange('head')}
        active={mode === 'head'}
      >
        <CustomTextLabel active={mode === 'head'}>{headClientName}</CustomTextLabel>
      </Tab>
      <Tab
        type="button"
        disable={!selectedAccountSystem}
        role="textbox"
        onClick={subsidiaryOnClick}
        active={mode === 'subsidiaries'}
        data-cy="subsidiary-tab"
      >
        <CustomTextLabel disable={!selectedAccountSystem} active={mode === 'subsidiaries'}>
          Subsidiaries
          {`(${subsidiaryCount})`}
        </CustomTextLabel>
      </Tab>
    </>
  );
};

export default CustomTabs;
