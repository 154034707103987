// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Button from '../Button';

const BoxWrapper = styled.div`
  margin: ${props => props.theme.space.inline.m};
  padding: ${props => props.theme.space.square.l};
  background-color: ${props => props.theme.color.background.light};
  h2 svg {
    margin-right: ${props => props.theme.space.single.m};
  }
`;

export const BoxItemWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.background.white};
  margin: ${props => props.theme.space.inline.m};
  > svg {
    margin: ${props => props.theme.space.stretch.m};
  }
  .success{
    color: ${props => props.theme.color.ui.success};
  }
  > div {
    border-left: 1px solid ${props => props.theme.color.border.default};
    padding: ${props => props.theme.space.square.s};
    flex-grow: 1;
    h3 {
      color: ${props => props.theme.color.ui.primary}
      margin: 0;
    }
  }
  > button {
    color: ${props => props.theme.color.ui.secondary}
  }
`;

type BoxProps = {
  Icon: any,
  header: string,
  subHeader?: string,
  children: Node
};

export const Box = ({ Icon, header, subHeader, children }: BoxProps) => (
  <BoxWrapper>
    {header && (
      <h2>
        {Icon && <Icon />}
        {header}
      </h2>
    )}
    {subHeader && <p>{subHeader}</p>}
    {children}
  </BoxWrapper>
);

Box.defaultProps = {
  subHeader: undefined
};

type BoxItemProps = {
  name: string,
  id?: string,
  onClick?: Function,
  Icon: string,
  status?: string
};

export const BoxItem = ({ Icon, name, id, onClick, status }: BoxItemProps) => (
  <BoxItemWrapper>
    <Icon />
    <div data-cy={`box-item-${name}`}>
      <h3>{name}</h3>
      {id && <span>{id}</span>}
      <span>
        {status &&
          ` (${status}
        )`}
      </span>
    </div>
    {status && <CheckCircle className="success" />}
    {onClick && (
      <Button link onClick={onClick}>
        Go back and make changes
      </Button>
    )}
  </BoxItemWrapper>
);

BoxItem.defaultProps = {
  onClick: undefined,
  status: undefined,
  id: ''
};
